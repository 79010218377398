import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { ModalModule } from "ngb-modal";
import { NgModule } from "@angular/core";
import { ErrorComponent } from "./error.component";

@NgModule({
    imports: [ModalModule, CommonModule, FormsModule],
    declarations: [ErrorComponent],
    exports: [ErrorComponent],
})
export class DCSErrorModule { }
