// Angular Imports
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from "@angular/common";
 import { ModalModule } from "ngb-modal";
import { DCSErrorModule } from '../error/error.module';
import { DcsDropdownModule } from '../dcs-dropdown/dcs-dropdown.module';

// This Module's Components
import { DcsValidatorModalComponent } from './dcs-validator-modal.component';
import { DcsTextboxModule } from '../dcs-textbox/dcs-textbox.module';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { DcsTextareaModule } from '../dcs-textarea/dcs-textarea.module';
import { DcsNumericModule } from '../dcs-numeric/dcs-numeric.module';
import { DcsTimeModule } from '../dcs-time/dcs-time.module';

@NgModule({
    imports: [ModalModule, CommonModule,ReactiveFormsModule,DCSErrorModule,DcsDropdownModule,DcsTextboxModule,DatePickerModule,DcsTextareaModule,DcsNumericModule],
    declarations: [
        DcsValidatorModalComponent,
    ],
    exports: [
        DcsValidatorModalComponent,
    ]
})
export class DcsValidatorModalModule {

}
