<!--component html goes here -->
<modal #myModal [ngStyle]="{ 'z-index': zindex }">
  <modal-header>
    <h5 class="modal-title">
      <i class="fa fa-bars mr-1"></i>
      Fields Configuration
    </h5>
  </modal-header>

  <modal-content>
    <div class="row" *ngIf="!hideModalToolbar">
      <div class="col-lg-12 col-md-12 px-8">
        <div [ngClass]="
              mode === 0 || mode === 1 || mode === 3
                ? 'action-button div-toolbar div-toolbar-header'
                : 'action-button'
            ">
          <div (appClickOutside)="outsideClick()">
            <ul #modalsidemenu *ngIf="isShown" class="addtional-button dropdown-menu dropdown-menu-left show"
              style="top: 20px; left: 10px; padding: 0px">
              <div *ngFor="let grp of groups" class="panel panel-default"
                style="border: none !important; margin-top: 0px !important">
                <div class="panel-heading px-2 py-1">
                  <h4 class="panel-title">
                    {{ grp }}
                  </h4>
                </div>
                <div class="panel-collapse collapse in">
                  <div class="panel-body">
                    <div *ngFor="let x of filterData(grp)">
                      <a class="dropdown-item px-2 py-1" id="{{ x.name }}" type="button"
                        (click)="screen.customNavClickBase(x); outsideClick()">
                        {{ x.caption }}</a>
                    </div>
                  </div>
                </div>
              </div>
            </ul>
          </div>
          <button *ngIf="
                (mode === 0 || mode === 1 || mode === 3) && showList === true
              " type="button" class="btn-group pull-left" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false" style="min-width: 0" (click)="dropdownToggle($event)">
            <i class="fa fa-bars"></i>
          </button>
          <button *ngIf="!hideNew && (mode == 0 || mode == 1)" type="button" class="btn-group toolbar-action"
            accesskey="n" (click)="screen.clear()">
            <i class="fa fa-plus"></i> <abbr title="Alt+n">New</abbr>
          </button>
          <button *ngIf="!hideSave && (mode == 0 || mode == 1 || mode == 3)" type="button"
            class="btn-group toolbar-action" accesskey="s" (click)="screen.save()">
            <i class="fa fa-save"></i>
            <abbr title="Alt+s">Save</abbr>
          </button>
          <button [disabled]="disabledAction" *ngIf="!hideSaveClose && (mode == 0 || mode == 1 || mode == 3)"
            type="button" class="btn-group toolbar-action" accesskey="c" (click)="saveAndClose()">
            <i class="fa fa-save"></i>
            <abbr title="Alt+c">Save &amp; Close</abbr>
          </button>
          <button *ngIf="!hideClose && (mode == 0 || mode == 1 || mode == 3)" type="button"
            class="btn-group toolbar-action" accesskey="x" (click)="closeModal()">
            <i class="fa fa-remove"></i> <abbr title="Alt+x">Close</abbr>
          </button>
          <button *ngIf="mode == 0" type="button" class="btn-group toolbar-action" (click)="deleteModalData()">
            <i class="fa fa-times"></i> Delete
          </button>
          <button *ngIf="mode == 0" type="button" class="btn-group toolbar-action" (click)="screen.moveFirst()">
            <i class="fa fa-angle-double-left"></i> First
          </button>
          <button *ngIf="mode == 0" type="button" class="btn-group toolbar-action" (click)="screen.movePrevious()">
            <i class="fa fa-angle-left"></i> Previous
          </button>
          <button *ngIf="mode == 0" type="button" class="btn-group toolbar-action" (click)="screen.moveNext()">
            Next <i class="fa fa-angle-right"></i>
          </button>
          <button *ngIf="mode == 0" type="button" class="btn-group toolbar-action" (click)="screen.moveLast()">
            Last <i class="fa fa-angle-double-right"></i>
          </button>
          <button *ngIf="mode == 0" type="button" class="btn-group toolbar-action" accesskey="h"
            (click)="showModalLister()">
            <i class="fa fa-search"></i> Search
          </button>
        </div>
      </div>
    </div>
    <form [formGroup]="myForm" novalidate>
      <div class="modal-body-content">
        <!-- <ng-content></ng-content> -->
        <div class="row" style=" padding-right: 12px;">
          <div style="padding-left: 6px;">
            <button class="btn-group" style="width: 80px; border-right: 1px solid #ddd;" (click)="closeDocModal()">
              <i class="fa fa-remove"></i>&nbsp;Close
            </button>
          </div>
          <div>
            <span class="select-title">Client :</span>
            <button type="button" class="btn-groupcust">
              <dcs-dropdown caption="Client" formControlName="userclientid" magnifierPosition="right"
                [data]="LookupData.utclient" [valueMember]="'clientid'" showCaption="false"
                [config]="lookupConfigs.utclientConfig" [displayMember]="'clientltxt'"></dcs-dropdown>
            </button>
          </div>
        </div>


        <div class="row">
          <div class="col-lg-6" style="height: 472px; overflow: hidden; overflow-y: scroll;">
            <table class="table table-bordered docfield-table">
              <colgroup>
                <col span="1" style="width: 10%;">
                <col span="1" style="width: 90%;">
              </colgroup>
              <thead>
                <tr>
                  <th scope="col" style="text-align: center;">S.No</th>
                  <th scope="col">
                    <span>Fields</span>
                    <span style="position: absolute;right: 18px;" (click)="SearchAction()">
                      <abbr title="Search Fields">
                        <i class="fa fa-search"></i>
                      </abbr>
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody *ngIf="ModuleType == '1'">
                <tr *ngIf="searchControl == true">
                  <td colspan="2">
                    <input type="text" style="height: 25px; font-size: 11px;" formControlName="searchbox"
                      class="form-control" placeholder="Search" (keyup)="InputForSearch($event);">
                  </td>
                </tr>
                <tr *ngFor="let data of fetchDataFiltered; let i = index"
                  [ngClass]="data.key == selectedKey ? 'active' : ''">
                  <td scope="row" style="text-align: center;">{{ i+1}}</td>
                  <td (click)="FieldClick(data.key);">{{ data.key }}</td>
                </tr>
              </tbody>
              <tbody *ngIf="ModuleType == '2'">
                <tr *ngIf="searchControl == true">
                  <td colspan="2">
                    <input type="text" style="height: 25px; font-size: 11px;" formControlName="searchbox"
                      class="form-control" placeholder="Search" (keyup)="InputForSearch($event);">
                  </td>
                </tr>
                <tr>
                  <td colspan="2"></td>
                </tr>
                <tr *ngFor="let data of fetchDataFiltered; let i = index"
                  [ngClass]="data.fieldname == selectedKey ? 'active' : ''">
                  <td scope="row" style="text-align: center;">{{ i+1}}</td>
                  <td (click)="FieldClick(data.fieldname);">{{ data.fieldname }}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="col-lg-6">
            <table class="table table-bordered docfield-table">
              <colgroup>
                <col span="1" style="width: 20%;">
                <col span="1" style="width: 60%;">
              </colgroup>
              <thead>
                <tr>
                  <th scope="row" colspan="2">Properties</th>
                </tr>
                <tr style="background: #f9f9f9;">
                  <th scope="row" style="color: #626262 !important; font-weight: 600;">Field Name</th>
                  <th scope="row" style="color: #626262 !important; font-weight: 600;">{{selectedKey}}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td scope="row">Caption</td>
                  <td>
                    <input class="form-control dcs-control" formControlName="caption" placeholder="Caption..."
                      autocomplete="off" />
                  </td>
                </tr>
                <tr>
                  <td scope="row">Disabled</td>
                  <td>
                    <div class="grid-design">

                      <label class="checkbox path">
                        <input type="checkbox" formControlName="disabled">
                        <svg viewBox="0 0 31 30">
                          <path
                            d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186">
                          </path>
                        </svg>
                      </label>
                    </div>
                  </td>
                </tr>
                <tr *ngIf="ModuleType == '1'">
                  <td scope="row">Masking Type</td>
                  <td>
                    <input class="form-control dcs-control" formControlName="maskingtypeid" autocomplete="off" />
                  </td>
                </tr>
                <tr>
                  <td scope="row" colspan="2">
                    <div style="display: flex; justify-content: center;">
                      <button class="btn-group indSavebtn" (click)="saveArray();"
                        style="width: 110px; font-size: 11px;">
                        <i class="fa fa-save"></i>&nbsp; Save
                      </button>

                      <button class="btn-group indSavebtn" (click)="DeleteArray();"
                        style="width: 110px; font-size: 11px; margin-left: 6px; background: #dd3939 !important;">
                        <i class="fa fa-trash"></i>&nbsp; Delete
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </form>
  </modal-content>

  <modal-footer> </modal-footer>
</modal>
<dcs-error #dcserror></dcs-error>