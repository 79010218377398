import { Component, Input } from '@angular/core';
@Component({
  selector: 'dcs-tile',
  templateUrl: 'dcs-tile.component.html',
  styleUrls: ['dcs-tile.component.scss']
})
export class DcsTileComponent {

  @Input() type: any;   // Icon Type Img Or Icon
  @Input() title: any;       // Tile Header Title
  @Input() valFirst: any = '';   // Value For First Line
  @Input() valSecond: any = '';   // Value For Second Line
  @Input() path: any;        // Url Or Icon Class
  position: any;


  ngOnInit() {
    if (this.valFirst == '') {
      this.position = "-21%";
    }
    else {
      this.position = "-29%";
    }
  }
}

