import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from "@angular/common";
import { ModalModule } from "ngb-modal";
import { NgModule } from "@angular/core";
import { DcsModalComponent } from "./dcs-modal.component";
import { DcsValidatorModalModule } from '../dcs-validator-modal/dcs-validator-modal.module';
import { DCSErrorModule } from '../error/error.module';
import { DcsDropdownModule } from '../dcs-dropdown/dcs-dropdown.module';
import { DcsDocfieldModalModule } from '../dcs-docfield-modal/dcs-docfield-modal.module';
import { DcsPrintviewerModule } from '../dcs-printviewer/dcs-printviewer.module';
// DcsDocfieldModalModule
@NgModule({
  imports: [ModalModule, CommonModule, FormsModule, DcsValidatorModalModule,
    ReactiveFormsModule, DCSErrorModule, DcsDropdownModule,DcsDocfieldModalModule,DcsPrintviewerModule],
  declarations: [DcsModalComponent],
  exports: [DcsModalComponent],
  bootstrap: [DcsModalComponent],
})
export class DcsModalModule { }
