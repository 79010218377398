<!-- ============================================================== -->
<!-- Bread crumb and right sidebar toggle -->
<!-- ============================================================== -->
<div class="page-breadcrumb p-0">
    <div class="row">
        <div class="col-12 align-self-center">
            <!-- <a (click)="homescreen()" style="cursor: pointer;"><i class="fa fa-home"></i> Home</a> -->
            <div class="d-flex align-items-center">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <!-- <ng-template ngFor let-url [ngForOf]="pageInfo?.urls" let-last="last"> -->
                        <!-- <li class="breadcrumb-item" *ngIf="!last" [routerLink]="url.url">
                                <a href='javascript:void(0)'>{{url.title}}</a>
                            </li>
                            <li class="breadcrumb-item active" *ngIf="last">{{url.title}}</li> -->
                        <!-- <i class="fa fa-angle-right" style="margin: 0 4px;"></i>
                            <span class="fs-13">{{modulename}}</span>
                            <i class="fa fa-angle-right" style="margin: 0 4px;"></i>
                            <li>{{title}}</li> -->
                        <!-- </ng-template> -->
                        <li>
                            <a class="foter-menu-icn"><img src="{{tabIcon}}" alt="">{{tab}}</a>
                            <!-- <a><i class="{{tabIcon}}"></i> {{tab}}</a> -->
                        </li>

                        <li *ngIf="tabId!==1"><i class="fa fa-angle-right" style="margin: 3px 4px;"></i><a class="onhoverchange"
                                routerLink="/menucomponent"> {{data}}</a></li>

                        <li *ngIf="title"><i class="fa fa-angle-right" style="margin: 3px 4px;"></i> {{title}}</li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</div>


<!-- ============================================================== -->
<!-- End Bread crumb and right sidebar toggle -->
<!-- ============================================================== -->

<!-- <div class="row">
    <div class="col-xs-12">
        <div class="box">
            <ol class="breadcrumb" style="float: left;margin-top: 0 !important;margin: 0;padding: 0px !important;">
                <li>
                    <a (click)="homescreen()" style="cursor: pointer;"><i class="fa fa-home"></i> Home</a>
                </li>
                <i class="fa fa-angle-right" style="margin: 0 4px;"></i>
                <li>{{modulename}}</li>
                <i class="fa fa-angle-right" style="margin: 0 4px;"></i>
                <li>{{title}}</li>

            </ol>
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                </div>
            </div>

            <router-outlet></router-outlet>
        </div>
    </div>
</div> -->
<!-- <div class="page-breadcrumb p-0">

    <a (click)="homescreen()" style="font-size: 12px;
    margin-right: 0px;
    color: #238bb2;cursor: pointer;">Home</a> >
    <a style="font-size: 12px;cursor: pointer;">{{title}}</a>
</div> -->