<dcs-dropdown
  #dropdown
  [(ngModel)]="value"
  (blur)="onBlur()"
  (keyup.backspace)="ondelete($event)"
  (keyup.delete)="ondelete($event)"
  [config]="config"
  [valueMember]="valueMember"
  [mappedField]="mappedField"
  [displayMember]="displayMember"
  [caption]="caption"
  [lookupCaption]="lookupCaption"
  [fieldName]="fieldName"
  [data]="data"
  [disabled]="disabled"
  [showCaption]="showCaption"
  [position]="position"
  [showNone]="false"
  [type]="2"
  [showTool]="true"
  (openAdditional)="openDimension($event)"
  [defaultOrderbyIs]="false"
  (onmousehover)="onOptionMouseOverent($event)"
  (onMouseOutevent)="onOptionMouseOutent($event)"
  >
</dcs-dropdown>