<ul class="row myUL">
    <li class="myList col-lg-2 col-md-3 col-sm-4 col-6" *ngFor="let item of data; let i = index" [class.selected]="item === selectedItem || i === selectedItem" (click)="menuClick(item)">
        <span class="badge {{item._class}}">
            <!-- <i class="{{item.icon}}"></i> -->
            <img *ngIf="item.image" src="{{item.image}}" class="svg-style" alt="">
            <i *ngIf="item.icon" class="icon-style {{item.icon}}"></i>
        </span>
        <span *ngIf="item.Value">
            {{item.Value}}
        </span>
        <span *ngIf="item.stxt">
            {{item.stxt}}
        </span>
    </li>
</ul>

<!-- Hello -->

<style>
    .myUL {
        list-style: none;
        display: flex;
        font-size: 11px;
        margin: 0;
        border-bottom: 1px solid #ddd;
        padding-bottom: 3px;
        padding: 0px;
        background: var(--edpbackgroundsecond);
    }
    /* .myUL:hover { */
    /* overflow: auto; */
    /* } */
    
    .myList {
        padding: 0px 5px;
        cursor: pointer;
        /* display: flex; */
        display: grid;
        grid-template-columns: 1fr 5fr;
        align-items: center;
        border-right: 1px solid #ddd;
        height: 23px;
    }
    
    .myList:hover {
        background: var(--edpdarkgray);
        color: #3e5569;
    }
    
    .badge {
        font-size: 12px !important;
    }
    
    .col-e {
        flex: 0 0 16.5%;
        max-width: 17.5%;
    }
    
    .svg-style {
        width: 24px;
        margin-top: -3px;
    }
    
    .icon-style {
        color: #3A6575;
    }
    
    .selected .icon-style {
        color: var(--edpmain);
    }
    
    .svg-style {
        filter: invert(29%) sepia(12%) saturate(1320%) hue-rotate( 166deg) brightness(97%) contrast(85%);
        width: 17px;
    }
    
    .selected .svg-style {
        filter: invert(48%) sepia(58%) saturate(928%) hue-rotate(166deg) brightness(89%) contrast(79%);
    }
</style>