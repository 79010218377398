import { Injectable } from "@angular/core";
import { AppService } from '../../../@core/service/App.Service';

@Injectable({
  providedIn: "root",
})
export class SystemPolicyService extends AppService<any> {
  constructor() {
    super("mst_SMPolicy");
  }

  async GetPolicy() {
    const result = await this.get("");
    return result;
  }

  async getsystemDaysPolicy(entityid: any, ttid: any) {
    const result = await this.get("SystemDays", [entityid, ttid]);
    return result;
  }

  async getsystemDaysNew(entityid: any, ttid: any, periodid: any) {
    let period = Number(periodid) === 0 ? "0" : Number(periodid);
    let timetable = !ttid || Number(ttid) === 0 ? "0" : Number(ttid);

    const result = await this.get("SystemDays", [entityid, timetable, period]);
    return result;
  }

  async getPolicy(entityid: any) {
    const result = await this.get("Policy", [entityid]);
    return result;
  }
  async getPolicyV2(entityid: any) {
    const result = await this.get("PolicyV2", [entityid]);
    return result;
  }
  async getClientPolicy(clientid: any) {
    const result = await this.get("ClientPolicy", [clientid]);
    return result;
  }
  async getSaveEnableSub() {
    const result = await this.post("SaveEnableSub");
    return result;
  }
}
