// Angular Imports
import { NgModule } from "@angular/core";

// This Module's Components
import { DcsDimentionComponent } from "./dcs-dimention.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { ModalModule } from "ngb-modal";
import { DcsDropdownModule } from "../dcs-dropdown/dcs-dropdown.module";

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    ModalModule,
    DcsDropdownModule,
  ],
  declarations: [DcsDimentionComponent],
  exports: [DcsDimentionComponent],
})
export class DcsDimentionModule {}
