import { AppService } from "./../../@core/service/App.Service";
import { Injectable } from "@angular/core";
import { DataTransferObject } from "../../@core/dto/DataTransferObject";

@Injectable({
  providedIn: "root",
})
export class ImageService extends AppService<any> {
  constructor() {
    super("mst_GNImage");
  }

  async uploadImage(data: any) {
    const dto: DataTransferObject<any> = <DataTransferObject<any>>{};
    dto.Data = {
      image: data,
    };
    const result = await this.post("image", dto);
    return result;
  }

  async getImage(id: any) {
    if (id) {
      const result = await this.get("image", [id, "Actual"]);
      return result;
    }
  }

  async getImageAsFile(id: any) {
    
    if (id > 0) {
      const result = await this.get("", id);
      return result;
    }
  }
}
