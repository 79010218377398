import { Component } from "@angular/core";
import { AgRendererComponent } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";


@Component({
    selector: 'gridprogressbar',


    template: `<dcs-progressbar [value]= "value"></dcs-progressbar>`,
    // styleUrls:['./gridprogressbar.css']
})
export class GridprogressbarComponent implements AgRendererComponent {

    refresh(params: any): boolean {

        return true;
    }
    agInit(params: ICellRendererParams): void {

        this.params = params;
        this.value = this.params.value;

    }

    private params: any;
    public value: any;

}