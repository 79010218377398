// Angular Imports
import { NgModule } from '@angular/core';

// This Module's Components
import { DcsCalendarComponent } from './dcs-calendar.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModalModule,
        CalendarModule.forRoot({
          provide: DateAdapter,
          useFactory: adapterFactory,
        }),
    ],
    declarations: [
        DcsCalendarComponent,
    ],
    exports: [
        DcsCalendarComponent,
    ]
})
export class DcsCalendarModule {

}
