<div class="dcs-loader"  [hidden]="!loading" >
<div class="preloader" > 
  <img class="imgcenter" src="assets/images/flash.gif" />
  <!-- <div class="lds-roller">
    <div></div>
    <div></div>
    <div></div>
  </div> -->
</div>
</div>
