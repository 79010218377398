import {
  Component,
  Input,
  ViewChild,
  AfterViewInit,
  EventEmitter,
  Output,
  forwardRef
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { AppConstants } from "src/app/@constant/app.constant";
import { ControlBase } from "src/app/@constant/config";
import { GenEnum } from "src/app/@constant/general.enum";
import { ImageService } from "src/app/shared/services/image.service";
import { LocalStorage } from "../../helper/localStorage";
import { DcsModalComponent } from "../dcs-modal/dcs-modal.component";

// import {ImageCropperComponent, CropperSettings} from 'ng2-img-cropper';
// import { ModalComponent } from "ng2-bs3-modal/components/modal";
// import { ImageService } from "../../shell/shared/image.service";
// import { BaseComponent } from "../../core/component/Base.Component";
// import { AppConstants } from "../../app.constant";
// import { GenEnum } from "../../shell/shared/general.enum";
// import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: "dcs-image",
  templateUrl: "dcs-image.component.html",
  styleUrls: ['dcs-image.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DcsImageComponent),
      multi: true
    }
  ]
})
export class DcsImageComponent extends ControlBase
  implements AfterViewInit, ControlValueAccessor {
  [x: string]: any;
  url: string = '';
  canDelete: boolean | any;

  constructor(private localStorage: LocalStorage, private imageService: ImageService) {
    super();
  }
  setDisabledState?(isDisabled: boolean): void { }

  connectConfig :any;
  imageSource: string | any;
  @Input()
  storeInUt: boolean = false;
  @Input()
  hideButtons: boolean = false;
  @Input()
  CustomOperation: boolean = false;
  @Output()
  onHitsOpen: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onHitsRemove: EventEmitter<any> = new EventEmitter<any>();

  ngAfterViewInit(): void {
    this.connectConfig = JSON.parse(this.localStorage.get('connectConfig'))
    this.imageSource = this.storeInUt
      ? this.connectConfig.urls.api.replace("api/", "") + GenEnum.Image.imageUrl
      : this.localStorage.get("OnlyUrl") + GenEnum.Image.imageUrl;

  }
  @ViewChild('modal') modal: DcsModalComponent | any
  imageChangedEvent: any = '';
  croppedImage: any = '';
  @Input()
  label: string = "Add Image";

  @Input()
  caption: string = "Add Image";

  @Input()
  editmood: boolean = true;

  @Output()
  valueChange: EventEmitter<any> = new EventEmitter<any>();

  private innerValue: any = "";
  get value(): any {

    return this.innerValue;
  }
  closeModal() {
    this.modal.customClosed()
    this.removePicture()
  }
  async closemymodal(evet:any){
    this.removePicture()
  }
  @Input() set value(v: any) {
    
    if (v !== this.innerValue && v !== null) {
      if (v) {
        this.url = "";
        this.canDelete = false;
        this.imageService.getImageAsFile(v).then((dto: any) => {
          if (dto && dto.IsSuccess && dto.Data) {
            this.url = this.imageSource + dto.Data.filename;
          } else {
            this.url = "";
          }
        });
      } else {
        // this.url = "";
        // this.canDelete = true;

        //    this.toaster.info('No image data');
      }
      this.innerValue = v;

      if (this.onChangeCallback)
        this.onChangeCallback(v);
    }

  }
  onBlur() {
    this.onTouchedCallback();

  }

  dummyImageHide: boolean = false
  writeValue(value: any) {
    if (!value) {
      this.innerValue = "";
      if (this.innerValue) {
        this.url = "";
        this.canDelete = false;
        this.imageService.getImageAsFile(this.innerValue).then((dto: any) => {
          if (dto && dto.IsSuccess && dto.Data) {
            this.url = this.imageSource + dto.Data.filename;
          } else {

          }
        });
      } else {
        this.url = "";
        this.canDelete = true;
        //    this.toaster.info('No image data');
      }
    } else if (value !== this.innerValue) {
      this.value = value;
    }
    this.croppedImage = ""
    this.imageChangedEvent = ''
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  fileChangeEvent(event: any): void {

    this.imageChangedEvent = event;
    if (this.imageChangedEvent) {
      this.disabled = false
    }
    this.dummyImageHide = true;
  }
  imageCropped(event: ImageCroppedEvent) {

    this.croppedImage = event.base64;

  }
  imageLoaded() {
    // show cropper

  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }
  openModalnew() {
    this.removePicture()
    if (this.CustomOperation == true) {
      this.onHitsOpen.emit();
    }
    else {
      this.modal.open('md');
    }
  }
  openModal() {
    if (this.CustomOperation == true) {
      this.onHitsOpen.emit();
    }
    else {
      this.modal.open('md');
    }
  }
  disabled: boolean = true
  async uploadImage(url: any) {
    this.canDelete = false;
    this.imgData = "";
    this.imgData = this.croppedImage;
    // this.data1 && this.data1.image ? this.data1.image : undefined;
    const code = this.imgData ? this.imgData.indexOf(",") : 0;

    const baseImg =
      this.imgData ? this.imgData.slice(code + 1, this.imgData.length) : ""

    const dto = await this.imageService.uploadImage(baseImg);

    if (dto && dto.IsSuccess && dto.Data) {

      this.url = this.imageSource + dto.Data.filename;

      this.value = dto.Data.Id;


      this.valueChange.emit({ value: this.value });

      this.data1 = undefined;

      if (this.cropper && this.cropper.cropcanvas) {

        this.cropper.reset();
        this.cropper.cropcanvas.nativeElement.parentElement.children[0].value = "";

      }
      this.modal.customClosed();
    } else {
      // console.log("close")
      // this.showErrors("Error in uploading image!", "", 2)
      // this.showErrors("Error in uploading image!");
    }
  }
  onInputClick(event: any) {
    event.target.value = ''
  }
  removePicture(): boolean {
    // this.url = "";
    this.croppedImage = ""
    this.value = undefined;
    this.valueChange.emit({ value: this.value });
    this.canDelete = this.url != "" ? false : true ;
    this.imageChangedEvent = ''
    this.disabled = true;

    this.onHitsRemove.emit();
    return false;
  }
  removeshowPicture(): boolean {
    this.url = "";
    this.croppedImage = ""
    this.value = undefined;
    this.valueChange.emit({ value: this.value });
    this.canDelete = true;
    this.imageChangedEvent = ''
    this.disabled = true;

    this.onHitsRemove.emit();
    return false;
  }

  // fileChangeListener(event: any) {
  //   
  //   const image: any = new Image();
  //   const file = event.base64;
  //   const fileReader: FileReader = new FileReader();
  //   
  //   fileReader.onload = () => {
  //     
  //     image.onload = () => {
  //       
  //       this.cropper.setImage(image);
  //     };
  //     // image.src = event.target.result;
  //     
  //   };
  //   fileReader.readAsDataURL(file);
  //   
  // }
}