import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { customButton, customButtonList } from '../../../@constant/config';
import { List } from "linqts";

@Component({
    selector: 'dcs-toolbar',
    templateUrl: 'dcs-toolbar.component.html',
    styleUrls: ['dcs-toolbar.component.scss']
})
export class DcsToolbarComponent {
    @Input()
    showList: boolean = false;

    @Output()
    toolbarClick: EventEmitter<customButton> = new EventEmitter<customButton>();

    @Output()
    toolbarClickList: EventEmitter<customButtonList> = new EventEmitter<customButtonList>();

    @Input()
    customButton: any = [];
    customButtonClick(item: customButton) {
        this.toolbarClick.emit(item);
    }

    menudata: customButtonList[] = [];
    @Input()
    set customButtonList(value: any) {
        this.menudata = value;
        this.loadData();
    }
    get customButtonList() {
        return this.menudata;
    }

    menugroup: any = [];

    async loadData() {
        if (this.menudata) {
          this.menugroup = new List<customButtonList>(this.menudata)
            .Select((o: any) =>
              o.grpname === "" || o.grpname === null || o.grpname === undefined
                ? "Options"
                : o.grpname
            )
            .Distinct()
            .ToArray();
        }
      }

    
    customButtonListClick(item: customButtonList) {
        this.toolbarClickList.emit(item);
    }

    filterMenu(grp: any): Array<customButtonList> {
        if (grp === "Options") {
            grp = undefined;
            return this.customButtonList.filter((o: any) => o.grpname === grp);
        } else {
            return this.customButtonList.filter((o: any) => o.grpname === grp);
        }
    }

    isShown: boolean = false;

    dropdownToggle($event: { stopPropagation: () => void }) {
        $event.stopPropagation();
        this.isShown = !this.isShown;
    }

    @HostListener("document:click", ["$event"]) clickedOutside(event: any) {
        this.isShown = false;
    }
}
