import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DownloadService } from '../../../dashboards/download.service';

@Component({
    selector: 'dcs-dashboardheader',
    templateUrl: 'dcs-dashboardheader.component.html',
    styleUrls: ['dcs-dashboardheader.component.scss']
})
export class DcsDashboardheaderComponent {
    constructor(
        private _downloadservice: DownloadService,
    ) {

    }


    @Output()
    modalClick: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    resetClickHeader: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    menuClickHeader: EventEmitter<any> = new EventEmitter<any>();


    @Input()
    title: string = ''

    @Input()
    downloadType: number = 2

    @Input()
    showRefresh: boolean = true;

    @Input()
    showDownload: boolean = true;

    @Input()
    showopenModal: boolean = true;

    @Input()
    showmenu: boolean = true;

    @Input()
    datalist: [] = []

    @Input()
    donwloadId: string = '';

    isReloadChart: boolean = false

    reloadchart() {
        this.isReloadChart = true
        setTimeout(() => {
            this.isReloadChart = false
        }, 500);
    }

    async downloadDataOrImage() {
        return await this._downloadservice.downloadData(this.donwloadId, this.title, this.downloadType)
    }

    async openModal(event: any) {
        this.modalClick.emit(event);

    }

    async resetChart(event: any) {
        this.resetClickHeader.emit(event);
    }

    async menuClick(event: any) {
        this.menuClickHeader.emit(event);
    }

}
