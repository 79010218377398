import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "SortByNumber",
})
export class SortByNumberPipe implements PipeTransform {
  transform(value: any[], order = "", column: string = ""): any[] {
    if (!value || order === "" || !order) {
      return value;
    } // no array
    if (value.length <= 1) {
      return value;
    } // array with only one item
    if (order === "asc") {
      return value.sort((a, b) => a[column] - b[column]);
    } else {
      return value.sort((a, b) => a[column] - b[column]).reverse();
    }
  }
}
