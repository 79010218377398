import { Injectable } from "@angular/core";
import { AppConstants } from "src/app/@constant/app.constant";
import { GenEnum } from "./../../@constant/general.enum";
import { UtService } from "./Ut.Service";
import { LocalStorage } from "./../helper/localStorage";
import { Observable, Subject } from "rxjs";
@Injectable({
  providedIn: "root",
})
export class MenusService extends UtService<any> {
  [x: string]: any;
  myMethod$: Observable<any> | any;
  _myMethod$: Observable<any> | any;
  private myMethodSubject = new Subject<any>();
  private currenttab = new Subject<any>();
  public dashboards: any[] = [];
  public menues: any[] = [];
  public reportmenues: any[] = [];
  protected storage!: LocalStorage;
  constructor() {
    super("utmenu");
    this.storage = AppConstants.injector.get(LocalStorage);
    this.myMethod$ = this.myMethodSubject.asObservable();
    this._myMethod$ = this.currenttab.asObservable();
  }

  async GetMenus(appcode: string) {
    const rslt = await this.get("MenusV2", [appcode]);
    return rslt.Data;
  }
  async getMenu() {
    this.menues = JSON.parse(this.storage?.get("menues"));
    this.dashboards = JSON.parse(this.storage?.get("dashboards"));

    if (!this.menues || this.menues.length === 0) {
      const result = await this.get("menu", [
        "null",
        GenEnum.Menucategory.Screens,
      ]);
      if (
        result &&
        result.IsSuccess &&
        result.Data &&
        result.Data.length &&
        result.Data.length > 0
      ) {
        this.menues = result.Data.filter(
          (i: any) => i.menucatid === GenEnum.Menucategory.Screens
        );
        this.dashboards = result.Data.filter(
          (i: any) => i.menucatid === GenEnum.Menucategory.Dashboard
        );
        this.storage?.set("menues", JSON.stringify(this.menues));
        this.storage?.set("dashboards", JSON.stringify(this.dashboards));
      }
      return this.menues;
    } else {
      return this.menues;
    }
  }
  async getMenuagainstMenuid(menuid: any) {
    this.menues = JSON.parse(this.storage?.get("menues"));
    this.dashboards = JSON.parse(this.storage?.get("dashboards"));
    if (!this.menues || this.menues.length === 0) {
      await this.getMenu();
    }
    if (this.menues && this.menues.length && this.menues.length > 0) {
      const result = this.menues.filter((i: any) => i.id === menuid)[0];
      return result;
    }
  }
  async getDashboard() {
    this.menues = JSON.parse(this.storage?.get("menues"));
    this.dashboards = JSON.parse(this.storage?.get("dashboards"));
    if (!this.dashboards || this.dashboards.length === 0) {
      await this.getMenu();
    }
    if (
      this.dashboards &&
      this.dashboards.length &&
      this.dashboards.length > 0
    ) {
      const result = this.dashboards;
      return result;
    }
  }
  async getReportMenu() {
    this.reportmenues = JSON.parse(this.storage?.get("reportmenues"));

    if (!this.reportmenues || this.reportmenues.length === 0) {
      const result = await this.get("menu", [
        "null",
        GenEnum.Menucategory.Reports,
      ]);
      if (
        result &&
        result.IsSuccess &&
        result.Data &&
        result.Data.length &&
        result.Data.length > 0
      ) {
        this.reportmenues = result.Data.filter(
          (i: any) => i.menucatid === GenEnum.Menucategory.Reports
        );
        this.storage?.set("reportmenues", JSON.stringify(this.reportmenues));
      }
      return this.reportmenues;
    } else {
      return this.reportmenues;
    }
  }
  async getReportMenuagainstMenuid(menuid: any) {
    this.menues = JSON.parse(this.storage?.get("menues"));
    this.reportmenues = JSON.parse(this.storage?.get("reportmenues"));
    if (this.menues && this.menues.length === 0) {
      await this.getReportMenu();
    }
    if (
      this.reportmenues &&
      this.reportmenues.length &&
      this.reportmenues.length > 0
    ) {
      const result = this.reportmenues.filter((i: any) => i.id === menuid)[0];
      return result;
    }
  }
  menuName(data: any) {
    // console.log(data)
    this.myMethodSubject.next(data);
  }
  tabData(tab: any) {
    this.currenttab.next(tab)
  }

  async getUTMenu() {
    const result = await this.get('menu', ['null', GenEnum.Menucategory.Screens]);
    return result
  }
  async getSubMenu(parentid:any) {
    const result = await this.get('ReportMenus', [parentid]);
    return result
  }

}
