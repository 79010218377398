<!--component html goes here -->
<quill-editor #quill [(ngModel)]="value" (onEditorCreated)="onEditorCreated($event)"></quill-editor>
<!-- (onBlur)="onBlur()" -->
<!-- <editor [(ngModel)]="value" class="DCS_EDITOR" apiKey="ec3u3t5vu8j345c0vw5qlc4m78k1q9f8swzqa3m8nlzo4mnz" [init]="{
    height: 500,
    menubar: true,
    toolbar_mode: 'wrap',
    plugins: [
    'print preview casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount tinymcespellchecker a11ychecker imagetools textpattern noneditable help formatpainter permanentpen pageembed charmap mentions quickbars linkchecker emoticons advtable export',
      'advlist autolink lists link image charmap print',
      'table preview anchor searchreplace visualblocks code',
      'fullscreen insertdatetime media paste',
      'help wordcount',
      'table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol'
    ],
    toolbar:
    'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link table anchor codesample | a11ycheck ltr rtl'

  }">
</editor> -->