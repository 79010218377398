import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild, OnInit } from '@angular/core';
import {
    CalendarEvent,
    CalendarEventAction,
    CalendarEventTimesChangedEvent,
    CalendarView,
} from 'angular-calendar';
import {
    startOfDay,
    endOfDay,
    subDays,
    addDays,
    endOfMonth,
    isSameDay,
    isSameMonth,
    addHours,
} from 'date-fns';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

const colors: any = {
    red: {
        primary: '#D1E8FF',
        secondary: '#D1E8FF',
    },
    blue: {
        primary: '#1e90ff',
        secondary: '#D1E8FF',
    },
    yellow: {
        primary: '#e3bc08',
        secondary: '#FDF1BA',
    },
};

@Component({
    selector: 'dcs-calendar',
    templateUrl: 'dcs-calendar.component.html',
    styleUrls: ['dcs-calendar.component.scss']
})
export class DcsCalendarComponent implements OnInit {
    @Input() view: string = CalendarView.Month;

    @Input() DisableAction: any = false;

    @Input() viewDate!: Date;

    @Input() locale: string = 'en';

    @Output() viewChange = new EventEmitter<CalendarView>();

    @Output() onDateChange = new EventEmitter<Date>();

    CalendarView = CalendarView;

    @ViewChild('modalContent', { static: true }) modalContent!: TemplateRef<any>;

    modalData: {
        action: string;
        event: CalendarEvent;
    } | undefined;

    actions: CalendarEventAction[] = [
        {
            label: '<i class="fas fa-fw fa-pencil-alt"></i>',
            a11yLabel: 'Edit',
            onClick: ({ event }: { event: CalendarEvent }): void => {
                this.handleEvent('Edited', event);
            },
        },
        {
            label: '<i class="fas fa-fw fa-trash-alt"></i>',
            a11yLabel: 'Delete',
            onClick: ({ event }: { event: CalendarEvent }): void => {
                this.events = this.events.filter((iEvent) => iEvent !== event);
                this.handleEvent('Deleted', event);
            },
        },
    ];

    refresh: Subject<any> = new Subject();

    @Input()
    events: CalendarEvent[] = [];


    activeDayIsOpen: boolean = false;

    constructor(private modal: NgbModal) { }

    async ngOnInit() {

    }

    dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {

        if (isSameMonth(date, this.viewDate)) {
            if ((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) || events.length === 0) {
                this.activeDayIsOpen = false;
            } else {
                this.activeDayIsOpen = true;
            }
            this.viewDate = date;
        }
        this.onDateChange.emit(date);
    }

    eventTimesChanged({
        event,
        newStart,
        newEnd,
    }: CalendarEventTimesChangedEvent): void {

        this.events = this.events.map((iEvent) => {
            if (iEvent === event) {
                return {
                    ...event,
                    start: newStart,
                    end: newEnd,
                };
            }
            return iEvent;
        });
        this.handleEvent('Dropped or resized', event);
    }

    handleEvent(action: string, event: CalendarEvent): void {

        this.modalData = { event, action };

        this.activeDayIsOpen = false;
        this.modal.open(this.modalContent, { size: 'lg' });
        this.activeDayIsOpen = false;
    }
    addEvent(): void {

        this.events = [
            ...this.events,
            {
                title: 'New event',
                start: startOfDay(new Date()),
                end: endOfDay(new Date()),
                color: colors.yellow,
                draggable: true,
                resizable: {
                    beforeStart: true,
                    afterEnd: true,
                },
            },
        ];
    }

    deleteEvent(eventToDelete: CalendarEvent) {
        this.events = this.events.filter((event) => event !== eventToDelete);
    }

    setView(view: CalendarView) {

        this.view = view;
    }

    closeOpenMonthViewDay() {

        this.activeDayIsOpen = false;
    }
}
