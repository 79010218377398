import { DcsTextareaComponent } from './dcs-textarea.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [DcsTextareaComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  exports: [DcsTextareaComponent],
})
export class 
DcsTextareaModule { }
