import { GridCheckBoxComponent } from "./checkbox.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

@NgModule({
  imports: [FormsModule, CommonModule, ReactiveFormsModule],
  exports: [GridCheckBoxComponent],
  declarations: [GridCheckBoxComponent],
})
export class CheckboxModule { }
