// Angular Imports
import { NgModule } from '@angular/core';

// This Module's Components
import { DcsToolbarComponent } from './dcs-toolbar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [
        FormsModule,ReactiveFormsModule,CommonModule
    ],
    declarations: [
        DcsToolbarComponent,
    ],
    exports: [
        DcsToolbarComponent,
    ]
})
export class DcsToolbarModule {

}
