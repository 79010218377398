import { LookupConfiguration } from "../../../@constant/config";



export const ClientConfigs = {
    lister: <LookupConfiguration[]>[
       
         {
            name: 'clientcode',
            caption: 'Code',
            width: '110px'
        },
        {
            name: 'clientltxt',
            caption: 'Client',
            width: '150px'
        },
         {
            name: 'contactperson',
            caption: 'Contact Person',
            width: '150px'
        },
        {
            name: 'contactno',
            caption: 'Contact No',
            width: '110px'
        }
    ]
};
