import { DcsTimeComponent } from './dcs-time.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DcsModalModule } from '../dcs-modal/dcs-modal.module';



@NgModule({
  declarations: [DcsTimeComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule,DcsModalModule],
  exports: [DcsTimeComponent],
})
export class DcsTimeModule { }
