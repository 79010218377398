import { HttpBackend, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

export class AppEnvironment {
  environment!: string;
  version!: string;
}
export class AppConfiguration {
  api!: string;
  reporting!: string;
  straming!: string;
}

export class AppConfigurationNEW {
  ut!: string;
  api!: string;
  reportUrl!: string;
  externalURL!: string;
  videourl!: string;
}

@Injectable({
  providedIn: "root",
})
export class EnvService extends AppConfiguration {
  public config!: AppConfigurationNEW;
  public env!: string;
  public version!: string;

  constructor(private httpBackend: HttpBackend) {
    super();
  }
  // public async loadConfigurationFiles() {
  //   const newHttpClient = new HttpClient(this.httpBackend);
  //   let data = await newHttpClient
  //     .get<AppEnvironment>("../assets/@config/env.json")
  //     .toPromise();
  //   this.env = data.environment;
  //   this.version = data.version;

  //   this.config = await newHttpClient
  //     .get<AppConfiguration>("../assets/@config/env." + this.env + ".json")
  //     .toPromise();
  // }

  public async loadConfigurationFiles(): Promise<void> {
    const newHttpClient = new HttpClient(this.httpBackend);
    const timestamp = new Date().getTime();

    try {
      const data = await newHttpClient
        .get<AppEnvironment>(`../assets/@config/env.json?t=${timestamp}`)
        .toPromise();
      this.env = data.environment;
      this.version = data.version;

      this.config = await newHttpClient
        .get<AppConfigurationNEW>(`../assets/@config/env.${data.environment}.json?t=${timestamp}`)
        .toPromise();
    } catch (error) {
      console.error('Error loading configuration files', error);
    }
  }
}
