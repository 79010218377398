import { DcsColorComponent } from './dcs-color.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [DcsColorComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  exports: [DcsColorComponent],
})
export class DcsColorModule { }
