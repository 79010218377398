import { AppService } from "../../@core/service/App.Service";
import { Injectable } from "@angular/core";
import { UtService } from "../../@core/service/Ut.Service";

@Injectable()
export class FavoriteService extends AppService<any> {
  constructor() {
    super("UTFavouritemenu");
  }

  async getFavouriteList(userid: any, mmenuid: any) {
    return await this.get("FavouriteList", [userid, mmenuid]);
  }

  async getFavouriteScreen(userid: any, mmenuid: any) {
    const result = await this.get("FavouriteScreen", [userid, mmenuid]);

    return result;
  }
}
