<modal #deletemodal id="delelte" style="z-index: 99999;">
  <modal-header>
      <h5 style="padding: 3px; font-weight:bold;" class="modal-title">{{setting.caption}}</h5>
  </modal-header>
  <modal-content>
      <h4>{{setting.message}}</h4>
  </modal-content>
  <modal-footer>
      <input type="button" class="btn btn-grid btn-btnsize" (click)="yes();" value="Yes">
      <input type="button" class="btn btn-grid btn-btnsize" (click)="no();" value="No">
  </modal-footer>
</modal>