import { DcsAdditionalTabsComponent } from "./dcs-additional-tabs.component";
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [DcsAdditionalTabsComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  exports: [DcsAdditionalTabsComponent],
})
export class DcsAdditionalTabsModule { }