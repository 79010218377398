import { Injectable } from '@angular/core';
import { AppService } from '../../@core/service/App.Service';
@Injectable()

export class utdocsubtypeassignService extends AppService<any>{

    constructor(

    ) {
        super('utdocsubtypeassign');
    }

    async GetControllerAssigned() {
        const result = await this.get('ControllerAssigned');
        return result;
    }
    async getBlockActivityActions(controllerid: any) {
        const result = await this.get('BlockActivityActions', [controllerid]);
        return result;
    }
    async getutdocsubtypedata(utdocassignid: any) {
        const result = await this.get('Subtypassignreportdoc', [utdocassignid]);
        return result;
    }
}