import { NgbAccordionConfig } from "@ng-bootstrap/ng-bootstrap";
import { AfterViewInit, Component, Input } from "@angular/core";

@Component({
  selector: "dcs-panel",
  templateUrl: "dcs-panel.component.html",
  styleUrls: ["dcs-panel.component.scss"],
  providers: [NgbAccordionConfig],
})
export class DcsPanelComponent implements AfterViewInit {
  ngAfterViewInit(): void {

  }

  @Input()
  id: any;

  @Input()
  title: any;

  @Input()
  icon: any;

  @Input()
  showSideicon: any = false;

  @Input()
  Sideicon: any;

  async ngOnInit() {
    this.showSideicon = JSON.parse(this.showSideicon);
  }
}

export class NgbdAccordionConfig {
  constructor(config: NgbAccordionConfig) {
    config.closeOthers = false;
    config.type = "info";
  }
}
