<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div
  id="main-wrapper"
  [ngClass]="{ 'show-sidebar': showMobileMenu }"
  [dir]="options.dir"
  [attr.data-theme]="options.theme"
  [attr.data-layout]="options.layout"
  [attr.data-sidebartype]="options.sidebartype"
  [attr.data-sidebar-position]="options.sidebarpos"
  [attr.data-header-position]="options.headerpos"
  [attr.data-boxed-layout]="options.boxed"
>
  <!-- ============================================================== -->
  <!-- Topbar header - style you can find in topbar.scss -->
  <!-- ============================================================== -->
  <header class="topbar" [attr.data-navbarbg]="options.navbarbg">
    <nav
      class="d-flex top-navbar navbar-expand-md"
      [ngClass]="options.navbarbg == 'skin6' ? 'navbar-light' : 'navbar-dark'"
    >
      <div class="navbar-header">
        <!-- This is for the sidebar toggle which is visible on mobile only -->
        <a
          (click)="showMobileMenu = !showMobileMenu"
          class="nav-toggler waves-effect waves-light d-block d-md-none"
          href="javascript:void(0)"
        >
          <i [ngClass]="showMobileMenu ? 'ti-close' : 'ti-menu'"></i>
        </a>
        <!-- ============================================================== -->
        <!-- Logo -->
        <!-- ============================================================== -->
        <a class="navbar-brand px-1">
          <!-- Logo icon -->
          <b class="logo-icon">
            <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
            <!-- Dark Logo icon -->
            <img
              src="assets/images/logo.png"
              alt="homepage"
              class="dark-logo"
            />
            <!-- Light Logo icon -->
            <!-- <img src="assets/images/logo-light-icon\.png" alt="homepage" class="light-logo" /> -->
            <!-- <img src="../../../assets/images/logo1.png" alt="" class="light-logo logo-width"> -->
            <img
              [src]="EntityLogo"
              onerror="this.src='../../../assets/images/logo1.png'"
              alt=""
              class="light-logo logo-width"
            />
          </b>

          <!--End Logo icon -->
          <!-- Logo text -->
          <span class="logo-text">
            <!-- dark Logo text -->
            <img
              src="assets/images/logo.png"
              alt="homepage"
              class="dark-logo"
            />
            <!-- Light Logo text -->
            <!-- <img src="../../../assets/images/logo1.png" class="light-logo logo-text-width" alt="homepage" /> -->
          </span>
        </a>
        <!-- ============================================================== -->
        <!-- End Logo -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Toggle which is visible on mobile only -->
        <!-- ============================================================== -->
        <a
          class="topbartoggler d-block d-md-none waves-effect waves-light"
          href="javascript:void(0)"
          (click)="isCollapsed = !isCollapsed"
          [attr.aria-expanded]="!isCollapsed"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
        >
          <i class="ti-more"></i>
        </a>
      </div>
      <!-- ============================================================== -->
      <!-- End Logo -->
      <!-- ============================================================== -->
      <div
        class="navbar-collapse collapse custom-header"
        id="navbarSupportedContent"
        [attr.data-navbarbg]="options.navbarbg"
        [ngbCollapse]="!isCollapsed"
      >
        <app-navigation
          (toggleSidebar)="toggleSidebarType()"
          class="w-100 w-100-header"
        ></app-navigation>
      </div>
    </nav>
    <div class="row justify-content-center menu-component">
      <div class="col-12 p-0">
        <div class="header-tile h3 d-flex align-items-baseline">
          <!-- <img src="{{dataIcon}}" class="header-icon pr-2" alt=""> -->
          <!-- <span class="fs-13">{{modulename}}</span> -->
          <span class="fs-13" *ngIf="!title && modulename">{{
            modulename
          }}</span>
          <span class="fs-13" *ngIf="title !== ''">{{ title }}</span>
        </div>
      </div>
    </div>
  </header>
  <!-- ============================================================== -->
  <!-- Left Sidebar - style you can find in sidebar.scss  -->
  <!-- ============================================================== -->

  <aside
    class="left-sidebar"
    style="top: 35px !important"
    #leftsidebar
    [attr.data-sidebarbg]="options.sidebarbg"
    (mouseover)="Logo()"
    (mouseout)="Logo()"
  >
    <!-- Sidebar scroll-->
    <div class="scroll-sidebar" [perfectScrollbar]="config">
      <app-sidebar [type]="5"></app-sidebar>
    </div>
    <!-- End Sidebar scroll-->
  </aside>
  <!-- ============================================================== -->
  <!-- End Left Sidebar - style you can find in sidebar.scss  -->
  <!-- ============================================================== -->
  <!-- ============================================================== -->
  <!-- Page wrapper  -->
  <!-- ============================================================== -->
  <div class="page-wrapper" style="padding-top: 55px">
    <!-- <app-breadcrumb></app-breadcrumb> -->

    <app-api-action
      [showAdd]="!showAdd"
      [showButtonList]="sList"
      [customButtonList]="cList"
      [showDelete]="!showDelete"
      [hidden]="!showTb"
    ></app-api-action>
    <!-- ============================================================== -->
    <!-- Container fluid  -->
    <!-- ============================================================== -->
    <div class="container-fluid p-0 pb-4">
      <!-- ============================================================== -->
      <!-- Start Page Content -->
      <!-- ============================================================== -->

      <router-outlet></router-outlet>

      <!-- ============================================================== -->
      <!-- End Start Page Content -->
      <!-- ============================================================== -->
    </div>

    <!-- ============================================================== -->
    <!-- End Container fluid  -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- footer -->
    <!-- ============================================================== -->
    <footer
      class="footer text-center fixed-bottom"
      style="
        box-shadow: 0 1px 7px rgba(0, 0, 0, 0.15) !important;
        padding: 5px 15px !important;
      "
    >
      <div class="row">
        <div class="col-4">
          <!-- <div class="breadcrumb-custom">
                        <app-breadcrumb></app-breadcrumb>
                    </div> -->
        </div>
        <div class="col-8">
          <div class="row justify-content-end">
            <div class="col-1">
              <!-- <div class="pull-left edap-logo px-2 border-right">
                                <a href="http://edap.com.pk/" target="_blank"><img class="image-fluid" src="assets/images/edap-size-2.png" /></a>
                            </div> -->
            </div>
            <div class="col-4">
              <div class="small px-2 border-right">
                All Rights Reserved by
                <b>Digicop Solutions</b>
              </div>
            </div>
            <div class="col-1">
              <div class="pull-right digicop-logo px-2">
                <a href="https://www.digicopsolutions.com" target="_blank"
                  ><img class="image-fluid" src="assets/images/digi-size-2.png"
                /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- ============================================================== -->
    <!-- End footer -->
    <!-- ============================================================== -->
  </div>
  <!-- ============================================================== -->
  <!-- End Page wrapper  -->
  <!-- ============================================================== -->

  <div class="chat-windows"></div>
</div>
<dcs-error #dcserror></dcs-error>
<app-dcsMessageBox #dcsmessagebox></app-dcsMessageBox>
