<!--component html goes here -->

<div class="col-lg-10 dsc-tile-main" style="padding: 0">
  <div class="c-lg-2 col-md-3 col-sm-4 col-xs-6">
    <div class="dashboard-tiles">
      <div class="mainlogo-div">
        <div class="dashboard-tiles-icon">
          <div class="imgdiv">
            <img *ngIf="type == 'img'" width="50" src="{{ path }}" alt="" />
          </div>
          <div class="icondiv">
            <i *ngIf="type == 'logo'" class="{{ path }}"></i>
          </div>
        </div>
      </div>
      <div class="revenue-tiles-head customMargin">
        {{ title }}
      </div>
      <div *ngIf="valFirst != ''" class="revnue-tiles-count cnt1">
        {{ valFirst }}
      </div>
      <div
        *ngIf="valSecond != ''"
        [style.margin-top]="position"
        class="revnue-tiles-count"
      >
        {{ valSecond }}
      </div>
    </div>
  </div>
</div>
