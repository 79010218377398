import { MenusService } from "../../@core/service/menus.service";
import { Component, Input } from "@angular/core";
import { NavigationExtras, Router } from "@angular/router";
import { ContainerComponent } from "../../@core/base/nav/Container.Component";
@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent extends ContainerComponent {
  showMenu = "";
  showSubMenu = "";
  sidebarnavItems: any[] = [];
  path = "";
  @Input()
  type: number = 1;
  spinner: any;
  selectedIndex: number | any;
  menuID: any;
  ParentmenuID: any;
  myarray: any = []
  isShow: boolean = true;
  constructor(private menuServise: MenusService) {
    super();
  }
  async ngOnInit() {
    if (this.type === 1) {
      const result = await this.menuServise.getDashboard();
      if (result && result.length && result.length > 0) {

        // this.sidebarnavItems = result[0].children[0].children;
        this.sidebarnavItems = result[0].children;
      } else {
        this.sidebarnavItems = [];
      }
    }

    if (this.type === 2) {
      const result = await this.menuServise.getMenu();
      if (result && result.length && result.length > 0) {
        this.sidebarnavItems = result;
      } else {
        this.sidebarnavItems = [];
      }
    }

    if (this.type === 3) {
      const result = await this.menuServise.getReportMenu();

      if (result && result.length && result.length > 0) {
        this.sidebarnavItems = result;
      } else {
        this.sidebarnavItems = [];
      }
    }
    if (this.type === 4) {
      this.myarray = [
        {
          id : 100000599399999,
          indx : 2,
          label : "Hello world",
          menucatid : 9999999,
          routeid : "Helloworld",
          webicon : "assets/ICONS/MODULES/front_office.svg"
        }
      ]
      this.sidebarnavItems = this.myarray;
      this.localStorage.set("utmenus", JSON.stringify(this.myarray));
    }
    if (this.type === 5) {
      const result = await this.menuServise.getUTMenu();

      if (result && result.IsSuccess && result.Data) {
        this.sidebarnavItems = result.Data;
        this.localStorage.set("utmenus", JSON.stringify(result.Data));
      } else {
        this.sidebarnavItems = [];
      }
    }
    let path = this.localStorage.get("activePath");
    let screenname = this.localStorage.get("activescreenname"); // getting active screenName from localstorage
    this.localStorage.set("screenname", screenname); // Setting title to localStorage
    let pre_menuid = this.localStorage.get("menuid");

    // this.router && this.router.url ? this.router.url.split("?")[0] : undefined;
    if (
      this.sidebarnavItems &&
      this.sidebarnavItems.length &&
      this.sidebarnavItems.length > 0 &&
      this.type === 2
    ) {
      let navigationExtras: NavigationExtras = {
        queryParams: {
          menuid: pre_menuid ? pre_menuid : null,
        },
        replaceUrl: false,
      };

      if (path && screenname) {
        if (pre_menuid) {
          const modulenameset = this.sidebarnavItems.filter((a:any) => a.id == pre_menuid)[0]
          this.localStorage.set("modulename", modulenameset.label);
          this.router.navigate([path], navigationExtras);
          // this.modulename = "";
          this.title = screenname;
          this.menuID = pre_menuid;
        } else {
          this.router.navigate([path]);
          // this.modulename = "";
          this.title = screenname;
          this.menuID = pre_menuid;
        }
      } else if (pre_menuid) {
        const modulenameset = this.sidebarnavItems.filter((a:any) => a.id == pre_menuid)[0]
        this.localStorage.set("modulename", modulenameset.label);
        await this.getID(pre_menuid, navigationExtras);
      } else {
        await this.getID(this.sidebarnavItems[0].id, null);
      }
    } else if (this.type === 1) {
      
      let navigationExtras: NavigationExtras = {
        queryParams: {
          menuid: this.sidebarnavItems[0].id,
        },
        replaceUrl: false,
      };
      var routechange = this.sidebarnavItems[0]
      if(routechange &&  routechange.children && routechange.children.length > 0){
        
        var finalroute = routechange.children.filter((a:any) => a.indx == 1)[0]
        if(finalroute && finalroute.routeid){

          this.router.navigate([finalroute.routeid]);
          this.showToolbar(false);
        }
        else{
          
          var finalroute0 = routechange.children[0].routeid
          this.router.navigate([finalroute0]);
          this.showToolbar(false);
        
        }
        
      }
      
      // this.router.navigate(["Dashboard/Attendance"], navigationExtras);
    }
    else if (this.type === 4) {
      
      await this.getID(this.sidebarnavItems[0].id, null);
    }
    else if (
      this.sidebarnavItems &&
      this.sidebarnavItems.length &&
      this.sidebarnavItems.length > 0 &&
      this.type === 3
    ) {
      await this.getID(this.sidebarnavItems[0].id, null);
    } else if (
      this.sidebarnavItems &&
      this.sidebarnavItems.length &&
      this.sidebarnavItems.length > 0 &&
      this.type === 5
    ) {
      await this.getID(this.sidebarnavItems[0].id, null);
    } else {
      this.router.navigate(["authentication/404"]);
    }
    this.ParentmenuID =
      this.type === 1
        ? this.sidebarnavItems[0].id
        : this.localStorage.get("menuid");
    if (this.type === 1) {
      this.menuID =
        this.type === 1
          ? this.sidebarnavItems[0].children[0].id
          : this.localStorage.get("menuid");
    }
  }
  getparentID(id: number) {
    var oldid = this.ParentmenuID;
    this.ParentmenuID = id;
    if (oldid == this.ParentmenuID) {
      this.isShow = !this.isShow;
    }
    else {
      this.isShow = true;
    }
  }
  getID(id: number, event: any) {
    this.localStorage.remove("reportCode");
    this.localStorage.remove("activePath");
    if (id > 0) {
      let navigationExtras: NavigationExtras = {
        queryParams: {
          menuid: id,
          typeid: this.type,
        },
        replaceUrl: false,
      };
      if (this.type !== 1 && this.type !== 3) {
        this.localStorage.set("menuid", id);
      } else if (this.type === 3) {
        this.localStorage.set("reportmenuid", id);
      }

      this.menuID = id;
      this.localStorage.set("menutype", this.type);
      // this.localStorage.set('modulename',);
      if (this.type === 1 && event) {
        this.router.navigate([event.routeid], navigationExtras);
      }
      else if(this.type == 4){
        this.router.navigate(["workbenchViewer"], navigationExtras);
      } 
      else if (this.type === 5) {
        this.router.navigate(["utmenu"], navigationExtras);
      } else {
        this.router.navigate(["menucomponent"], navigationExtras);
      }
    }

    // this.menuID = this.localStorage.get('menuid')
  }
  // Set module Name to LocalStorage
  setmoduleName(label: any) {
    this.localStorage.set("modulename", label);
    if (this.localStorage.get("screenname")) {
      this.localStorage.remove("screenname");
    }
  }
  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = "0";
    } else {
      this.showMenu = element;
    }
  }
  addActiveClass(element: any) {
    if (element === this.showSubMenu) {
      this.showSubMenu = "0";
    } else {
      this.showSubMenu = element;
    }
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }
  setIndex(index: number) {
    this.selectedIndex = index;
  }

  ShowChild() {

  }
}
