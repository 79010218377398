// Angular Imports
import { NgModule } from '@angular/core';

// This Module's Components
import { DcsMultiInputComponent } from './dcs-multi-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [
        FormsModule,ReactiveFormsModule,CommonModule
    ],
    declarations: [
        DcsMultiInputComponent,
    ],
    exports: [
        DcsMultiInputComponent,
    ]
})
export class DcsMultiInputModule {

}
