import { LoaderService } from "../helper/loader.service";
import { ErrorObject } from "./../dto/ErrorObject";
import { AppConstants } from "../../@constant/app.constant";
import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpResponse,
} from "@angular/common/http";
import { Observable, of, throwError } from "rxjs";

import { LocalStorage } from "../helper/localStorage";
import {
  catchError,
  delay,
  finalize,
  map,
  retryWhen,
  tap,
} from "rxjs/operators";
import { Broadcast } from "./../events/broadcast";
import { Router } from "@angular/router";
import { GenEnum } from "src/app/@constant/general.enum";
import { ErrorConfig } from "src/app/@constant/config";

@Injectable()
export class DefaultInterceptor implements HttpInterceptor {
  protected localStorage: LocalStorage;
  protected broadcast!: Broadcast;

  constructor(public router: Router, private loaderService: LoaderService) {
    this.localStorage = AppConstants.injector.get(LocalStorage);
    this.broadcast = AppConstants.injector.get(Broadcast);
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = localStorage.getItem("token");


    // Enhancement Work For Integration 
    var URLResource = this.router.url.includes('IntegratedEnquiryComp') || this.router.url.includes('IntegratedRegistrationComp');
    //URLResource = this.router.url.includes('IntegratedRegistrationComp');
    // End

    if (!token) {
      this.router.navigate(["/authentication/login"]);
    }

    const modified = req.clone({
      setHeaders: { Authorization: token ? token : "xxx" },
    });
    if(req.method == 'POST' || req.method == 'PUT'  || req.method == 'DELETE'){
      this.loaderService.isLoading.next(true);
    }
    else{
      // this.loaderService.isLoading.next(false);
    }
    return next.handle(modified).pipe(
      catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          switch ((<HttpErrorResponse>err).status) {
            case 400:
              return throwError(err);
            case 401:
              URLResource != true ? this.goto(`/authentication/login`) : false;
            default:
              return throwError(err);
          }
        } else {
          return throwError(err);
        }
      }),
      retryWhen((err) => {
        let retries = 1;
        return err.pipe(
          delay(1000),
          tap(() => { }),
          map((error) => {
            if (retries++ === 3) {
              throw error;
            }
            this.loaderService.isLoading.next(false);
            return error;
          })
        );
      }),
      finalize(() => {
        this.loaderService.isLoading.next(false);
      })
    );
  }

  private goto(url: string) {
    setTimeout(() => this.router.navigateByUrl(url));
  }
}
