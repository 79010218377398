import { Inject, Injectable } from "@angular/core";
import { AppConstants } from "../../@constant/app.constant";
import { BaseService } from "./Base.Service";

@Injectable({
  providedIn: "root",
})
export class UtService<T extends any> extends BaseService<T> {
  constructor(@Inject(String) private utController: string) {
    super(utController, localStorage.getItem('apiUrl'));
  }
}
