import { DcsDropdownComponent } from "./dcs-dropdown.component";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

@NgModule({
  declarations: [DcsDropdownComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  exports: [DcsDropdownComponent],
  providers:[]
})
export class DcsDropdownModule {}
