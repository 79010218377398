import { CommonModule } from "@angular/common";
import { MoodEditorComponent } from "./mood-editor.component";
import { MoodRendererComponent } from "./mood-renderer.component";
import { FormsModule } from "@angular/forms";
import { NgModule } from "@angular/core";
import { MoodSummaryComponent } from './mood-Summary.component';
import { MoodListerComponent } from './mood-List.component';
import { MoodListerRendererComponent } from './mood-list-rendere.component';

@NgModule({
  imports: [FormsModule, CommonModule],
  exports: [MoodRendererComponent, MoodEditorComponent, MoodSummaryComponent, MoodListerComponent, MoodListerRendererComponent],
  declarations: [MoodRendererComponent, MoodEditorComponent, MoodSummaryComponent, MoodListerComponent, MoodListerRendererComponent],
})
export class MoodComponentModule { }
