// Angular Imports
import { NgModule } from '@angular/core';

// This Module's Components
import { DcsTileComponent } from './dcs-tile.component';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [
      FormsModule,CommonModule
    ],
    declarations: [
        DcsTileComponent,
    ],
    exports: [
        DcsTileComponent,
    ]
})
export class DcsTileModule {

}
