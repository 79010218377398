import { Component, Input } from '@angular/core';

@Component({
    selector: 'dcs-progressbar',
    template: `<div class="progress" *ngIf=" type === 1" style="height: 17px;margin-bottom: 0px;margin-top: 4px;">
  <div class="progress-bar progress-bar-success" role="progressbar" aria-valuenow="value"
  aria-valuemin="0" aria-valuemax="100" [ngStyle]="{'width': value + '%'}">
    {{value}}%
  </div>
</div>



<div class="progress" *ngIf=" type === 2" style="margin-bottom: auto;height: 11px;">
  <div class="progress-bar progress-bar-success" role="progressbar" aria-valuenow="value"
  aria-valuemin="0" aria-valuemax="100" [ngStyle]="{'width': value + '%'}" style="/* margin-top: 3px; */border-radius: 10px;width: 100%;font-size: 11px;line-height: 10px;">
    {{value}}%
  </div>
</div>

<div class="progress" *ngIf=" type === 3" style="margin-bottom: auto;height: 15px;">
  <div class="progress-bar progress-bar-success" role="progressbar" aria-valuenow="value"
  aria-valuemin="0" aria-valuemax="100" [ngStyle]="{'width': value + '%' }" style="/* margin-top: 3px; */border-radius: 10px; width: 100%;padding: 2px 0; text-align:center; font-size: 11px;line-height: 10px;">
    {{value}}%
  </div>
</div>

<div class="progress progressbar-typ4" *ngIf=" type === 4" >
  <div class="progress-bar progress-bar-success" role="progressbar" aria-valuenow="value"
  aria-valuemin="0" aria-valuemax="100" [ngStyle]="{'width': value + '%' }" style="/* margin-top: 3px; */border-radius: 10px; width: 100%;text-align:center; font-size: 11px;line-height: 16px;  ">
    {{value}}%
  </div>
</div>`,
})
export class DcsProgressbarComponent {

    private _value: number = 0;
    @Input()
    set value(val: number) {
        this._value = val;
    }
    get value(): number {
        return this._value
    }



    _type: number = 1

    @Input()
    set type(type: number) {
        this._type = type;
    }
    get type(): number {
        return this._type
    }
    
}
