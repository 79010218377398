import { BaseService } from "../../service/Base.Service";
import { NavComponent } from "./Nav.Component";

export abstract class NavModalComponent<T> extends NavComponent<any> {
  constructor(private baseservice: BaseService<T>) {
    super(baseservice);
  }
  async initialize() {
    this.modalInitialize().then(() => {
      setTimeout(() => {
        if (this.controls) {
          var control: any = this.controls.filter(
            (o: any) => o["disabled"] !== true
          )[0];

          if (control && control.element) {
            control.renderer.invokeElementMethod(
              control.element.nativeElement.children[1].children[0],
              "focus",
              [event]
            );
          } else if (control && control.ixsTextBox) {
            control.ixsTextBox.nativeElement.focus();
          }
        }
      }, 500);
    }).then(()=>{
      this.forcestopLoading()
    });
  }
}
