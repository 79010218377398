import { AppConstants } from "./../../@constant/app.constant";
import { Inject } from "@angular/core";
import { Injectable } from "@angular/core";
import { DataTransferObject } from "../dto/DataTransferObject";
import { HttpHelper } from "../helper/http.Helper";
import { LocalStorage } from "../helper/localStorage";
import { HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { EnvService } from "./../../env.service";
import { GenEnum } from "src/app/@constant/general.enum";

@Injectable({
  providedIn: "root",
})
export class BaseService<T extends any> {
  headers = new HttpHeaders();
  private httpHelper: HttpHelper;
  protected localStorage: LocalStorage;
  protected attributes: any = {};
  protected attribProp: any = {};
  protected env: EnvService;

  constructor(
    @Inject(String) public controller: string,
    @Inject(String) private url: any
  ) {
    this.httpHelper = AppConstants.injector.get(HttpHelper);
    this.localStorage = AppConstants.injector.get(LocalStorage);
    this.env = AppConstants.injector.get(EnvService);
    const token = this.localStorage.get("token");
    this.headers = this.headers.append("Content-Type", "application/json");
    this.headers = this.headers.append("Authorization", token ? token : "xxx");
    this.headers = this.headers.append("Access-Control-Allow-Origin", "*");
  }

  addDefaultCondition(key: string, params: Array<any>) {
    this.attributes[key] = params;
    this.setAttributes();
  }

  setAttributes() {
    this.headers.delete("attributes");
    this.headers = new HttpHeaders();
    const token = this.localStorage.get("token");
    this.headers = this.headers.append("Content-Type", "application/json");
    this.headers = this.headers.append("Authorization", token ? token : "xxx");
    this.headers = this.headers.append("Access-Control-Allow-Origin", "*");
    if (this.attributes) {
      this.headers = this.headers.append(
        "attributes",
        "{'DefaultConditions':" + JSON.stringify(this.attributes) + "}"
      );
    }
  }

  clearCondition() {
    this.headers.delete("DefaultConditions");
    this.attributes = {};
    this.headers.delete("attributes");
    this.headers = new HttpHeaders();
  }
  async getNext(nextOf: any) {
    const dto = await this.get("next", nextOf);
    return dto;
  }

  async getPrevious(previousOf: any) {
    const dto = await this.get("previous", previousOf);

    return dto;
  }

  async getLast() {
    const dto = await this.get("last");

    return dto;
  }

  async getFirst() {
    const dto = await this.get("first");

    return dto;
  }

  async Get(key: any) {
    const dto = await this.get("", key);

    return dto;
  }

  async getAll() {
    const dto = await this.get();

    return dto;
  }

  async getreportAll(url: any) {
    const dto = await this.getreportdata(url);

    return dto;
  }

  async save(dto: DataTransferObject<any>) {
    let result;
    if (dto.KeyValue) {
      result = await this.put("", dto);
    } else {
      result = await this.post("", dto);
    }

    return result;
  }

  async delete(Id: any) {
    const dto = <DataTransferObject<T>>{
      KeyValue: Id,
      ReturnObject: true,
    };

    const result = await this.del("", dto);
    return result;
  }

  async get(method?: any, params?: Array<any>): Promise<DataTransferObject<T>> {
    return await this.httpHelper.get<DataTransferObject<T>>(
      this.getUrl(method, params),
      this.headers
    );
  }

  async getreportdata(urlreport?: any, method?: any, params?: Array<any>): Promise<DataTransferObject<T>> {
    return await this.httpHelper.get<DataTransferObject<T>>(
      // this.getUrl(method, params),
      urlreport,
      this.headers
    );
  }

  getObservable(
    method?: any,
    params?: Array<any>
  ): Observable<DataTransferObject<T>> {
    return this.httpHelper.getObservable<DataTransferObject<T>>(
      this.getUrl(method, params),
      this.headers
    );
  }

  async post(
    method: string,
    body?: DataTransferObject<T>,
    params?: Array<any>,
  ): Promise<DataTransferObject<T>> {
    return await this.httpHelper.post<DataTransferObject<T>>(
      this.getUrl(method, params),
      this.headers,
      body
    );
  }

  async put(
    method: string,
    body?: DataTransferObject<T>,
    params?: [any]
  ): Promise<DataTransferObject<T>> {
    return await this.httpHelper.put<DataTransferObject<T>>(
      this.getUrl(method, params),
      this.headers,
      body
    );
  }

  async del(
    method: string,
    body?: DataTransferObject<T>,
    params?: [any]
  ): Promise<DataTransferObject<T>> {
    return await this.httpHelper.delete<DataTransferObject<T>>(
      this.getUrl(method, params),
      this.headers,
      body
    );
  }

  private getUrl(method: string, params?: any): string {
    let result: string;

    if (!this.url) {
      this.url = this.localStorage.get("apiUrl");
    }

    result = this.url
      .concat(this.controller)
      .concat("/v2/")
      .concat(method || "");

    if (params) {
      result = result
        .concat("/")
        .concat(Array.isArray(params) ? params.join("/") : params);
    }

    return result;
  }

  async uploadFile(
    method: string,
    header: HttpHeaders,
    body?: DataTransferObject<T>,
    params?: Array<any>,
  ): Promise<DataTransferObject<T>> {
    return await this.httpHelper.post<DataTransferObject<T>>(
      this.getUrl(method, params),
      header,
      body
    );
  }
}
