import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DcsModalComponent } from '../dcs-modal/dcs-modal.component';
import { ChartType } from 'chart.js';

@Component({
    selector: 'dcs-charts',
    templateUrl: 'dcs-charts.component.html',
    styleUrls: ['dcs-charts.component.scss']
})
export class DcsChartsComponent {

    @Output()
    resetClick: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    modalClick: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    clickmanu: EventEmitter<any> = new EventEmitter<any>();

    @Input()
    title: string = ''

    @Input()
    chartlabels: [] = [];

    @Input()
    charttype: ChartType = 'bar';

    @Input()
    chartColor: [] = [];

    @Input()
    chartData: [] = [];

    @Input()
    chartDataset: [] = [];

    @Input()
    chartlegend: boolean = true;

    @Input()
    showHeader: boolean = true;

    @Input()
    donwloadId: string = '';

    @Input()
    canvasheight: any = '210px';

    @Input()
    menuDat: [] = [];

    @Input()
    datalist: [] = [];

    @Input()
    showmenu: boolean = false;



    @ViewChild('piecasegroup') piegradient?: DcsModalComponent;

    async openpieModal() {
        this.piegradient?.open('md')
    }

    async resetChart(event: any) {
        this.resetClick.emit(event);
    }

    async clickevent(event: any) {
        this.clickmanu.emit(event);
    }


    chartOption: any = {
        scaleShowValues: true,
        responsive: true,
        legend: {
            position: 'bottom',
            labels: {
                fontSize: 10,
                boxWidth: 8,
            },
            hover: { enabled: false },
            tooltips: { enabled: false },
        },
        plugins: {
            // outerLabels: {
            //     fontNormalSize: 12,
            //     fontNormalFamily: 'sans-serif',
            //   },
            //   label:{
            //     position:'outside'
            //   }
            datalabels: {
                align: 'center',
                anchor: 'center',
                color: '#fff',
                display: function (context: any) {
                    return context.dataset.data[context.dataIndex] > 10;
                },
                font: {
                    weight: 'bold',
                    size: 9,
                },
                formatter: function (value: any) {
                    return value;
                },
                scales: {
                    xAxes: [{ stacked: true, ticks: { mirror: true } }],
                    yAxes: [{
                        stacked: true,
                        ticks: {
                            beginAtZero: true,
                        }
                    }]
                },
            }
        },
    };


    barChartOptions6 = {
        scaleShowVerticalLines: false,
        responsive: true,

        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                    min: 0,
                    stepSize: 10
                },
            }]
        },
        legend: {
            position: 'bottom',
            labels: {
                fontSize: 10,
            },

        },
        plugins: {
            datalabels: {
                align: 'center',
                anchor: 'center',
                color: '#fff',
                display: function (context: any) {
                    return context.dataset.data[context.dataIndex] > 0;
                },
                font: {
                    weight: 'bold',
                    size: 11,
                },
                formatter: function (value: any) {
                    return (value);
                }
            }
        },
    };
}
