// Angular Imports
import { NgModule } from "@angular/core";

// This Module's Components
import { GridDimentionComponent } from "./dimention.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { DcsDimentionModule } from "../../../dcs-dimention/dcs-dimention.module";
import { DcsDimentionbaseModule } from "../../../dcs-dimention/dcs-dimentionbase/dcs-dimentionbase.module";

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    DcsDimentionModule,
    DcsDimentionbaseModule,
  ],
  declarations: [GridDimentionComponent],
  exports: [GridDimentionComponent],
})
export class DimentionModule {}
