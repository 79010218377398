import { Component } from "@angular/core";
import { IHeaderAngularComp } from "ag-grid-angular";

@Component({
    selector: 'app-custom-header',
    template: `
    <div>
    <div class="customHeaderLabel" style="font-size: 10px;">{{headerName}}<br><span *ngIf="subHeaderName1" style="font-size: 11px;color: #20a220 !important;">{{subHeaderName1}}</span><br><span *ngIf="subHeaderName2"  style="font-size: 11px;color: red !important;">{{subHeaderName2}}</span></div> 
    </div>
    `
})
export class CustomHeader implements IHeaderAngularComp {
    params: any;
    headerName: string = "";
    subHeaderName1: string = "";
    subHeaderName2: string = "";

    refresh(params: any): boolean {
        this.params = params;
        return true;
      }

    agInit(params: any) {
        
        this.params = params;
        this.headerName = params.displayName.split('-')[0] ? params.displayName.split('-')[0] : params.displayName;
        this.subHeaderName1 = params.displayName.split('-')[1] ? params.displayName.split('-')[1] : '';
        this.subHeaderName2 = params.displayName.split('-')[2] ? params.displayName.split('-')[2] : '';
    }
}