import {
  AfterViewInit,
  Component,
  ElementRef,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { AgEditorComponent, AgRendererComponent, ICellEditorAngularComp } from "ag-grid-angular";
import { ColDef, IAfterGuiAttachedParams, ICellRendererParams } from 'ag-grid-community';
import { LocalStorage } from '../../../../helper/localStorage';
const KEY_BACKSPACE = 8;
const KEY_DELETE = 46;
const KEY_F2 = 113;
const KEY_ENTER = 13;
const KEY_TAB = 9;

@Component({
  selector: "color-cell",
  template: `
  <input type="color" [disabled]="disabled" [(ngModel)]="value"  #input (change)="valueChange($event.target.value)"   >
  `,
})
export class GridcolorComponent implements AgRendererComponent {

  disabled: boolean = false;
  refresh(params: ICellRendererParams): boolean {
    return true;
  }
  // constructor(
  //   private localStorage: LocalStorage,
  // ) { }
  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.params.colDef.editable = this.params.colDef.editable === true ? undefined : this.params.colDef.editable;
    // this.disabled = !Boolean(this.params.colDef.editable === undefined ? true : this.params.colDef.editable);
    this.value = this.params.value;

  }
  afterGuiAttached(params?: IAfterGuiAttachedParams): void { }
  private params: any;
  public value: any;

  @ViewChild('input', { read: ViewContainerRef }) public input: any;
  getValue(): any {

    return this.params.value;
  }

  valueChange(data: any) {
    this.params.setValue(this.value);
  }

}
