import { GridMenuBoxComponent } from "./menu-box-component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { DcsNumericModule } from '../../../dcs-numeric/dcs-numeric.module';

@NgModule({
  imports: [FormsModule, CommonModule, ReactiveFormsModule, DcsNumericModule],
  exports: [GridMenuBoxComponent],
  declarations: [GridMenuBoxComponent],
})
export class MenuBoxModule { }
