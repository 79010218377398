import {AppService} from '../../../../@core/service/App.Service';
import { Injectable } from '@angular/core';

@Injectable()
export class FIPostinggrpService extends AppService<any> {

    constructor() {
        super('hdr_FIPostinggrp');
    }

    async getFIpostGrpSelected(grpid : any){
      
        const result = await this.get('PostingGrpData',grpid);
        return result;
    }

   
}