import { Injectable } from "@angular/core";
import * as XLSX from "xlsx";
import { GenEnum } from '../../@constant/general.enum';
import { saveAs } from 'file-saver';

@Injectable()
export class ExcelService {
  constructor() { }

  static toExportFileName(excelFileName: string): string {
    if (excelFileName != "") {
      return `${excelFileName}.xlsx`;
    }
    else {
      return `export_${new Date().getTime()}.xlsx`;
    }
  }

  // Previous Approch Commit because its not working when number of record in thousands Start

  // public exportAsExcelFile(json: any[], excelFileName: string, format: number): void {
  //   var wscols = [];
  //   var value = undefined;
  //   let objectMaxLength: any = {};
  //   for (let i = 0; i < json.length; i++) {
  //     value = <any>Object.keys(json[i]);
  //     for (let j = 0; j < value.length; j++) {
  //       if (typeof value[j] === "number") {
  //         objectMaxLength[j] = 10;
  //       } else {
  //         objectMaxLength[j] =
  //           objectMaxLength[j] >= value[j].length
  //             ? objectMaxLength[j]
  //             : value[j].length;
  //         wscols.push({ wch: objectMaxLength[j] + 5 });
  //       }
  //     }
  //   }

  //   const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json, {
  //     header: value,
  //     cellStyles: true,
  //   });

  //   if (wscols && wscols.length > 0) worksheet["!cols"] = wscols;

  //   const workbook: XLSX.WorkBook = {
  //     Sheets: { data: worksheet },
  //     SheetNames: ["data"],
  //   };
  //   let caption = ExcelService.toExportFileName(excelFileName);

  //   let booktype: any = format === GenEnum.FileType.Excel ? "xlsx" : "csv";
  //   XLSX.writeFile(workbook, caption, {
  //     bookType: booktype,
  //     type: "buffer",
  //     cellStyles: true,
  //   });

  // }

  // Previous Approch Commit because its not working when number of record in thousands End


/////////////////////////////New Approch/////////////////////////////////////////////
// public async exportAsExcelFile(json: any[], excelFileName: string, format: number): Promise<void> {
//   const chunkSize = 1000;
//   let currentIndex = 0;
//   let combinedData: any[] = [];
//   while (currentIndex < json.length) {
//     const chunk = json.slice(currentIndex, currentIndex + chunkSize);
//     currentIndex += chunkSize;
    
//     combinedData = combinedData.concat(chunk);
//       await this.delay(0);
//     }
//     await this.exportChunkToExcel(combinedData, excelFileName, format);
//   }
  
  // private exportChunkToExcel(chunk: any[], excelFileName: string, format: number): Promise<void> {
  //   return new Promise<void>((resolve) => {
  //     const estimatedColumnWidth = 15;
  //     const wscols = Array(chunk[0].length).fill({ wch: estimatedColumnWidth });
      
  //     const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(chunk, {
  //       cellStyles: true,
  //     });
  //     if (wscols && wscols.length > 0) worksheet["!cols"] = wscols;
  //     const workbook: XLSX.WorkBook = {
  //       Sheets: { data: worksheet },
  //       SheetNames: ["data"],
  //     };
  //     let caption = ExcelService.toExportFileName(excelFileName);
  //     let booktype: any = format === GenEnum.FileType.Excel ? "xlsx" : "csv";
  //     XLSX.writeFile(workbook, caption, {
  //       bookType: booktype,
  //       type: "buffer",
  //       cellStyles: true,
  //     });
  //     resolve();
  //   });
  // }
  
//   private delay(ms: number): Promise<void> {
//     return new Promise<void>((resolve) => setTimeout(resolve, ms));
//   }
  /////////////////////////////New Approch/////////////////////////////////////////////
  
  ////////////////////////////Latest Approach////////////////////////////////////////
  public async exportAsExcelFile(json: any[], excelFileName: string, format: number): Promise<void> {
    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');
    let caption = ExcelService.toExportFileName(excelFileName);
    let booktype: any = format === GenEnum.FileType.Excel ? "xlsx" : "csv";
    const excelBuffer: any = XLSX.write(workbook, { bookType: booktype, type: 'array',bookSST:false,compression:true });
    const data: Blob = new Blob([excelBuffer], { type: XLSX.write(workbook, { bookType: booktype, type: 'binary' }) });

    saveAs(data, caption );
  }
  ////////////////////////////Latest Approach////////////////////////////////////////
}
