import { Injectable } from "@angular/core";
import { UtService } from "../../service/Ut.Service";

@Injectable({ providedIn: "root" })
export class DCSReportConfigDtlService extends UtService<any> {
  constructor() {
    super("utreportconfigdtl");
  }

  async getreportconfig(reportid:any , userid:any) {
    const result = await this.get('reportconfig',[reportid , userid]);
    return result;
}

//   async GetReportType(code: any) {
//     const rslt = await this.get("ReportType", code);
//     return rslt;
//   }
}
