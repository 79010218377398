import { ErrorHandler, Injectable, Injector } from "@angular/core";
import { Router } from "@angular/router";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private injector: Injector, private router: Router) {}

  handleError(error: any) {
    if (!this.router) {
      this.router = this.injector.get(Router);
    }
   
    if (error) {
      {
        console.log(error);
      }

      if (error && error.message) {
        const errObject = JSON.parse(error?.message);
        if (errObject && errObject.status === 401) {
          localStorage.clear();
          this.router.navigate(["/authentication/login"]);
        }
      }
    }
  }
}
