import { CommonModule, DatePipe } from '@angular/common';
import { DcsDateComponent } from "./dcs-date.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgModule } from "@angular/core";
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';

@NgModule({
  declarations: [DcsDateComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule,DatePickerModule],
  exports: [DcsDateComponent],
  providers: [DatePipe]
})
export class DcsDateModule {}
