<!--component html goes here -->
<div class="card mb-1 cases" style="min-height: 100px;">
    <div *ngIf="showHeader" style="position: sticky; top: 0px;background: #f9f9f9;z-index: 11;">
        <dcs-dashboardheader [title]="title" (modalClick)="openpieModal()" (resetClickHeader)="resetChart($event)"
            downloadType="2" [donwloadId]="donwloadId" [showmenu]="showmenu" [datalist]="datalist"
            (menuClickHeader)="clickevent($event)"></dcs-dashboardheader>
    </div>
    <div class="chartheight">
        <canvas *ngIf="chartData && chartData.length > 0" id="{{donwloadId}}" baseChart [data]="chartData"
            [attr.height]="canvasheight" [labels]="chartlabels" [options]="chartOption" [chartType]="charttype"
            [colors]="chartColor" [legend]="chartlegend">
        </canvas>

        <canvas *ngIf="chartDataset && chartDataset.length > 0" id="{{donwloadId}}" baseChart [datasets]="chartDataset"
            [attr.height]="canvasheight" [labels]="chartlabels" [options]="barChartOptions6" [chartType]="charttype"
            [colors]="chartColor" [legend]="chartlegend">
        </canvas>
    </div>
</div>

<dcs-modal #piecasegroup mode="4" Modal="true" Caption="{{title}}">
    <div class="row">
        <div class="dcscolumn-12" id="{{donwloadId}}">
            <canvas *ngIf="chartData && chartData.length > 0" id="{{donwloadId}}" baseChart [data]="chartData"
                style="width: 100% !important; height: 100% !important;" [labels]="chartlabels" [options]="chartOption"
                [chartType]="charttype" [colors]="chartColor" [legend]="chartlegend">
            </canvas>

            <canvas *ngIf="chartDataset && chartDataset.length > 0" id="{{donwloadId}}" baseChart
                [datasets]="chartDataset" style="width: 100% !important; height: 100% !important;"
                [labels]="chartlabels" [options]="barChartOptions6" [chartType]="charttype" [colors]="chartColor"
                [legend]="chartlegend">
            </canvas>
        </div>
    </div>
</dcs-modal>