import { SortByNumberPipe } from "./SortByNumber.pipe";
import { SortByTextPipe } from "./SortBy";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

@NgModule({
  imports: [CommonModule],
  declarations: [SortByTextPipe, SortByNumberPipe],
  exports: [SortByTextPipe, SortByNumberPipe],
})
export class PipeModule {}
