import { DcsNumericComponent } from './dcs-numeric.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [DcsNumericComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  exports: [DcsNumericComponent],
})
export class DcsNumericModule { }
