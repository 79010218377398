<!--component html goes here -->
<button id="item.Id" style="padding-right: 10px" *ngFor="let item of buttons ; let i = index" type="button" class="btn btn-sm btn-outline-primary attendance-button" [class.active]="date && this.params.data[date] === i+1" (click)="onClick(item)" [ngStyle]="{'background-color': date && this.params.data[date] === i+1 ? item.colorcode : ''}"
    [style.border]="'1px solid ' + getColor(item,i)">
  {{ item.Caption }}
</button>
<style>
    /* Cell Button */

    .attendance-button {
        height: 20px;
        padding: 0px 0px !important;
        margin: 1px;
        border: 1px solid #1B81AB;
        color: #2188B0;
        cursor: pointer;
        min-width: 23px;
        border-radius: 4px;
    }

    .attendance-button:hover {
        background: #2188B0;
    }

    .btn-outline-primary:not(:disabled):not(.disabled):active,
    .btn-outline-primary:not(:disabled):not(.disabled).active,
    .show>.btn-outline-primary.dropdown-toggle {
        color: #fff;
        background-color: #2188B0;
        border-color: #2188B0;
    }

    .attendance-button:focus {
        box-shadow: none;
    }

    .btn-outline-primary:not(:disabled):not(.disabled):active:focus,
    .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show>.btn-outline-primary.dropdown-toggle:focus {
        box-shadow: none !important;
    }
    /* Cell Button */
</style>
