<!-- commented by rana zeeshan on 14/april/2021 -->
<!-- <table class="myTable">
    <thead>
        <tr>
            <td colspan="4">
                <label class="label-multi-input">{{ caption }}</label>
            </td>
        </tr>
    </thead>
    <tr>
        <td [ngStyle]="{ 'width' : numericWidth }">
            <ng-content select="[numeric]"></ng-content>
        </td>
        <td [ngStyle]="{ 'width' : dropdownWidth }">
            <ng-content select="[dropdown]"></ng-content>
        </td>
    </tr>
</table> -->

<div class="multi-input">
    <label class="multi-input-label">{{caption}}</label>
    <!-- [ngStyle]="{'grid-template-columns': numericWidth + 'fr' + dropdownWidth + 'fr'}" -->
    <div class="controls">
        <ng-content select="[numeric]"></ng-content>
        <ng-content select="[dropdown]"></ng-content>
    </div>
</div>
<style>
    .myTable {
        width: 100%;
        line-height: 0.5;
        margin-top: 7px;
    }
    
    .label-multi-input {
        margin-bottom: 5px !important;
    }
    
    .controls {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
</style>