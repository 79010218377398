import { ExpenseService } from './../../../../modules/Cash-Bank/Cash/Transaction/expense/expense.service';
// Angular Imports
import { NgModule } from '@angular/core';

// This Module's Components
import { FiDimensionbaseComponent } from './dcs-dimentionbase.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DcsDateModule } from '../../dcs-date/dcs-date.module';
import { DcsTextboxModule } from '../../dcs-textbox/dcs-textbox.module';
import { DcsNumericModule } from '../../dcs-numeric/dcs-numeric.module';
import { DcsDropdownModule } from '../../dcs-dropdown/dcs-dropdown.module';
import { DcsCheckboxModule } from '../../dcs-checkbox/dcs-checkbox.module';
import { DcsTimeModule } from '../../dcs-time/dcs-time.module';
import { DCSDimensionbaseService } from './dcs-dimentionbase.service';
import { DCSCustomService } from '../../dcs-report/dcs-custom.service';

@NgModule({
    imports: [
        ReactiveFormsModule, CommonModule, DcsDateModule, DcsTextboxModule, DcsNumericModule, DcsDropdownModule, DcsCheckboxModule, DcsTimeModule, FormsModule
    ],
    declarations: [
        FiDimensionbaseComponent,
    ],
    exports: [
        FiDimensionbaseComponent,
    ],
    providers: [DCSDimensionbaseService, DCSCustomService,ExpenseService]
})
export class DcsDimentionbaseModule {

}
