import { Component, ViewChild } from "@angular/core";
import { AgRendererComponent } from "ag-grid-angular";
import {
  IAfterGuiAttachedParams,
  ICellRendererParams,
} from "ag-grid-community";
import { element } from "protractor";

@Component({
  selector: "time-editor-cell",
  template: `
    <dcs-time
      #input
      class="grid-time"
      (changeTime)="valueChange($event.target.value)"
      (selectedTime)="selectedTime($event)"
      [(ngModel)]="value"
    ></dcs-time>
  `,
  styles: ["border:none;"],
})
export class GridTimePickerComponent implements AgRendererComponent {
  refresh(params: any): boolean {
    return true;
  }
  agInit(params: ICellRendererParams): void {
    setTimeout(() => {
      this.params = params;

      if (
        this.input &&
        this.input.element &&
        this.input.element.nativeElement
      ) {
        const element =
          this.input.element.nativeElement.children[0].children[1];

        if (
          element &&
          element.children &&
          element.children.length &&
          element.children.length > 0
        ) {
          element.style.marginTop = "-24px";
          // element.children[0].classList.add("grid-time-control");
          // element.children[1].classList.add("grid-time-clock");
          // element.children[0].style.border = "none";
        }

        this.input.element.nativeElement.children[0].children[1].children[0].disabled =
          !Boolean(
            this.params.colDef.editable === undefined
              ? true
              : this.params.colDef.editable
          );
      }

      this.value = this.params.value;

      const element1: HTMLElement = this.input.element.nativeElement;
      if (element1) {
        element1.getElementsByTagName("input")[0].style.backgroundColor =
          "transparent";
      }
    }, 0);

    setTimeout(async () => {
      if (
        this.params &&
        this.params.colDef &&
        this.params.colDef.focus &&
        this.params.colDef.focus === true
      ) {
        await this.setControlFocused();
      }
    }, 0);
  }

  selectedTime(time: any) {
    this.params.setValue(time);
  }

  afterGuiAttached(params?: IAfterGuiAttachedParams): void {}
  private params: any;
  public value: any;
  private cancelBeforeStart: boolean = false;

  @ViewChild("input") public input: any;

  getValue(): any {
    return this.params.value;
  }

  valueChange(data: any) {
    if (data) this.params.setValue(data);
    else this.params.setValue(this.value);
  }

  setControlFocused() {
    setTimeout(() => {
      if (
        this.input &&
        this.input.element &&
        this.input.element.nativeElement &&
        this.input.element.nativeElement.children
      ) {
        this.input.element.nativeElement.children[0].children[1].children[0].focus();
      }
    }, 0);
  }
}
