import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DcsDropdownComponent } from "./../dcs-dropdown/dcs-dropdown.component";
import {
  FiDimensionbaseComponent,
  DimensionValue,
} from "./dcs-dimentionbase/dcs-dimentionbase.component";
import {
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  Renderer2,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { LookupConfiguration } from "../../../@constant/config";
import { ModalComponent, ModalManager } from "ngb-modal";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { CellMouseOverEvent } from "ag-grid-community";

@Component({
  selector: "dcs-dimention",
  templateUrl: "dcs-dimention.component.html",
  styleUrls: ["dcs-dimention.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DcsDimentionComponent),
      multi: true,
    },
  ],
})
export class DcsDimentionComponent implements ControlValueAccessor {
  @ViewChild("dimensionbaseviewer") public dimensionViewer?: ModalComponent;
  @ViewChild("dimensionbase", { read: ViewContainerRef }) container: any;
  @ViewChild("dropdown", { static: false }) dropdown!: DcsDropdownComponent;

  @Output()
  afterDimenstionSelected: EventEmitter<Array<DimensionValue>> =
    new EventEmitter<Array<DimensionValue>>();

  @Output()
  onOpenDimenstion: EventEmitter<Array<DimensionValue>> = new EventEmitter<
    Array<DimensionValue>
  >();

  @Output()
  onOptionMouseOver: EventEmitter<Array<DimensionValue>> = new EventEmitter<any>();

  @Output()
  onOptionMouseOut: EventEmitter<Array<DimensionValue>> = new EventEmitter<any>();

  componentRef: any;
  modalRef: any;
  constructor(
    private element: ElementRef,
    private renderer: Renderer2,
    private resolver: ComponentFactoryResolver,
    private modalService: NgbModal
  ) { }

  public triggerChanged() {
    let event = new CustomEvent("change", { bubbles: true });
  }

  private innerValue: any = "";

  private onTouchedCallback!: () => void;
  private onChangeCallback!: (_: any) => void;

  @Input()
  get value(): any {
    return this.innerValue;
  }

  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  onBlur() {
    this.onTouchedCallback();
  }

  writeValue(value: any) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  // @ViewChild("lookup") public lookup: LookupComponent;

  @Input()
  config: LookupConfiguration[] = [];

  @Input()
  valueMember: string = "";

  @Input()
  mappedField: string = "";


  @Input()
  displayMember: string = "";

  @Input()
  caption: string = "";

  @Input()
  lookupCaption: string = "";

  @Input()
  fieldName: string = "";

  _data: any[] = [];
  get data(): any[] {
    return this._data;
  }

  @Input()
  set data(value: any[]) {
    this._data = value;
  }

  @Input()
  disabled: boolean = false;

  @Input()
  showCaption: boolean = true;

  async Modelchange() {
    this.value = Number(this.value);
  }
  ondelete(event: any) {
    this.value = "";
  }
  @Input()
  position: number = 1;

  @Input()
  showNone: boolean = false;

  _defaultValues?: Array<DimensionValue>;
  get Values(): Array<DimensionValue> {
    return this.componentRef.instance.Values;
  }


  @Input()
  set Values(value: Array<DimensionValue>) {
    this._defaultValues = value;
  }

  get title() {
    return this.caption.replace("*", "");
  }

 
  public onOptionMouseOverent = (event:any) => {
    this.onOptionMouseOver.emit(event);
  };
  public onOptionMouseOutent = (event:any) => {
    this.onOptionMouseOut.emit(event);
  };
  public async openDimension(selectedValue: Number) {
    
    if (selectedValue) {
      this.onOpenDimenstion.emit();
      const modalRef = this.modalService.open(FiDimensionbaseComponent, {
        size: "md",
        centered: false,
      });

      modalRef.componentInstance.Values = this._defaultValues;
      modalRef.componentInstance.Values = this._defaultValues;
      modalRef.componentInstance.getDimension(selectedValue);

      modalRef.componentInstance.afterDimenstionSelected.subscribe((o: any) => {
        this.afterDimenstionSelected.emit(o);
        this.modalService.dismissAll();
      });
    }
  }
}
