<!-- *ngIf="type === 1" -->
<!-- <div> -->
<ul class="nav nav-tabs">

  <li *ngFor="let tab of tabs" id="{{tab.ElementId}}" (click)="selectTab(tab)" [class.active]="tab.active">
    <span *ngIf="!tab.hide">
      <i class="{{tab.icon}}"></i>
      {{ tab.title }}
    </span>
  </li>
</ul>
<ng-content></ng-content>
<!-- </div> -->
<!-- <div class="row" *ngIf="type === 2" [ngClass]="type==2 ? 'verticle-tabs' : ''" >
    <div class="col-lg-3 col-md-4 col-sm-12 col-xs-12">
        <ul class="nav nav-tabs" style="display: grid !important;">
            <li *ngFor="let tab of tabs" (click)="selectTab(tab)" [class.active]="tab.active">
                <i class="{{tab.icon}}"></i>
                MY {{ tab.title }}
            </li>
        </ul>
    </div>
    <div class="col-lg-9 col-md-8 col-sm-12 col-xs-12">
        <ng-content></ng-content>
    </div>
</div> -->
