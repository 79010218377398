// Template ka sab comment he kyun k errors arhe hain template.component ki file me
import { StudentBirthdayComponent } from "./student-birthday.component";
import { Routes } from "@angular/router/router";
import { ModuleWithProviders } from "@angular/core/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { SMStudentinfoService } from "../../modules/Student/Profilling/studentprofile/studentprofile.service";
import { TemplateService } from '../../modules/Front-Office/Communication/Message/template/template.service';
import { SMMessageService } from "../../modules/Front-Office/Communication/Message/message/message.service";
// import { TemplateComponent } from '../../modules/Front-Office/Communication/Message/template/template.component';
import { NgModule } from "@angular/core";
import { ComponentModule } from "../../@core/components/components.module";
import { StudentbirthdayService } from "./student-birthday.service";
import { StudentguardianService } from "src/app/modules/Student/Profilling/studentprofile/student-additional-info/Additional-Component/studentguardian/studentguardian.service";
// import { TemplateService } from 'src/app/modules/Front-Office/Communication/Message/template/template.service';
import { AttachmentModule } from '../../modules/shared-modals/SMAttachment/attachment/attachment.module';
const routes: Routes = [{ path: "", component: StudentBirthdayComponent }];
export const routing: ModuleWithProviders<any> = RouterModule.forChild(routes);

@NgModule({
  imports: [FormsModule, ReactiveFormsModule, CommonModule, ComponentModule,AttachmentModule],

  declarations: [StudentBirthdayComponent],
  exports: [StudentBirthdayComponent],
  providers: [
    SMStudentinfoService,
    TemplateService,
    //need to open I commented because errors are coming from template screen
    SMMessageService,
    StudentguardianService,
    StudentbirthdayService,
    AttachmentModule,
  ],
})
export class studentbirthmodule {}
