import { ApiActionComponent } from "./api-action.component";
import { ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

@NgModule({
  imports: [CommonModule, ReactiveFormsModule],
  exports: [ApiActionComponent],
  declarations: [ApiActionComponent],
  providers: [],
})
export class ApiActionModule {}
