<div class="div-toolbar responsive-toolbar">
  <button
    [hidden]="showButtonList"
    type="button"
    class="btn-group pull-right"
    data-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="false"
    style="min-width: 0"
  >
    <i class="glyphicon glyphicon-align-justify"></i>
  </button>
  <button
    [hidden]="showAdd"
    type="button"
    class="btn-group toolbar-action left-bor"
    (click)="toolbarEvent.boardcast('clear')"
    accesskey="n"
  >
    <i class="fa fa-plus"></i> <b><abbr title="Alt+n">New</abbr></b>
  </button>
  <button
    type="button"
    class="btn-group toolbar-action"
    (click)="toolbarEvent.boardcast('save')"
    accesskey="q"
  >
    <i class="fa fa-save"></i> <b><abbr title="Alt+q">Save</abbr></b>
  </button>
  <button
    [hidden]="showDelete"
    type="button"
    class="btn-group toolbar-action"
    (click)="toolbarEvent.boardcast('delete')"
    accesskey="d"
  >
    <i class="fa fa-trash"></i> <b><abbr title="Alt+d">Delete</abbr></b>
  </button>
  <button
    type="button"
    class="btn-group toolbar-action"
    (click)="toolbarEvent.boardcast('first')"
    accesskey="f"
  >
    <i class="fa fa-angle-double-left"></i>
    <b><abbr title="Alt+f">First</abbr></b>
  </button>
  <button
    type="button"
    class="btn-group toolbar-action"
    (click)="toolbarEvent.boardcast('previous')"
    accesskey="p"
  >
    <i class="fa fa-angle-left"></i> <b><abbr title="Alt+p">Previous</abbr></b>
  </button>
  <button
    type="button"
    class="btn-group toolbar-action-last"
    (click)="toolbarEvent.boardcast('next')"
    accesskey=">"
  >
    <b><abbr title="Alt+>">Next</abbr></b> <i class="fa fa-angle-right"></i>
  </button>
  <button
    type="button"
    class="btn-group toolbar-action-last"
    (click)="toolbarEvent.boardcast('last')"
    accesskey="l"
  >
    <b><abbr title="Alt+l">Last</abbr></b>
    <i class="fa fa-angle-double-right"></i>
  </button>
  <button
    type="button"
    class="btn-group toolbar-action-last"
    (click)="toolbarEvent.boardcast('search')"
    accesskey="h"
  >
    <i class="fa fa-search"></i> <b><abbr title="Alt+h">Search</abbr></b>
  </button>
  <ul class="addtional-button dropdown-menu dropdown-menu-right">
    <div
      *ngFor="let grp of groups"
      class="panel panel-default"
      style="border: none !important; margin-top: 0px !important"
    >
      <div class="panel-heading px-2 py-1">
        <h4 class="panel-title">
          {{ grp }}
        </h4>
      </div>
      <div class="panel-collapse collapse in">
        <div class="panel-body">
          <div *ngFor="let x of filterData(grp)">
            <a
              class="dropdown-item px-2 py-1"
              id="{{ x.name }}"
              (click)="toolbarEvent.boardcast('customButtonClick', x)"
            >
              {{ x.caption }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </ul>
</div>
