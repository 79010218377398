import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { moodConfig } from "../../../../../@constant/config";
@Component({
  selector: "mood-cell",
  template: `
      <i id="moodcell"  style="margin-left: 3px; cursor: pointer;  color: #1764A1;" [ngClass]="icon ? icon : ''"
        ><a >{{ innervalue ? "    " + innervalue : "" }}</a></i
      >
    `,
  styles: [
    // "a{font-Family:'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;color:black;font-weight:400}"
  ]
})
export class MoodRendererComponent implements ICellRendererAngularComp {
  private params: any;
  public innervalue: string = "";
  private data: moodConfig[] = [];
  private valueMember: any;
  private displayMember: any;
  private column: any;
  icon: any;
  private isMenu: boolean = false;
  agInit(params: any) {
    this.params = params;
    this.column = params.column;
    this.loadData();
    this.setvalue(params.value);
  }
  refresh(params: any): boolean {
    this.params = params;
    this.column = params.column;
    this.loadData();
    if (params.value) {
      this.setvalue(params.value);
    }
    return true;
  }
  private setvalue(value: any) {
    if (this.data && value) {
      const fil: any = this.data.filter(
        (o: any) => String(o[this.valueMember]) === String(value)
      )[0];
      if (!this.isMenu) {
        this.innervalue = fil[this.displayMember];
        this.icon = fil["moodIcon"];
      } else {
        this.innervalue = "";
        this.icon = "fa fa-bars ";
      }
    } else {
      this.innervalue = "";
      this.icon = this.isMenu ? "fa fa-bars " : this.isMenu === false ? "fa fa-cogs" : "";
    }
  }
  private loadData() {
    this.valueMember = this.column.colDef.dataValueField;
    this.displayMember = this.column.colDef.dataDisplayField;
    this.isMenu = Boolean(this.column.colDef.isMenu);
    this.data = this.column.colDef.dropdownData;
  }
}