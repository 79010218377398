import { DCSLoaderModule } from './dcs-loader/dcs-loader.module';
import { DcsDimentionbaseModule } from "./dcs-dimention/dcs-dimentionbase/dcs-dimentionbase.module";
import { DcsPrintviewerModule } from "./dcs-printviewer/dcs-printviewer.module";
import { DcsLookupModule } from "./dcs-lookup/dcs-lookup.module";
import { GridDropDownModule } from "./dcs-aggrid/controls/dropdown-component/dropdown.module";
import { CheckboxModule } from "./dcs-aggrid/controls/checkbox-component/checkbox.module";
import { MoodComponentModule } from "./dcs-aggrid/controls/mood-component/mood-component.module";
import { NumericModule } from "./dcs-aggrid/controls/numeric-component/numeric.module";
import { colorModule } from "./dcs-aggrid/controls/color-component/color.module";
import { MenuBoxModule } from "./dcs-aggrid/controls/menu-box-component/menu-box.module";
import { BrowserModule } from "@angular/platform-browser";
import { ProgressbarModule } from "./dcs-aggrid/controls/progressbar-component/progressbar.module";
import { DcsTimeModule } from "./dcs-time/dcs-time.module";
import { DcsTextboxModule } from "./dcs-textbox/dcs-textbox.module";
import { DcsSearchboxModule } from "./dcs-searchbox/dcs-searchbox.module"
import { DcsTextareaModule } from "./dcs-textarea/dcs-textarea.module";
import { DcsprogressbarModule } from "./dcs-progressbar/dcs-progressbar.module";
import { DcsNumericModule } from "./dcs-numeric/dcs-numeric.module";
import { DcsDropdownModule } from "./dcs-dropdown/dcs-dropdown.module";
import { DcsCheckboxModule } from "./dcs-checkbox/dcs-checkbox.module";
import { DcsRadioButtonModule } from "./dcs-radio/dcs-radio.module";
import { GridTimePickerModule } from "./dcs-aggrid/controls/time-component/time.module";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { AgGridModule } from "ag-grid-angular";
import { GridDropDownComponent } from "./dcs-aggrid/controls/dropdown-component/dropdown.component";
import { GridTimePickerComponent } from "./dcs-aggrid/controls/time-component/time.component";
import { GridprogressbarComponent } from "./dcs-aggrid/controls/progressbar-component/progressbar.component";
import { GridNumericComponent } from "./dcs-aggrid/controls/numeric-component/numeric.component";
import { MoodEditorComponent } from "./dcs-aggrid/controls/mood-component/mood-editor.component";
import { MoodRendererComponent } from "./dcs-aggrid/controls/mood-component/mood-renderer.component";
import { GridCheckBoxComponent } from "./dcs-aggrid/controls/checkbox-component/checkbox.component";
import { GridActionsComponent } from "./dcs-aggrid/controls/action-component/action.component";
import { GridDatePickerComponent } from "./dcs-aggrid/controls/date-component/date.component";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { DCSAggridModule } from "./dcs-aggrid/dsc-aggrid.module";
import { DcsDateModule } from "../components/dcs-date/dcs-date.module";
import { DcsModalModule } from "../components/dcs-modal/dcs-modal.module";
import { DCSErrorModule } from "../components/error/error.module";
import { DCSMessageBoxModule } from "../components/MessageBox/MessageBox.module";
import { ApiActionModule } from "./api-action/api-action-module";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { DcsEditorModule } from "./dcs-editor/dcs-editor.module";
import { DcsTabsModule } from "./dcs-tabs/dcs-tabs.module";
import { DcsTabSetModule } from "./dcs-tabs/dcs-tabset.module";
import { GridDatePickerModule } from "./dcs-aggrid/controls/date-component/date.module";
import { DcsPanelModule } from "./dcs-panel/dcs-panel.module";
import { DcsImageModule } from "./dcs-image/dcs-image.module";
import { DcsColorModule } from "./dcs-color/dcs-color.module";
import { DcsMultiInputModule } from "../components/dcs-multi-input/dcs-multi-input.module";
import { DcsToolbarModule } from "./dcs-toolbar/dcs-toolbar.module";
import { DcsTileModule } from "../components/dcs-tile/dcs-tile.module";
import { DcsCalendarModule } from "./dcs-calendar/dcs-calendar.module";
import { DcsAdditionalTabsModule } from "./dcs-tabs-additional/dcs-additional-tabs.module";
import { DcsDimentionModule } from "./dcs-dimention/dcs-dimention.module";
import { GridDimentionComponent } from "./dcs-aggrid/controls/dimention/dimention.component";
import { DimentionModule } from "./dcs-aggrid/controls/dimention/dimention.module";
import { CellButtonsComponent } from "./dcs-aggrid/controls/cell-buttons/cell-buttons.component";
import { DcsLabelModule } from "./dcs-label/dcs-label.module";
import { MoodSummaryComponent } from './dcs-aggrid/controls/mood-component/mood-Summary.component';
import { MoodListerComponent } from './dcs-aggrid/controls/mood-component/mood-List.component';
import { MoodListerRendererComponent } from './dcs-aggrid/controls/mood-component/mood-list-rendere.component';
import { customHeaderModule } from './dcs-aggrid/controls/Custom-Header/customHeader.module';
import { CustomHeader } from './dcs-aggrid/controls/Custom-Header/customHeader.component';
import { DcsMultiselectDropdownModule } from './dcs-multiselect-dropdown/dcs-multiselect-dropdown.module';
import { DcsChartsModule } from './dcs-charts/dcs-charts.module';
import { DcsDashboardheaderModule } from './dcs-dashboardheader/dcs-dashboardheader.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    CheckboxModule,
    NumericModule,
    colorModule,
    MoodComponentModule,
    DcsDateModule,
    DcsCheckboxModule,
    DcsDropdownModule,
    DcsMultiselectDropdownModule,
    DcsNumericModule,
    DcsTimeModule,
    DcsprogressbarModule,
    DcsTextareaModule,
    DcsTextboxModule,
    DcsSearchboxModule,
    ProgressbarModule,
    DcsRadioButtonModule,
    DcsTabsModule,
    DcsPanelModule,
    DcsImageModule,
    DcsTileModule,
    MenuBoxModule,
    DcsChartsModule,
    DcsDashboardheaderModule,
    AgGridModule.withComponents([
      GridDropDownComponent,
      CellButtonsComponent,
      GridNumericComponent,
      GridDatePickerComponent,
      GridTimePickerComponent,
      GridActionsComponent,
      MoodEditorComponent,
      MoodSummaryComponent,
      MoodListerComponent,
      MoodListerRendererComponent,
      MoodRendererComponent,
      GridDropDownModule,
      GridDatePickerModule,
      GridCheckBoxComponent,
      GridprogressbarComponent,
      GridDropDownComponent,
      GridDimentionComponent,
      CustomHeader,
      customHeaderModule,
      DimentionModule,
    ]),
    DcsModalModule,
    DCSAggridModule,
    DcsLookupModule,
    DCSLoaderModule,

    DCSErrorModule,
    DCSMessageBoxModule,
    ApiActionModule,
    NgbModule,
    DcsPrintviewerModule,
    DcsEditorModule,
    DcsTabSetModule,
    DcsColorModule,
    DcsMultiInputModule,
    DcsToolbarModule,
    DcsAdditionalTabsModule,
    DcsDimentionbaseModule,
    DcsDimentionModule,
    DcsLabelModule,
  ],
  declarations: [],
  exports: [
    DCSAggridModule,
    CheckboxModule,
    NumericModule,
    colorModule,
    MoodComponentModule,
    DcsDateModule,
    DcsCheckboxModule,
    DcsDropdownModule,
    DcsMultiselectDropdownModule,
    DcsNumericModule,
    DcsTimeModule,
    DcsprogressbarModule,
    DcsTextareaModule,
    DcsTextboxModule,
    DcsSearchboxModule,
    ProgressbarModule,
    DcsModalModule,
    DcsLookupModule,
    DCSLoaderModule,
    DCSErrorModule,
    DCSMessageBoxModule,
    ApiActionModule,
    NgbModule,
    DcsPrintviewerModule,
    DcsEditorModule,
    DcsRadioButtonModule,
    DcsTabsModule,
    DcsTabSetModule,
    DcsPanelModule,
    DcsImageModule,
    DcsColorModule,
    DcsMultiInputModule,
    DcsCalendarModule,
    DcsTileModule,
    DcsAdditionalTabsModule,
    DcsDimentionbaseModule,
    DcsDimentionModule,
    DcsLabelModule,
    MenuBoxModule,
    DcsChartsModule,
    DcsDashboardheaderModule
  ],
  entryComponents: [],
  providers: [],
})
export class ComponentModule {}
