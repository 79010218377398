// Angular Imports
import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { ChartsModule } from 'ng2-charts';
import { DcsChartsComponent } from './dcs-charts.component';
import { DcsModalModule } from '../dcs-modal/dcs-modal.module';
import { DcsDashboardheaderModule } from '../dcs-dashboardheader/dcs-dashboardheader.module';

@NgModule({
    imports: [
        CommonModule,
        DcsModalModule,
        ChartsModule,
        DcsDashboardheaderModule
    ],
    declarations: [
        DcsChartsComponent,
    ],
    exports: [
        DcsChartsComponent,
    ],
    providers: [
        
    ]
})
export class DcsChartsModule {

}
