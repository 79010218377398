import { Injectable } from '@angular/core';
import { AppService } from '../../../../../@core/service/App.Service';


@Injectable()
export class TemplateService extends AppService<any> {

    constructor() {
        super('mst_Smstemplate');

    }

    async GetTemplates() {
        const result = await this.get('');
        return result;
    }

    async GetTemplate(templatetypeid:any) {
        const result = await this.get('Template', [templatetypeid]);
        return result;
    }

    async TemplateData() {
        const result = await this.get('TemplateData');
        return result;
    }

    async DefaulterTemplateData() {
        var entityid = Number(this.localStorage.get('selectedEntity'))
        const result = await this.get('DefaulterTemplateData', [entityid]);
        return result;
    }
    async syspolicyTemp() {
        var entityid = Number(this.localStorage.get('selectedEntity'))
        const result = await this.get('TemplateDataSysPolStd', [entityid]);
        return result;
    }
    async syspolicyTempEmp() {
        var entityid = Number(this.localStorage.get('selectedEntity'))
        const result = await this.get('TemplateDataSysPolEmp', [entityid]);
        return result;
    }
}