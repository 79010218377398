import { GridprogressbarComponent } from './progressbar.component';
import { DcsprogressbarModule } from './../../../dcs-progressbar/dcs-progressbar.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';



@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, DcsprogressbarModule],
  declarations: [GridprogressbarComponent],
  exports: [GridprogressbarComponent],
  
})
export class ProgressbarModule { }
