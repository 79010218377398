import { Injectable } from "@angular/core";
import { AppService } from '../../@core/service/App.Service';



@Injectable()
export class StudentbirthdayService extends AppService<any> {

    constructor() {
        super('hdr_SMMessage');
    }
}
