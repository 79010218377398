import { Component, ViewChild } from "@angular/core";
import { AgRendererComponent } from "ag-grid-angular";
import { IAfterGuiAttachedParams, ICellRendererParams } from "ag-grid-community";
import * as moment from 'moment';

@Component({
  selector: "date-cell",
  template: `
  <input #input type="date" class="dcs-control w-100" [(ngModel)]="value" min="{{MinrangeDate}}" max="{{MaxrangeDate}}" (blur)="valueChange($event.target.value)"/>
    `,


  styles: ["border:none;"]
})
export class GridDatePickerComponent implements AgRendererComponent {
  refresh(params: any): boolean {
    return true;
  }
  MinrangeDate: any = ""
  MaxrangeDate: any = ""
  async agInit(params: ICellRendererParams) {

    setTimeout(() => {

      const element = this.input.nativeElement;
      if (element) {
        element.classList.add('grid-date-height');
        // element.style.marginTop = "-26px";
        // element.children[0].classList.remove("form-control");
        // element.children[0].style.border = "none";

      }

      this.params = params;
      this.input.nativeElement.disabled = !Boolean(
        this.params.colDef.editable === undefined
          ? true
          : this.params.colDef.editable
      );

      /* if(this.params.value === undefined)
      {
        
        this.params.value.setValue(new Date);
      } */
      
      this.MinrangeDate = this.params.colDef.minRange;
      this.MaxrangeDate = this.params.colDef.maxRange;
      this.value = moment(this.params && this.params.value != undefined ? this.params.value : this.params.colDef.value).format("YYYY-MM-DD");
    }, 0);
  }
  afterGuiAttached(params?: IAfterGuiAttachedParams): void { }
  private params: any;
  public value: any;
  private cancelBeforeStart: boolean = false;

  @ViewChild("input") public input: any;

  getValue(): any {

    return this.params.value;
  }

  valueChange(data: any) {
    if (data)
      this.params.setValue(data);
    else
      this.params.setValue(this.value);
  }

  public async setControlFocused() {
    if (this.input && this.input.nativeElement && this.input.nativeElement.children) {
      this.input.nativeElement.children[1].children[0].focus();
    }
  }
}

