<!--component html goes here -->
<div class="loader" *ngIf="showloader == true">
    <img src="../../../../assets/images/flash.gif" alt="">
</div>
<form [formGroup]="myForm" novalidate>
    <div class="row" *ngIf="hidegenerate == false">
        <div class="col-12">
            <div class="div-toolbar" *ngIf="isAutoGenerated == false">
                <button *ngIf="showConfigButton == true" class="docfield btn-group" (click)="openDocfieldModal()">
                    <abbr title="Fields Configuration">
                        <i class="fas fa-cogs"></i>
                    </abbr>
                </button>
                <button class="btn-group" (click)="generate()">
                    <i class="fa fa-gear"></i>&nbsp;Generate
                </button>
                <button class="btn-group" (click)="clear()">
                    <i class="fa fa-remove"></i>&nbsp;Clear Filter
                </button>
                <div class="reportnameStyle">
                    <img src="../../../../assets/ICONS/MODULES/library.svg" alt="">
                    <span>{{reportname}}</span>
                </div>

            </div>
        </div>
        <!-- <report-viewer #report [reportUrl]="url"></report-viewer> -->
    </div>
    <div *ngIf="_showCriteria && isAutoGenerated == false" class=" " style="padding: 0px 10px !important;">
        <div class="with-border m-0 rep-bor-bot" (click)="Collapsed()">
            <h3 class="box-title ml-0">Report Criteria</h3>
            <div class="box-tools pull-right btn-right">
                <button type="button" class="btn btn-box-tool">
                    <i [ngClass]="isCollapsed ? 'fa fa-angle-down' : 'fa fa-angle-up'"></i>
                </button>
            </div>
        </div>
        <div class="box-body" [ngStyle]="{ display: isCollapsed ? 'none' : 'block' }" style="padding-top: 0px">
            <div id="collapseOne" class="box-collapse collapse in box-body p-0 pt-1">
                <div class="row">

                    <div class="col-lg-4">
                        <div class="row" *ngFor="let item of directconfig">
                            <div class="col-md-12 col-sm-12 col-xs-12 ReportDiv"
                                [ngClass]="item.statusid !== 1102 ? '' : 'display-none'">
                                <div *ngIf="item.statusid !== 1102">
                                    <dcs-textbox [caption]="item.caption" *ngIf="item.fieldtypeid === 1095"
                                        [formControlName]="item.fieldname"
                                        [disabled]="item.statusid === 1100 ? false : true" [required]="item.ismandatory"
                                        [position]="2" [fieldWidth]="item.fieldwidth" [captionWidth]="item.capwidth"
                                        [ReportDropdown]="true">
                                    </dcs-textbox>
                                    <!-- (onButtonmag)="searchboxclick(item)" -->
                                    <!-- (focusout)="searchfocus($event)" -->
                                    <dcs-searchbox [caption]="item.caption" *ngIf="item.fieldtypeid === 1343"
                                        [formControlName]="item.fieldname" [data]="LookupData[item.fieldname]"
                                        (focusout)="searchfocus(item)"
                                        [disabled]="item.statusid === 1100 ? false : true"
                                        [config]="item.utlisterconfigdtls" [required]="item.ismandatory" [position]="2"
                                        [fieldWidth]="item.fieldwidth" (openmodalconfig)="searchboxclick(item)"
                                        [captionWidth]="item.capwidth" [ReportDropdown]="true"
                                        [valueMember]="item.displaymember" [displayMember]="item.displaymember">
                                    </dcs-searchbox>
                                    <dcs-date [caption]="item.caption" *ngIf="item.fieldtypeid === 1096"
                                        [formControlName]="item.fieldname"
                                        [disabled]="item.statusid === 1100 ? false : true" [required]="item.ismandatory"
                                        [position]="2" [fieldWidth]="item.fieldwidth" [captionWidth]="item.capwidth"
                                        [ReportDropdown]="true">
                                    </dcs-date>
                                    <dcs-dropdown [caption]="item.caption" [showall]="item.allfield"
                                        *ngIf="item.isdimension != true && item.fieldtypeid === 1097"
                                        [formControlName]="item.fieldname" [fieldName]="item.fieldname"
                                        [data]="LookupData[item.fieldname]" [config]="item.utlisterconfigdtls"
                                        [valueMember]="item.valuemember" [displayMember]="item.displaymember"
                                        [disabled]="item.statusid === 1100 ? false : true"
                                        (change)="valueChange(item.fieldname)" [required]="item.ismandatory"
                                        [position]="2" [fieldWidth]="item.fieldwidth" [captionWidth]="item.capwidth"
                                        [showNone]="!item.allfield" [ReportDropdown]="true">
                                    </dcs-dropdown>
                                    <dcs-dropdown [caption]="item.caption"
                                        *ngIf="item.isdimension == true && item.fieldtypeid === 1097"
                                        [formControlName]="item.fieldname" [fieldName]="item.fieldname"
                                        [data]="LookupData[item.fieldname]" [config]="item.utlisterconfigdtls"
                                        [valueMember]="item.valuemember" [displayMember]="item.displaymember"
                                        [disabled]="item.statusid === 1100 ? false : true"
                                        (change)="valueChange(item.fieldname)" [required]="item.ismandatory"
                                        [position]="2" [fieldWidth]="item.fieldwidth" [captionWidth]="item.capwidth"
                                        [showNone]="true" [showTool]="true" (openAdditional)="openDimension($event)"
                                        [ReportDropdown]="true">
                                        <!-- [mappedField]="mappedField" -->
                                    </dcs-dropdown>
                                    <dcs-multiselect-dropdown [caption]="item.caption" *ngIf="item.fieldtypeid === 1278"
                                        [formControlName]="item.fieldname" [fieldName]="item.fieldname"
                                        [data]="LookupData[item.fieldname]" [config]="item.utlisterconfigdtls"
                                        [valueMember]="item.valuemember" [displayMember]="item.displaymember"
                                        [disabled]="item.statusid === 1100 ? false : true"
                                        (onValueChanged)="valueChangeForMulti(item.fieldname)"
                                        [required]="item.ismandatory" [position]="2" [fieldWidth]="item.fieldwidth"
                                        [captionWidth]="item.capwidth" [showNone]="true" [ReportDropdown]="true"
                                        [itemsShowLimit]="1">
                                    </dcs-multiselect-dropdown>
                                    <dcs-numeric [caption]="item.caption" *ngIf="item.fieldtypeid === 1098"
                                        [formControlName]="item.fieldname"
                                        [disabled]="item.statusid === 1100 ? false : true" [required]="item.ismandatory"
                                        [position]="2" [fieldWidth]="item.fieldwidth" [captionWidth]="item.capwidth"
                                        [ReportDropdown]="true">
                                    </dcs-numeric>
                                    <dcs-checkbox [caption]="item.caption" *ngIf="item.fieldtypeid === 1099"
                                        [formControlName]="item.fieldname"
                                        [disabled]="item.statusid === 1100 ? false : true" [required]="item.ismandatory"
                                        [position]="2"></dcs-checkbox>
                                    <dcs-time [caption]="item.caption" *ngIf="item.fieldtypeid === 1105"
                                        [formControlName]="item.fieldname"
                                        [disabled]="item.statusid === 1100 ? false : true" [required]="item.ismandatory"
                                        [position]="2" [fieldWidth]="item.fieldwidth" [captionWidth]="item.capwidth"
                                        [ReportDropdown]="true">
                                    </dcs-time>
                                </div>
                            </div>



                        </div>
                    </div>
                    <div class="col-lg-4">
                        <h5 *ngIf="configDimensiondata && configDimensiondata.length > 0" class="dimensiondes">Dimension
                        </h5>
                        <div class="row" *ngFor="let item of directconfigDimensiondata">
                            <div class="col-md-12 col-sm-12 col-xs-12 ReportDiv"
                                [ngClass]="item.statusid !== 1102 ? '' : 'display-none'">
                                <div *ngIf="item.statusid !== 1102">
                                    <dcs-dropdown [caption]="item.caption" *ngIf="item.fieldtypeid === 1097"
                                        [formControlName]="item.fieldname" [fieldName]="item.fieldname"
                                        [data]="LookupData[item.fieldname]" [config]="item.utlisterconfigdtls"
                                        [valueMember]="item.valuemember" [displayMember]="item.displaymember"
                                        [disabled]="item.statusid === 1100 ? false : true"
                                        (change)="valueChange(item.fieldname)" [required]="item.ismandatory"
                                        [position]="2" [fieldWidth]="item.fieldwidth" [captionWidth]="item.capwidth"
                                        [showNone]="true" [showTool]="item.isdimension"
                                        (openAdditional)="openDimension($event)" [ReportDropdown]="true">
                                        <!-- [mappedField]="mappedField" -->
                                    </dcs-dropdown>
                                    <dcs-searchbox [caption]="item.caption" *ngIf="item.fieldtypeid === 1343"
                                        [formControlName]="item.fieldname" [data]="LookupData[item.fieldname]"
                                        (focusout)="searchfocus(item)"
                                        [disabled]="item.statusid === 1100 ? false : true"
                                        [config]="item.utlisterconfigdtls" [required]="item.ismandatory" [position]="2"
                                        [fieldWidth]="item.fieldwidth" (openmodalconfig)="searchboxclick(item)"
                                        [captionWidth]="item.capwidth" [ReportDropdown]="true"
                                        [valueMember]="item.displaymember" [displayMember]="item.displaymember">
                                    </dcs-searchbox>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--<div class="box box-default" style="padding: 0px 10px !important;" *ngIf="LookupData.reportdatalistshow && LookupData.reportdatalistshow.length && LookupData.reportdatalistshow.length > 0">-->
    <div class="box box-default" style="padding: 0px 10px !important;" *ngIf="showReportPanel == true">

        <!--  -->
        <div *ngIf="hidereportTab == false">
            <dcs-tabs (onClick)="tabClick($event)" #newtabs1>
                <dcs-tabset tabTitle="Report Preview">
                    <div class="box-body" style="margin-top:25px ;">
                        <div id="collapseTwo" class="box-collapse collapse in box-body">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0">
                                    <iframe class="reportFrame" #frame *ngIf="url" [src]="url" height="700px"
                                        width="100%" (load)="loadEnd()"></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </dcs-tabset>

                <dcs-tabset
                    *ngIf="menutype === 3 && LookupData.reportdatalist && LookupData.reportdatalist.length && LookupData.reportdatalist.length > 0"
                    tabTitle="Data">
                    <div>
                        <dcs-aggrid lvl="4" [pageSize]="15" [type]="1" [hideSelection]="false" #agGrid
                            [columns]="columnDefs" [pagination]="true" [isExport]="true" [pagination]="true"
                            [columnPanel]="true" [filter]="true" [data]="LookupData.reportdatalist"
                            [customButton]="Sharedbutton" (toolbarClick)="toolbarclickopenmodale($event)"
                            exportprefix="{{reportname}}">
                        </dcs-aggrid>
                    </div>
                </dcs-tabset>
            </dcs-tabs>
        </div>
        <div *ngIf="hidereportTab == true">
            <dcs-tabs (onClick)="tabClick($event)" #newtabs1>
                <dcs-tabset tabTitle="Data">
                    <div>
                        <dcs-aggrid lvl="4" [pageSize]="15" [type]="1" [hideSelection]="false" #agGrid
                            [columns]="columnDefs" [pagination]="true" [isExport]="true" [pagination]="true"
                            [columnPanel]="true" [filter]="true" [data]="LookupData.reportdatalist"
                            [customButton]="Sharedbutton" (toolbarClick)="toolbarclickopenmodale($event)"
                            exportprefix="{{reportname}}">
                        </dcs-aggrid>
                    </div>
                </dcs-tabset>
                <dcs-tabset tabTitle="Report HTML"
                    *ngIf="visibleColumns && visibleColumns.length > 0 && groupedData && groupedData.length > 0 && this._reportCode == 'SalaryDetailReport'">
                    <div style="    display: flex;
    flex-direction: column;">
                        <div class="exportExcelBtn">
                            <button (click)="exportTable()" type="button" class="btn-group filter-button"
                                style="min-width: 20px !important; border-right: none;"><i
                                    class="fas fa-download"></i></button>
                        </div>
                        <div class="reportTable">
                            <table id="myTable" class="tableMain">
                                <thead>
                                    <tr>
                                        <th *ngFor="let header of visibleColumns">{{ header.headerName }}</th>
                                    </tr>
                                </thead>
                                <tbody *ngFor="let entity of groupedData">
                                    <tr>
                                        <td [attr.colspan]="visibleColumns?.length > 0 ? visibleColumns.length : 1"
                                            class="department-header" style="color: var(--edpmain);">
                                            Entity: {{ entity.entity }}
                                        </td>
                                    </tr>
                                    <ng-container *ngFor="let department of entity.departments | keyvalue">
                                        <tr>
                                            <td [attr.colspan]="visibleColumns?.length > 0 ? visibleColumns.length : 1"
                                                class="department-header">
                                                Department: {{ department.value.Department }}
                                            </td>
                                        </tr>
                                        <tr *ngFor="let employee of department.value.employees">
                                            <td *ngFor="let key of visibleColumns">{{ employee[key.field] }}</td>
                                        </tr>
                                        <tr class="fontBoldTD">
                                            <td colspan="2">Total for {{ department.value.Department }}</td>
                                            <td *ngFor="let key of visibleColumns?.slice(2)">
                                                {{ department.value.totals[key.field] || 0 }}
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colspan="2">Total</td>
                                        <td *ngFor="let key of visibleColumns?.slice(2)">
                                            {{ overallTotals[key.field] || 0 }}
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </dcs-tabset>
            </dcs-tabs>
        </div>


        <!-- <div class="box-header with-border p-0" style="padding-top: 5px !important;">
            <h3 class="box-title ml-0">Report Preview</h3>
        </div>
        <div class="box-body">
            <div id="collapseTwo" class="box-collapse collapse in box-body">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0">
                        <iframe class="reportFrame" #frame *ngIf="url" [src]="url" height="700px" width="100%"
                            (load)="loadEnd()"></iframe>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
    <div class="noreordfound-img" *ngIf="LookupData.reportdatalist &&  LookupData.reportdatalist.length <= 0">
        <img src="../../../../assets/images/norecordfoundblue.png" alt="">
    </div>
    <dcs-modal #openmodal [showClose]="false" [mode]="4" [lvl]="3" Modal="true" Caption="{{reportname}}">

        <dcs-aggrid [hideNoRecordOverlay]="true" [hideSelection]="false" [pageSize]="15" [filter]="true"
            [hideDelete]="true" [hideAdd]="true" #configgrid [pagination]="true" [columns]="columnListDefslegact"
            [data]="modalgriddata" [customButton]="customButtonEntity" (toolbarClick)="rptconfigsave($event)">
        </dcs-aggrid>

    </dcs-modal>

    <dcs-modal #dimensionModal mode="4" Modal="true" Caption="Select Dimension" (onClose)="CloseDimModal($event)">

        <div>
            <div class="row">
                <div class="div-toolbar">
                    <button class="btn-group" style="padding-left: 14px !important;" (click)="SaveClose()">
                        <!-- (click)="saveClose()" -->
                        <i class="fa fa-gear"></i>&nbsp;Save & Close
                    </button>
                </div>
            </div>
            <!-- <div class="box box-default">
          <div class="box-body"> -->
            <div id="collapseOne" class="box-collapse collapse in box-body">
                <div *ngFor="let item of configDimension" class="row" style="height: 25px;">
                    <div *ngIf="item.statusid !== 23">
                        <div class="form-group-cust">
                            <dcs-textbox [caption]="item.caption" *ngIf="item.fieldtypeid === 16"
                                [formControlName]="item.fieldname" [disabled]="item.statusid === 21 ? false : true"
                                [required]="item.ismandatory" [position]="2" [fieldWidth]="item.fieldwidth"
                                [captionWidth]="item.capwidth"></dcs-textbox>
                            <dcs-date [caption]="item.caption" *ngIf="item.fieldtypeid === 17"
                                [formControlName]="item.fieldname" [disabled]="item.statusid === 21 ? false : true"
                                [required]="item.ismandatory" [position]="2" [fieldWidth]="item.fieldwidth"
                                [captionWidth]="item.capwidth"></dcs-date>
                            <dcs-dropdown [type]="2" [Id]="item.Id" [caption]="item.stxt" [formControlName]="item.code"
                                [fieldName]="item.code" [data]="item.data" [config]="item.utlisterconfigdtls"
                                [valueMember]="item.valuemember" [displayMember]="item.displaymember"
                                (change)="valueChange(item.fieldname)" [required]="item.ismandatory" [position]="2"
                                [fieldWidth]="item.fieldwidth" [captionWidth]="item.capwidth">
                            </dcs-dropdown>
                            <dcs-numeric [caption]="item.caption" *ngIf="item.fieldtypeid === 19"
                                [formControlName]="item.fieldname" [disabled]="item.statusid === 21 ? false : true"
                                [required]="item.ismandatory" [position]="2" [fieldWidth]="item.fieldwidth"
                                [captionWidth]="item.capwidth"></dcs-numeric>
                            <dcs-checkbox [caption]="item.caption" *ngIf="item.fieldtypeid === 20"
                                [formControlName]="item.fieldname" [disabled]="item.statusid === 21 ? false : true"
                                [required]="item.ismandatory" [position]="2">
                            </dcs-checkbox>
                            <dcs-time [caption]="item.caption" *ngIf="item.fieldtypeid === 26"
                                [formControlName]="item.fieldname" [disabled]="item.statusid === 21 ? false : true"
                                [required]="item.ismandatory" [position]="2" [fieldWidth]="item.fieldwidth"
                                [captionWidth]="item.capwidth"></dcs-time>
                        </div>
                    </div>
                </div>
            </div>
            <!-- </div>
        </div> -->
        </div>
    </dcs-modal>
</form>
<Dcs-loader #dcserror></Dcs-loader>
<dcs-docfield-modal (CloseDocModal)="closeDocfieldModal()" #FieldConfigModal [showClose]="false" mode="4"
    [ModuleType]="2" [fields]="config" Modal="true" caption="Report Fields Configuration">

</dcs-docfield-modal>