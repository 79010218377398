import { AnyARecord } from 'dns';
import { Injectable } from '@angular/core';
import { DataTransferObject } from '../../../../@core/dto/DataTransferObject';
import { AppService } from '../../../../@core/service/App.Service';

@Injectable()
export class SMStudentinfoService extends AppService<any> {

    constructor() {
        super('hdr_SMStudentinfo');

    }

    async getStudent(entityid: any) {
        const result = await this.get('Student', entityid);
        return result;
    }

    async getStatusWiseStudent(entityid: any, lovstatusid: any) {
        const result = await this.get('StatusWiseStudent', [entityid, lovstatusid]);
        return result;
    }
    async GetStudentForTransfer(entityid: any, stdid: any) {
        const result = await this.get('StudentForTransfer', [entityid, stdid]);
        return result;
    }
    async GetSingleStudent(stdid: any) {
        const result = await this.get('SingleStudent', [stdid]);
        return result;
    }
    async GetStudentdataAsync(entityid: any) {
        const result = await this.get('Studentdata', [entityid]);
        return result;
    }
    async getStatusWiseStudentV2(entityid: any, lovstatusid: any) {
        const result = await this.get('StatusWiseStudentV2', [entityid, lovstatusid]);
        return result;
    }
    async getStatusWiseStudentV3(entityid: any, lovstatusid: any) {
        const result = await this.get('StatusWiseStudentV3', [entityid, lovstatusid]);
        return result;
    }

    async getFeeCollectionStudent(lovstatusid: any) {
        const result = await this.get('FeeCollectionStudent', [lovstatusid]);
        return result;
    }

    async getFeeCollectionStudentpolicy(entityid: any, lovstatusid: any) {
        const result = await this.get('FeeCollectionStudentpolicy', [entityid, lovstatusid]);
        return result;
    }

    async getStudentList(feeforid: any, applyonid: any) {
        const result = await this.get('StudentList', [feeforid, applyonid]);
        return result;
    }

    async getStatusWiseStudentList(feeforid: any, applyonid: any, lovstatusid: any) {
        const result = await this.get('StatusWiseStudentList', [feeforid, applyonid, lovstatusid]);
        return result;
    }

    async getregistrationdetailcoursewise(entityid: any, courseid: any, joiningdate: any) {
        const result = await this.get('RegdtlCourseWise', [entityid, courseid, joiningdate]);
        return result;
    }


    async getstudentList(entityid: number, driverid: number, routeid: number, guardianid: number, regno: string, name: string, genderid: number, religionid: number, shiftid: number, courseid: number) {

        const result = await this.get('StudentList', [entityid, driverid, routeid, guardianid, regno, name, genderid, religionid, shiftid, courseid]);
        return result;
    }

    async getRoutesStudents(routeid: any) {
        return await this.get('RoutesStudents', [routeid])

    }

    async getGuardianStudent(guardianid: any) {
        return await this.get('GuardianStudent', [guardianid])

    }

    async getStatusWiseRoutesStudents(routeid: any, lovstatusid: any) {
        return await this.get('StatusWiseRoutesStudents', [routeid, lovstatusid]);
    }
    async getEntityStudent(entityid: any) {
        const result = await this.get('Student', entityid);
        return result;
    }

    async geStudentList(studentid: any) {
        const result = await this.get('StudentList', studentid);
        return result;
    }

    async getStatusWiseStudentsList(studentid: any, lovstatusid: any) {
        const result = await this.get('StatusWiseStudentList', [studentid, lovstatusid]);
        return result;
    }

    async getCourseStudents(courseid: any, sectionid: any) {
        let entityid = this.localStorage.get('selectedEntity');
        const result = await this.get('CourseStudents', [entityid, courseid, sectionid]);
        return result;
    }
    async getCourseStudenttarbiyah(courseid: any, sectionid: any, tarbiyahid: any, trndate: any) {
        let entityid = this.localStorage.get("selectedEntity");
        const result = await this.get("CourseStudentsTarbiyah", [entityid, courseid, sectionid, tarbiyahid, trndate]);
        return result;
    }
    async getCourseStudentsENT(entityid: any, courseid: any, sectionid: any) {
        const result = await this.get('CourseStudents', [entityid, courseid, sectionid]);
        return result;
    }
    async getCourseStudentsV2(entityid: any, courseid: any, sectionid: any, acdyearid: any) {
        const result = await this.get('CourseStudentsV2', [entityid, courseid, sectionid, acdyearid]);
        return result;
    }

    async getStatusWiseCourseStudents(courseid: any, sectionid: any, lovstatusid: any) {
        let entityid = this.localStorage.get('selectedEntity');
        const result = await this.get('StatusWiseCourseStudents', [entityid, courseid, sectionid, lovstatusid]);
        return result;
    }

    async getMeetingManagerCourseStudents(courseid: any, sectionid: any, lovstatusid: any, ptmid: any) {
        let entityid = this.localStorage.get('selectedEntity');
        const result = await this.get('MeetingManagerCourseStudents', [entityid, courseid, sectionid, lovstatusid, ptmid]);
        return result;
    }
    async GetStudentGuardian(guardianid: any) {
        let entityid = this.localStorage.get('selectedEntity');
        const result = await this.get('GuardianStudent', [entityid, guardianid]);
        return result;
    }

    async GetStatusWiseStudentGuardian(guardianid: any, lovstatusid: any) {
        let entityid = this.localStorage.get('selectedEntity');
        const result = await this.get('StatusWiseGuardianStudent', [entityid, guardianid, lovstatusid]);
        return result;
    }

    async getStudentByFilter(clientid: any, entityid: any, studentno: any, dateofbirth: any, curcourseid: any, cursectionid: any, genderid: any, firstname: any, fathername: any) {

        let refclientid = (clientid == undefined || clientid == 0 || clientid == "") ? this.localStorage.get("clientid") : clientid;
        let refentityid = (entityid == undefined || entityid == 0 || entityid == "") ? this.localStorage.get("selectedEntity") : entityid;
        let refstudentno = (studentno == "" || studentno == undefined) ? 'null' : studentno;
        let refdateofbirth = (dateofbirth == "" || dateofbirth == undefined) ? 'null' : dateofbirth;
        let refcurcourseid = (curcourseid == undefined || curcourseid == 0 || curcourseid == "") ? 'null' : curcourseid;
        let refcursectionid = (cursectionid == undefined || cursectionid == 0 || cursectionid == "") ? 'null' : cursectionid;
        let refgenderid = (genderid == undefined || genderid == 0 || genderid == "") ? 'null' : genderid;
        let reffirstname = (firstname == undefined || firstname == 0 || firstname == "") ? 'null' : firstname;
        let reffathername = (fathername == undefined || fathername == 0 || fathername == "") ? 'null' : fathername;


        const result = await this.get('StudentByFilter', [refclientid, refentityid, refstudentno, refdateofbirth, refcurcourseid, refcursectionid, refgenderid, reffirstname, reffathername]);

        return result;
    }



    async getStudentCourseSection(entityid: any, acdyearid: any, courseid: any, sectionid: any, fromdate: any, tilldate: any) {

        let refcourseid = (courseid == undefined || courseid == 0 || courseid == "") ? 'null' : courseid;
        let refsectionid = (sectionid == undefined || sectionid == 0 || sectionid == "") ? 'null' : sectionid;
        let reffromdate = (fromdate == "" || fromdate == undefined) ? 'null' : fromdate;
        let reftilldate = (tilldate == "" || tilldate == undefined) ? 'null' : tilldate;
        const result = await this.get('StudentCourseSection', [entityid, acdyearid, refcourseid, refsectionid, reffromdate, reftilldate]);
        return result;
    }

    async getStudentInformation(clientid: any, entityid: any, lovstatusid: any) {

        let reflovstatusid = (lovstatusid == undefined || lovstatusid == 0 || lovstatusid == "") ? 'null' : lovstatusid;
        const result = await this.get('StatusWiseStudentInformation', [clientid, entityid, reflovstatusid]);
        return result;
    }

    async getStudentListRollNo(entityid: any, courseid: any, sectionid: any) {

        const result = await this.get('StudentList', [entityid, courseid, sectionid]);
        return result;
    }

    async getStatusWiseStudentListRollNo(entityid: any, courseid: any, sectionid: any, lovstatusid: any) {

        const result = await this.get('StatusWiseStudentList', [entityid, courseid, sectionid, lovstatusid]);
        return result;
    }
    async getStatusWiseStudentInformation(clientid: any, entityid: any, lovstatusid: any) {

        const result = await this.get('StatusWiseStudentInformation', [clientid, entityid, lovstatusid]);
        return result;
    }

    async GetStudentCourseSectionSubject(entityid: any, acdyearid: any, courseid: any, sectionid: any, subjectid: any, fromdate: any, tilldate: any) {

        let refcourseid = (courseid == undefined || courseid == 0 || courseid == "") ? 'null' : courseid;
        let refsectionid = (sectionid == undefined || sectionid == 0 || sectionid == "") ? 'null' : sectionid;
        let refsubjectid = (subjectid == undefined || subjectid == 0 || subjectid == "") ? 'null' : subjectid;
        let reffromdate = (fromdate == "" || fromdate == undefined) ? 'null' : fromdate;
        let reftilldate = (tilldate == "" || tilldate == undefined) ? 'null' : tilldate;
        const result = await this.get('StudentCourseSectionSubject', [entityid, acdyearid, refcourseid, refsectionid, refsubjectid, reffromdate, reftilldate]);
        return result;
    }

    async GetCourseSectionStudents(entityid: any, assignmentid: any, totalmarks: any, courseid: any, sectionid: any) {
        entityid = Number(this.localStorage.get('selectedEntity'));
        const result = await this.get('CourseSectionStudents', [entityid, assignmentid, totalmarks, courseid, sectionid]);
        return result;
    }

    async getStudentSiblings(studentid: any, cnic: any, fathermobile: any) {

        //  let CNIC = cnic.trim();
        // if (cnic.length < 10) {
        //     const result = await this.get('StudentSiblings', [studentid, 'null', fathermobile]);
        //     return result;
        // }
        // else {

        var contactno = fathermobile != undefined ? fathermobile : "null"
        var cnic = cnic != undefined ? cnic : "null"

        const result = await this.get('StudentSiblings', [studentid, cnic, contactno]);
        return result;
        // }

    }


    async getFiltersStudents(entityid: any, studentno: any, dateofbirth: any, curcourseid: any, cursectionid: any, genderid: any, firstname: any, fathername: any, fathercnic: any) {

        let refentityid = (entityid == undefined || entityid == 0 || entityid == "") ? this.localStorage.get("selectedEntity") : entityid;
        let refstudentno = (studentno == "" || studentno == undefined) ? 'null' : studentno;
        let refdateofbirth = (dateofbirth == "" || dateofbirth == undefined) ? 'null' : dateofbirth;
        let refcurcourseid = (curcourseid == undefined || curcourseid == 0 || curcourseid == "") ? 'null' : curcourseid;
        let refcursectionid = (cursectionid == undefined || cursectionid == 0 || cursectionid == "") ? 'null' : cursectionid;
        let refgenderid = (genderid == undefined || genderid == 0 || genderid == "") ? 'null' : genderid;
        let reffirstname = (firstname == undefined || firstname == 0 || firstname == "") ? 'null' : firstname;
        let reffathername = (fathername == undefined || fathername == 0 || fathername == "") ? 'null' : fathername;
        let reffathercnic = (fathercnic == undefined || fathercnic == 0 || fathercnic == "") ? 'null' : fathercnic;


        const result = await this.get('FiltersStudents', [refentityid, refstudentno, refdateofbirth, refcurcourseid, refcursectionid, refgenderid, reffirstname, reffathername, reffathercnic]);

        return result;
    }

    async getFiltersStudentsV2(entityid: any, studentno: any, dateofbirth: any, curcourseid: any, cursectionid: any, genderid: any, firstname: any, fathername: any, fathercnic: any, grno: any) {

        let refentityid = (entityid == undefined || entityid == 0 || entityid == "") ? this.localStorage.get("selectedEntity") : entityid;
        let refstudentno = (studentno == "" || studentno == undefined) ? 'null' : studentno;
        let refdateofbirth = (dateofbirth == "" || dateofbirth == undefined) ? 'null' : dateofbirth;
        let refcurcourseid = (curcourseid == undefined || curcourseid == 0 || curcourseid == "") ? 'null' : curcourseid;
        let refcursectionid = (cursectionid == undefined || cursectionid == 0 || cursectionid == "") ? 'null' : cursectionid;
        let refgenderid = (genderid == undefined || genderid == 0 || genderid == "") ? 'null' : genderid;
        let reffirstname = (firstname == undefined || firstname == 0 || firstname == "") ? 'null' : firstname;
        let reffathername = (fathername == undefined || fathername == 0 || fathername == "") ? 'null' : fathername;
        let reffathercnic = (fathercnic == undefined || fathercnic == 0 || fathercnic == "") ? 'null' : fathercnic;
        let Grno = (grno == undefined || grno == 0 || grno == "") ? 'null' : grno;



        const result = await this.get('FiltersStudents', [refentityid, refstudentno, refdateofbirth, refcurcourseid, refcursectionid, refgenderid, reffirstname, reffathername, reffathercnic, Grno]);

        return result;
    }

    async getFiltersBulkWithdrawStudentsV2(entityid: any, studentno: any, dateofbirth: any, curcourseid: any, cursectionid: any, genderid: any, firstname: any, fathername: any, fathercnic: any, grno: any) {

        let refentityid = (entityid == undefined || entityid == 0 || entityid == "") ? "null" : entityid;
        let refstudentno = (studentno == "" || studentno == undefined) ? 'null' : studentno;
        let refdateofbirth = (dateofbirth == "" || dateofbirth == undefined) ? 'null' : dateofbirth;
        let refcurcourseid = (curcourseid == undefined || curcourseid == 0 || curcourseid == "") ? 'null' : curcourseid;
        let refcursectionid = (cursectionid == undefined || cursectionid == 0 || cursectionid == "") ? 'null' : cursectionid;
        let refgenderid = (genderid == undefined || genderid == 0 || genderid == "") ? 'null' : genderid;
        let reffirstname = (firstname == undefined || firstname == 0 || firstname == "") ? 'null' : firstname;
        let reffathername = (fathername == undefined || fathername == 0 || fathername == "") ? 'null' : fathername;
        let reffathercnic = (fathercnic == undefined || fathercnic == 0 || fathercnic == "") ? 'null' : fathercnic;
        let Grno = (grno == undefined || grno == 0 || grno == "") ? 'null' : grno;
        const result = await this.get('FiltersStudents', [refentityid, refstudentno, refdateofbirth, refcurcourseid, refcursectionid, refgenderid, reffirstname, reffathername, reffathercnic, Grno]);
        return result;
    }
    async getFiltersBulkWithdrawArrystdnoStudentsV2(studentno: any, entityid: any) {
        const result = await this.post('FiltersWithdrawStudents', studentno);
        return result;
    }

    async getstudentbirthdays() {
        let entityid = Number(this.localStorage.get('selectedEntity'));
        const result = await this.get('StudentBirthdays', [entityid]);
        return result;
    }

    async getCNICagainstFatherdata(cnic: any) {
        const result = await this.get('CNICagainstFatherdata', [cnic]);
        return result;
    }

    async getCNICagainstMotherdata(cnic: any) {
        const result = await this.get('CNICagainstMotherdata', [cnic]);
        return result;
    }
    async getStudentsWithMarks(courseid: any, sectionid: any, examid: any, subjectid: any) {

        let entityid = Number(this.localStorage.get('selectedEntity'));
        const result = await this.get('StudentsWithMarks', [entityid, courseid, sectionid, examid, subjectid]);
        return result;
    }
    async getAssessmentLeavepolicy(courseid: any, sectionid: any, examid: any, subjectid: any, studentid: any) {

        let entityid = Number(this.localStorage.get('selectedEntity'));
        const result = await this.get('AssessmentLeavepolicy', [entityid, courseid, sectionid, examid, subjectid, studentid]);
        return result;
    }

    async GetStudentsWithMarksCoachingCentres(courseid: any, sectionid: any, examid: any, subjectid: any) {

        let entityid = Number(this.localStorage.get('selectedEntity'));
        const result = await this.get('StudentsWithMarksCoachingCentres', [entityid, courseid, sectionid, examid, subjectid]);
        return result;
    }

    async GetCourseAgainstStudents(courseid: any, sectionid: any, subjectid: any, lovstatusid: any) {
        let entityid = this.localStorage.get('selectedEntity');
        const result = await this.get('CourseAgainstStudents', [entityid, courseid, sectionid, subjectid, lovstatusid]);
        return result;
    }

    async GetPromoteStudent(acdyearid: any, courseid: any, sectionid: any) {
        let entityid = this.localStorage.get('selectedEntity');
        const result = await this.get('PromoteStudent', [entityid, acdyearid, courseid, sectionid]);
        return result;
    }


    async getStudentNotifySMSorApp(entityid: any) {
        const result = await this.get('StudentNotifySMSorApp', [entityid]);
        return result;
    }
    async getguardianagaintsstud(Id: any) {
        const result = await this.get('StudentDetail', [Id, 'null']);
        return result;
    }

    ChildList: any = [];
    async getChildList(Id: any) {
        const result = await this.getguardianagaintsstud(Id);
        if (result && result.IsSuccess) {
            this.ChildList = result.Data;
        }
    }



    async getguesttypestudent(clientid: any, entityid: any) {

        const result = await this.get('StatusWiseStudentInformation', [clientid, entityid, 357]);
        return result;
    }

    async deletechild(stdid: any, gurid: any) {
        const result = await this.get('GuardianStudentDelete', [stdid, gurid]);
        return result;
    }

    async getStudentEntity(entityid: any) {
        const result = await this.get('Students', entityid);
        return result;
    }
    async bulkstudetnsaving(Data: any) {
        const _data = <DataTransferObject<any>>{
            Data: Data,
        };

        const result = await this.post('Bulkstudentsaving', _data);
        return result;
    }

    //Get Student For All Selected Course 
    async getStudensCourseWiseAsync() {
        let entityid = Number(this.localStorage.get('selectedEntity'));
        const result = await this.get('StudensCourseWiseAsync', [entityid]);
        return result;
    }

    async getStudentInfoList(studentno: any, _Data: any) {
        this.clearCondition()
        const _data = <DataTransferObject<any>>{
            Data: _Data,
        };

        const result = await this.post('StudentInformationList', _data, studentno);
        return result;
    }

    async getPassedOutStudent(courseid: any, sectionid: any, acdyearid: any) {
        let entityid = Number(this.localStorage.get("selectedEntity"));
        const result = await this.get('PassedOutStudent', [entityid, courseid, sectionid, acdyearid]);
        return result;
    }
    async getStudentWithFields(studentid: any) {
        const result = await this.get('GetStudentWithFields', [studentid]);
        return result;
    }
    async GetEnrStud(clientid: any, acdyearid: any) {
        const dto = await this.get('StudentEnrGraphMnthWise', [clientid, acdyearid]);
        return dto;
    }
    async GetCampusTypeWiseStatus(clientid: any, entityid: any) {
        const dto = await this.get('CampusTypeWiseStatus', [clientid, entityid]);
        return dto;
    }
    async GetRegionWiseCampusWise(clientid: any, acdyearid: any, entityid: any) {
        const dto = await this.get('RegionWiseCampusWise', [clientid, acdyearid, entityid]);
        return dto;
    }
    async GetCampusEnrollmentGrph(clientid: any, entityid: any) {
        const dto = await this.get('CampusEnrollmentGraph', [clientid, entityid]);
        return dto;
    }
    async GetCampusWiseMonthWiseAdmEnq(clientid: any, entityid: any) {
        const dto = await this.get('CampusWiseMonthWiseAdmEnq', [clientid, entityid]);
        return dto;
    }
    async GetAdmissionStatusCampusWise(clientid: any, entityid: any) {
        const dto = await this.get('AdmissionStatusCampusWise', [clientid, entityid]);
        return dto;
    }
    async GetStdWidrawalCMWise(entityId: any, acdyearid: any) {
        const dto = await this.get('StdWidrawalCMWise', [entityId, acdyearid]);
        return dto;
    }
    async GetStudentStrenghtClassWise(clientid: any, entityid: any) {
        const dto = await this.get('StudentStrenghtClassWise', [clientid, entityid]);
        return dto;
    }
    async GetPIChartGraph(clientid: any, entityid: any) {
        const dto = await this.get('PIChartGraph', [clientid, entityid]);
        return dto;
    }
    async GetStrengthBGWise(clientid: any, entityid: any) {
        const dto = await this.get('StrengthBGWise', [clientid, entityid]);
        return dto;
    }
    async GetTeacherGenderWise(clientid: any, entityid: any) {
        const dto = await this.get('TeacherGenderWise', [clientid, entityid]);
        return dto;
    }
    async getReligionWise(clientid: any, entityid: any) {
        const dto = await this.get('ReligionWise', [clientid, entityid]);
        return dto;
    }
    async getRegionWisePieChart(clientid: any, entityid: any) {
        const dto = await this.get('RegionWisePieChart', [clientid, entityid]);
        return dto;
    }
    async getEntityWsieStudent(clientid: any, entityid: any) {
        const dto = await this.get('EntityWsieStudent', [clientid, entityid]);
        return dto;
    }
    async getClassWiseApi(clientid: any, entityid: any) {
        const dto = await this.get('ClassWiseApi', [clientid, entityid]);
        return dto;
    }
    async getCategoryWiseTeacherSubject(clientid: any, entityid: any) {
        const dto = await this.get('CategoryWiseTeacherSubject', [clientid, entityid]);
        return dto;
    }
    async getStudentfromno(studentno: any) {
        // let clientid = this.localStorage.get('clientid');
        const result = await this.post('StudentnoList', studentno);
        return result;
    }

    async getStudentDetail(studentno: any) {
        const result = await this.get('StudentDetail', [studentno]);
        return result;
    }
    async getStudentRegDetail(appid: any) {
        const result = await this.get('RegStudentDetail', [appid]);
        return result;
    }

    async updatereadmission(dto: any) {
        const result = await this.put('Readmission', dto);
        return result;
    }

    async GetStudentGuardianForRequest(entityid: any, guardianid: any) {
        const result = await this.get('GuardianStudent', [entityid, guardianid]);
        return result;
    }
    async getStudentsearch(valuegiven: any) {
        const result = await this.get('Studentsearch', [valuegiven]);
        return result;
    }

    // 09-Dec-2023
    // Amir Moavia
    // Left Student Search
    // Start
    async getLeftStudentsearch(valuegiven: any) {
        const result = await this.get('LeftStudentsearch', [valuegiven]);
        return result;
    }
    // End

    // 29-Dec-2023
    // Amir Moavia
    // Continue Student Search
    // Start
    async getContinueStudentsearch(valuegiven: any) {
        const result = await this.get('ContinueStudentsearch', [valuegiven]);
        return result;
    }
    // End

    async getEntityStudentList(entityid: any) {
        entityid = entityid == null || entityid == undefined ? Number(this.localStorage.get("selectedEntity")) : entityid;
        const result = await this.get('EntityStudent', entityid);
        return result;
    }
    async GetSingleStudentacd(std: any, status: any) {
        const result = await this.get('SingleStudentacd', [std, status]);
        return result;
    }

    async getStudentNotifySMSorAppClient(clientid: any) {
        const result = await this.get('StudentNotifySMSorApp', [clientid, 0]);
        return result;
    }
    async GetStudentDefaulterList(entityid: any, lovstatusid: any) {
        const result = await this.get('DefaulterStudentList', [entityid, lovstatusid]);
        return result;
    }
    async getStudentsForConduct(clientid: any, entityid: any, lovstatusid: any, courseid: any, sectionid: any, subjectid: any, ques: any) {

        let reflovstatusid = (lovstatusid == undefined || lovstatusid == 0 || lovstatusid == "") ? 'null' : lovstatusid;
        const result = await this.get('StudentForConduct', [clientid, entityid, reflovstatusid, courseid, sectionid, subjectid, ques]);
        return result;
    }


    async PostStudentsInBulk(data: any) {
        const result = await this.post('StudentsInBulk', data);
        return result;
    }

    async getStudentRefundRequest(entityid: any) {
        const result = await this.get('StudentRefundRequest', entityid);
        return result;
    }
    async GetStudentWiseGuardian(entityid: any, stdid: any) {
        const result = await this.get('StudentWiseGuardian', [entityid, stdid]);
        return result;
    }
    async getAllEntityStudents() {
        const result = await this.get('AllEntityStudent', [this.localStorage.get('selectedEntity')]);
        return result;
    }    

    // 14-May-2023
    // Amir Moavia
    // Student Course & Status Wise
    // Start
    async getStudentsCourseAndStatusWise(courseid: any, sectionid: any, lovstatusid: any) {
        let entityid = this.localStorage.get('selectedEntity');
        const result = await this.get('StudentsCourseAndStatusWise', [entityid, courseid, sectionid, lovstatusid]);
        return result;
    }
    // End
    async GetCouSecSubwise(acdyearid: any, courseid: any, sectionid: any, subjectid: any, atndate: any) {
        let entityid = this.localStorage.get('selectedEntity');
        const result = await this.get('StudentCourseSecSubwise', [entityid, acdyearid, courseid, sectionid,subjectid,atndate]);
        return result;
    }
}