<modal #myModal [ngStyle]="{ 'z-index': zindex }">
  <modal-header>
    <h5 class="modal-title">
      <i class="fa fa-bars mr-1"></i>
      Mandatory Fields
    </h5>
  </modal-header>

  <modal-content>
    <div class="row" *ngIf="!hideModalToolbar" style="border-bottom:1px solid #dddd;">
      <div class="col-lg-12 col-md-12 px-8">
        <div [ngClass]="
              mode === 0 || mode === 1 || mode === 3
                ? 'action-button div-toolbar div-toolbar-header'
                : 'action-button'
            ">
          <div (appClickOutside)="outsideClick()">
            <ul #modalsidemenu *ngIf="isShown" class="addtional-button dropdown-menu dropdown-menu-left show"
              style="top: 20px; left: 10px; padding: 0px">
              <div *ngFor="let grp of groups" class="panel panel-default"
                style="border: none !important; margin-top: 0px !important">
                <div class="panel-heading px-2 py-1">
                  <h4 class="panel-title">
                    {{ grp }}
                  </h4>
                </div>
                <div class="panel-collapse collapse in">
                  <div class="panel-body">
                    <div *ngFor="let x of filterData(grp)">
                      <a class="dropdown-item px-2 py-1" id="{{ x.name }}" type="button"
                        (click)="screen.customNavClickBase(x); outsideClick()">
                        {{ x.caption }}</a>
                    </div>
                  </div>
                </div>
              </div>
            </ul>
          </div>
          <button *ngIf="
                (mode === 0 || mode === 1 || mode === 3) && showList === true
              " type="button" class="btn-group pull-left" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false" style="min-width: 0" (click)="dropdownToggle($event)">
            <i class="fa fa-bars"></i>
          </button>
          <button *ngIf="!hideNew && (mode == 0 || mode == 1)" type="button" class="btn-group toolbar-action"
            accesskey="n" (click)="screen.clear()">
            <i class="fa fa-plus"></i> <abbr title="Alt+n">New</abbr>
          </button>
          <button *ngIf="!hideSave && (mode == 0 || mode == 1 || mode == 3)" type="button"
            class="btn-group toolbar-action" accesskey="s" (click)="SaveFields()">
            <i class="fa fa-save"></i>
            <abbr title="Alt+s">Save &amp; Close</abbr>
          </button>
          <button [disabled]="disabledAction" *ngIf="!hideSaveClose && (mode == 0 || mode == 1 || mode == 3)"
            type="button" class="btn-group toolbar-action" accesskey="c" (click)="saveAndClose()">
            <i class="fa fa-save"></i>
            <abbr title="Alt+c">Save &amp; Close</abbr>
          </button>
          <button *ngIf="!hideClose && (mode == 0 || mode == 1 || mode == 3)" type="button"
            class="btn-group toolbar-action" accesskey="x" (click)="closeModal()">
            <i class="fa fa-remove"></i> <abbr title="Alt+x">Close</abbr>
          </button>
          <button *ngIf="mode == 0" type="button" class="btn-group toolbar-action" (click)="deleteModalData()">
            <i class="fa fa-times"></i> Delete
          </button>
          <button *ngIf="mode == 0" type="button" class="btn-group toolbar-action" (click)="screen.moveFirst()">
            <i class="fa fa-angle-double-left"></i> First
          </button>
          <button *ngIf="mode == 0" type="button" class="btn-group toolbar-action" (click)="screen.movePrevious()">
            <i class="fa fa-angle-left"></i> Previous
          </button>
          <button *ngIf="mode == 0" type="button" class="btn-group toolbar-action" (click)="screen.moveNext()">
            Next <i class="fa fa-angle-right"></i>
          </button>
          <button *ngIf="mode == 0" type="button" class="btn-group toolbar-action" (click)="screen.moveLast()">
            Last <i class="fa fa-angle-double-right"></i>
          </button>
          <button *ngIf="mode == 0" type="button" class="btn-group toolbar-action" accesskey="h"
            (click)="showModalLister()">
            <i class="fa fa-search"></i> Search
          </button>
        </div>
      </div>
    </div>
    <form [formGroup]="myForm" novalidate>
      <div class="modal-body-content" style="padding:8px 30px 0px 30px;max-height: 290px !important;">
        <!-- <ng-content></ng-content> -->
        <div class="row" *ngFor="let data of fetchFields;">
          <div class="dcscolumn-12">
            <dcs-textbox *ngIf="data.type == 1" caption="{{data.caption}} *" Type="{{data.controltype}}"
              formControlName="{{data.field}}" placeholder=true [ReportDropdown]="true">
            </dcs-textbox>
            <dcs-dropdown *ngIf="data.type == 3" caption="{{data.caption}} *" [data]="data.dataset" [config]="data.config"
              [valueMember]="data.valueMember" [displayMember]="data.displayMember" formControlName="{{data.field}}"
              placeholder=true [ReportDropdown]="true">
            </dcs-dropdown>
            <dcs-textarea *ngIf="data.type == 2" class="validatorTextarea" caption="{{data.caption}} *" formControlName="{{data.field}}" rows="4" placeholder=true>
            </dcs-textarea>
            <dcs-numeric *ngIf="data.type == 8" caption="{{data.caption}} *" formControlName="{{data.field}}" [ReportDropdown]="true">
            </dcs-numeric>
            <div class="report-control" *ngIf="data.type == 4">
              <label class="report-label">{{data.caption}} *</label>
              <input type="date" formControlName="{{data.field}}"
                class="form-control dcs-control border-outline-valid w-100" />
              <!-- <ejs-datepicker #date formControlName="{{data.field}}" format="{{dateFormat}}" placeholder="{{dateFormat}}" class="newDatePickerReport border-outline-valid w-100"></ejs-datepicker> -->
            </div>
            <!-- <dcs-time *ngIf="data.type == 5" caption="{{data.caption}} *" formControlName="{{data.field}}" [ReportDropdown]="true">
            </dcs-time> -->
          </div>
        </div>
      </div>
    </form>
  </modal-content>

  <modal-footer> </modal-footer>
</modal>