import { Injectable } from "@angular/core";
import { UtService } from "../../service/Ut.Service";

@Injectable({ providedIn: "root" })
export class DCSReportService extends UtService<any> {
  constructor() {
    super("utreporthdr");
  }

  async GetReport(reportCode: any) {
    const rslt = await this.get("Report", reportCode);
    return rslt;
  }

  async GetReportType(code: any) {
    const rslt = await this.get("ReportType", code);
    return rslt;
  }
}
