import { GridDropDownComponent } from "./dropdown.component";
import { CommonModule } from "@angular/common";

import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DcsDropdownModule } from "../../../dcs-dropdown/dcs-dropdown.module";
@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, DcsDropdownModule],
  exports: [GridDropDownComponent],
  declarations: [GridDropDownComponent],
})
export class GridDropDownModule {}
