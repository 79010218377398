<!--component html goes here -->
<div class="div-toolbar">
    <button *ngIf="showList === true" type="button" class="btn-group pull-left" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" (click)="dropdownToggle($event)" style="min-width: 0;">
        <i class="fa fa-bars"></i>
    </button>

    <button *ngFor="let item of customButton"  [ngClass]="item.customButtonClass ? item.customButtonClass : ''" type="button" class="btn-group" [name]="item.name" (click)="customButtonClick(item)">
        <i [ngClass]="item.icon ? 'fa ' + item.icon : 'fa fa-gear'"></i>
        {{ item.caption }}
    </button>


</div>
<ul *ngIf="isShown" class="addtional-button show dropdown-menu-left grid-menu-mood p-0 list">
    <div *ngFor="let grp of menugroup" class="panel panel-default" style="border: none !important;margin-top: 0px !important;max-width: 180px;">
        <div class="panel-heading px-2 py-1">
            <h4 class="panel-title">
                {{ grp }}
            </h4>
        </div>
        <div class="panel-collapse collapse in">
            <div class="panel-body">
                <div *ngFor="let x of filterMenu(grp)">
                    <a class="dropdown-item px-2 py-1" id="{{ x.name }}" type="button" (click)="customButtonListClick(x)">
                        {{ x.caption }}</a>
                </div>
            </div>
        </div>
    </div>
</ul>


<style>
    .list {
        position: absolute;
        z-index: 999;
        left: 10px;
        top: 23px;
    }
</style>