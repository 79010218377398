import {AppService} from '../../../../../@core/service/App.Service';
import { Injectable } from '@angular/core'


@Injectable()
export class ExpenseService extends AppService<any>{

    constructor() {
        super('hdr_FITran');
    }

    // public selecteddata :any; 
    // async getExpenseList(entityid:any, fromdate:any, tilldate:any){
    //     var result = await this.get('ExpenseList',[entityid, fromdate, tilldate]);
    //     return result;
    // }
}
