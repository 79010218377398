import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { moodConfig } from "../../../../../@constant/config";


@Component({
  selector: "mood-cell",
  template: `
      <i *ngIf="isMenu" id="moodcell"  style="margin-left: 3px; cursor: pointer; " class="customMood" [class.selectedMenuDetail]="selectedMenuDetail" (click)="activeMenu()" [ngClass]="icon ? icon : ''"
        ><a >{{ innervalue ? "    " + innervalue : "" }}</a></i
      >
      <span *ngIf="!isMenu"  style="margin-left: 3px; cursor: pointer; " class="cellMood" (click)="moodCellClick($event)"
        >{{ firstValue ? " " + firstValue : "" }} <br/> {{ secondValue ? " " + secondValue : "" }}</span
      >
    `,
  styles: [
    `.cellMood {
        white-space: normal;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 16px !important;
        width: 70px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

    }

    `
    // "a{font-Family:'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;color:black;font-weight:400}"
  ]
})
export class MoodListerRendererComponent implements ICellRendererAngularComp {
  private params: any;
  public innervalue: string = "";
  private data: moodConfig[] = [];
  private valueMember: any;
  private displayMember: any;
  private column: any;
  icon: any;
  values: any = [];
  isMenu: boolean = false;
  firstValue: string = "";
  secondValue: string = "";

  agInit(params: any) {
    this.params = params;
    this.column = params.column;
    this.loadData();
    this.setvalue(params.value);
  }

  refresh(params: any): boolean {
    this.params = params;
    this.column = params.column;
    this.loadData();
    if (params.value) {
      this.setvalue(params.value);
    }
    return true;
  }

  moodCellClick(params: any) {
    if (typeof this.params.colDef.moodClick == "function") {
      this.params.colDef.moodClick(this.params);
    }
  }

  private setvalue(value: any) {
    if (this.data && value) {

      const fil: any = this.data.filter(
        (o: any) => String(o[this.valueMember]) === String(value)
      )[0];

      if (!this.isMenu && fil) {

        this.innervalue = fil[this.displayMember];
        this.icon = "";
      } else if (value && (value !== "1" && value !== "2" && value !== "3")) {

        this.firstValue = value.split(" ")[0];
        this.secondValue = value.split(" ")[1];
        this.innervalue = value;

        this.icon = "";
      }
      else if (this.params.values) {

        this.firstValue = this.params.values.split(" ")[0];
        this.secondValue = this.params.values.split(" ")[1];
        this.innervalue = this.params.values;

        this.icon = "";
      }
    }
    else {
      this.innervalue = "";

      this.icon = this.isMenu ? "fa fa-cogs" : "";

    }
  }

  private loadData() {
    this.valueMember = this.column.colDef.dataValueField;
    this.displayMember = this.column.colDef.dataDisplayField;
    this.isMenu = Boolean(this.column.colDef.isMenu);
    this.data = this.column.colDef.dropdownData;
  }
  selectedMenuDetail: boolean = false
  activeMenu() {
    if (Number(this.params.node.id) === this.params.node.childIndex) {
      this.selectedMenuDetail = true

    }
  }
}