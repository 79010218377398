// Angular Imports
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QuillModule } from 'ngx-quill';

// This Module's Components
import { DcsEditorComponent } from './dcs-editor.component';
import { EditorModule } from '@tinymce/tinymce-angular';

@NgModule({
    imports: [
        FormsModule, CommonModule, ReactiveFormsModule, QuillModule.forRoot(),
        EditorModule
    ],
    declarations: [
        DcsEditorComponent,
    ],
    exports: [
        DcsEditorComponent,
    ]
})
export class DcsEditorModule {

}
