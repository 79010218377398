import { Component, forwardRef, Input, ViewChild, ElementRef, AfterViewInit, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { QuillEditorBase } from 'ngx-quill';
import Quill, { Delta } from 'quill';
@Component({
    selector: 'dcs-editor',
    templateUrl: 'dcs-editor.component.html',
    styleUrls: ['dcs-editor.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DcsEditorComponent),
            multi: true
        }
    ]
})
export class DcsEditorComponent implements ControlValueAccessor, AfterViewInit {

    QuillEditor: any;
    NewValue: any = "";

    @ViewChild('quill') quill: QuillEditorBase | any;
    @ViewChild('hiddenEditor', { static: true }) hiddenEditor: any;

    private onTouchedCallback!: () => void;
    private onChangeCallback!: (_: any) => void;
    discloseValidatorChange: (() => void) | undefined;

    async ngAfterViewInit() {
    }
    async onEditorCreated(editor: any) {
        this.QuillEditor = editor;
    }

    // This Function Will Convert the Given Data From Delta Into HTML

    convertDeltaToHTML(delta: any) {
        const quill = new Quill(document.createElement('div'));
        quill.setContents(delta);
        this.innerValue = quill.root.innerHTML;
        // this.NewValue = this.innerValue
        // this.quill.writeValue();
    }
    // This Function Will Convert the Given Data From HTML Into Delta

    convertHTMLToDelta(html: string) {
        const quill = new Quill(document.createElement('div'));
        quill.clipboard.dangerouslyPasteHTML(html);
        const deltaStringfy = JSON.stringify(quill.getContents().ops);
        this.innerValue = deltaStringfy;
        this.value = html;
        this.NewValue = deltaStringfy;
    }

    private _name: string = "";
    @Input()
    get name(): string {

        return this._name;
    }
    set name(value: string) {

        this._name = value;
    }

    private innerValue: any = "";
    get value(): any {
        return this.innerValue;
    }

    @Input()
    set value(v: any) {
        if (v !== this.innerValue) {
            this.innerValue = v;
            // this.FormateData();
            this.onChangeCallback(v);
        }
    }
    // onBlur() {
    //     if (this.QuillEditor && this.innerValue) {
    //         this.value = this.convertHTMLToDelta(this.innerValue);
    //     }
    // }


    async FormateData() {
        if (this.quill && this.value && (this.value == this.innerValue)) {
            if (this.isHTMLFormat(this.value)) {
                this.convertHTMLToDelta(this.value);
            }
            else {
                if (await this.isDeltaFormat(JSON.parse(this.value))) {
                    this.convertDeltaToHTML(JSON.parse(this.value));
                }
            }
        }
        return this.NewValue;
    }
    isHTMLFormat(data: any): boolean {
        try {
            const parser = new DOMParser();
            const doc = parser.parseFromString(data, 'text/html');
            return Array.from(doc.body.childNodes).some(node => node.nodeType === Node.ELEMENT_NODE);
        } catch (error) {
            return false;
        }
    }

    async isDeltaFormat(data: any) {
        if (Array.isArray(data)) {
            for (const item of data) {
                if (typeof item === 'object' && 'insert' in item) {
                    if (typeof item.insert === 'string') {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    private _fullpage: string = ""
    @Input()
    get fullpage(): string {

        return this._fullpage
    }
    set fullpage(value: string) {

        this._fullpage = value;
    }

    _type: number = 0;

    @Input()
    set type(type: number) {
        this._type = type;
    }
    get type(): number {
        return this._type
    }

    _height: number = 400;
    @Input()
    set height(type: number) {
        this._height = type;
        this.setHeight(this._height);
    }
    get height(): number {
        return this._height
    }

    async setHeight(height: any) {

    }

    writeValue(value: any) {
        if (value !== this.innerValue) {
            this.innerValue = value;
        }
        this.FormateData();
    }

    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }
    registerOnValidatorChange?(fn: () => void): void {
        this.discloseValidatorChange = fn;
    }
}