import { Component, OnInit } from "@angular/core";
import { EnvService } from "./env.service";

@Component({
  selector: "app-root",
  template: `<router-outlet> </router-outlet>`,
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  title = "app";
  constructor(private env: EnvService) {}
  ngOnInit(): void {}
}
