<!--component html goes here -->
<div class="card-header d-flex flex-row align-items-center justify-content-between">
    <div style="display: flex; align-items: center;">
        <div *ngIf="showmenu == true" class="menuopen"><i class="fa fa-bars"></i>
            <ol class="videonlist">
                <li>
                    <h4>Options</h4>
                </li>
                <li *ngFor="let item of datalist" (click)="menuClick($event)">
                    <p>{{item}}</p>
                </li>
            </ol>
        </div>
        <h6 class="m-0 font-weight-bold text-primary removeafter">{{title}}</h6>
    </div>
    <div class="dropdown no-arrow d-flex">
        <button *ngIf="showopenModal == true" type="button " class="btn btn-box-tool" data-widget=" "
            (click)="openModal()">
            <i class="fa fa-window-maximize dashboard-box-icon"></i>
        </button>
        <button *ngIf="showRefresh == true" type="button " class="btn btn-box-tool" (click)="resetChart($event)"
            data-widget=" ">
            <i class="fa fa-refresh dashboard-box-icon cursor-pointer" aria-hidden="true" (click)="reloadchart()"
                [ngClass]="{ 'fa-spin': isReloadChart }"></i>
        </button>
        <button *ngIf="showDownload == true" type="button " class="btn btn-box-tool" data-widget=" ">
            <i (click)="downloadDataOrImage()" class="fa fa-download dashboard-box-icon"
                style="float: right; cursor: pointer; text-align: center">
            </i></button>
    </div>
</div>