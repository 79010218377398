import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute, Data } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { ContainerComponent } from 'src/app/@core/base/nav/Container.Component';
import { MenusService } from 'src/app/@core/service/menus.service';
// import { NavComponent } from 'src/app/@core/base/nav/Nav.Component';
// import {BreadcrumbService} from "./breadcrumb.service"
@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html'
})
export class BreadcrumbComponent extends ContainerComponent implements OnInit {


  data = this.localStorage.get("modulename")
  pageInfo: Data = Object.create(null);
  tab: string="Dashboard";
  tabIcon: any;
  tabId: any;
  constructor(
    // private BreadcrumbService : BreadcrumbService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private myService: MenusService,
  ) {
    super()

    this.myService.myMethod$.subscribe((data: any) => {

      this.data = data; // And he have data here too!
      this.title = ''
    },
      this.myService._myMethod$.subscribe((data: any) => {
        this.setValues(data)
      }

      ),
      this.router.events
        .pipe(filter(event => event instanceof NavigationEnd))
        .pipe(map(() => this.activatedRoute))
        .pipe(
          map(route => {
            while (route.firstChild) {
              route = route.firstChild;
            }
            return route;
          })
        )
        .pipe(filter(route => route.outlet === 'primary'))
        .pipe(mergeMap(route => route.data))
        .subscribe(event => {
          this.pageInfo = event;
        }));
  }
  async ngOnInit() { }
  homescreen() {
    this.router.navigate(['Dashboard/Attendance'])
  }
  linkToscreen(data: any) {
    //
    // this.router.navigate(['Student'])
  }
  setValues(data:any){
    
    this.tab = data.Value
    this.tabIcon = data.icon
    this.tabId = data.id
  }
}
