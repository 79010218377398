import { AfterViewInit, Component, Input, ViewChild } from "@angular/core";
import { AgRendererComponent } from "ag-grid-angular";
import {
  IAfterGuiAttachedParams,
  ICellRendererParams,
} from "ag-grid-community";
import { MapConfiguration } from "../../../../../@constant/config";

@Component({
  selector: "select-cell",
  template: ` <dcs-dropdown
    #select
    class="grid-dropdown"
    [type]="2"
    (change)="valueChange()"
    (KeyPress)="onKeyDown($event)"
    [showNone]="ShowNoneVal"
    [(ngModel)]="value"
  ></dcs-dropdown>`,
})
export class GridDropDownComponent
  implements AgRendererComponent, AfterViewInit {
  ngAfterViewInit(): void {
    setTimeout(() => {
      if (
        this.dropdown &&
        this.dropdown.element &&
        this.dropdown.element.nativeElement
      ) {
        this.dropdown.element.nativeElement.focus();
      }
    });
  }

  @ViewChild("select") dropdown: any;

  @Input()
  defaultOrderbyIs: boolean = true;

  ShowNoneVal: boolean = true;

  refresh(params: any): boolean {
    return true;
  }

  onKeyDown(e: any) {
    if (e.shiftKey && e.keyCode == 79) {
    }
    if (e.keyCode === 8) {
      this.value = undefined;
      this.params.setValue(this.value);
    }
  }

  afterGuiAttached(params?: IAfterGuiAttachedParams): void { }
  private params: any;

  private column: any;

  public value: any;

  constructor() { }

  private async loadData() {
    this.dropdown.fieldName = this.column.colId + this.params.rowIndex;
    this.dropdown.showCaption = false;
    this.dropdown.valueMember = this.column.colDef.dataValueField;
    this.dropdown.displayMember = this.column.colDef.dataDisplayField;
    this.dropdown.caption = this.column.colDef.headerName;
    this.dropdown.config = this.column.colDef.config;
    this.dropdown.data = this.column.colDef.dropdownData;
    this.dropdown.defaultOrderbyIs = this.column.colDef.defaultOrderbyIs;
    if (
      this.dropdown &&
      this.dropdown.element &&
      this.dropdown.element.nativeElement
    )
      this.dropdown.element.nativeElement.children[0].children[2].children[0].children[1].disabled =
        !Boolean(
          this.params.colDef.editable === undefined
            ? true
            : this.params.colDef.editable
        );
  }

  async agInit(params: ICellRendererParams) {
    setTimeout(async () => {
      const m_element: HTMLElement =
        this.dropdown.element.nativeElement.children[0].children[1];

      if (m_element) {
        m_element.classList.add("grid-dropdown-height");
      }
      const elementheight: HTMLElement =
        this.dropdown.element.nativeElement.children[0].children[2];
      if (elementheight) {
        elementheight.classList.add("grid-dropdown-height");
      }
      const element: HTMLElement =
        this.dropdown.element.nativeElement.children[0].children[2].children[0]
          .children[1];

      if (element) {
        element.classList.add("grid-select-control");
        element.style.width = "100%";
        // element.classList.add('grid-dropdown-height');
      }

      const span: HTMLElement =
        this.dropdown.element.nativeElement.children[0].children[2].children[0]
          .children[0];

      if (span) {
        span.classList.add("grid-dropdown-lister");
        span.classList.add("grid-icon-control");
        span.classList.add("grid-input-search-icon");
      }
      
      this.params = params;
      this.value = this.params.value;
      this.column = this.params.column;
      await this.loadData();
      this.value = !this.value ? this.column.colDef.value : this.value;
      this.ShowNoneVal = this.params && this.params.colDef && this.params.colDef.ShowNoneVal != undefined ? this.params.colDef.ShowNoneVal : true;
      params.node.setDataValue(
        this.column.colDef.field,
        !this.value ? this.column.colDef.value : this.value
      );
      this.mapFields();
      const span1: HTMLElement =
        this.dropdown.element.nativeElement.children[0].children[0].children[1];
      if (span1) {
        span1.classList.remove("dropdown-radius");
      }
    }, 0);
  }

  getValue(): any {
    return this.params.value;
  }

  valueChange() {
    this.params.setValue(this.value);
    this.mapFields();
  }

  mapFields() {
    if (
      this.params &&
      this.params.node &&
      this.column.colDef.mapFields &&
      this.dropdown.data &&
      this.value
    ) {
      this.column.colDef.mapFields.forEach((element: MapConfiguration) => {
        const data = this.dropdown.data.filter(
          (o: any) => o[this.dropdown.valueMember] === this.value
        )[0];
        if (data) {
          const val = data[element.mapField];
          if (val) {
            this.params.node.setDataValue(element.field, val);
          }
        }
      });
    }
  }

  public async setControlFocused() {
    setTimeout(() => {
      if (
        this.dropdown &&
        this.dropdown.element &&
        this.dropdown.element.nativeElement &&
        this.dropdown.element.nativeElement.children &&
        this.dropdown.element.nativeElement.children[0] &&
        this.dropdown.element.nativeElement.children[0].children[0]
      ) {

        this.dropdown.element.nativeElement.children[0].children[1].children[0].children[1].focus();
      }
    }, 0);
  }
}
