import {ComponentModule} from '../../../../@core/components/components.module';
// Angular Imports
import { NgModule } from "@angular/core";

// This Module's Components
import { AttachmentComponent } from "./attachment.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { SMAttachmentService } from "./attachment.service";
import { ChartsModule } from "ng2-charts";
import { ImageService } from '../../../../shared/services/image.service';
import { LovService } from '../../../../shared/services/lov.service';

@NgModule({
  imports: [
    FormsModule,
    // JustgageModule,
    ReactiveFormsModule,
    CommonModule,
    // NgxErrorsModule,
    // Ng2Bs3ModalModule,
    ComponentModule,
    // TextMaskModule,
    // DataTableModule,
    ChartsModule
  ],
  declarations: [AttachmentComponent],
  exports: [AttachmentComponent],
  providers: [SMAttachmentService, LovService, ImageService],
})
export class AttachmentModule {}
