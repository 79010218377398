<!-- <div class="upload-std-img bordered largeGaps">
    <div class="imgBoX">
        <img src="../../../../assets/images/image_upload.png" *ngIf="!!!url" alt="Avatar" style="width: 140px;
    height: 125px;">
        <img [src]="url" *ngIf="url" alt="Image" onerror="this.src='../../../../assets/images/image_upload.png'" style="width: 140px;
height: 125px;">
    </div>
    <div class="middle upload-btn-area">
        <div class="file-input-wrapper" *ngIf="canDelete">
            <button (click)="openModal()" class="btn btn-primary btn-block upload-img-btn">
                <i class="fa fa-plus"></i> Add Image</button>
        </div>
        <div class="file-input-wrapper" *ngIf="!canDelete">
            <a href="#" class="btn btn-primary btn-block upload-img-btn" (click)="removePicture()">
                <i class="fa fa-trash"></i> Remove Image</a>
        </div>
    </div>
</div>
<dcs-modal #modal mode='4' Caption='upload image'>
    <input type="file" (change)="fileChangeEvent($event)" />
    <div class="row align-items-center">
        <div class="col-lg-6 col-md-6">
            <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="2/2"
                format="png" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
                (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()"></image-cropper>
        </div>
        <div class="col-lg-6 col-md-6 my-auto mx-auto p-3">
            <img [src]="croppedImage" width="250" class="img-fluid"
                [style.border]="croppedImage ? '1px solid black' : 'none'" />
        </div>
    </div>
    <button type="button" class="btn btn-primary btn-flat" (click)="uploadImage(croppedImage)">
        <i class="fa fa-upload"></i> Upload
    </button>
</dcs-modal> -->
<label style="padding-left: 31px;">{{caption}}</label>
<!-- [editmood]="false"  this is property for disable edit aur enable edit -->
<div class="imgcheck" [ngClass]="editmood == true ? 'image-box' : 'image-boxs'">
    <div class="image-box-bor"><i class="fas fa-pencil-alt"></i> </div>
    <div class="image-hover-eff" *ngIf="canDelete">
        <div class="image-hover-eff-add">
            <i class="fa fa-plus addbtnbl" (click)="openModalnew()"></i>
        </div>
        <img src="../../../../assets/images/upload_image.png" *ngIf="!!!url" alt="Avatar">
        <img [src]="url" *ngIf="url" alt="Image" onerror="this.src='../../../../assets/images/upload_image.png'">
        <!-- <div *ngIf="!hideButtons">
            <div class="file-input-wrapper" *ngIf="canDelete">
                <button (click)="openModal()" class="btn btn-primary upload-img-btn">
                    <i class="fa fa-plus"></i> Add Image</button>
            </div>
            <div class="file-input-wrapper" *ngIf="!canDelete">
                <a href="#" class="btn btn-primary upload-img-btn" (click)="removePicture()">
                    <i class="fa fa-trash"></i> Remove Image</a>
                </div>
            </div> -->
        <!-- (click)="removePicture()" -->
    </div>
    <div class="image-hover-eff-cancel" *ngIf="!canDelete">
        <div class="image-hover-eff-add">
            <i class="fas fa-times addbtnred" style="background: red;" (click)="removeshowPicture()"></i>
            <i class="fas fa-pencil-alt addbtngren" style="background: green;" (click)="openModal()"></i>

        </div>
        <img src="../../../../assets/images/upload_image.png" *ngIf="!!!url" alt="Avatar">
        <img [src]="url" *ngIf="url" alt="Image" onerror="this.src='../../../../assets/images/upload_image.png'">
        <!-- <div *ngIf="!hideButtons">
            <div class="file-input-wrapper" *ngIf="canDelete">
                <button (click)="openModal()" class="btn btn-primary upload-img-btn">
                    <i class="fa fa-plus"></i> Add Image</button>
            </div>
            <div class="file-input-wrapper" *ngIf="!canDelete">
                <a href="#" class="btn btn-primary upload-img-btn" (click)="removePicture()">
                    <i class="fa fa-trash"></i> Remove Image</a>
                </div>
            </div> -->
        <!-- (click)="removePicture()" -->
    </div>
</div>
<div class="upload-modal">
    <dcs-modal #modal mode='4' Caption="Upload Image" (onClose)="closemymodal($event)">
        <div class="row">
            <div class="dcscolumn-12 div-toolbar">
                <button class="btn-group" (click)="uploadImage(croppedImage)">
                    <i class="fa fa-save"></i>&nbsp;Save
                </button>
                <button class="btn-group" (click)="closeModal()">
                    <i class="fa fa-remove"></i>&nbsp;Close
                </button>
            </div>
            <!-- <report-viewer #report [reportUrl]="url"></report-viewer> -->
        </div>
        <div class="row align-items-center">
            <!-- <div class="col-12" *ngIf = "!dummyImageHide">
                <img src="../../../../assets/images/helloworld.jpg" alt="" class="img-fluid" >
            </div> -->
            <div class="col-lg-6 col-md-6">
                <div style="width: 250px;min-height: 250px; background: var(--edphilight);">
                    <div *ngIf="imageChangedEvent">
                        <image-cropper [imageChangedEvent]="imageChangedEvent" [containWithinAspectRatio]="true"
                            [maintainAspectRatio]="true" [aspectRatio]="4/3" format="png"
                            (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
                            (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()"></image-cropper>
                    </div>
                </div>
                <div class="row">
                    <div class="dcscolumn-12 div-toolbar">
                        <input type="file" name="file" id="file" class="inputfile " (change)="fileChangeEvent($event)"
                            (click)="onInputClick($event)" accept="image/*" />
                        <!-- <i class="fas fa-images "></i>&nbsp;Browse -->
                        <label class='btn-group' style="padding-top: 3px !important;" for="file"><i
                                class="fas fa-images "></i>&nbsp;Browse</label>
                        <button class="btn-group" (click)="removePicture()">
                            <i class="fa fa-remove"></i>&nbsp;Clear
                        </button>
                    </div>
                    <!-- <report-viewer #report [reportUrl]="url"></report-viewer> -->
                </div>
                <!-- <div class="div-toolbar">
                    <input type="file" name="file" id="file" class="inputfile " (change)="fileChangeEvent($event)" />
                    <label class='my-label' for="file"><i class="fas fa-images "></i>Browse</label>
                    <button type="button" class="upload-btn mx-1" (click)="uploadImage(croppedImage)">
                        <i class="fa fa-upload"></i> Clear
                    </button>
                </div> -->
                <!-- <button></button> -->
            </div>
            <div class="col-lg-6 col-md-6 my-auto mx-auto p-3">
                <img [src]="croppedImage" style="background: #ddd;" width="250" class="img-fluid"
                    [style.border]="croppedImage ? '1px solid black' : 'none'" />
            </div>
        </div>
        <!-- <div style="float: right;">
            <input type="file" name="file" id="file" class="inputfile mx-1" (change)="fileChangeEvent($event)" />
            <label class='my-label' for="file"><i class="fas fa-images mr-1"></i>Browse</label>
            <button type="button" class="upload-btn mx-1" [disabled]="disabled" (click)="uploadImage(croppedImage)">
                <i class="fa fa-upload"></i> Upload
            </button>
        </div> -->
    </dcs-modal>
</div>