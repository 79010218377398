<div *ngIf="type == 1">
  <modal #modal class="lmodal" style="z-index: 99999">
    <modal-header class="with-border">
      <h5 style="padding: 3px; font-weight: bold" class="modal-title">
        {{ Caption }}
      </h5>
    </modal-header>

    <modal-content class="lister-body">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="padding-top: 2px">
          <!-- <template #lookupGrid></template> -->
          <dcs-aggrid #agGrid *ngIf="defaultOrderby == true || defaultOrderby == undefined" [type]="Gridtype"
            [hideSerial]="false" [isExport]="true" [pagination]="true" [columnPanel]="true" [columns]="columns"
            [data]="Data" [customButton]="customButtongrid"
            (toolbarClick)="toolbarclickgrid($event)" (onRowClicked)="Gridtype != 2 ? select($event) : null">
          </dcs-aggrid>
          <!-- | sortByText: 'asc':displayMember -->
          <dcs-aggrid #agGrid *ngIf="defaultOrderby == false" [type]="Gridtype" [hideSerial]="false" [isExport]="true"
            [pagination]="true" [columnPanel]="true" [columns]="columns" [data]="Data" [customButton]="customButtongrid"
            (toolbarClick)="toolbarclickgrid($event)" (onRowClicked)="Gridtype != 2 ? select($event) : null">
          </dcs-aggrid>
        </div>
      </div>
    </modal-content>
    <modal-footer>
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div style="width: 100%; margin-top: -4px; height: 18px">
            <span class="lookup-Totalcount-label">Total Records :
              <span style="font-size: 12px; font-weight: bold; margin-left: 5px">{{ totalrecord }}</span>
            </span>
          </div>
        </div>
      </div>
    </modal-footer>
  </modal>
</div>
<style>
  .lookup-Totalcount-label {
    color: var(--edpmain);
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 600;
    /* background-color: #e2e2e2; */
    height: 22px;
    padding-right: 0px;
    padding-left: 5px;
  }
</style>
<div *ngIf="type == 2">
  <div class="modal-header with-border">
    <h5 style="padding: 3px; font-weight: bold" class="modal-title">
      {{ Caption }}
    </h5>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body lister-body">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="padding-top: 2px">
        <dcs-aggrid #agGrid *ngIf="defaultOrderby == true || defaultOrderby == undefined" [type]="4" [columns]="columns"
          [data]="Data " (onRowClicked)="select($event)">
        </dcs-aggrid>
        <!-- | sortByText: 'asc':displayMember -->
        <dcs-aggrid #agGrid *ngIf="defaultOrderby == false" [type]="4" [columns]="columns" [data]="Data"
          (onRowClicked)="select($event)">
        </dcs-aggrid>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div style="width: 100%; margin-top: -4px; height: 18px">
          <span class="lookup-Totalcount-label">Total Records :
            <span style="font-size: 12px; font-weight: bold; margin-left: 5px">{{ totalrecord }}</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>