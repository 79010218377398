import { Component, ComponentFactoryResolver, ContentChild, ElementRef, forwardRef, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AppConstants } from 'src/app/@constant/app.constant';
import { ControlBase } from '../../../@constant/config';
import { Broadcast } from '../../events/broadcast';
import { LocalStorage } from "../../helper/localStorage";
import { GenEnum } from '../../../@constant/general.enum';
import { number } from 'ngx-custom-validators/src/app/number/validator';

@Component({
  selector: 'dcs-numeric',
  templateUrl: 'dcs-numeric.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DcsNumericComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DcsNumericComponent),
      multi: true
    },
    {
      provide: ControlBase,
      useExisting: DcsNumericComponent
    },
  ]
})
export class DcsNumericComponent extends ControlBase
  implements ControlValueAccessor, Validator {
  selectedItem: any[] = [];
  decimalPipe: number = 0;
  @ContentChild(forwardRef(() => "DcsNumericComponent"))
  myscreens?: DcsNumericComponent;
  @ViewChild("droplookup", { read: ViewContainerRef }) container: any;
  componentRef: any;
  protected lookupSubscription?: Subscription;

  @ViewChild("numeric") select?: ElementRef;
  touchedEL: boolean = false;

  constructor(
    private element: ElementRef,
    private resolver: ComponentFactoryResolver,
    private broadcast: Broadcast,
    private localStorage: LocalStorage,) {
    super();
    this.broadcast = AppConstants.injector.get(Broadcast);
    this.decimalPipe = Number(localStorage.get("defaultDecimalPlaces"));
  }


  DocFieldsData: any = [];
  DocFieldsReportData: any = [];
  @Input()
  formControlName: string | any;

  DefaultProperties: any = {};
  async ngOnInit() {

    // Default Modifications
    this.DefaultProperties = {};
    this.DefaultProperties.caption = this.caption;
    this.DefaultProperties.disabled = this.disabled;
    // Default Modifications

    if (this.ReportDropdown == true) {
      this.DocFieldsReportData = JSON.parse(this.localStorage.get("DocFieldsReport"));
      if (this.DocFieldsReportData && this.DocFieldsReportData.length && this.DocFieldsReportData.length > 0) {
        var isConfig = this.DocFieldsReportData.filter((dt: any) => dt.fieldname == this.formControlName)[0];
        if (isConfig && isConfig.caption) {
          if (this.caption.includes("*")) {
            this.caption = isConfig.caption + "*";
          }
          else {
            this.caption = isConfig.caption;
          }
        }
        if (isConfig && isConfig.disabled == "True") {
          this.disabled = true;
        }
        else {
          this.disabled = false;
        }
      }
    }
    else if (this.ReportDropdown == false) {
      this.DocFieldsData = JSON.parse(this.localStorage.get("DocFields"));
      if (this.DocFieldsData && this.DocFieldsData.length && this.DocFieldsData.length > 0) {
        var isConfig = this.DocFieldsData.filter((dt: any) => dt.fieldname == this.formControlName)[0];
        if (isConfig && isConfig.caption) {
          if (this.caption.includes("*")) {
            this.caption = isConfig.caption + "*";
          }
          else {
            this.caption = isConfig.caption;
          }
        }
        if (isConfig != undefined) {
          if (isConfig && isConfig.disabled == "True") {
            this.disabled = true;
          }
          else {
            this.disabled = false;
          }
        }
      }
    }

    // Setup Form Control Properties 
    var item: any = [{
      field: this.formControlName,
      caption: this.caption,
      type: GenEnum.ControlType.Number,
    }];
    var old = this.localStorage.get('ControlProperties');
    if (old === "null" || old === null || old == undefined || old == "undefined") {
      this.localStorage.set('ControlProperties', JSON.stringify(item));
    } else {
      old = JSON.parse(old);
      var dupli = old.filter((a: any) => a.field == this.formControlName);
      if (dupli.length == 0) {
        old.push({
          field: this.formControlName,
          caption: this.caption,
          type: GenEnum.ControlType.Number,
        });
        this.localStorage.set('ControlProperties', JSON.stringify(old));
      }
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    // Field Configuration Algorithem 
    setTimeout(async () => {
      
      await this.FieldCOnfigurationAlgorithm();
    }, 0);
    // Field Configuration Algorithem
    if (!this.touchedEL == false) {
      this.touchedEL = false
    }

    this.controlEl = control
    if ((control.value == '' || control.value == undefined || control.value == null) && this.select && control.errors && control.errors.required) {
      this.errMsg = "cannot be blank"
      return { invalid: true }
    }


    if (this.maxValue && this.minValue) {
      var min = Number(this.minValue);
      var max = Number(this.maxValue)
      if ((control.value < min || control.value > max) && this.select) {
        this.touchedEL = true
        this.errMsg = "cannot be greater than " + this.maxValue
        return { maxInvalid: true }
      }
    }
    return null;
  }
  errorCaption: any
  controlEl: any
  invalid: boolean = false
  errMsg: any

  @Input()
  ReportDropdown: boolean = false;
  public innerValue: any = "";

  private onTouchedCallback!: () => void;
  private onChangeCallback!: (_: any) => void;

  @Input()
  get value(): any {
    return this.innerValue;
  }

  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  onBlur() {


    if ((this.innerValue == '' || this.innerValue == undefined || this.innerValue == null) && this.controlEl && this.controlEl.errors && this.controlEl.errors.required) {
      this.touchedEL = true
      this.errMsg = "cannot be blank"
    }
    else {
      this.touchedEL = false
      this.errMsg = ""
    }
    this.onTouchedCallback();

    if (this.allowDecimal == true) {
      this.innerValue = Number(this.innerValue).toFixed(this.decimalPipe != undefined ? this.decimalPipe : 0);
    }

  }

  writeValue(value: any) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  @Input()
  caption: any;
  @Input()
  minValue: any;
  @Input()
  maxValue: any;

  @Input()
  required: boolean = false;

  @Input()
  disabled: boolean = false;

  @Input()
  showCaption: boolean = true;

  @Input() placeholder: boolean = false;
  @Input() // Placeholder value set after input property "placeholder=true"
  placeholderValue() {

    if (this.placeholder) {
      return "Enter " + this.cleanCaption()
    }
    else {
      return "";
    }
  }
  @Input()
  allowDecimal: boolean = false;
  @Input()
  maxLength: number = 0;

  public innerChange(event: any) {

    if (!isFinite(event.key)) {
      if (event.key === "." && this.allowDecimal) return true;
      else return false;
    }

    if (this.maxLength) {

      if (this.value.length == this.maxLength) return false;
    }
  }

  @Input()
  max: number = 0;

  @Input()
  isFloat: boolean = false;

  @Input()
  min: number = 0;

  @Input()
  position: number = 1;

  @Input()
  fieldName: string = "";

  public _fieldWidth = "input-group col-lg-3 col-md-3 col-sm-9 col-xs-9 p-0";

  @Input()
  get fieldWidth(): string {
    return this._fieldWidth;
  }
  set fieldWidth(value: string) {
    if (value && value.indexOf(",") !== -1) {
      let colSplit = value.split(",");

      if (colSplit && colSplit.length === 4) {
        this._fieldWidth =
          "input-group col-lg-" +
          (colSplit[0] ? colSplit[0] : "3") +
          " col-md-" +
          (colSplit[1] ? colSplit[1] : "3") +
          " col-sm-" +
          (colSplit[2] ? colSplit[2] : "3") +
          " col-xs-" +
          (colSplit[3] ? colSplit[3] : "3") +
          " p-0";
      }
    }
  }

  public _captionWidth = "col-lg-1 col-md-1 col-sm-3 col-xs-3 col-form-label";

  @Input()
  get captionWidth(): string {
    return this._captionWidth;
  }
  set captionWidth(value: string) {
    if (value && value.indexOf(",") !== -1) {
      let colSplit = value.split(",");

      if (colSplit && colSplit.length === 4) {
        this._captionWidth =
          "col-lg-" +
          (colSplit[0] ? colSplit[0] : "1") +
          " col-md-" +
          (colSplit[1] ? colSplit[1] : "1") +
          " col-sm-" +
          (colSplit[2] ? colSplit[2] : "3") +
          " col-xs-" +
          (colSplit[3] ? colSplit[3] : "3") +
          " col-form-label p-0";
      }
    }
  }
  cleanCaption() {
    if (this.caption.slice(-1) === "*") {
      return this.errorCaption = this.caption.slice(0, -1)
    }
    else {
      return this.errorCaption = this.caption
    }
  }

  async FieldCOnfigurationAlgorithm() {
    
    var AccessKey = this.localStorage.get("AccessKey");
    if (this.ReportDropdown != true) {
      this.DocFieldsReportData = JSON.parse(this.localStorage.get("DocFieldssingle"));
      if (this.DocFieldsReportData && this.DocFieldsReportData.length && this.DocFieldsReportData.length > 0) {
        var isConfig = this.DocFieldsReportData.filter((dt: any) => dt.fieldname == this.formControlName && dt.rightid == AccessKey)[0];
        if (isConfig) {
          if (isConfig && isConfig.caption) {
            if (this.caption.includes("*")) {
              this.caption = isConfig.caption + "*";
            }
            else {
              this.caption = isConfig.caption;
            }
          }
          // if (isConfig && isConfig.maskingtypeid) {
          //   this.type = "cprid";
          // }
          if (isConfig && (isConfig.disabled == true || isConfig.disabled == 'true')) {
            this.disabled = true;
          }
          else {
            this.disabled = false;
          }
        }
        else {
          if (this.caption && this.caption != "" ? (this.DefaultProperties.caption != this.caption) : false) {
            await this.SetDefaultStates();
          }
        }

      }
      else {
        if (this.caption && this.caption != "" ? (this.DefaultProperties.caption != this.caption) : false) {
          await this.SetDefaultStates();
        }
      }
    }
  }

  async SetDefaultStates() {
    this.caption = this.DefaultProperties.caption && this.DefaultProperties.caption != '' ? this.DefaultProperties.caption : this.caption;
    this.disabled = this.DefaultProperties.disabled;
  }
}
