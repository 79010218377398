// Angular Imports
import { NgModule } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

// This Module's Components
import { DcsPanelComponent } from "./dcs-panel.component";
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [NgbModule,  CommonModule],
  declarations: [DcsPanelComponent],
  exports: [DcsPanelComponent],
})
export class DcsPanelModule { }
