import { Component, forwardRef, Input, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { GenEnum } from '../../../@constant/general.enum';

@Component({
  selector: 'dcs-checkbox',
  templateUrl: 'dcs-checkbox.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DcsCheckboxComponent),
      multi: true
    }
  ]
})
export class DcsCheckboxComponent implements ControlValueAccessor {
  constructor() { }

  private innerValue: any = "";

  // private onTouchedCallback!: () => void;
  // private onChangeCallback!: (_: any) => void;'
  @Output()
  onCheckChanged: EventEmitter<any> = new EventEmitter<any>();

  onTouchedCallback: any = () => { };
  onChangeCallback: any = () => { };

  @Input()
  get value(): any {
    return this.innerValue;
  }


  @Input()
  checked: boolean = false;

  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  DocFieldsData: any = [];
  @Input()
  formControlName: string | any;
  async ngOnInit() {
    this.DocFieldsData = JSON.parse(localStorage.getItem("DocFields") || '{}');
    if (this.DocFieldsData && this.DocFieldsData.length && this.DocFieldsData.length > 0) {
      var isConfig = this.DocFieldsData.filter((dt: any) => dt.fieldname == this.formControlName)[0];
      if (isConfig && isConfig.caption) {
        if (this.caption.includes("*")) {
          this.caption = isConfig.caption + "*";
        }
        else {
          this.caption = isConfig.caption;
        }
        if(isConfig != undefined){
          if (isConfig && isConfig.disabled == "True") {
            this.disabled = true;
          }
          else {
            this.disabled = false;
          }
        }
      }
    }

    // Setup Form Control Properties 
    // var item: any = [{
    //   field: this.formControlName,
    //   caption: this.caption,
    //   type: GenEnum.ControlType.Checkbox,
    // }];
    // var old = localStorage.get('ControlProperties') || '{}';
    // if (old === "null" || old === null || old == undefined || old == "undefined") {
    //   localStorage.set('ControlProperties', JSON.stringify(item));
    // } else {
    //   old = JSON.parse(old);
    //   var dupli = old.filter((a: any) => a.field == this.formControlName);
    //   if (dupli.length == 0) {
    //     old.push({
    //       field: this.formControlName,
    //       caption: this.caption,
    //       type: GenEnum.ControlType.Checkbox,
    //     });
    //     localStorage.set('ControlProperties', JSON.stringify(old));
    //   }
    // }
  }
  onBlur() {
    this.onTouchedCallback();
  }

  onChange(ev: any) {
    this.onCheckChanged.emit(ev);
  }
  writeValue(value: any) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  @Input()
  caption: any;

  @Input()
  name: any;

  @Input()
  disabled: boolean = false;

  @Input()
  fieldName: any;

  @Input()
  position: number = 1;

  @Input()
  required: boolean = false;
}
