import { Component, Output, EventEmitter } from "@angular/core";
import { CellButtons } from "./buttons";
import { ICellRendererAngularComp } from "ag-grid-angular";
@Component({
  selector: "cell-buttons",
  templateUrl: "cell-buttons.component.html",
  styleUrls: ["cell-buttons.component.scss"]
})
export class CellButtonsComponent implements ICellRendererAngularComp {
  params: any;
  buttons: CellButtons[] = [];
  date: any
  constructor() { }
  agInit(params: any): void {
    
    this.params = params;
    this.buttons =
      this.params && this.params.colDef && this.params.colDef.buttons;
    this.date =
      this.params && this.params.colDef && this.params.colDef.date;
  }
  refresh(params?: any): boolean {
    return true;
  }
  onClick(item: CellButtons) {
    var data = []
    data.push(item)
    data.push(this.params.data)
    this.params.context.cellButtonClicked.emit(data);
    // this.params.data
  }
  getColor(item: any, i: any) {
    if (this.date && this.params.data[this.date] === i + 1) {
      return item.colorcode
    }
    else {
      return "#2188B0"
    }
  }
}
