
import { AfterViewInit, Component, EventEmitter, Output, ViewChild, ViewContainerRef } from "@angular/core";
import { ICellEditorAngularComp } from "ag-grid-angular";
import { List } from "linqts";
import { moodConfig, moodSummaryConfig } from '../../../../../@constant/config';

@Component({
  selector: "moodeditor-Summary",
  template: `
  
    <div #container class="addtional-button dropdown-menu show dropdown-menu-left grid-menu-mood mood p-0 position-contain"
  tabindex="0" (keydown)="onKeyDown($event)" style="box-shadow: 2px 2px 8px rgb(0 0 0 / 15%);max-height: 380px;left: 30px !important;
  background: var(--edpbackground);    
  ">

  <div class="panel-heading px-2 py-1" style="border-bottom: 1.5px solid #2878aa;background: var(--edphilight);">
    <h4 class="panel-title">
      {{data.title}}
    </h4>
  </div>
  <div class="panel-heading px-2 py-1" style="background: var(--edphilight);">
    <h4 class="panel-title">
      Weekly Summary
    </h4>

  </div>

  <div class="panel-collapse collapse in">
    <div class="panel-body">
      <div class="row">
        <div class="col-md-7 col-sm-7 col-xs-7" style="padding-top: 13px;
        padding-left: 27px;">
          <div class="row" style="display: grid;grid-template-columns: 6fr 1fr;">
            <div class="hours-details">
              <p style="display: grid;margin-bottom: 2px !important;font-size: 12px;
            grid-template-columns: 4fr 1fr;"><span style="font-weight: 600;">Total Hours:</span> <span
                  style="color: #1764a1;">{{data.headerObj.TotalHour}}</span></p>
            </div>
          </div>
          <div class="row" style="display: grid;
        grid-template-columns: 6fr 1fr;">
            <div class="hours-details">
              <p style="display: grid;margin-bottom: 2px !important;font-size: 12px;
          grid-template-columns: 4fr 1fr;"><span style="font-weight: 600;">Academic Hours: </span><span
                  style="color: #00a65a;">{{data.headerObj.Academichours}}</span></p>
            </div>
          </div>
          <div class="row" style="display: grid;
        grid-template-columns: 5fr 1fr;">
            <div class="hours-details">
              <p style="display: grid;margin-bottom: 2px !important;font-size: 12px;
            grid-template-columns: 9fr 2fr;"><span style="font-weight: 600;">Remaining Hours: </span><span
                  style="color: #1764a1;">{{data.headerObj.NotAllocated}}</span></p>
            </div>
          </div>
        </div>
        <div class="col-md-5 col-sm-5 col-xs-5">
          <div class="hours-details">
            <div class="single-chart" style="  width: 66px;  margin: 0 auto; margin-bottom: 0;">
              <svg viewBox="0 0 36 36" class="circular-chart blue">
                <path class="circle-bg" d="M18 2.0845
                                        a 15.9155 15.9155 0 0 1 0 31.831
                                        a 15.9155 15.9155 0 0 1 0 -31.831" stroke-dasharray="100" />
                <path class="circle present" attr.stroke-dasharray={{data.headerObj.Percentage}},100 d="M18 2.0845
                                        a 15.9155 15.9155 0 0 1 0 31.831
                                        a 15.9155 15.9155 0 0 1 0 -31.831" />
                <text style="font-size: 7px;" x="10" y="20.35" class="percentage">{{data.headerObj.Percentage}}%</text>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="panel-heading px-2 py-1"style="background: var(--edphilight);">
      <h4 class="panel-title" >
        Subject Summary
      </h4>

    </div>
    <div class="panel-collapse collapse in">
      <div class="panel-body" style="width: 558px;">
        <div class="col-md-12"
          style=" margin-top: 6px;height: 200px;overflow: scroll;overflow-x: hidden;overflow-y: auto;margin-top: 6px;">
          <table style="width: 100%; font-size: 13px;">
            <tbody class="timetbl-tbl">

              <tr style="font-size: 12px; border-bottom: 1px dotted #ddd;">
                <th style="text-align: left;  width: 95px;"> Subject</th>
                <th>Periods</th>
                <th>Hours</th>
                <th style="text-align: left;     ">Percentage</th>
              </tr>

              <tr *ngFor="let i of data.childrens">
                <td
                  style="text-align: left;     width: 95px;     display: block;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;font-size: 12px;"
                  title="{{i.Subject}}">
                  {{i.Subject}}</td>
                <td style="font-size: 12px;">{{i.Periods}}</td>
                <td  style="font-size: 12px;">{{i.SubjectHours}}</td>
                <td>
                  <p style="margin: 0;float: right; font-size: 9px;" data-value="80"> {{i.SubjectPercentage}}%</p>
                  <progress max="100" value="{{i.SubjectPercentage}}" class="progress-bar-width" style="width: 80%;">

                    <div class="progress-bar">
                      <span>{{i.SubjectPercentage}}</span>
                    </div>
                  </progress>
                </td>
              </tr>

              
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
    `,
  styles: [
    `
    .position-contain{
      z-index: 100;
align-items: center;
justify-content: center;
top: 15em; 
bottom: 0%;
  position: fixed;
  
    }
        .mood {
          // border-radius: 15px;
          // border: 2px solid #287fc4;
          // background: #e6e6e6;
          // padding: 3px 0 0 0;
          // text-align: left;
          // display: inline-block;
          // outline: none;
          // background-color: white;
          font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial,
            sans-serif;
            
         
            padding-top: 0px;
           
        }
  
        i {
          margin: 3px;
          padding: 3px;
          color: #287fc4;
          background-color: transparent;
          font-size: 12px;
        }
  
        // i span {
        //   font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial,
        //     sans-serif;
        //   font-weight: 600;
        // }
  
        i:hover {
          cursor: pointer;
          color: #fff;
          background-color: #1764a1;
        }
  
        // i:hover span {
        //   color: #fff !important;
        // }
        .boxWrapper {
          min-height: auto;
          max-width: 180px;
        }
  
        .selected {
          color: #fff;
          background-color: #1764a1;
        }
        .default {
          color: black;
          background-color: transparent;
        }
      `
  ]
})
export class MoodSummaryComponent
  implements ICellEditorAngularComp, AfterViewInit {
  private params: any;

  @ViewChild("container", { read: ViewContainerRef }) public container: any;
  data: moodSummaryConfig | any;
  private valueMember: any;
  private displayMember: any;
  private column: any;
  public value: any;
  groups: any = [];

  private async loadData() {

    this.valueMember = this.column.colDef.dataValueField;
    this.displayMember = this.column.colDef.dataDisplayField;

    this.data = this.column.colDef.dropdownData;

    if (!this.data.title)
      this.data.title = '';
    //  this.groups = new List<moodConfig>(this.data).Select(x => <moodConfig>{
    //    MoodGroup : x['MoodGroup']
    // }).Distinct().ToArray();

    // this.groups = new List<moodConfig>(this.data)
    //     .Select(o => o.MoodGroup === "" || o.MoodGroup === null || o.MoodGroup === undefined ? "Options" : o.MoodGroup)
    //     .Distinct()
    //     .ToArray();
    
  }

  // dont use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
  ngAfterViewInit() {
    //this.container.element.nativeElement.focus();
    
  }

  async agInit(params: any) {

    params.value = undefined;
    this.params = params;
    this.value = this.params.value;
    this.column = this.params.column;

    await this.loadData();

    this.setClass(this.value);
    var $ = jQuery;
    var tab = $(".ag-popup-editor")[0];
    if (tab) {
      var top = tab.style.top;
      var splittop = Number(top.split('px')[0]);
      var db = splittop + 17;
      tab.style.top = db + "px";

    }
  }

  getValue(): any {
    return this.value;
  }

  isPopup(): boolean {
    return true;
  }

  toggleMood(): void {
    this.value = this.params.value;
  }

  onClick(value: any) {
    this.value = value;
    this.setClass(value);
    this.params.api.stopEditing();
  }

  onKeyDown(event: any): void {
    let key = event.which || event.keyCode;
    if (
      key == 37 || // left
      key == 39
    ) {
      // right
      this.toggleMood();
      event.stopPropagation();
    }
  }

  filterData(grp: any): Array<moodConfig> {

    if (grp === "Options") {

      grp = "";
      return this.data.filter((o: any) => o.MoodGroup === grp);
    }
    else {

      return this.data.filter((o: any) => o.MoodGroup === grp);
    }

  }

  private setClass(id: any) {
    if (id && !Boolean(this.column.colDef.isMenu)) {
      var tags: NodeListOf<
        HTMLElement
      > = this.container.element.nativeElement.getElementsByTagName("a");
      for (var index = 0; index < tags.length; index++) {
        if (Number(tags[index].id) === Number(id)) {
          tags[index].classList.add("selected");
        } else {
          tags[index].classList.add("default");
        }
      }
    }
  }


 
}