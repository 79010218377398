import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from "@angular/common";
 import { ModalModule } from "ngb-modal";
import { NgModule } from "@angular/core";
import { DcsDocfieldModalComponent } from './dcs-docfield-modal.component';
import { utdocfieldsService } from '../../../shared/services/utdocfields.Service';
import { DCSErrorModule } from '../error/error.module';
import { utdocfieldsReportService } from '../../../shared/services/utdocfieldsReport.Service';
import { DcsDropdownModule } from '../dcs-dropdown/dcs-dropdown.module';
import { ClientService } from 'src/app/modules/userManagement/client/client.service';

@NgModule({
  imports: [ModalModule, CommonModule,ReactiveFormsModule,DCSErrorModule,DcsDropdownModule],
  declarations: [DcsDocfieldModalComponent],
  exports: [DcsDocfieldModalComponent],
  providers:[utdocfieldsService,utdocfieldsReportService,ClientService]
})
export class DcsDocfieldModalModule {}

