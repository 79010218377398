// Angular Imports
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngb-modal';
import { ComponentModule } from '../components.module';

// This Module's Components
import { DcsPrintviewerComponent } from './dcs-printviewer.component';

@NgModule({
    imports: [
        FormsModule, CommonModule, ModalModule, ReactiveFormsModule
    ],
    declarations: [
        DcsPrintviewerComponent,
    ],
    exports: [
        DcsPrintviewerComponent,
    ],
    providers:[
    ]

})
export class DcsPrintviewerModule {

}
