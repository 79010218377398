import { AfterViewInit, Component, Input } from '@angular/core';

@Component({
    selector: 'dcs-multiinput',
    templateUrl: 'dcs-multi-input.component.html',
    styleUrls: ['dcs-multi-input.component.scss']
})
export class DcsMultiInputComponent implements AfterViewInit {
    ngAfterViewInit(): void {

    }

    @Input()
    caption: string = "";

    private _numericWidth: string = "30%";
    @Input()
    get numericWidth(): string {
        return this._numericWidth;
    }
    set numericWidth(val) {
        this._numericWidth = val;
    }

    private _dropdownWidth: string = "70%";
    @Input()
    get dropdownWidth(): string {
        return this._dropdownWidth;
    }
    set dropdownWidth(val) {
        this._dropdownWidth = val;
    }
}
