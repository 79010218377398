/**
 * The main component that renders single TabComponent
 * instances.
 */

import {
  Component,
  ContentChildren,
  QueryList,
  AfterContentInit,
  ViewChild,
  ComponentFactoryResolver,
  ViewContainerRef,
  EventEmitter,
  Output,
  Input
} from "@angular/core";

import { DcsTabSetComponent } from "./dcs-tab.component";

@Component({
  selector: "dcs-tabs",
  templateUrl: 'dcs-tabs.component.html',
  styleUrls: ['dcs-tabs.component.scss']
})
export class DcsTabsComponent implements AfterContentInit {
  // contentChildren are set
  @ContentChildren(DcsTabSetComponent) tabs: QueryList<DcsTabSetComponent> | any;
  @Output()
  onClick: EventEmitter<any> = new EventEmitter<any>();
  // @Input()
  // type: number = 1;
  ngAfterContentInit() {
    
    // get all active tabs
    let activeTabs;
    if (this.tabs && this.tabs.length && this.tabs.length > 0) {
      
      activeTabs = this.tabs.filter((tab: { active: any; }) => tab.active);
      
      // if there is no active tab set, activate the first
      if (activeTabs.length === 0 && this.tabs && this.tabs.first) {
        
        this.selectTab(this.tabs.first);
      }
    }
  }

  selectTab(tab: any) {
    // deactivate all tabs
    
    if (this.tabs) {
      
      this.tabs.toArray().forEach((tab: { active: boolean; }) => {

        return (tab.active = false);
      });
      this.onClick.emit(tab);
      // activate the tab the user has clicked on.
      tab.active = true;
      
    }
  }
}