<form [formGroup]="myForm" novalidate>
  <div class="box box-default">
    <div class="box-body">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="form-group">
                <dcs-textbox caption="Attachment No" formControlName="attchmentno" maxlength="20" readonly="true"
                  [disabled]="true" placeholder="Enter Attchment No"></dcs-textbox>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="form-group">
                <dcs-dropdown caption="Document Type*" formControlName="lovreftypeid" [data]="LookupData.DocumentTypes"
                  [config]="lookupConfigs.DocumentType" [valueMember]="'Id'" (change)="getExtension()"
                  [displayMember]="'stxt'">
                  <!-- [disabled]="myForm.controls['lovreftypeid'].value ? (myFiles.length == 0 ? false : true) :false " -->
                </dcs-dropdown>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div class="form-group">
                <dcs-textbox caption="Document Name*" formControlName="docname" placeholder="Enter Document Name"
                  required></dcs-textbox>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div class="form-group">
                <dcs-textbox caption="External Link" formControlName="externallink" placeholder="Enter External Link">
                </dcs-textbox>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div class="custom-import form-group btn w-100">
                <!-- <input class="file-att custom-file-input" name="file_source" size="40" #file id="file" type="file"
                (change)="getFileDetails($event)" /> -->

                <div class="file-drop-area">
                  <span class="fake-btn">Choose File</span>
                  <span class="file-msg" *ngIf="LookupData.file == undefined">or drag and drop files here</span>
                  <span class="file-msg"
                    *ngIf="LookupData.file && LookupData.file != undefined && LookupData.file.name != undefined">{{LookupData.file.name}}</span>
                  <input #file class="file-input" type="file" (change)="getFileDetails($event)">
                </div>




              </div>
              <div class="d-flex mysie  mt-3 ">
                <span>{{LookupData.sizes}}MB</span>
                <span class="ml-auto">15MB</span>
              </div>
              <div class="progress  active" style="margin-bottom: 0px !important;height: 10px;">
                <!-- <span style="margin-left: 2px;">Free Space   {{ LookupData.freegb  | number }}  GB</span> -->
                <div class="progress-bar"
                  [style.background]="LookupData.bargb <= 30 ? 'forestgreen': LookupData.bargb <= 60 ? 'coral':LookupData.bargb > 60 ? 'crimson':'' "
                  [ngStyle]="{ 'width': ((LookupData.sizes / 15)* 100) + '%' } ">
                  <!-- -->
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6" style="border-left: 1px solid #dddddd61;">
          <h2 style="margin-top: -5px;" class="ml-0">Storage <span style="font-size: 15px;font-weight: 500;">
              ({{LookupData.bargb}}%
              full)</span></h2>
          <div class="span4">

            <span style="font-weight: 600;">Usage :</span> {{ LookupData.usage | number }} GB
            ({{ LookupData.usagemb | number }} MB) <div class="pull-right">
              <span style="font-weight: 600;">Limit : </span>15 GB (15,360 MB)
            </div>
            <div class="progress   active" style="margin-bottom: 0px !important;height: 10px;">
              <!-- <span style="margin-left: 2px;">Free Space   {{ LookupData.freegb  | number }}  GB</span> -->
              <div class="progress-bar"
                [style.background]="LookupData.bargb <= 30 ? 'forestgreen': LookupData.bargb <= 60 ? 'coral':LookupData.bargb > 60 ? 'crimson':'' "
                [ngStyle]="{ 'width': LookupData.sizes + '%' } ">
                <!-- -->
              </div>
            </div>
            <span style="margin-left: 2px;font-weight: 600;"> Free :</span> {{ LookupData.freegb | number }} GB
            ({{ LookupData.freemb | number }} MB)



          </div>


        </div>

      </div>
    </div>
  </div>
</form>
<dcs-lookup #dcslookup></dcs-lookup>
<style>
  .file-drop-area {
    position: relative;
    align-items: center;
    padding: 10px;
    border: 1px dashed #2bb2d5;
    border-radius: 3px;
    transition: 0.2s;
    margin-top: 7px;

  }

  .file-drop-area.is-active {
    background-color: rgba(255, 255, 255, 0.05);
  }

  .fake-btn {
    flex-shrink: 0;
    color: white;
    background-color: #2baad5;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 3px;
    padding: 8px 15px;
    margin-right: 10px;
    font-size: 12px;
    cursor: pointer;
  }

  .file-msg {
    font-size: small;
    font-weight: 300;
    line-height: 1.4;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .file-input {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
    opacity: 0;

  }

  .file-input:focus {
    outline: none;
  }
</style>