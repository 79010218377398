import { Injector } from "@angular/core";
export const AppConstants = {
  // urls: {
  //   //live
  //   ut: "https://api.edap.com.pk/uateu/api/",
  //   api: "https://api.edap.com.pk/uateu/api/",
  //   //dev
  //   // ut: "http://192.168.18.100:1001/eu/api/",
  //   // api: "http://192.168.18.100:1001/eu/api/",
  //   // reportNGUrl: "https://reports.edap.com/#/",
  //   //reportUrl: "http://localhost:6005//WebForms/DevExReportViewer.aspx",
  //   reportUrl: "https://uatreport.edap.com.pk//WebForms/DevExReportViewer.aspx",
  //   externalURL: "https://live.edap.com.pk/",
  //   videourl : "https://tutorials.edap.com.pk/"
  // },
  settings: {
    dateFormatforString: "DD-MMM-YYYY",
    timeFormatforString: "hh:mm:ss A",
    dateformat: "dd-MMM-yyyy",
    timeFormat: "HH:mm:ss",
    dateAndTime: "YYYY-MM-DDTHH:mm",
    dateFormat: "dd-MMM-yyyy",
    monthDateYear: "MM/DD/YYYY",
    timeFormatTile: "hh:mm A",
    date: "MMM d, y",
    appCode: "SB",
    instanceid: 1,
    instancecode: "UT001",
    clientid: 154,
  },
  policy: {
    dateFormat: "",
    timeFormat: "",
    gridpagging: "15",
    defaultcityid: 0,
    defaultctryid: 0,
    defaultstateid: 0,
  },
  injector: <Injector>{},
  /////  Etisalcom  ////////
  ourpartner: {
    name: "",
  }
};
