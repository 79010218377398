import { DcsRadioButtonComponent } from "./dcs-radio.component";
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [DcsRadioButtonComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  exports: [DcsRadioButtonComponent],
})
export class DcsRadioButtonModule { }