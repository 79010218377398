import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild,
  Routes,
} from "@angular/router";
import { LocalStorage } from "./localStorage";

@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router, private localStorage: LocalStorage) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (state.url === "/") {
      this.router.navigate(["/authentication/login"]);
      return false;
    }

    return this.checkToken();
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.canActivate(route, state);
    return this.checkToken();
  }

  private checkToken() {
    if (this.localStorage.get("token")) {
      return true;
    }

    this.router.navigate(["authentication/login"]);
    return false;
  }
}
