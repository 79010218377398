import { Injectable } from "@angular/core";
import { AppService } from "../../../../../../../@core/service/App.Service";


@Injectable()
export class StudentguardianService extends AppService<any> {

    constructor() {
        super('SMStudentguardian');
    }

    async getSMStudentguardian() {

        const result = await this.get('');
        return result;
    }

    async getStudentGuardian(studentid:any) {

        const result = await this.get('StudentGuardain', [studentid]);
        return result;
    }

    async getStudentGuardianList(studentid:any) {

        const result = await this.get('StudentGuardains', [studentid]);
        return result;
    }
   
}