import { Component, ViewChild, ViewContainerRef } from "@angular/core";
import { AgRendererComponent } from "ag-grid-angular";
import { IAfterGuiAttachedParams, ICellRendererParams } from "ag-grid-community";

@Component({
    selector: 'date-editor-cell',
    template: `<label class="container">  <input type="checkbox" [disabled]="disabled" [(ngModel)]="value"  #input (change)="valueChange($event.target.value)"   >  <span class="checkmark"></span> </label>`,
    styleUrls: ['./checkbox.css']
})
export class GridCheckBoxComponent implements AgRendererComponent {
    disabled: boolean = false;
    refresh(params: ICellRendererParams): boolean {
        return true;
    }
    agInit(params: ICellRendererParams): void {
        this.params = params;
        this.params.colDef.editable = this.params.colDef.editable === true ? undefined : this.params.colDef.editable;
        this.disabled = !Boolean(this.params.colDef.editable === undefined ? true : this.params.colDef.editable);
        this.value = this.params.value;

    }
    afterGuiAttached(params?: IAfterGuiAttachedParams): void {


    }
    private params: any;
    public value: any;
    private cancelBeforeStart: boolean = false;

    @ViewChild('input', { read: ViewContainerRef }) public input: any;




    getValue(): any {

        return this.params.value;
    }

    valueChange(data: any) {
        this.params.setValue(this.value);
    }

}