import { BehaviorSubject, Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { ErrorObject } from "../../dto/ErrorObject";

@Injectable({ providedIn: "root" })
export class ErrorComponentService {
  public isOpen$ = new BehaviorSubject(false);

  public toggle(): void {
    this.isOpen$.next(!this.isOpen$.getValue());
    console.log("Im inside toggle");
  }

  public showErrors(errors?: ErrorObject[]): Observable<boolean> {
    // console.log("Im inside getIsOpen");
    return this.isOpen$;
  }
}
