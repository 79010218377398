// Angular Imports
import { NgModule } from '@angular/core';

// This Module's Components
import { DcsLabelComponent } from './dcs-label.component';

@NgModule({
    imports: [

    ],
    declarations: [
        DcsLabelComponent,
    ],
    exports: [
        DcsLabelComponent,
    ]
})
export class DcsLabelModule {

}
