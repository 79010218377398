/**
 * A single tab page. It renders the passed template
 * via the @Input properties by using the ngTemplateOutlet
 * and ngTemplateOutletContext directives.
 */

import { Component, Input } from '@angular/core';

@Component({
  selector: 'dcs-tabset',
  templateUrl: 'dcs-tab.component.html'
})
export class DcsTabSetComponent {
  @Input('tabTitle') title: string | any;
  @Input('tabIcon') icon: string | any;
  @Input('active') active = false;
  @Input('hide') hide = false;
  @Input('ElementId') ElementId: any;

  ngOnInit() {
    
  }
}
