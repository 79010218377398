import { AppService } from '../../../@core/service/App.Service';
import { Injectable } from '@angular/core';
import { DataTransferObject } from '../../../@core/dto/DataTransferObject';


@Injectable({
  providedIn: "root",
})
export class ClientService extends AppService<any> {

  constructor() {
    super('utclient');
  }
  async GetClientsCred(clientid: any) {
    const result = await this.get('ClientsCredentials', [clientid])
    return result
  }
  async GetClientsData(clientid: any) {
    const result = await this.get('Clients', [clientid])
    return result
  }
  // async PutClientsData() {
  //   const result = await this.put('Pututclients')
  //   return result
  // }
  async PutClientsData(Item: any){
    const _data = <DataTransferObject<any>>{
      Data: Item,
  };

  const result = await this.put('Pututclients', _data);
  return result;
    }
}
