// Angular Imports
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { PipeModule } from "src/app/_pipe/pipe.module";
import { ComponentModule } from "../components.module";
import { DCSCustomService } from "./dcs-custom.service";

// This Module's Components
import { DcsReportComponent } from "./dcs-report.component";
import { DCSReportService } from "./dcs-report.service";
import { DcsDocfieldModalModule } from '../dcs-docfield-modal/dcs-docfield-modal.module';
import { DCSLoaderModule } from '../dcs-loader/dcs-loader.module';
import { DCSReportConfigDtlService } from "./dcs-reportconfigdtl.service";
import { LovService } from "src/app/shared/services/lov.service";

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    ComponentModule,
    ReactiveFormsModule,
    PipeModule,
    DcsDocfieldModalModule,
    DCSLoaderModule
  ],
  declarations: [DcsReportComponent],
  exports: [DcsReportComponent],
  providers: [DCSReportService, DCSCustomService,DCSReportConfigDtlService,LovService],
})
export class DcsReportModule {}
