
import { Injectable } from "@angular/core";
import { AppService } from "src/app/@core/service/App.Service";

@Injectable({
    providedIn: "root",
  })
export class utdocfieldsService extends AppService<any>{

    constructor() {
        super('utdocfields');
    }

    async getDocfields( doctypeid: any, docsubtypeid: any) {
        let clientid = await this.localStorage.get('clientid');
        const result = await this.get('utdocumentFields', [ doctypeid, docsubtypeid, clientid]);
        return result;
    }

    async SaveDocfieldConfig(_data: any) {
        
        var result = await this.post("SaveDocfieldConfig", _data)
        return result;
    }

    async Deletedocfield(Data: any) {
        const result = await this.post('Deletedocfield', Data);
        return result;
      }

}