
import { Injectable } from "@angular/core";
import { AppService } from "src/app/@core/service/App.Service";

@Injectable()

export class SMMessageService extends AppService<any>{
    // save(json: any) {
    //   throw new Error('Method not implemented.');
    // }
    constructor() {
        super('hdr_SMMessage');
    }

    async getEntityWiseMessages(typeid:any) {
        var entityid = this.localStorage.get('selectedEntity')

        const data = await this.get('EntityWiseMessages', [entityid, typeid]);

        return data;

    }

    async getMessageLog(msgid:any) {
        var entityid = Number(this.localStorage.get('selectedEntity'))
        const data = await this.get('MessageLog', [entityid, msgid]);
        return data;
    }

    async getSMSLog(msgid:any) {
        var entityid = Number(this.localStorage.get('selectedEntity'))
        const data = await this.get('SMSLog', [entityid, msgid]);
        return data;
    }

    async getTypeWiseMessage(msgtypeid:any) {
        var entityid = Number(this.localStorage.get('selectedEntity'))
        const data = await this.get('TypeWiseMessage', [entityid, msgtypeid]);
        return data;
    }

    async getMessageChart(msgid:any) {

        var entityid = Number(this.localStorage.get('selectedEntity'))
        const data = await this.get('MessageChart', [entityid, msgid]);
        return data;
    }

    async getSMSChart(msgid:any) {
        var entityid = Number(this.localStorage.get('selectedEntity'))
        const data = await this.get('SMSChart', [entityid, msgid]);
        return data;
    }

    async getMessageDeliveredChart(msgid:any) {
        var entityid = Number(this.localStorage.get('selectedEntity'))
        const data = await this.get('MessageDeliveredChart', [entityid, msgid]);
        return data;
    }
    async getMessageInfo(entityid:any, typeid:any, fromdate:any, tilldate:any) {
        const data = await this.get('EntityWiseMessages', [entityid, typeid, fromdate, tilldate]);
        return data;
    }

    async getMessageNotifications(entityid:any, msgtypeid:any, typeid:any, typerefenceid:any) {

        let selectedEntity = Number(this.localStorage.get('selectedEntity'))
        const data = await this.get('Notifications', [selectedEntity, msgtypeid, typeid, typerefenceid]);
        return data;
    }

    async getCircularNotifications(entityid:any, msgtypeid:any, typeid:any, typerefenceid:any) {

        let selectedEntity = Number(this.localStorage.get('selectedEntity'))
        const data = await this.get('Notifications', [selectedEntity, msgtypeid, typeid, typerefenceid]);
        return data;

    }
    async getnotificationsForMobile(msgtypeid:any, typeid:any, typerefenceid:any) {

        let selectedEntity = Number(this.localStorage.get('selectedEntity'))
        const data = await this.get('NotificationsForMobile', [selectedEntity, msgtypeid, typeid, typerefenceid]);
        return data;

    }

}
