import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CustomHeader } from "./customHeader.component";

@NgModule({
  imports: [FormsModule, CommonModule],
  exports: [CustomHeader],
  declarations: [CustomHeader],
})
export class customHeaderModule { }
