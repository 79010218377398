<div *ngIf="showStdOrEmp == false">
    <div class="row" style="position: absolute;    margin: -23px 0px 0px 0px;">
        <div class="">
            <button type="button" class="btn-group" (click)="CloseModal()">
                <i class="fa fa-remove"></i> Close</button>
        </div>
    </div>
    <form [formGroup]="myForm">
        <div class="row align-items-center">
            <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12 ">
                <div class="formGroup" style="padding: 0px 10px;">
                    <dcs-dropdown fieldName="drop" [disabled]="true" formControlName="drop" caption="Message Template*"
                        [data]="LookupData.smstemplate" [displayMember]="'stxt'" [valueMember]="'Id'">
                    </dcs-dropdown>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12" style="    margin-top: 7px;">
                <label style="margin: 0;display: block;">Sent On*</label>
                <div style="min-width: 65px;
                    float: left;     margin-top: -5px;">
                    <input class="radio-button check-mrk check-mrk-btn" type="checkbox" name="radio" #sms />
                    <label> SMS </label>
                </div>
                <div style=" min-width: 65px; float: left;     margin-top: -5px;">
                    <input class="radio-button check-mrk check-mrk-btn" type="checkbox" name="radio" #MobApp />
                    <label> Mobile Application </label>
                </div>
            </div>
            <div class="col-lg-2">
                <div>
                    <button type="button" class="btn-group sent-btn" style="padding: 3px 35px !important;
                margin: 4px 30px 0px 16px;
            " (click)="Sentsms(1)">Sent
                    </button>
                </div>
            </div>
        </div>
        <div class="row m-0">
            <div class="col-lg-4" *ngFor="let item of birthdayStdId">
                <div class="std-card-birth">
                    <div class="std-card-birth-img">
                        <img src="{{item.stdimage}}" onerror="this.src='assets/AdminLTE_New/images/blank.png'"
                            alt="User Image">
                    </div>
                    <div class="std-card-birth-content">
                        <p class="std-card-birth-content-grno"><span>GR No :</span><span>{{item.grno}}</span></p>
                        <p class="std-card-birth-content-stdname"><span>{{item.firstname}}</span></p>

                        <p class="std-card-birth-content-section"><span class="maincol">Course
                                :</span><span>{{item.Course}}</span>
                        </p>
                        <p class="std-card-birth-content-section"><span class="maincol">Section
                                :</span><span>{{item.Section}}</span></p>
                        <!-- <p class="std-card-birth-content-section"><span class="maincol">Father
                                :</span><span></span>
                        </p> -->
                        <p class="std-card-birth-content-section"><span class="maincol">Age :</span><span>{{item.age
                                }}</span></p>

                    </div>
                    <div class="std-card-birth-status">
                        <div *ngIf="item.notiforbirthdaywished == false">
                            <span>
                                Pending
                            </span>
                            <input class="radio-button check-mrk-btn" type="checkbox" name="radio"
                                (click)="filterData($event,item.Id)" />
                        </div>
                        <div *ngIf="item.notiforbirthdaywished == true">
                            <span class="wishedstd">
                                Wished
                            </span>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </form>
</div>
<div *ngIf="showStdOrEmp == true">
    <div class="row" style="position: absolute;    margin: -23px 0px 0px 0px;">
        <div class="">
            <button type="button" class="btn-group" (click)="CloseModal()">
                <i class="fa fa-remove"></i> Close</button>
        </div>
    </div>
    <form [formGroup]="myForm">
        <div class="row align-items-center">
            <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12 ">
                <div class="formGroup" style="padding: 0px 10px;">
                    <dcs-dropdown fieldName="drop" [disabled]="true" formControlName="drop" caption="Message Template*"
                        [data]="LookupData.smstemplate" [displayMember]="'stxt'" [valueMember]="'Id'">
                    </dcs-dropdown>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12" style="    margin-top: 7px;">
                <label style="margin: 0;display: block;">Sent On*</label>
                <div style="min-width: 65px;
                    float: left;     margin-top: -5px;">
                    <input class="radio-button check-mrk check-mrk-btn" type="checkbox" name="radio" #sms />
                    <label> SMS </label>
                </div>
                <div style=" min-width: 65px; float: left;     margin-top: -5px;">
                    <input class="radio-button check-mrk check-mrk-btn" type="checkbox" name="radio" #MobApp />
                    <label> Mobile Application </label>
                </div>
            </div>
            <div class="col-lg-2">
                <div>
                    <button type="button" class="btn-group sent-btn" style="padding: 3px 35px !important;
                margin: 4px 30px 0px 16px;
            " (click)="Sentsms(2)">Sent
                    </button>
                </div>
            </div>
        </div>
        <div class="row m-0">
            <div class="col-lg-4" *ngFor="let item of birthdayStdId">
                <div class="std-card-birth">
                    <div class="std-card-birth-img">
                        <img src="{{item.stdimage}}" onerror="this.src='assets/AdminLTE_New/images/blank.png'"
                            alt="User Image">
                    </div>
                    <div class="std-card-birth-content">
                        <p class="std-card-birth-content-grno"><span>Employee Code :</span><span>{{item.employeecode}}</span></p>
                        <p class="std-card-birth-content-stdname"><span>{{item.fullname}}</span></p>

                        <p class="std-card-birth-content-section"><span class="maincol">Designation
                                :</span><span>{{item.designationstxt}}</span>
                        </p>
                        <p class="std-card-birth-content-section"><span class="maincol">Date Of Birth
                                :</span><span>{{item.dateofbirth}}</span></p>
                        <!-- <p class="std-card-birth-content-section"><span class="maincol">Father
                                :</span><span></span>
                        </p> -->
                        <p class="std-card-birth-content-section"><span class="maincol">Age :</span><span>{{item.age
                                }}</span></p>

                    </div>
                    <div class="std-card-birth-status">
                        <div *ngIf="item.notiforbirthdaywished == false">
                            <span>
                                Pending
                            </span>
                            <input class="radio-button check-mrk-btn" type="checkbox" name="radio"
                                (click)="filterDataEmp($event,item.Id)" />
                        </div>
                        <div *ngIf="item.notiforbirthdaywished == true">
                            <span class="wishedstd">
                                Wished
                            </span>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </form>
</div>


<!--///////////////////////// Old Code Start /////////////////////////-->
<!-- <form [formGroup]="myForm">
    <div class="row" style="padding-left: 7px;">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 " style="border-bottom: 1px dotted #ddd;
          padding-bottom: 4px;
        margin-top: 7px;       ">
            <img src="../../../assets/Celebration-icon.png" alt="User Image" style="float: left; margin-right: 4px;">
            <div class="row">
                <div class="col-lg-8">
                    <h4 class="wish-label">Happy Birthday to "{{Firstname}}"</h4>
                </div>
                <div *ngIf="notiforbirthdaywished" class="col-lg-4">
                    <h4 class="wish-label" style="text-align: right; color:green;">
                        <i class="fa fa-check"></i> Wished
                    </h4>
                </div>
            </div>
        </div>
    </div>
    <div class="row" style="padding-bottom: 5px;">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 " style="box-shadow: 0 2px 2px #ddd; padding: 5px 10px;">
            <div class="row">
                <div class="col-lg-1 col-md-1 col-sm-1 col-xs-12 " style="padding: 0 4px;">
                    <div class="stud-image">
                        <img class="img-circle " style="margin-left:9px;" src="{{studentimage}}" width="66px"
                            onerror="this.src='assets/AdminLTE_New/images/blank.png'" alt="User Image">
                    </div>
                </div>
                <div class="col-lg-5 col-md-5 col-sm-5 col-xs-12 "
                    style="border-left: 1px dotted #ddd; padding-left: 11px;margin-left: 25px;">
                    <div class="stud-info-data">
                        <div class="stud-info-data">
                            <div class="stud-info-details-h">
                                <p>GR.No:</p>
                            </div>
                            <div class="stud-profile-birth-p">
                                <p style="color: red;">[{{grno}}] </p>
                            </div>
                        </div>
                        <div class="stud-info-details-h">
                            <p> Student Name:</p>
                        </div>
                        <div class="stud-profile-birth-p">
                            <p title="{{Firstname}}">{{Firstname}} </p>
                        </div>
                    </div>
                    <div class="stud-info-data">
                        <div class="stud-info-details-h">
                            <p>Date of Birth:</p>
                        </div>
                        <div class="stud-profile-birth-p">
                            <p>{{dateofbirth}} </p>
                        </div>
                    </div>
                    <div class="stud-info-data">
                        <div class="stud-info-details-h">
                            <p>Age:</p>
                        </div>
                        <div class="stud-profile-birth-p">
                            <p style="color: forestgreen;">{{Age}} </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-5 col-md-5 col-sm-5 col-xs-12 ">
                    <div class="stud-info-data">
                        <div class="stud-info-details-h">
                            <p>Course:</p>
                        </div>
                        <div class="stud-profile-birth-p">
                            <p>{{Course}} </p>
                        </div>
                    </div>
                    <div class="stud-info-data">
                        <div class="stud-info-details-h">
                            <p>Section:</p>
                        </div>
                        <div class="stud-profile-birth-p">
                            <p> {{Section}} </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-8">
            <dcs-tabs>
                <dcs-tabset tabTitle="Template" tabIcon='fa fa-user'>
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 ">
                            <div class="formGroup" style="padding: 0px 10px;">
                                <dcs-dropdown fieldName="drop" formControlName="drop" caption="Message Template*"
                                    [data]="LookupData.smstemplate" [displayMember]="'stxt'" [valueMember]="'Id'">
                                </dcs-dropdown>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12" style="    margin-top: 7px;">
                            <label style="margin: 0;display: block;">Sent On*</label>
                            <div style="min-width: 65px;
                        float: left;     margin-top: -5px;">
                                <input class="radio-button check-mrk check-mrk-btn" type="checkbox" name="radio" #sms />
                                <label> SMS </label>
                            </div>
                            <div style=" min-width: 65px; float: left;     margin-top: -5px;">
                                <input class="radio-button check-mrk check-mrk-btn" type="checkbox" name="radio"
                                    #MobApp />
                                <label> Mobile Application </label>
                            </div>
                            <button type="button" class="btn-group sent-btn" style="padding: 3px 35px !important;
                        margin: 4px 30px 0px 16px;
                    " (click)="Sentsms()">Sent
                            </button>
                        </div>
                    </div>
                </dcs-tabset>
                <dcs-tabset tabTitle="Attachments" tabIcon='fa fa-paperclip'>
                    <div class="row">
                        <div class="dcscolumn-12 px-1">
                            <dcs-aggrid lvl="3" #aggrid [pageSize]="7" (actionOpen)="syncValue($event)"
                                [hideNoRecordOverlay]="true" [columns]="columnDefs" [type]="3"
                                [data]="LookupData.Attachments" [hideNew]="true" [hideSave]="true"
                                [hideActionView]="true" [hideActionEdit]="true" [hideAllControlBtn]="false"
                                [hideActionColumn]="true">
                                <attachment Modal=" true " #ngContent>
                                </attachment>
                            </dcs-aggrid>
                        </div>
                    </div>
                </dcs-tabset>
            </dcs-tabs>
        </div>
        <div class="col-lg-4">
            <div class="row" style="border-bottom: 1px solid #dddd;
            padding: 5px 0px 0px 0px;">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
                    <h4 class="wish-label">Wish To</h4>
                </div>
            </div>
            <div class="row" style="padding-right: 13px;border-left: 1px solid #dddd;margin-top: 5px;">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                    style="margin-top: 3px; overflow-y: scroll;max-height: 84px;">
                    <div *ngFor="let item of StudentGuardian" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 "
                        style="padding: 0;">
                        <div class="input-container" id="drive" *ngIf="StudentGuardian">
                            <input *ngIf="item.notiforbirthdaywished == false" class="radio-button check-mrk-btn"
                                type="checkbox" id="{{item.guardianid}}" name="radio" (click)="filterData($event)" />
                            <input *ngIf="item.notiforbirthdaywished" class="radio-button check-mrk-btn" type="checkbox"
                                id="{{item.guardianid}}" name="radio" checked (click)="filterData($event)" />
                            <div class="radio-tile">
                                <img class="img-circle " style="width: 50px;height: 48px;margin-right: 5px;    border-radius: 5px;
                                float: left;" src={{item.Guardianimg}}
                                    onerror="this.src='assets/AdminLTE_New/images/blank.png'" alt="User Image">
                                <ul class="radio-tile-label">
                                    <li title="{{item.relationid}}" style="Color: #1584b1;font-weight: bold;">Relation :
                                        {{item.relationid}}</li>
                                    <li title="{{item.name}}">{{item.name}}</li>
                                    <li title="{{item.contactno}}">{{item.contactno}}</li>
                                </ul>
                            </div>

                        </div>
                    </div>
                    <div>
                        <div *ngIf="StudentGuardian.length === 0">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="text-align: center;">
                                <p style="color: #3c8dbc; ">Student Guardian Not Registered...!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form> -->
<!-- ///////////////////////// Old Code End ///////////////////////// -->