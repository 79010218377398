import { Component, Input } from '@angular/core';

@Component({
    selector: 'dcs-label',
    templateUrl: 'dcs-label.component.html',
    styleUrls: ['dcs-label.component.scss']
})
export class DcsLabelComponent {
    @Input() label: string = "";

    ngOnInit() {
    }
}
