import { Component, ViewChild, Input, ElementRef, Output, EventEmitter } from "@angular/core";
import { SMStudentinfoService } from "../../modules/Student/Profilling/studentprofile/studentprofile.service";
import { AppConstants } from "../../@constant/app.constant";
import { GenEnum } from "../../@constant/general.enum";
import { TemplateService } from '../../modules/Front-Office/Communication/Message/template/template.service';
import { SMMessageService } from "../../modules/Front-Office/Communication/Message/message/message.service";
import { DcsModalComponent } from "../../@core/components/dcs-modal/dcs-modal.component";
import { DcsDropdownComponent } from "../../@core/components/dcs-dropdown/dcs-dropdown.component";
import { StudentbirthdayService } from "./student-birthday.service";
// import { Component, ViewChild, ElementRef } from '@angular/core';
// import { BaseComponent } from '../../@core/base/nav/Base.Component';
import { LocalStorage } from "../../@core/helper/localStorage";
import { StudentguardianService } from "../../modules/Student/Profilling/studentprofile/student-additional-info/Additional-Component/studentguardian/studentguardian.service";
import { NavModalComponent } from "../../@core/base/nav/NavModal.Component";
import * as moment from 'moment';
import { MoodEditorComponent } from '../../@core/components/dcs-aggrid/controls/mood-component/mood-editor.component';
import { MoodRendererComponent } from '../../@core/components/dcs-aggrid/controls/mood-component/mood-renderer.component';
import { ErrorObject } from "src/app/@core/dto/ErrorObject";
@Component({
  // moduleId: module.id,
  selector: "student-birthday",
  templateUrl: "./student-birthday.component.html",
  styleUrls: ["student-birthday.component.scss"],
})

// Template ki
export class StudentBirthdayComponent extends NavModalComponent<any> {
  @ViewChild("Birthdaymodal") birthdaymodal?: DcsModalComponent;
  @ViewChild("drop") drop?: DcsDropdownComponent;
  @ViewChild("sms") sms?: ElementRef;
  @ViewChild("MobApp") MobApp?: ElementRef;
  @Input() birthdayStdId: any = [];
  @Input() showStdOrEmp: boolean = false;
  @Output() close = new EventEmitter<string>();
  protected localStorage: LocalStorage;
  studentid: number = 0;
  studentimage: string = "";
  Section: String = "";
  imageSource: any;
  today: number = Date.now();
  time: Date = new Date();
  url: string = "";
  rolestxt: string = "";
  Custom: any = {};
  userclients: any;
  items: any = [];
  Firstname: string = "";
  Course: string = "";
  Age: number = 0;
  dateofbirth: string = "";
  studimg: string = "";
  Guardianimg: string = "";
  dropvalue: any;
  grno: any;
  Msgtemplate: any;
  StudentGuardian: any[] = [];
  columnDefs: any;
  notiforbirthdaywished: boolean = false;
  check: string = "unchecked"
  initilizeForm() {
    this.title = "Studentbirthday";
    this.myForm = this.fb.group({
      drop: ["", []],
      sms: ["", []],
      MobApp: ["", []],
      firstname: ["", []],
    });
  }

  async modalInitialize() {

    // await this.getStudebtList(this.studentid);

  }
  constructor(
    private StudentbirthdayService: StudentbirthdayService,
    private _SMStudentinfoService: SMStudentinfoService,
    private _SMStudentGuardianService: StudentguardianService,
    private templateService: TemplateService,
    private smmessageservice: SMMessageService
  ) {
    super(StudentbirthdayService);
    this.initilizeForm();
    this.localStorage = AppConstants.injector.get(LocalStorage);
    // this.imageSource =
    //   AppConstants.urls.api.replace("api/", "") + GenEnum.Image.imageUrl;
    var url = this.localStorage.get("OnlyUrl");
    this.imageSource = url + GenEnum.Image.imageUrl;
  }


  async ngOnInit() {
    this.myForm.controls['drop'].setValue(undefined)
    this.LookupData.smstemplate = [];
    if (this.showStdOrEmp == true) {
      await this.getEmpsmstemplate();

    }
    else {
      this.birthdayStdId.forEach((ele: any) => {
        ele.stdimage = this.imageSource + ele.filename
      });
      this.myForm.controls['MobApp'].setValue(true);
      await this.getsmstemplate();
    }
  }
  // async gridConfig() {
  //   this.columnDefs = [
  //     {
  //       headerName: "id",
  //       field: "id",
  //       allowClear: true,
  //       hide: true,
  //       isPrimeryKey: true,
  //     },
  //     {
  //       headerName: "Attachment No",
  //       field: "attchmentno",
  //       width: 200,
  //       editable: false,
  //       hide: true,
  //     },

  //     {
  //       headerName: "Document Name",
  //       field: "docname",
  //       width: 100,
  //       editable: false,
  //     },
  //     {
  //       headerName: "Document Name",
  //       field: "uploadpath",
  //       width: 100,
  //       editable: false,
  //       hide: true,
  //     },
  //     {
  //       headerName: "Document Type",
  //       field: "doctype",
  //       width: 100,
  //       editable: false,
  //     },
  //     {
  //       headerName: "External Link",
  //       field: "externallink",
  //       width: 450,
  //       editable: false,
  //     },
  //   ];
  // }

  // async getAttachment(referenceid: any) {
  //   const dto = await this.smattachmentsService.GetAttachmentdetail(
  //     referenceid,
  //     GenEnum.ReferenceType.Message
  //   );
  //   if (dto && dto.IsSuccess) {
  //     this.LookupData.Attachments = dto.Data;
  //   } else {
  //     this.showErrors(dto && dto.Errors);
  //   }
  // }
  // async getStudebtList(stdid: any) {
  //   const dto = await this._SMStudentinfoService.geStudentList(stdid);
  //   if (dto && dto.IsSuccess) {
  //     this.LookupData.StudentData = dto.Data;

  //     if (this.LookupData.StudentData && this.LookupData.StudentData.length && this.LookupData.StudentData.length > 0) {
  //       this.getstudbirthdaymodal(this.LookupData.StudentData[0]);
  //     }
  //   }
  // }
  async getstudguardian(studentid: number) {
    this.LookupData.StudentGuardian = [];
    const dto = await this._SMStudentGuardianService.getStudentGuardianList(
      studentid
    );
    if (dto && dto.IsSuccess) {
      // this.StudentGuardian =
      this.LookupData.StudentGuardian = dto.Data;
      // this.StudentGuardian.forEach((element) => {
      //   if (element.filename) {
      //     element.Guardianimg = this.imageSource + element.filename;
      //     element.ch = element.notiforbirthdaywished == true ? "checked" : "unchecked"
      //   }
      // });
    } else {
    }
  }

  // async getstudbirthdaymodal(event: any) {
  //   this.studentid = Number(event.Id);
  //   this.Firstname = event.firstname;
  //   this.Course = event.course;
  //   this.Section = event.Section;
  //   this.dateofbirth = event.dateofbirth;
  //   this.Age = moment().diff(this.dateofbirth, 'years');
  //   this.grno = event.grno;
  //   this.studentimage = this.imageSource + event.filename;
  // }

  async getsmstemplate() {
    this.LookupData.smstemplate = [];
    var dta: any = []
    const dto = await this.templateService.syspolicyTemp();
    if (dto && dto.IsSuccess && dto.Data) {
      dta.push(dto.Data)
      this.LookupData.smstemplate = dta;
      if (this.LookupData.smstemplate && this.LookupData.smstemplate.length && this.LookupData.smstemplate.length > 0) {
        this.myForm.controls['drop'].setValue(this.LookupData.smstemplate[0].Id)
      }
    }
  }
  async getEmpsmstemplate() {
    this.LookupData.smstemplate = [];
    var dta: any = []
    const dto = await this.templateService.syspolicyTempEmp();
    if (dto && dto.IsSuccess && dto.Data) {
      dta.push(dto.Data)
      this.LookupData.smstemplate = dta;
      if (this.LookupData.smstemplate && this.LookupData.smstemplate.length && this.LookupData.smstemplate.length > 0) {
        this.myForm.controls['drop'].setValue(this.LookupData.smstemplate[0].Id)
      }
    }
  }

  async syncValue(event: any) {
    window.setTimeout(() => {
      $('body div').removeClass("modal-backdrop");
    })
  }

  async filterData(event: any, stdid: any) {
    if (event.target.checked) {
      var dto :any = []
      await this.getstudguardian(stdid)
      if (this.LookupData.StudentGuardian && this.LookupData.StudentGuardian.length && this.LookupData.StudentGuardian.length > 0) {
        this.LookupData.StudentGuardian.forEach((ele: any) => {
          dto.push({
            parentid: ele.guardianid,
            studentid: stdid,
          });
        });
        this.items.push({
          parentid: dto,
          studentid: stdid,
        });
      }
      else {
        this.LookupData.StudentGuardian = []
      }
    
    } else {
      let indexof: number = this.items.filter(
        (o: any) => o.studentid == stdid
      )[0];
      this.items.splice(indexof, 1);
    }
  }
  async filterDataEmp(event: any, stdid: any) {
    if (event.target.checked) {
      var dto :any = []
      dto.push({
        teacherid: stdid,
      });
        this.items.push({
          teacherid: dto,
        });
      }
     else {
      let indexof: number = this.items.filter(
        (o: any) => o.teacherid == stdid
      )[0];
      this.items.splice(indexof, 1);
    }
  }

  async Sentsms(num:any) {
    let errors: ErrorObject[] = [];
    if (this.LookupData.smstemplate && this.LookupData.smstemplate.length && this.LookupData.smstemplate.length > 0) {
      if (this.items && this.items.length && this.items.length > 0) {
        let smssent = this.sms && this.sms.nativeElement ? this.sms.nativeElement.checked : false;
        let mobappsent = this.MobApp && this.MobApp.nativeElement ? this.MobApp.nativeElement.checked : false;
        if (smssent == true || mobappsent == true) {
          let result: any = {};
          const Data: any = {};
          let Msgtemplate = this.LookupData.smstemplate[0];
          if (Msgtemplate) {
            let msgcaption = Msgtemplate.stxt;
            let msgtxt = Msgtemplate.ltxt;
            let tempalteId = Msgtemplate.Id;
            this.items.forEach(async (b: any) => {
              if (Data) {
                let entityid = Number(this.localStorage.get("selectedEntity"));
                Data.entityid = entityid;
                Data.lovstatusid = GenEnum.Activity_Status_Id.Active;
                Data.msgtypeid = GenEnum.MessageType.Notification;
                Data.lovsenttoid = GenEnum.SendBy.Individual;
                Data.senttoid = num == 1 ? GenEnum.SendTo.Parents : GenEnum.SendTo.Teacher;
                Data.mobileapp = mobappsent;
                Data.sms = smssent;
                // Data.messagetoid =
                  Data.msgdate = new Date();
                Data.publishdate = new Date();
                Data.publishtime = new Date(1970, 1, 1, new Date().getHours() + 5, new Date().getMinutes() + 2, 0);
                Data.msgtxt = msgtxt;
                Data.trn_SMMessagelogs = num == 1 ? b.parentid : b.teacherid ;
                Data.title = msgcaption;
                Data.templateid = tempalteId;
                Data.notiforbirthdaywished = true
                let json: any = {};
                json.Data = Data;
                result = await this.smmessageservice.save(json);
                if (result) {
                  if (result.Errors && result.Errors.length && result.Errors.length > 0) {
                    errors.push({
                      Description: result.Errors,
                      Fields: [],
                    });
                  }
                  if (this.sms && this.sms.nativeElement)
                    this.sms.nativeElement.checked = false;
                  if (this.MobApp && this.MobApp.nativeElement)
                    this.MobApp.nativeElement.checked = false;
                  this.items = [];
                } else {
                  this.items = [];
                }
              }
            });
            if (errors && errors.length && errors.length > 0) {
              this.showErrors("There Is an Error While Sending The Message...!");
            }
            else {
              this.showSuccess("Record saved successfully...");
              this.CloseModal()
            }
          } else {
            this.showErrors("Select " + this.drop?.caption);
          }
        }
        else {
          this.showErrors('Please select any one : Mobile App or SMS')
        }
      }
      else {
        this.showErrors('Please select the Data First !')
      }
    }
    else {
      this.showErrors("Please set the Message Template in system policy first.")
    }
  }


  CloseModal() {
    this.close.emit();
  }
}
