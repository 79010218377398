import { DcsImageComponent } from "./dcs-image.component";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DcsModalModule } from "../dcs-modal/dcs-modal.module";
import { ImageCropperModule } from 'ngx-image-cropper';

@NgModule({
  declarations: [DcsImageComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule,DcsModalModule,ImageCropperModule],
  exports: [DcsImageComponent],
  providers:[]
})
export class DcsImageModule {}
