import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "sortByText" })
export class SortByTextPipe implements PipeTransform {
  transform(value: any[], order = "", column: string = ""): any[] {
    if (!value || order === "" || !order) {
      return value;
    } // no array
    if (value.length <= 1) {
      return value;
    } // array with only one item
    if (order === "asc") {
      return value.sort((a, b) => a[column].localeCompare(b[column]));
    } else {
      return value.sort((a, b) => a[column].localeCompare(b[column])).reverse();
    }
  }
}
