import { Component, Input, forwardRef } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { ControlBase } from "../../../@constant/config";
@Component({
    selector: "dcs-color",
    templateUrl: "dcs-color.component.html",
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DcsColorComponent),
            multi: true,
        },
        {
            provide: ControlBase,
            useExisting: DcsColorComponent,
        },
    ],
})
export class DcsColorComponent
    extends ControlBase
    implements ControlValueAccessor {
    errorCaption: string = "";
    constructor() {
        super();
    }
    private innerValue: any = "#ffffff";
    private onTouchedCallback!: () => void;
    private onChangeCallback!: (_: any) => void;
    get value(): any {
        return this.innerValue
    }
    hexToRGB(hex: any, alpha: any) {
        if (hex) {
            var r = parseInt(hex.slice(1, 3), 16),
                g = parseInt(hex.slice(3, 5), 16),
                b = parseInt(hex.slice(5, 7), 16);

            if (alpha) {
                return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
            } else {
                return "rgb(" + r + ", " + g + ", " + b + ")";
            }
        }
    }
    set value(v: any) {
        if (v !== this.innerValue) {
            this.innerValue = v;
            this.onChangeCallback(v);
        }
    }
    onBlur() {

        this.onTouchedCallback();
    }
    writeValue(value: any) {
        if (value !== this.innerValue) {
            this.innerValue = value;
        }
    }
    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }
    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }
    @Input()
    fieldName: string = "";
    @Input()
    caption: string = "";
    @Input()
    showCaption: boolean = true;
    @Input()
    rows: number = 3;
    @Input()
    colorCode: string = "hex";
    @Input() placeholder: boolean = false;
    @Input()
    placeholderValue() {

        if (this.placeholder) {

            return "Enter " + this.cleanCaption()
        }
        else {
            return "";
        }
    }
    cleanCaption() {
        if (this.caption.slice(-1) === "*") {
            return this.errorCaption = this.caption.slice(0, -1)
        }
        else {
            return this.errorCaption = this.caption
        }
    }
    onchange() {
        
        // this.writeValue(this.hexToRGB(this.innerValue, 1))
        // this.writeValue(this.hexToRGB(this.innerValue,1))
    }
    @Input()
    disabled: boolean = false;
    @Input()
    maxlength: number = 500;
    @Input()
    minlength: number = 0;
    @Input()
    position: number = 1;
    public _fieldWidth = "input-group col-lg-3 col-md-3 col-sm-9 col-xs-9 p-0";
    @Input()
    get fieldWidth(): string {
        return this._fieldWidth;
    }
    set fieldWidth(value: string) {
        if (value && value.indexOf(",") !== -1) {
            let colSplit = value.split(",");
            if (colSplit && colSplit.length === 4) {
                this._fieldWidth =
                    "input-group col-lg-" +
                    (colSplit[0] ? colSplit[0] : "3") +
                    " col-md-" +
                    (colSplit[1] ? colSplit[1] : "3") +
                    " col-sm-" +
                    (colSplit[2] ? colSplit[2] : "3") +
                    " col-xs-" +
                    (colSplit[3] ? colSplit[3] : "3")+
                    " p-0";
            }
        }
    }
    public _captionWidth = "col-lg-1 col-md-1 col-sm-3 col-xs-3 col-form-label";
    @Input()
    get captionWidth(): string {
        return this._captionWidth;
    }
    set captionWidth(value: string) {
        if (value && value.indexOf(",") !== -1) {
            let colSplit = value.split(",");
            if (colSplit && colSplit.length === 4) {
                this._captionWidth =
                    "col-lg-" +
                    (colSplit[0] ? colSplit[0] : "1") +
                    " col-md-" +
                    (colSplit[1] ? colSplit[1] : "1") +
                    " col-sm-" +
                    (colSplit[2] ? colSplit[2] : "3") +
                    " col-xs-" +
                    (colSplit[3] ? colSplit[3] : "3") +
                    "col-form-label";
            }
        }
    }
}