<div class="form-group">
    <label *ngIf="showCaption" [ngClass]="position === 1 ? '' : _captionWidth">{{
        caption
        }}
        <!-- <span *ngIf="name.invalid && (name.dirty || name.touched) && name.errors && name.errors.required">
        <span *ngIf="name.errors.required">
          <span class="tooltipss" style="color: red; margin-left: 5px; font-weight: bolder;" type="button"><i
              class="fa fa-info-circle" aria-hidden="true"></i><span class="tooltiptextss"><i
                class="fas fa-exclamation-triangle" style="color: red;"></i> {{cleanCaption()}} can not be
              blank</span></span>
        </span>
      </span> -->
    </label>
    <!-- <form [formGroup] = "myForm"></form> -->
    <div [ngClass]="position === 1 ? '' : _fieldWidth">
        <input #inputColor type="color" name="color" class="dcs-control invalid" id="color" (blur)="onBlur()"
            required="required" [(ngModel)]="value"  #name="ngModel" (change)="onchange()">
    </div>
</div>