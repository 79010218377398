import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationExtras, Router } from '@angular/router';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { MenusService } from 'src/app/@core/service/menus.service';
import { ContainerComponent } from "../../@core/base/nav/Container.Component";
import { FavoriteService } from '../menu-component/menu-favorite.service';

@Component({
  selector: 'app-ut',
  templateUrl: './ut.component.html',
  styleUrls: ['./ut.component.scss'],
  providers: [
    FavoriteService
  ]
})
export class UtComponent extends ContainerComponent implements OnInit {

  // titlePresent = this.localStorage.get('screenname')
  selectedItem: any;
  public config: PerfectScrollbarConfigInterface = {};
  EntityLogo: any;
  constructor(public router: Router, private myService: MenusService, private favoriteService: FavoriteService, private titleService: Title) {
    super();

    this.myService.myMethod$.subscribe((data: any) => {
      this.modulename = data; // And he have data here too!
      this.title = ''
    });
  }
  public setHeaderTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  tabStatus: any;

  public isCollapsed = false;

  public innerWidth: any;
  public defaultSidebar: any;
  public showSettings = false;
  public showMobileMenu = false;
  public expandLogo = false;

  options = {
    theme: "light", // two possible values: light, dark
    dir: "ltr", // two possible values: ltr, rtl
    layout: "vertical", // fixed value. shouldn't be changed.
    sidebartype: "full", // four possible values: full, iconbar, overlay, mini-sidebar
    sidebarpos: "fixed", // two possible values: fixed, absolute
    headerpos: "fixed", // two possible values: fixed, absolute
    boxed: "full", // two possible values: full, boxed
    navbarbg: "skin1", // six possible values: skin(1/2/3/4/5/6)
    sidebarbg: "skin1", // six possible values: skin(1/2/3/4/5/6)
    logobg: "skin1", // six possible values: skin(1/2/3/4/5/6)
  };

  Logo() {
    this.expandLogo = !this.expandLogo;
  }

  async ngOnInit() {
    this.EntityLogo = this.localStorage.get('EntityLogo');
    if (this.router.url === "/") {
      this.router.navigate(["/login"]);
    }
    this.defaultSidebar = this.options.sidebartype;

    this.handleSidebar();

  }

  @HostListener("window:resize", ["$event"])
  onResize(event: string) {
    this.handleSidebar();
  }

  menuClick(item: any) {

    this.selectedItem = item;
    this.myService.tabData(item)
    this.setHeaderTitle(`EDAP - ${item.Value.toUpperCase()}`);
    this.localStorage.set("tabState", JSON.stringify(item));
    if (item && item.id === 4) {
      this.router.navigate(['authentication/404']);
    }
  }

  handleSidebar() {
    this.innerWidth = window.innerWidth;
    switch (this.defaultSidebar) {
      case "full":
      case "iconbar":
        if (this.innerWidth < 1170) {
          this.options.sidebartype = "mini-sidebar";
        } else {
          this.options.sidebartype = this.defaultSidebar;
        }
        break;

      case "overlay":
        if (this.innerWidth < 767) {
          this.options.sidebartype = "mini-sidebar";
        } else {
          this.options.sidebartype = this.defaultSidebar;
        }
        break;

      default:
    }
  }

  toggleSidebarType() {
    switch (this.options.sidebartype) {
      case "full":
      case "iconbar":
        this.options.sidebartype = "mini-sidebar";
        break;

      case "overlay":
        this.showMobileMenu = !this.showMobileMenu;
        break;

      case "mini-sidebar":
        if (this.defaultSidebar === "mini-sidebar") {
          this.options.sidebartype = "full";
        } else {
          this.options.sidebartype = this.defaultSidebar;
        }
        break;

      default:
    }
  }
  showDropdown: any = false
  showNotification: any = false
  @ViewChild('leftsidebar') leftsidebar: ElementRef | any
  dropdownFunction($event: { stopPropagation: () => void }) {
    if (this.showDropdown == false) {
      this.leftsidebar.nativeElement.classList.add('z-index-50')
    }
    $event.stopPropagation();
    setTimeout(() => {
      this.showDropdown = !this.showDropdown
      if (this.showDropdown == false) {
        this.leftsidebar.nativeElement.classList.remove('z-index-50')
      }
      // console.log(i);
    }, 200);



    // this.showNotification = !this.showNotification
  }
  notificationFunction($event: { stopPropagation: () => void }) {

    $event.stopPropagation();
    // this.showDropdown = !this.showDropdown
    this.showNotification = !this.showNotification
  }
  @ViewChild("dropdown") dropdown?: ElementRef
  @HostListener("document:click", ["$event"]) Documentclick(event: Event) {
    if (this.dropdown && this.dropdown.nativeElement && this.dropdown.nativeElement.contains(event.target)) {
      this.showDropdown = true;
    }
    else {
      this.showDropdown = false;
    }
    this.showNotification = false;
  }
  // getFavData() {
  //   if (this.LookupData.FavoriteScreens) {
  //     var data = this.LookupData.FavoriteScreens
  //     if (data && data.length && data.length > 0){
  //       data.forEach((element: any) => {
  //         element.
  //       });
  //     }
  //   }
  // }
  // dataFav : any = []
  refreshFavoriteScreens() {
    this.isClicked = true
    setTimeout(() => {
      this.isClicked = false
    }, 500);
  }
  changeTitle(item: any, item2: any) {
    this.localStorage.set("activePath", item2.routeid);
    this.title = item2.label
    this.localStorage.set('screenname', item2.label)

    this.localStorage.set('menuid', item.mmenuid)
    this.localStorage.set('modulename', item.label)
  }
  isClicked: boolean = false;
  dropdownClicked: boolean = false;


}
