<!--component html goes here -->
<ngb-accordion #acc="ngbAccordion" animation activeIds="{{id}}">
    <ngb-panel class="dcs-panel-container" id={{id}}>
        <ng-template ngbPanelTitle>
            <a><span class="float-left fs-12"><i class="{{icon}} pr-1"></i>{{title}}</span>

                <div *ngIf="showSideicon == true" class="TickConf">
                    <i style="color:white;" [ngClass]="Sideicon ? Sideicon : 'fa fa-gear' "></i>
                </div>
            </a>
        </ng-template>
        <ng-template ngbPanelContent aria-expanded="true" opened>
            <ng-content></ng-content>
        </ng-template>
    </ngb-panel>
</ngb-accordion>
<style>
    .TickConf {
        position: absolute;
        top: 5px;
        width: 16px;
        height: 16px;
        right: 30px;
        background: green;
        border-radius: 10px;
        padding: 2px;
        font-size: 9px !important;
        padding-left: 2px !important;
    }
</style>