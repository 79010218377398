import { Injectable } from "@angular/core";
import { AppService } from "../../../../@core/service/App.Service";




@Injectable()
export class FICOAService extends AppService<any>{

    constructor() {
        super('hdr_FICOA');
    }

    async getCOA(entityid:any, chartid:any, suspend:any) {
        const data =await this.get('COA', [entityid, chartid, suspend]);
        return data;
    }

    async getChartofAccount(chartid:any, suspend:any) {
        this.clearCondition();
        const data =await this.get('COA', [chartid, suspend]);
        return data;
    }

    async getCOAList(accchartid:any) {
        const data =await this.get('COAList',[accchartid]);
        return data;
    }

    async getCOAListEntityWise(vint_entityid:any) {
        const data =await this.get('COAEntityWise', [vint_entityid]);
        return data;
    }
    async getCOAEntityWise(vint_entityid:any) {
        const data =await this.get('COAEntityWise', [vint_entityid]);
        return data;
    }
    async getCOAEntityWithAccountCategoryWise(vint_entityid:any) {
        const data =await this.get('COAEntityWithAccountCategoryWise', [vint_entityid]);
        return data;
    }

    async getBankAC(entityid:any) {
        const data =await this.get('BankAC', [entityid]);
        return data;
    }

    async GetChartOFAccount(){
       
        const result = await this.get('ChartOFAccount',[]);
        return result;
    }

    async GetChartOfAccount(entityid:any){
       //let entityid = await this.localStorage.get('selectedEntity');
        const result = await this.get('ChartOfAccount',[entityid]);
        return result;
    }

    async GetJLAccount(entityid:any,transtypeid:any,bankaccctid:any){
        //let entityid = await this.localStorage.get('selectedEntity');
         const result = await this.get('JLAccountAgainstTransType',[entityid,transtypeid,bankaccctid]);
         return result;
     }
    async GetGLAccount(entityid:any,bankaccctid:any){
        //let entityid = await this.localStorage.get('selectedEntity');
         const result = await this.get('PDCGLAccount',[entityid,bankaccctid]);
         return result;
     }

    async getCashAccount(){
        let entityid = this.localStorage.get('selectedEntity');
        const result = await this.get('CashAccount',[entityid]);
        return result; 
    }
    async ChartofAccount(entityid:any) {
        
        const result = await this.get('ChartOfAccount', [entityid]);
        return result;
    }
    async GetIncomeAccounts(entityid:any) {
        
        const result = await this.get('IncomeAccounts', [entityid]);
        return result;
    }
    async GetAccounts(entityid:any ) {
        
        const result = await this.get('Accounts', [entityid]);
        return result;
    }

    // 05-Mar-2024
    // Amir Moavia
    // Get Dimension & Account Entity Wise Data
    // Start
    async GetDimensionCOAEntityWise(entityid: any) {
        var result = await this.get('DimensionCOAEntityWise', [entityid]);
        return result;
    }
    // End
}