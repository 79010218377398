import {
  AfterViewInit,
  Component,
  ElementRef,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { AgEditorComponent, ICellEditorAngularComp } from "ag-grid-angular";
import { ColDef } from 'ag-grid-community';
import { LocalStorage } from '../../../../helper/localStorage';
const KEY_BACKSPACE = 8;
const KEY_DELETE = 46;
const KEY_F2 = 113;
const KEY_ENTER = 13;
const KEY_TAB = 9;

@Component({
  selector: "numeric-cell",
  template: `
    <input
      #number
      type="number"
      (keydown)="onKeyDown($event)"
      (blur)="BlurOn($event)"
      class="dcs-control grid-numeric gridnumericcontroler"
      [(ngModel)]="value"
    />
  `,
  styleUrls: ["gridNumeric.scss"],
})
export class GridNumericComponent implements AgEditorComponent, AfterViewInit {
  private params: any;
  // public value: number = 0;
  public highlightAllOnFocus: boolean = true;
  private cancelBeforeStart: boolean = false;

  constructor(
    private localStorage: LocalStorage,
  ) { }
  @ViewChild("number", { read: ViewContainerRef })
  public input: any;
  public value: any = 0;
  agInit(params: any): void {
    if (this.input) {
      const element: HTMLElement = this.input.nativeElement;
      if (element) {
        element.style.height = "26px";
        element.style.textAlign = "right";
      }
    }
    this.params = params;
    this.setInitialState(this.params);
    if (params.colDef.isFloat && params.colDef.isFloat == true) {
      var decimalPipe: any = Number(localStorage.getItem("defaultDecimalPlaces"));
      if (this.params && this.params.value) {
        this.value = Number(this.params.value).toFixed(decimalPipe != undefined ? decimalPipe : 0);
      }
      else {
        this.value = this.params.value;
      }
    }
    else {
      this.value = this.params.value;
    }

    this.cancelBeforeStart =
      params.charPress && "1234567890".indexOf(params.charPress) < 0;
  }

  setInitialState(params: any) {
    let startValue;
    let highlightAllOnFocus = true;

    if (params.keyPress === KEY_BACKSPACE || params.keyPress === KEY_DELETE) {
      // if backspace or delete pressed, we clear the cell
      startValue = "";
    } else if (params.charPress) {
      // if a letter was pressed, we start with the letter
      startValue = params.charPress;
      highlightAllOnFocus = false;
    } else {
      // otherwise we start with the current value
      startValue = params.value;
      if (params.keyPress === KEY_F2) {
        highlightAllOnFocus = false;
      }
    }

    this.value = startValue;
    this.highlightAllOnFocus = highlightAllOnFocus;
  }

  isCancelBeforeStart(): boolean {
    return this.cancelBeforeStart;
  }

  isCancelAfterEnd(): boolean {
    return this.value > 1000000000000000;
  }
  getValue(): any {

    return this.value;
  }
  onKeyDown(event: any): void {

    if (this.isLeftOrRight(event) || this.deleteOrBackspace(event)) {
      event.stopPropagation();
      return;
    }

    if (this.params && this.params.colDef && this.params.colDef.IsFloat && this.params.colDef.IsFloat != true) {
      if (
        !this.finishedEditingPressed(event) &&
        !this.isKeyPressedNumeric(event)
      ) {
        if (event.preventDefault) event.preventDefault();
      }
    }

  }
  BlurOn(event: any): void {
    
    if (this.params.colDef.min && this.params.colDef.max) {
      this.value = this.params.colDef.min <= this.value && this.params.colDef.max >= this.value ? this.value : 0;
    }

  }

  private getCharCodeFromEvent(event: any): any {
    event = event || window.event;
    return typeof event.which == "undefined" ? event.keyCode : event.which;
  }

  private isCharNumeric(charStr: string): boolean {
    return !!/\d/.test(charStr);
  }

  private isKeyPressedNumeric(event: any): boolean {
    const charCode = this.getCharCodeFromEvent(event);
    const charStr = event.key ? event.key : String.fromCharCode(charCode);
    return this.isCharNumeric(charStr);
  }

  private deleteOrBackspace(event: any) {
    return (
      [KEY_DELETE, KEY_BACKSPACE].indexOf(this.getCharCodeFromEvent(event)) > -1
    );
  }

  private isLeftOrRight(event: any) {
    return [37, 39].indexOf(this.getCharCodeFromEvent(event)) > -1;
  }

  private finishedEditingPressed(event: any) {
    const charCode = this.getCharCodeFromEvent(event);
    return charCode === KEY_ENTER || charCode === KEY_TAB;
  }

  ngAfterViewInit() {
    window.setTimeout(() => {
      this.input.element.nativeElement.focus();
      if (this.highlightAllOnFocus) {
        this.input.element.nativeElement.select();

        this.highlightAllOnFocus = false;
      } else {
        // when we started editing, we want the carot at the end, not the start.
        // this comes into play in two scenarios: a) when user hits F2 and b)
        // when user hits a printable character, then on IE (and only IE) the carot
        // was placed after the first character, thus 'apply' would end up as 'pplea'
        const length = this.input.element.nativeElement.value
          ? this.input.element.nativeElement.value.length
          : 0;
        if (length > 0) {
          this.input.element.nativeElement.setSelectionRange(length, length);
        }
      }

      this.input.element.nativeElement.focus();
    });
  }

  setControlFocused() {
    setTimeout(() => {
      this.input.nativeElement.focus();
    });
  }
}
