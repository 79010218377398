// Angular Imports
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ModalModule } from "ngb-modal";
import { PipeModule } from "src/app/_pipe/pipe.module";
import { DCSAggridModule } from "../dcs-aggrid/dsc-aggrid.module";

// This Module's Components
import { DcsLookupComponent } from "./dcs-lookup.component";

@NgModule({
  imports: [
    DCSAggridModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule,
    PipeModule,
  ],
  declarations: [DcsLookupComponent],
  exports: [DcsLookupComponent],
  providers: [],
})
export class DcsLookupModule {}
