import { Component, Input, ViewChild } from "@angular/core";
import { AgRendererComponent } from "ag-grid-angular";
import {
  IAfterGuiAttachedParams,
  ICellRendererParams,
} from "ag-grid-community";
import { MapConfiguration } from "../../../../../@constant/config";
import { DimensionValue } from "../../../dcs-dimention/dcs-dimentionbase/dcs-dimentionbase.component";

@Component({
  selector: "dimention",
  template: `
    <dcs-dimention
      #select
      (change)="valueChange()"
      [(ngModel)]="value"
      (afterDimenstionSelected)="dimensionSelected($event)"
      [Values]="Values"
      (onOpenDimenstion)="onOpenDimenstion()"
      (onOptionMouseOver)="onOptionMouseOver($event)"
      (onOptionMouseOut)="onOptionMouseOut($event)"
    >
    </dcs-dimention>
  `,
})
export class GridDimentionComponent implements AgRendererComponent {
  @ViewChild("select") dropdown: any;

  refresh(params: any): boolean {
    return true;
  }
  afterGuiAttached(params?: IAfterGuiAttachedParams): void { }
  private params: any;

  private column: any;

  public value: any;

  constructor() { }

  private async loadData() {
    this.dropdown.fieldName = this.column.colId + this.params.rowIndex;
    this.dropdown.showCaption = false;
    this.dropdown.valueMember = this.column.colDef.dataValueField;
    this.dropdown.mappedField = this.column.colDef.mappedField;
    this.dropdown.displayMember = this.column.colDef.dataDisplayField;
    this.dropdown.caption = this.column.colDef.headerName;
    this.dropdown.config = this.column.colDef.config;
    this.dropdown.data = this.column.colDef.dropdownData;
    this.dropdown.Values = this.column.colDef.Values;
    this.dropdown.disabled = !Boolean(
      this.params.colDef.editable === undefined
        ? true
        : this.params.colDef.editable
    );
  }

  async agInit(params: ICellRendererParams) {
    setTimeout(async () => {
      this.params = params;
      this.value = this.params.getValue();
      this.column = this.params.column;
      await this.loadData();
      this.value = !this.value ? this.column.colDef.value : this.value;
      params.node.setDataValue(
        this.column.colDef.field,
        !this.value ? this.column.colDef.value : this.value
      );
      this.mapFields();
    }, 0);
  }

  getValue(): any {
    return this.params.value;
  }

  valueChange() {
    this.params.setValue(this.value);

    this.mapFields();
  }

  mapFields() {
    if (
      this.params &&
      this.params.node &&
      this.column.colDef.mapFields &&
      this.dropdown.data &&
      this.value
    ) {
      this.column.colDef.mapFields.forEach((element: MapConfiguration) => {
        const data = this.dropdown.data.filter(
          (o: any) => o[this.dropdown.valueMember] === this.value
        )[0];
        if (data) {
          const val = data[element.mapField];
          if (val) {
            this.params.node.setDataValue(element.field, val);
          }
        }
      });
    }
  }

  dimensionSelected(event: any) {
    this.params.colDef.Values = event;
    this.params.colDef.displayMemberValue = this.dropdown.displayMemberValue;
    this.params.colDef.afterDimenstionSelected(this.params);
  }
  async onOpenDimenstion() {
    //this.params.colDef.Values = event;
    this.params.colDef.onOpenDimenstion(this.params);
    this.params = this.params;

    await this.agInit(this.params);
  }
  async onOptionMouseOver(event: any) {
    this.params.colDef.onOptionMouseOver(this.params,event);
    this.params = this.params;
  }
  async onOptionMouseOut(event: any) {
    this.params.colDef.onOptionMouseOut(this.params,event);
    this.params = this.params;
  }

  get Values(): Array<DimensionValue> {
    return this.params && this.params.colDef.Values;
  }
  @Input()
  set Values(value: Array<DimensionValue>) {
    this.params.colDef.Values = value;
  }

  public async setControlFocused() {
    setTimeout(() => {
      if (
        this.dropdown &&
        this.dropdown.element &&
        this.dropdown.element.nativeElement &&
        this.dropdown.element.nativeElement.children &&
        this.dropdown.element.nativeElement.children[0] &&
        this.dropdown.element.nativeElement.children[0].children[0] &&
        this.dropdown.element.nativeElement.children[0].children[0].children &&
        this.dropdown.element.nativeElement.children[0].children[0].children[0]
      ) {

        this.dropdown.element.nativeElement.children[0].children[0].children[0].focus();
      }
    }, 0);
  }
}
