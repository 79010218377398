<!-- <nav class="sidebar-nav">
  <ul id="sidebarnav">
    <li class="sidebar-item" [class.active]="showMenu === sidebarnavItem.title"
      *ngFor="let sidebarnavItem of sidebarnavItems"
      [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'active'" id="{{ sidebarnavItem.id }}">
      <div class="nav-small-cap" *ngIf="sidebarnavItem.extralink === true">
        <i [ngClass]="[sidebarnavItem.webicon]"></i><span class="hide-menu">{{ sidebarnavItem.label }}</span>
      </div>
      <a class="sidebar-link waves-effect waves-dark" [routerLink]="
          sidebarnavItem.class === '' ? [sidebarnavItem.path] : null
        " [ngClass]="[sidebarnavItem.class]" *ngIf="!sidebarnavItem.extralink"
        (click)="addExpandClass(sidebarnavItem.title)"
        [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'active'">
        <i-feather [name]="sidebarnavItem.icon" [ngClass]="[sidebarnavItem.icon]"></i-feather>
        <span class="hide-menu">{{ sidebarnavItem.title }}
          <span *ngIf="sidebarnavItem.label != ''"
            [ngClass]="[sidebarnavItem.labelClass]">{{ sidebarnavItem.label }}</span>
        </span>
      </a>
      
    </li>
  </ul>
</nav> -->
<nav *ngIf="type == 1">
  <ul class="menu" id=" w-100-header list-group">
    <li class="OuterLi" *ngFor="let item of sidebarnavItems" id={{item.id}} [ngClass]="item.id ==  ParentmenuID  ? 'active' : 'treeview'">
      <a class="OutA" (click)="getparentID(item.id);">
        <img src="{{item.webicon}}" class="circle_object" alt="">
        <span> {{ item.label }} </span> 
        <i [ngClass]="item.id == ParentmenuID && isShow ? 'fas fa-angle-down activeparent' : 'fas fa-angle-right'"></i>
      </a>
      <ul class="Innerul" [style.height]="item.id ==  ParentmenuID  ? 'auto' : '0'" [style.display]="item.id ==  ParentmenuID && isShow ? 'block' : 'none'">
        <li *ngFor="let child of item.children" id={{child.id}} [style.height]="item.id ==  ParentmenuID  ? 'auto' : '0'"
          (click)="getID(child.id,child);setmoduleName(child.label)" [ngClass]="child.id ==  menuID  ? 'active' : 'treeview'">
          <a class="">
            <img src="{{child.webicon}}" class="circle_object" alt="">
            <span> {{ child.label }} </span>
          </a>
        </li>
      </ul>
    </li>
  </ul>
</nav>






<nav class="sidebar-nav" *ngIf="type != 1">
  <ul id="sidebarnav w-100-header list-group">
    <!-- [routerLinkActive]="['is-active']" -->
    <li *ngFor="let item of sidebarnavItems" id={{item.id}}
      [ngClass]="item.id ==  menuID  ? 'active sidebar-item w-100-header' : 'treeview sidebar-item w-100-header'"
      (click)="getID(item.id,item);setmoduleName(item.label)">
      <a class="sidebar-link waves-effect waves-dark">
        <!-- <i-feather [ngClass]="[item.webicon]"></i-feather> -->
        <img src="{{item.webicon}}" class="circle_object" alt="">
        <!-- <object type="image/svg+xml"
        data="item.webicon"
        class="circle_object"></object> -->
        <span> {{ item.label }}
        </span>
      </a>
      <ul class="treeview-menu display">
      </ul>
    </li>
  </ul>
</nav>