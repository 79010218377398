import {
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  forwardRef,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
} from "@angular/core";
import { ModalComponent, ModalManager } from "ngb-modal";
import { SyncValue } from "../../service/syncdata.service";
import { DcsModalComponent } from "../dcs-modal/dcs-modal.component";
import { DcsReportComponent } from "../dcs-report/dcs-report.component";
import { DCSReportService } from "../dcs-report/dcs-report.service";

declare var $: any;

@Component({
  selector: "dcs-printviewer",
  templateUrl: "dcs-printviewer.component.html",
  styleUrls: ["dcs-printviewer.component.scss"],
})
export class DcsPrintviewerComponent {
  // @ViewChildren(forwardRef(() => ModalComponent))
  @ViewChild("printViewer") printViewer?: ModalComponent;
  @ViewChild("report", { read: ViewContainerRef }) container: any;
  componentRef: any;
  reportTitle = "";
  reportCode: string = "";
  ReportDtl: any = [];
  modalRef: any;
  @ViewChild(DcsReportComponent) report?: DcsReportComponent;
  constructor(
    private resolver: ComponentFactoryResolver,
    private reportservice: DCSReportService,
    private modalmanager: ModalManager
  ) { }

  async drag() {
    $(".modal-dialog").draggable({
      handle: ".modal-header",
    });
    $("modal-dialog").css({ top: 0, left: 0, right: 0, bottom: 0 });
  }

  public async openReport(
    reportCode: string,
    reportTitle: string,
    showCriteria: boolean,
    isCollapsed: boolean,
    defaultValues: Array<SyncValue>,
    isAutoGenerated: boolean = true
    // reporttype: number = 1
  ) {
    this.reportCode = reportCode;

    await this.getReportType(this.reportCode);

    if (this.ReportDtl) var data = this.ReportDtl[0];

    this.reportTitle = reportTitle;
    this.container.clear();
    const factory: ComponentFactory<any> = this.resolver.resolveComponentFactory(
      DcsReportComponent
    );
    this.componentRef = this.container.createComponent(factory);
    this.componentRef.instance.defaultValues = defaultValues;
    this.componentRef.instance.isAutoGenerated = isAutoGenerated;
    this.componentRef.instance.isCollapsed = isCollapsed;
    this.componentRef.instance.showCriteria = showCriteria;
    this.componentRef.instance.reportCode = reportCode;
    var size: string = "";
    if (this.printViewer) {
      if (data && data.islandscape === true) {
        size = "xl";
        //         const dialog: HTMLElement = this.printViewer.last.element.nativeElement.children[0];
        //         const content: HTMLElement = this.printViewer.last.element.nativeElement.children[0];
        //         if (dialog && content) {
        //             dialog.style.width = "1220px";
        //             content.style.width = "1220px";
        //         }
        //         this.componentRef.instance.height = "1020px";
        //         this.printViewer.last.open();
      } else {
        size = "lg";
        //         const dialog: HTMLElement = this.printViewer.last.element.nativeElement.children[0];
        //         const content: HTMLElement = this.printViewer.last.element.nativeElement.children[0];
        //         if (dialog && content) {
        //             dialog.style.width = "950px";
        //             content.style.width = "950px";
        //         }
        //         this.componentRef.instance.height = "1350px";
        //         this.printViewer.last.open();
        //     }
      }
      setTimeout(() => {
        this.modalRef = this.modalmanager.open(this.printViewer, {
          size: size,
          modalClass: "mymodal",
          hideCloseButton: false,
          centered: false,
          backdrop: true,
          animation: true,
          keyboard: false,
          closeOnOutsideClick: false,
          backdropClass: "modal-backdrop",
        });

        this.drag();
      }, 0);
    }
  }
  async ngOnInit() {
    // if (this.printViewer && this.printViewer.last) {
    // this.printViewer.last.backdrop = "static";
    // this.printViewer.last.keyboard = true;
    // }
  }

  async innerClose() {
    // if (this.printViewer && this.printViewer.last) {
    //     this.printViewer.last.keyboard = false;
    //     $(".modal-backdrop").remove(':last');
    //     this.printViewer.last.close();
    // }
    if (this.printViewer) this.printViewer.close();
  }

  async getReportType(code: any) {
    this.ReportDtl = [];
    const dto = await this.reportservice.GetReportType(code);

    if (dto.IsSuccess) {
      this.ReportDtl = dto.Data;
    } else {
    }
  }
}
