import { Component, OnInit, HostListener, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationExtras, Router } from '@angular/router';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AppConstants } from 'src/app/@constant/app.constant';
import { DCSAggridComponent } from 'src/app/@core/components/dcs-aggrid/dcs-aggrid.component';
import { DcsModalComponent } from 'src/app/@core/components/dcs-modal/dcs-modal.component';
import { MenusService } from 'src/app/@core/service/menus.service';
import { utdocfieldsReportService } from 'src/app/shared/services/utdocfieldsReport.Service';
import { ContainerComponent } from "../../@core/base/nav/Container.Component";
import { FavoriteService } from '../menu-component/menu-favorite.service';
import { SidebarComponent } from '../../shared/sidebar/sidebar.component';

@Component({
  selector: 'app-full-layout',
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.scss'],
  providers: [
    FavoriteService
  ]
})
export class FullComponent extends ContainerComponent implements OnInit {
  public tabjson = [
    { id: 1, Value: "DashBoard", icon: "../../../assets/images/LoginAssest/dash.png", visible: true, class: "" },
    { id: 2, Value: "Modules", icon: "../../../assets/images/LoginAssest/mod.png", visible: true, class: "" },
    { id: 3, Value: "Reports", icon: "../../../assets/images/LoginAssest/rep.png", visible: true, class: "" },
    {
      id: 4,
      Value: "Workbench",
      icon: "../../../assets/images/LoginAssest/wor.png",
      visible: true,
      class: "",
    },
  ];
  // public tabjson = [
  //   { id: 1, Value: "DashBoard", icon: "fas fa-chart-bar", visible: true, class: "" },
  //   { id: 2, Value: "Modules", icon: "bi bi-boxes", visible: true, class: "" },
  //   { id: 3, Value: "Reports", icon: "far fa-file-alt", visible: true, class: "" },
  //   {
  //     id: 4,
  //     Value: "Workspace",
  //     icon: "fa fa-table",
  //     visible: true,
  //     class: "",
  //   },
  // ];
  // titlePresent = this.localStorage.get('screenname')
  @Output() toggleSidebar = new EventEmitter<void>();
  selectedItem: any;
  public config: PerfectScrollbarConfigInterface = {};
  EntityLogo: any;
  schoolname: any;
  labelset: boolean = false;
  finallistdata: any = [];
  gridmenus: any = [];
  linkIndex: any = 0
  online: boolean = false;
  currentSectionIndex: number = 0;
  userData: any = this.localStorage.get("utuser");
  UserLog = JSON.parse(this.userData);
  constructor(public router: Router, private myService: MenusService, private favoriteService: FavoriteService, private titleService: Title,
    private _utdocfieldsReportService: utdocfieldsReportService) {
    super();

    this.myService.myMethod$.subscribe((data: any) => {
      this.modulename = data; // And he have data here too!
      this.title = ''
    });
  }
  public setHeaderTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  tabStatus: any;
  OurPartner: any;
  public isCollapsed = false;

  public innerWidth: any;
  public defaultSidebar: any;
  public showSettings = false;
  public showMobileMenu = false;
  public expandLogo = false;

  options = {
    theme: "light", // two possible values: light, dark
    dir: "ltr", // two possible values: ltr, rtl
    layout: "vertical", // fixed value. shouldn't be changed.
    sidebartype: "full", // four possible values: full, iconbar, overlay, mini-sidebar
    sidebarpos: "fixed", // two possible values: fixed, absolute
    headerpos: "fixed", // two possible values: fixed, absolute
    boxed: "full", // two possible values: full, boxed
    navbarbg: "skin1", // six possible values: skin(1/2/3/4/5/6)
    sidebarbg: "skin1", // six possible values: skin(1/2/3/4/5/6)
    logobg: "skin1", // six possible values: skin(1/2/3/4/5/6)
  };

  Logo() {
    this.expandLogo = !this.expandLogo;
  }

  async ngOnInit() {
    this.schoolname = this.localStorage.get('schoolName')
    this.EntityLogo = this.localStorage.get('EntityLogo');
    let state = this.localStorage.get("tabState");
    await this.myService.getMenu();

    var DashBoardData = JSON.parse(this.localStorage.get("dashboards"));
    if (this.tabjson && this.tabjson.length > 0) {
      if (DashBoardData && DashBoardData.length > 0) {
        this.tabjson[0].visible = true;
      }
      else {
        this.tabjson[0].visible = false;
      }
    }
    this.OurPartner = AppConstants.ourpartner.name;

    if (state && state !== "undefined" && state !== "null") {

      let tab = JSON.parse(state);

      if (tab)
        await this.menuClick(tab);
      // this.router.navigate(item2.routeid);

    } else {
      var showTab;
      if (this.tabjson && this.tabjson.length > 0) {
        showTab = this.tabjson.filter((a: any) => a.visible == true);
      }
      if (showTab && showTab.length > 0) {
        this.menuClick(showTab[0]);
      }

    }

    if (this.router.url === "/") {
      this.router.navigate(["/login"]);
    }
    this.defaultSidebar = this.options.sidebartype;

    this.handleSidebar();

    // await this.getFavoriteScreen()
    this.updateStatus();
  }

  @HostListener("window:resize", ["$event"])
  onResize(event: string) {
    this.handleSidebar();
  }

  async menuClick(item: any) {
    if (item && item.id === 4) {
      // var test = this.localStorage.get("workbench");
      // if (test == 'true') {
      this.router.navigate([]).then((o: any) => window.open("/WFWorkBench", "_blank"))
      // this.router.navigate([""]);
      // }
      // this.router.navigate(['authentication/404']);
      // this.localStorage.set("workbench", "true");
    }
    else {
      this.selectedItem = item;
      this.myService.tabData(item)
      this.setHeaderTitle(`EDAP - ${item.Value.toUpperCase()}`);
      this.localStorage.set("tabState", JSON.stringify(item));
      var userid = JSON.parse(localStorage.utuser).userid;
      var UserRole = JSON.parse(this.localStorage.get("favouritescreen"));
      if(UserRole == null || UserRole && UserRole.length == 0){
        await this.getFavouriteList(userid);
      }
    }

    // this.getgrid()
  }

  handleSidebar() {
    this.innerWidth = window.innerWidth;
    switch (this.defaultSidebar) {
      case "full":
      case "iconbar":
        if (this.innerWidth < 1170) {
          this.options.sidebartype = "mini-sidebar";
        } else {
          this.options.sidebartype = this.defaultSidebar;
        }
        break;

      case "overlay":
        if (this.innerWidth < 767) {
          this.options.sidebartype = "mini-sidebar";
        } else {
          this.options.sidebartype = this.defaultSidebar;
        }
        break;

      default:
    }
  }

  toggleSidebarType() {
    switch (this.options.sidebartype) {
      case "full":
      case "iconbar":
        this.options.sidebartype = "mini-sidebar";
        break;

      case "overlay":
        this.showMobileMenu = !this.showMobileMenu;
        break;

      case "mini-sidebar":
        if (this.defaultSidebar === "mini-sidebar") {
          this.options.sidebartype = "full";
        } else {
          this.options.sidebartype = this.defaultSidebar;
        }
        break;

      default:
    }
  }
  showDropdown: any = false
  showNotification: any = false
  @ViewChild('leftsidebar') leftsidebar: ElementRef | any
  @ViewChild('activelist') activelist: ElementRef | any
  @ViewChild('inputfield') inputfield: ElementRef | any
  @ViewChild('menumodal') menumodal?: DcsModalComponent;
  @ViewChild('menugrid') menugrid?: DCSAggridComponent;
  @ViewChild('sidebarmenu') sidebarmenu?: SidebarComponent;
  async dropdownFunction($event: { stopPropagation: () => void }) {
    if (this.showDropdown == false) {
      await this.getFavoriteScreen()
      this.leftsidebar.nativeElement.classList.add('z-index-50')
    }
    $event.stopPropagation();
    setTimeout(() => {
      this.showDropdown = !this.showDropdown
      if (this.showDropdown == false) {
        this.leftsidebar.nativeElement.classList.remove('z-index-50')
      }
      // console.log(i);
    }, 200);



    // this.showNotification = !this.showNotification
  }
  notificationFunction($event: { stopPropagation: () => void }) {

    $event.stopPropagation();
    // this.showDropdown = !this.showDropdown
    this.showNotification = !this.showNotification
  }
  @ViewChild("dropdown") dropdown?: ElementRef
  @HostListener("document:click", ["$event"]) Documentclick(event: Event) {
    if (this.dropdown && this.dropdown.nativeElement && this.dropdown.nativeElement.contains(event.target)) {
      this.showDropdown = true;
    }
    else {
      this.showDropdown = false;
    }
    this.showNotification = false;
  }
  // getFavData() {
  //   if (this.LookupData.FavoriteScreens) {
  //     var data = this.LookupData.FavoriteScreens
  //     if (data && data.length && data.length > 0){
  //       data.forEach((element: any) => {
  //         element.
  //       });
  //     }
  //   }
  // }
  // dataFav : any = []
  async getFavoriteScreen() {

    var userid = JSON.parse(localStorage.utuser).userid;
    let favstate = this.localStorage.get("tabState");
    let tabfav = JSON.parse(favstate);
    this.LookupData.FavoriteScreensModule = [];
    this.LookupData.FavoriteScreensReport = [];
    this.LookupData.FavoriteScreens = [];
    if (tabfav && tabfav.id == 2) {
      const dto = await this.favoriteService.getFavouriteScreen(userid, 'null');
      this.LookupData.FavoriteScreensModule = [];
      this.LookupData.FavoriteScreensReport = [];
      if (dto && dto.IsSuccess) {
        dto.Data.children.forEach((parent: any) => {
          parent.children.forEach((child: any) => {
            var children = parent.children.filter((a: any) => a.menucatid == 2);
            var dupli = this.LookupData.FavoriteScreensModule.filter((a: any) => a.mmenuid == parent.mmenuid);
            if (dupli && dupli.length == 0 && children && children.length > 0) {
              this.LookupData.FavoriteScreensModule.push({
                children: children,
                indx: parent.indx,
                label: parent.label,
                mmenuid: parent.mmenuid,
                modulerouteid: parent.modulerouteid,
                webicon: parent.webicon,
              });
            }
          });
        });

        this.LookupData.FavoriteScreens = this.LookupData.FavoriteScreensModule



        // this.dataFav = dto.Data.children
      }
    }

    if (tabfav && tabfav.id == 3) {
      const dto = await this.favoriteService.getFavouriteScreen(userid, 'null');
      this.LookupData.FavoriteScreensModule = [];
      this.LookupData.FavoriteScreensReport = [];
      if (dto && dto.IsSuccess) {
        dto.Data.children.forEach((parent: any) => {
          parent.children.forEach((child: any) => {
            var childrenRe = parent.children.filter((a: any) => a.menucatid == 3);
            var duplir = this.LookupData.FavoriteScreensReport.filter((a: any) => a.mmenuid == parent.mmenuid);
            if (duplir && duplir.length == 0 && childrenRe && childrenRe.length > 0) {
              this.LookupData.FavoriteScreensReport.push({
                children: childrenRe,
                indx: parent.indx,
                label: parent.label,
                mmenuid: parent.mmenuid,
                modulerouteid: parent.modulerouteid,
                webicon: parent.webicon,

              });
            }
          });
        });

        this.LookupData.FavoriteScreens = this.LookupData.FavoriteScreensReport



        // this.dataFav = dto.Data.children
      }
    }
    // if (this.LookupData.FavoriteScreens && this.LookupData.FavoriteScreens.length && this.LookupData.FavoriteScreens.length > 0)
    //   this.LookupData.FavoriteScreens.forEach((element: any) => {

    //     // console.log(element.mmenuid)
    //     let navigationExtras: NavigationExtras = {
    //       queryParams: {
    //         menuid: element.mmenuid,
    //       },
    //       replaceUrl: false,
    //     };
    //   });

  }
  refreshFavoriteScreens() {
    this.isClicked = true
    setTimeout(() => {
      this.getFavoriteScreen()
      this.isClicked = false
    }, 500);
  }
  changeTitle(item: any, item2: any) {
    this.localStorage.remove("modulename");
    if (this.labelset == false) {
      var menuidroute = (item.mmenuid ? item.mmenuid : null)
    }
    else {
      var menuidroute = item2.mmenuid
    }
    let navigationExtras: NavigationExtras = {
      queryParams: {
        menuid: menuidroute,
      },
      replaceUrl: false,
    };
    this.router.navigate([item2.routeid], navigationExtras);

    this.localStorage.set("activePath", item2.routeid);
    this.title = item2.label
    this.localStorage.set('screenname', item2.label)

    this.localStorage.set('menuid', item.mmenuid)
    if (this.labelset == false) {
      item.parentlabel = item.label
      this.localStorage.set('modulename', item.parentlabel)
      this.labelset = false
    }
    else {
      this.localStorage.set('modulename', item.parentlabel)
      this.myService.menuName(item.parentlabel);
    }

    if (this.tabjson && this.tabjson.length && this.tabjson.length > 0) {
      this.tabjson.forEach(element => {

        if (element && element.id == 2) {
          this.localStorage.set('tabState', JSON.stringify(element))
        }
      });
    }
  }
  async changeTitlereport(item: any, item2: any) {

    var data = await this.myService.getReportMenuagainstMenuid(item.mmenuid);
    if (data && data.children) {
      var reportdata: any;
      data.children.forEach((element: any) => {
        if (element.children) {
          element.children.forEach((element2: any) => {
            if (element2.children) {
              element2.children.forEach((element3: any) => {
                if (element3 && element3.id == item2.menuid) {

                  reportdata = element3
                }
              });
            }
          });
        }
      });
      let navigationExtras: NavigationExtras = {
        queryParams: {
          menuid: item2.menuid,
        },
        replaceUrl: false,
      };

      if (reportdata && reportdata.utreporthdr) {
        this.localStorage.set("reportCode", reportdata.utreporthdr.code);
        this.localStorage.set("reportCodeName", reportdata.utreporthdr.reportname);
        this.localStorage.set("reportId", reportdata.utreporthdr.Id);
        // this.utreporthdr = item.utreporthdr;

        this.router.navigate(["reportViewer"]);
      } else {
        this.localStorage.set("reportCode", undefined);
        this.localStorage.set("reportId", undefined);
        this.localStorage.set("reportCodeName", undefined);
      }
      this.localStorage.set("screenname", item2.label);
      this.localStorage.set("activeReportScreenname", item2.label);
      this.localStorage.set("activeReportPath", item2.routeid);

      if (reportdata.utreporthdr && reportdata.utreporthdr.Id) {
        await this.getDocfieldsReport(reportdata.utreporthdr.Id, reportdata.utreporthdr.reportname)
      }
    }

  }
  async getDocfieldsReport(reportid: any, reportcode: number) {
    const dto = await this._utdocfieldsReportService.getDocfieldsReport(reportid, reportcode)
    if (dto && dto.IsSuccess) {
      this.localStorage.set("DocFieldsReport", JSON.stringify(dto.Data))
    }
  }
  routeid: any;
  isClicked: boolean = false;
  dropdownClicked: boolean = false;

  async getgrid() {
    this.LookupData.listmenu = []
    if (this.selectedItem && this.selectedItem.id == 1) {
      var DashBoardData = JSON.parse(this.localStorage.get("dashboards"));
      DashBoardData.forEach((element: any) => {
        if (element.children && element.children.length > 0) {
          element.children.forEach((element2: any) => {
            if (element2.children && element2.children.length > 0) {
              element2.children.forEach((element3: any) => {
                element3.mmenuid = element.id
                element3.parentlabel = element2.label
                this.LookupData.listmenu.push(element3)
              });
            }
          });
        }
      });
    }
    else if (this.selectedItem && this.selectedItem.id == 2) {
      var menuesData = JSON.parse(this.localStorage.get("menues"));
      menuesData.forEach((element: any) => {
        if (element.children && element.children.length > 0) {
          element.children.forEach((element2: any) => {
            if (element2.children && element2.children.length > 0) {
              element2.children.forEach((element3: any) => {
                if (element3.children && element3.children.length > 0) {
                  element3.children.forEach((element4: any) => {
                    element4.mmenuid = element.id
                    element4.menuid = element4.id
                    element4.parentlabel = element.label
                    this.LookupData.listmenu.push(element4)
                  });
                }
              });
            }
          });
        }
      });
    }
    else if (this.selectedItem && this.selectedItem.id == 3) {
      var reportmenuesData = JSON.parse(this.localStorage.get("reportmenues"));
      reportmenuesData.forEach((element: any) => {
        if (element.children && element.children.length > 0) {
          element.children.forEach((element2: any) => {
            if (element2.children && element2.children.length > 0) {
              element2.children.forEach((element3: any) => {
                if (element3.children && element3.children.length > 0) {
                  element3.children.forEach((element4: any) => {
                    element4.mmenuid = element.id
                    element4.parentlabel = element.label
                    this.LookupData.listmenu.push(element4)
                  });
                }
              });
            }
          });
        }
      });
    }
  }

  async menumodalopen() {
    await this.gridconfigmenu()
    this.menumodal?.open();
  }

  onKeyup(event: any) {
    this.finallistdata = [];
    var char = event.target.value.toLowerCase()
    if (this.LookupData.listmenu && this.LookupData.listmenu.length > 0) {
      if (char && char != "" && char != undefined && char != null) {
        var filterddata = this.LookupData.listmenu.filter((a: any) => a.label.toLowerCase().indexOf(char) !== -1)
        if (filterddata && filterddata.length > 0) {
          this.finallistdata = filterddata
        }
        else {
          var norecord = "none"
          this.finallistdata.push(norecord)
        }
      }
      else {
        event.target.value = ""
      }
    }
    else {
      this.finallistdata = [];
    }
    if (event.keyCode == 40 || event.keyCode == 38) {
      this.navigateUsingKey(event)
    }
  }

  async navigateUsingKey(event: any) {
    switch (event.keyCode) {
      case 38:       
        this.linkIndex === -1 ? this.linkIndex = 0 : this.linkIndex--;
        this.downTraverse(2, this.finallistdata.length);
        break;
      case 40: 
        this.upTraverse(1, this.finallistdata.length);
        break;
    }
  }

  downTraverse(sectionIndex: number, listLength: any) {
    if (this.linkIndex <= listLength) {
      this.linkIndex === -1 ? (this.currentSectionIndex = sectionIndex, this.linkIndex = listLength - 1) : '';
    }
    else {
      this.linkIndex = 1 + this.linkIndex;
    }
  }
  upTraverse(sectionIndex: number, listLength: number) {
    if ((this.linkIndex + 1) < listLength) {
      listLength - 1 <= this.linkIndex ? (this.currentSectionIndex = sectionIndex, this.linkIndex = -1) : '';
      this.linkIndex++;
    }
    else {
      this.linkIndex = (this.linkIndex + 1) - listLength;
    }
  }
  

  async getFavouriteList(userid: any) {
    const dto = await this.favoriteService.getFavouriteList(userid, "null")
    if (dto && dto.IsSuccess && dto.Data) {
      this.LookupData.favouritelist = dto.Data;   
      localStorage.setItem(
        "favouritescreen",
        JSON.stringify(this.LookupData.favouritelist)
      );
    }
  }

  @HostListener("document:keydown.enter", ["$event"]) onKeydownHandler(
    event: KeyboardEvent
  ) {
    event.preventDefault();
    if(this.finallistdata && this.finallistdata.length > 0 ){
      if (this.activelist && this.activelist.nativeElement && this.activelist.nativeElement.children && this.activelist.nativeElement.children.length > 0 && this.activelist.nativeElement.children[0].innerText && this.activelist.nativeElement.children[0].innerText != "none") {
        var children = this.activelist.nativeElement.children;
        for (var i = 0; i < children.length; i++) {
          var child = children[i];
          if (child.classList.contains('section-color')) {
            let check: HTMLElement = child
            check.click();
            this.finallistdata = []
            this.linkIndex = 0
            this.currentSectionIndex = 0
          }
        }
      }
    }
  }

  async searchmenu(item: any, item2: any) {
    this.localStorage.remove("reportCode");
    this.localStorage.remove("activePath");
    var menutypeid = this.localStorage.get("menutype")
    if (this.selectedItem && this.selectedItem.id == 1) {
      let navigationExtras: NavigationExtras = {
        queryParams: {
          menuid: item.id,
          typeid: menutypeid,
        },
        replaceUrl: false,
      };
      this.localStorage.set("activePath", item.routeid);
      this.localStorage.set('screenname', item2.label)
      this.sidebarmenu!.ParentmenuID = item.parentid
      this.sidebarmenu!.menuID = item.id
      this.router.navigate([item.routeid], navigationExtras);
    }
    else if (this.selectedItem && this.selectedItem.id == 2) {
      this.labelset = true
      await this.changeTitle(item, item2)
      this.sidebarmenu!.menuID = item.mmenuid
    }
    else if (this.selectedItem && this.selectedItem.id == 3) {
      let navigationExtras: NavigationExtras = {
        queryParams: {
          menuid: item2.id,
        },
        replaceUrl: false,
      };
      if (item2 && item2.utreporthdr) {
        this.localStorage.set("reportCode", item2.utreporthdr.code);
        this.localStorage.set("reportCodeName", item2.utreporthdr.reportname);
        this.localStorage.set("reportId", item2.utreporthdr.Id);

        var reportmenuiddata = this.localStorage.get("reportmenuid")
        this.router.navigateByUrl('menucomponent?menuid=' + reportmenuiddata + '&typeid=' + menutypeid, { skipLocationChange: true }).then(() => {
          this.router.navigate(['reportViewer']);
        });
      } else {
        this.localStorage.set("reportCode", undefined);
        this.localStorage.set("reportId", undefined);
        this.localStorage.set("reportCodeName", undefined);
      }
      this.localStorage.set("screenname", item2.label);
      this.localStorage.set("activeReportScreenname", item2.label);
      this.localStorage.set("activeReportPath", item2.routeid);
      this.sidebarmenu!.menuID = item.mmenuid
      this.myService.menuName(item.parentlabel);
    }

    this.finallistdata = []
    this.inputfield.nativeElement.value = ''
  }
  onFocusOutEvent(event: any) {
    // this.finallistdata = [];
  }
  async clickeddoouble(event: any, event2: any) {
    await this.searchmenu(event.data, event2.data)
    this.menumodal?.closeModal();
  }

  focusin() {
    this.getgrid()
  }


  @HostListener('window:online')
  onOnline() {
    this.updateStatus();
    // Additional actions when online
  }

  @HostListener('window:offline')
  onOffline() {
    this.updateStatus();
    // Additional actions when offline
  }

  updateStatus() {
    this.online = navigator.onLine;
  }
  async gridconfigmenu() {
    this.gridmenus = [
      {
        headerName: 'Id',
        field: 'id',
        width: 100,
        hide: true
      },
      {
        headerName: 'Doc Type',
        field: 'id',
        width: 100,
        // hide: true
      },
      {
        headerName: 'Screen Name',
        field: 'label',
        width: 220,
      },
      {
        headerName: 'Module Name',
        field: 'parentlabel',
        width: 100,
      },
    ]
  }

}
