<!-- ============================================================== -->
<!-- toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-left mr-auto">
    <!-- Toggler of header J -->

    <li class="d-none d-md-block">
        <a (click)="toggleSidebar.emit()" style="z-index: 999999;"
            class="nav-link sidebartoggler waves-effect waves-light p-0" href="javascript:void(0)">
            <!-- <i class="mdi mdi-menu font-24"></i> -->
            <img class="menu-lines" src="../../../assets/images/LoginAssest/lines.png" alt="">
        </a>
    </li>
</ul>
<div class="float-left school-name"> {{this.campusName}}
</div>

<ul class="navbar-nav float-right">
    <!-- ============================================================== -->
    <!-- create new Icons-->
    <!-- ============================================================== -->
    <li class="sub-menu-parents mx-2" tab-index="0">
        <a (click)="switchcampusEvent()" class="nav-link px-0 py-0" style="cursor: pointer;">
            <!-- <i class="fas fa-building fa-lg"></i> -->
            <img class="top-rig-ico" src="../../../assets/images/LoginAssest/branch.png" alt="Branch Name">
        </a>
        <ul class="sub-menu mailbox bday entitysetup">
            <span class="triangle"></span>
            <span class="triangle"></span>
            <div class="Switch-Campus-head">
                <h5>
                    <i class="fa fa-university"></i> Switch Campus
                </h5>
            </div>
            <div class="filter-area">
                <input type="search" placeholder="Search Campus" aria-label="Filter" class="filter-campus"
                    [(ngModel)]="campFilter.stxt">
                <i class="fa fa-search"></i>
            </div>
            <!-- <div class="drop-title bg-primary bday-panel p-0 overflow-hidden">
                <div class="row p-2 with-border mb-0 text-dark">
                    <div class="camp-list" *ngFor="let item of instituteList ">
                        <a (click)="setselectedEntity(item.Id)" title="{{item.stxt}}">
                            <li>
                                <div class="pull-left Campus-img">
                                    onerror="this.src='assets/AdminLTE_New/images/campus_blank.png'"
                                    <img class="img-circle img-border" src="{{item.image}}" alt="User Image">
                                </div>

                                <div class="Campus-title">
                                    <h4>
                                        {{item.stxt}}
                                    </h4>
                                    <p>
                                        <strong> Address:</strong> {{item.address}}
                                    </p>
                                </div>
                            </li>
                        </a>
                    </div>
                </div>
            </div> -->
            <div class="birth-area" id="notification-scroll">
                <!-- | filterBy: campFilter -->
                <div class="with-border mb-0" *ngFor="let item of instituteList ; let i = index">
                    <a (click)="setselectedEntity(item.Id)" title="{{item.stxt}}">
                        <li [ngClass]="{'active-div': selectedItem === item.Id}" style="list-style: none;">
                            <div class="">
                                <div class="">
                                    <div class="pull-left Campus-img">
                                        <img *ngIf="item.image.includes('.png')" class="img-circle img-border"
                                            src="{{item.image}}">
                                        <img *ngIf="!item.image.includes('.png')" class="img-circle img-border"
                                            src="../../../assets/images/no-image.png">
                                    </div>
                                </div>
                                <div class="p-1 campus-details">
                                    <div class="Campus-title">
                                        <h4 class="mb-1 mt-1"> {{item.stxt}} </h4>
                                        <p><strong> Address:</strong> {{item.address}} </p>
                                    </div>
                                </div>
                            </div>


                        </li>
                    </a>
                </div>
            </div>
        </ul>
    </li>
    <li class="sub-menu-parent mx-2" tab-index="0">
        <a class="nav-link px-0 py-0" style="cursor: pointer;">
            <img class="top-rig-ico1" src="../../../assets/images/LoginAssest/birth.png" alt="Happy Birthday">
            <div *ngIf="studentbirthday && studentbirthday.length && studentbirthday.length > 0 || LookupData.empBirthdata && LookupData.empBirthdata.length && LookupData.empBirthdata.length > 0"
                class="Birthdaycounter"></div>&nbsp;&nbsp;
        </a>
        <ul class="sub-menu mailbox bday">
            <span class="triangle"></span>
            <div class="birthday-countsection">
                <p (click)="getstudbirthdaymodal();" style="border-bottom: 1px solid #ddd;"><i class="fa fa-user"></i>
                    <span class="text">Student Birthday</span>
                    <span class="count">{{studentbirthday && studentbirthday.length && studentbirthday.length > 0 ?
                        studentbirthday.length : 0}}</span>
                </p>
                
                <!-- <p (click)="getEmpdaymodal();"><i class="fa fa-user"></i>
                    <span class="text">Employees Birthday</span>
                    <span class="count">{{LookupData.empBirthdata && LookupData.empBirthdata.length && LookupData.empBirthdata.length > 0 ?
                        LookupData.empBirthdata.length : 0}}</span>
                    
                </p> -->
            </div>
        </ul>
    </li>

    <li class=" dropdown d-flex  datetime " ngbDropdown placement="bottom-right">
        <span class="text-center py-1 ">
            <p class="mb-0 date">{{today | date:'mediumDate'}}</p>
            <p class="mb-0 time ">{{currentTime}}</p>
        </span>
    </li>

    <li class=" dropdown profil" ngbDropdown placement="bottom-right">
        <a ngbDropdownToggle class="dropdown-toggle nav-link p-0 profileItem px-2 " href="javascript:void(0)"
            id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <!-- <i class="fas fa-user-circle"></i> -->
            <img class="top-rig-ico2" src="../../../assets/images/LoginAssest/user.png" alt="User">
            <!-- <img src="img_avatar.png" alt="Avatar" style="width:200px" style="border-radius:50%"> -->
            <!-- <i class="fa fa-angle-down" aria-hidden="true"></i> -->
            <!-- <i class="fas fa-angle-down fa-lg arrow-icon-bottom"></i> -->
        </a>
        <div class="dropdown-menu dropdown-menu-right mailbox custom-dropdown-menu-right" ngbDropdownMenu>
            <span class="with-arrow">
                <span class="bg-primary"></span>
            </span>
            <ul class="list-style-none">
                <li>
                    <div class="custom-dropdown-menu-right-header">
                        <div class="dcscolumn-12 heading">
                            <!-- <i class="fas fa-user"></i>  -->
                            Profile
                        </div>
                    </div>
                </li>
                <li>
                    <div class="drop-titles bg-primarys">
                        <div class="row d-flex align-items-center">
                            <div class="dcscolumn-12">
                                <div class="log-profilr-img">
                                    <h4 class="text-center mb-0 ">
                                        <!-- <i class="fas fa-user fa-lg"></i> -->
                                        <div [style.background-image]="userImage  ? 'url(' + userImage +')' : ''" alt=""
                                            class="light-logo logo-width school-logo-icon">
                                        </div>
                                        <!-- <img [src]="userImage" alt=""> -->
                                    </h4>
                                </div>
                            </div>
                            <div class="dcscolumn-12">
                                <span class="text-center">
                                    <ul class="pl-0">
                                        <li class="user-log-name">{{fullname}}</li>
                                    </ul>
                                </span>
                            </div>
                            <div class="dcscolumn-12">
                                <div class="for-change-pass">
                                    <a class="" (click)="openModal()" href="javascript:void(0)">
                                        <i class="fas fa-unlock fa-lg"><span class="drop-title-icon-text">Change
                                                Password</span></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="row">

                        <div class="dcscolumn-12">
                            <div class="for-logout-btn">
                                <a class="" href="/">
                                    <i class="fas fa-sign-out-alt fa-lg"><span
                                            class="drop-title-icon-text">Logout</span></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </li>
</ul>
<dcs-modal #Birthdaymodal [mode]="3" [hideSave]="true" [hideprint]="true" [hideClose]="true" (onClose)="Close($event)" [hideSaveClose]="true"
    lvl="2" class="birthday-modal" Caption="Student Birthday">
    <student-birthday #StdBirthday *ngIf="showBirth == true" (close)="CloseBirthdayMod()"
        [birthdayStdId]="studentbirthday" [showStdOrEmp]="showstdaurempmodal"></student-birthday>
</dcs-modal>
<dcs-modal #Empbirthdaymodal [mode]="3" [hideSave]="true" [hideprint]="true" [hideClose]="true" (onClose)="Closeemp($event)" [hideSaveClose]="true"
    lvl="2" class="birthday-modal" Caption="Employee Birthday">
    <student-birthday #StdBirthday *ngIf="showBirth == true" (close)="CloseempbirthdayMod()"
        [birthdayStdId]="LookupData.empBirthdata" [showStdOrEmp]="showstdaurempmodal"></student-birthday>
</dcs-modal>

<dcs-modal #switchcampus [mode]="3" [hideSave]="true" [hideSaveClose]="true" lvl="2" Caption="Switch Campus"
    class="switch-cam-modal">
    <div class="form-group mt-2">
        <div class="contain_box">

            <div class="campus-logo-switch">
                <div>
                    <img [src]="EntityLogo ? EntityLogo: '../../../assets/images/flash.png'" />
                </div>
            </div>

            <div class="main-sel-campus">
                <label for="sel1" class="select-campus-sel">Select Entity </label>
                <div class="sel-sub-campus">
                    <span class="searchentity" (click)="openentity()"><i class="fa fa-search"></i></span>
                    <select #selectentity [(ngModel)]="entid" style="font-size: 13px;border-top-left-radius: 0px !important;
                  border-bottom-left-radius: 0px !important;" class="form-control"
                        (change)="getSubsidiaryEntites(entid)" required>
                        <!-- <option value="0">--Select--</option> -->
                        <option value="{{ e.Id }}" *ngFor="let e of entitylist; let i = index">
                            {{ e.stxt }}
                        </option>
                    </select>
                </div>
            </div>
            <div *ngIf="showsubsidiaryCampus == 'true'">
                <div class="main-sel-campus">
                    <label for="sel1" class="select-campus-sel">Select Campus*</label>
                    <div class="sel-sub-campus">
                        <span class="searchentity" (click)="opensubsidary()"><i class="fa fa-search"></i></span>
                        <select #subselectentity [(ngModel)]="subsidiaryentid" style="font-size: 13px;border-top-left-radius: 0px !important;
                      border-bottom-left-radius: 0px !important;" class="form-control" required>
                            <!-- <option value="0">--Select--</option> -->
                            <option (change)="subEntityId12($event)" value="{{ se.Id }}"
                                *ngFor="let se of subsidiaryEntitiesList; let i = index">
                                {{ se.stxt }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="form-group text-center">
                <div class="col-xs-12 ">
                    <div class="switch-inbtn">
                        <!-- <a class="btn btn-block btn-lg btn-info" (click)="proceed()"><span #defaultButton>Proceed</span> </a> -->
                        <button type="submit" class="" (click)="subEntityId12()">
                            <span #defaultButton>Switch Campus</span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="campus-img-sec">
                <img src="../../../assets/images/LoginAssest/school 01.png" alt="">
            </div>
        </div>
    </div>
</dcs-modal>
<dcs-modal #openentitymodal class="openentitymodal" mode="4" lvl="5" Caption="Select Entity">
    <div>
        <dcs-aggrid #menugridentity [lvl]="3" (onRowDbClicked)="clickeddoouble($event)" [filter]="true"
            [columns]="gridmenus" [type]="1" [data]="entitylist" [hideSerial]="false" [isExport]="true"
            [pagination]="true" [columnPanel]="true">
        </dcs-aggrid>
    </div>
</dcs-modal>
<dcs-modal *ngIf="showsubsidiaryCampus == 'true'" #opensubsidarymodal class="opensubsidarymodal" mode="4"
    Caption="Select Campus">
    <div>
        <dcs-aggrid #menugridsubentity [lvl]="3" (onRowDbClicked)="clickeddooublesubsidary($event)" [filter]="true"
            [columns]="gridmenussubsdary" [type]="1" [data]="subsidiaryEntitiesList" [hideSerial]="false"
            [isExport]="true" [pagination]="true" [columnPanel]="true">
        </dcs-aggrid>
    </div>
</dcs-modal>

<form [formGroup]="myForm" novalidate>
    <dcs-modal #passwordmodal [mode]="4" [hideSave]="true" [hideprint]="true" [hideClose]="true" [hideSaveClose]="true"
        lvl="4" class="birthday-modal" Caption="Change Password">

        <div class="changepassword mt-2">
            <div class="row m-0">
                <div class="col-lg-12 mb-2">
                    <div class="btnwitheye">
                        <dcs-textbox [ReportDropdown]="true" [Type]="oldpass ? 'text' : 'password'"
                            caption="Old Password*" formControlName="oldpass" Type="password" placeholder=true>
                        </dcs-textbox>
                        <i class="eyenew-icon" [ngClass]="oldpass ? 'fa fa-eye eye-btn' : 'fa fa-eye-slash eye-btn'"
                            id="togglePassword" (click)="OldtextPassword()"></i>
                    </div>
                </div>
                <div class="col-lg-12 mb-2">
                    <div class="btnwitheye">
                        <dcs-textbox [ReportDropdown]="true" formControlName="newpass"
                            [Type]="newpass ? 'text' : 'password'" caption="New Password*" placeholder=true>
                        </dcs-textbox>
                        <i class="eyenew-icon" [ngClass]="newpass ? 'fa fa-eye eye-btn' : 'fa fa-eye-slash eye-btn'"
                            id="togglePassword" (click)="NewtextPassword()"></i>
                    </div>
                </div>
                <div class="col-lg-12 mb-2">
                    <div class="btnwitheye">
                        <dcs-textbox [ReportDropdown]="true" [Type]="confirmpass ? 'text' : 'password'"
                            formControlName="confirmpass" Type="password" caption="Confirm Password*" placeholder=true>
                        </dcs-textbox>
                        <i class="eyenew-icon" [ngClass]="confirmpass ? 'fa fa-eye eye-btn' : 'fa fa-eye-slash eye-btn'"
                            id="togglePassword" (click)="ConfirmtextPassword()"></i>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="sig-inbtn">
                        <button type="submit" class="btn btn-block btn-lg btn-info" (click)="confirmChange()">
                            <span>Confirm</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>

    </dcs-modal>
</form>

<style>
    .eyenew-icon {
        background-color: transparent;
        border: none;
        padding: 0px 0.45rem !important;
        color: var(--edpmain);
        z-index: 11;
        display: flex !important;
        align-items: center;
        position: absolute;
        top: 5px;
        right: 35px;
    }

    .sig-inbtn {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .sig-inbtn button {
        width: 130px;
        height: 40px;
        padding: 0;
        margin: 0 auto;
        border-radius: 25px;
        background:  var(--edpmain) ;
        border: none;
        font-size: 14px;
        font-weight: 600;
        box-shadow: 3px 3px 4px #00000075;
    }
</style>