import { ImageService } from '../../../../shared/services/image.service';
import { LovService } from '../../../../shared/services/lov.service';
import { Component, ViewChild, Input, ElementRef } from "@angular/core";
import { SMAttachmentService } from "./attachment.service";
import { DocumenttypeConfigs, SMAttachmentConfigs } from "./attachment.config";
import { DcsModalComponent } from '../../../../@core/components/dcs-modal/dcs-modal.component';
import { Document_Type, LovConfigs } from '../../../../@constant/config';
import { GenEnum } from '../../../../@constant/general.enum';
import { DataTransferObject } from '../../../../@core/dto/DataTransferObject';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { NavComponent } from 'src/app/@core/base/nav/Nav.Component';
import { NavModalComponent } from 'src/app/@core/base/nav/NavModal.Component';

@Component({
  selector: "attachment",
  templateUrl: "attachment.component.html",
  styleUrls: ["./attachment.css"],
})
export class AttachmentComponent extends NavModalComponent<any> {
  // @ViewChild("imgControl") imageControl: ImageComponent |any;
  @ViewChild("imageModal") public imageModal: DcsModalComponent | any;
  @ViewChild("file") fileUpload: ElementRef | any;

  clientid: number = 0;
  entityid: number = 0;
  imageSource: string = "";
  showImageModal: boolean = false;
  imgData: String = "";
  url: any;
  uploadpath: string = "";

  

  myFiles: string[] = [];
  sMsg: any = "";

  showProcess: boolean = false;
  public pieChartData: number[] = [];
  public pieChartLabels: string[] = ["Storage Limit", "Storage Usage"];
  public pieChartType: string = "pie";
  public piecolors: any[] = [
    {
      backgroundColor: ["#00a65a", "#e2303a"],
    },
  ];
  private chartOption: any = {
    scaleShowValues: true,
    responsive: true,
    legend: {
      position: "left",
      labels: {
        fontSize: 12,
        boxWidth: 12,
      },
      hover: { enabled: false },
      tooltips: { enabled: false },
    },
    plugins: {
      datalabels: {
        align: "center",
        anchor: "center",
        color: "#fff",
        display: function (context: any) {
          return context.dataset.data[context.dataIndex] > 10;
        },
        font: {
          weight: "bold",
          size: 9,
        },
        formatter: function (value: any) {
          return Math.round(value) + "%";
        },
        scales: {
          xAxes: [{ stacked: true, ticks: { mirror: true } }],
          yAxes: [
            {
              stacked: true,
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },
    },
  };

  options = {
    min: 0,
    title: "",
  };
  max = 15;
  value = 0;

  constructor(
    private _Smattachment: SMAttachmentService,
    private _lovService: LovService,
    private imageService: ImageService,
    private httpService: HttpClient
  ) {
    super(_Smattachment);
    this.lookupConfigs.lister = SMAttachmentConfigs.lister;
    this.lookupConfigs.LovConfig = Document_Type.lister;
    this.lookupConfigs.DocumentType = DocumenttypeConfigs.lister;
    this.imageSource =
      this.localStorage.get("OnlyUrl") + GenEnum.Image.imageUrl;
    // this.showToolbar(false);
  }

  async ngOnInit() {
    
    await this.initilizeForm();
    await this.getdocsType();
    await this.getDriveInfo();
    this.LookupData.file = undefined;
  }
  async modalInitialize() {
    await this.getdocsType();
    this.iscustomSaving = true;
    this.myForm.controls["doctypeid"].setValue(this.SharedData.doctypeid);
    this.myForm.controls["refdocid"].setValue(this.SharedData.refdocid);

    this.clientid = Number(this.localStorage.get("clientid"));
    this.entityid = Number(this.localStorage.get("selectedEntity"));
    // this._Smattachment.addDefaultCondition("clientid=@0 && entityid=@1", [this.clientid, this.entityid]);

    if (
      this.fileUpload &&
      this.fileUpload.nativeElement &&
      this.fileUpload.nativeElement.value
    )
      this.fileUpload.nativeElement.value = "";

  }

  async getDriveInfo() {
    
    const result = await this._Smattachment.getdriveInfo();
    if (result && result.Data && result.Data.storageQuota) {
      this.max = Number(this.LookupData.limit);
      this.LookupData.limit =
        result.Data.storageQuota.limit / 1024 / 1024 / 1024;

      this.LookupData.usage = Number(Math.round(result.Data.storageQuota.usage / 1024 / 1024 / 1024));

      this.LookupData.usagemb = Number(Math.round(this.LookupData.usage * 1024));

      this.LookupData.usageInDrive =
        result.Data.storageQuota.usageInDrive / 1024 / 1024 / 1024;

      this.LookupData.usageInDriveTrash =
        result.Data.storageQuota.usageInDriveTrash / 1024 / 1024 / 1024;


      this.value = Number(this.LookupData.usage);

      this.LookupData.bargb = Number(Math.round((this.LookupData.usage) / 15 * 100));
      this.LookupData.barmb = Number(Math.round((this.LookupData.usagemb) / 15360 * 100));

      this.LookupData.freemb = Number(Math.round(15360 - this.LookupData.usagemb));

      this.LookupData.freegb = Number(Math.round(15 - this.LookupData.usage));

      // this.pieChartData = [
      //  Number(Math.round(this.LookupData.limit).toFixed(2)),
      //  Number(Math.round(this.LookupData.usage).toFixed(2)),
      //  Number(Math.round(this.LookupData.usagemb).toFixed(2)),
      //  Number(Math.round(this.LookupData.freemb).toFixed(2)),
      //  Number(Math.round(this.LookupData.freegb).toFixed(2)),
      //  Number(Math.round(this.LookupData.bargb).toFixed(2)),
      //  Number(Math.round(this.LookupData.barmb).toFixed(2)),

      // ];

    }
  }



  async initilizeForm() {
    this.title = "Attachment";
    this.myForm = this.fb.group({
      attchmentno: ["", []],
      lovreftypeid: ["", []],
      docname: ["", []],
      browsepath: ["", []],
      entityid: ["", []],
      externallink: ["", []],
      doctypeid: ["", []],
      refdocid: ["", []],
      imageid: ["", []],
    });
  }

  async before() {
    this.myForm.controls["entityid"].setValue(this.entityid);
    this.myForm.controls["doctypeid"].setValue(this.LookupData.doctypeid);
    this.myForm.controls["refdocid"].setValue(this.LookupData.refdocid);
  }

  async getdocsType() {
    const result = await this._lovService.getdocumentsType();
    if (result.IsSuccess) {
      this.LookupData.DocumentTypes = result.Data;
    }
  }

  async afterClear() {
    this.myFiles = [];
    this.LookupData.file = undefined;
    this.myForm.controls["doctypeid"].setValue(this.SharedData.doctypeid);
    this.myForm.controls["refdocid"].setValue(this.SharedData.refdocid);
    this.clientid = Number(this.localStorage.get("clientid"));
    this.entityid = Number(this.localStorage.get("selectedEntity"));

    if (
      this.fileUpload &&
      this.fileUpload.nativeElement &&
      this.fileUpload.nativeElement.value
    )
      this.fileUpload.nativeElement.value = "";
  }

  async afterDisplay() {
    this.getExtension();
  }
  // file: File | any;
  getFileDetails(e: any) {
    
    this.myFiles = [];
    this.LookupData.file = e.target.files[0]
    var _size = e.target.files[0].size;

    var fSExt = new Array('Bytes', 'KB', 'MB', 'GB'),i=0;
    while(_size>900){_size/=1024;i++;}

    if(i == 0){
      this.LookupData.sizes  = _size;
    }
    else if(i == 1){
      this.LookupData.sizes  = (_size/=1024).toFixed(2);
    }
    else if(i == 2){
      this.LookupData.sizes = (_size).toFixed(2);
    }
    // else{

    // }
    // var exactSize = (Math.round(_size*100)/100)+' '+fSExt[i];
    // alert(exactSize);

    for (var i = 0; i < e.target.files.length; i++) {
      
      this.myFiles.push(e.target.files[i]);
    }
  }

  async uploadFiles(): Promise<boolean> {
    if (
      this.myFiles.length > 0 ||
      this.myForm.controls["lovreftypeid"].value ===
      GenEnum.Document_Types.External_Link
    ) {
      var frmData: any = new FormData();

      if (!this.myForm.controls["docname"].value) {
        this.showErrors("Document Name cannot be empty");

        return false;
      }
      if (!this.myForm.controls["lovreftypeid"].value) {
        this.showErrors("Document Type cannot be empty");

        return false;
      }
      var fileToUpload: any;
      if (this.myFiles.length > 0) {
        fileToUpload = this.myFiles[0];
        var formData: any = new FormData();
        formData.append("file", fileToUpload, fileToUpload.name);

        for (var i = 0; i < this.myFiles.length; i++) {
          frmData.append("fileUpload", this.myFiles[i]);
        }
      }
      let jsData = JSON.stringify({
        Id: this.model ? this.model.PrimaryKeyValue : undefined,
        docname: this.myForm.controls["docname"].value,
        lovreftypeid: this.myForm.controls["lovreftypeid"].value,
        doctypeid: this.myForm.controls["doctypeid"].value,
        refdocid: this.myForm.controls["refdocid"].value,
        externallink: this.myForm.controls["externallink"].value,
        utdoctype: fileToUpload ? fileToUpload.size : null,
        entityid: Number(this.localStorage.get("selectedEntity")),
      });
      frmData.append(
        "dto",
        jsData
      );


      let dto: DataTransferObject<any> = <DataTransferObject<any>>{};
      dto.Data = frmData;

      let headers = new HttpHeaders();
      headers.append("Authorization", this.localStorage.get("token"));
      headers.append("Content-Type", "multipart/form-data");
      headers.append("Access-Control-Allow-Origin", "*")
  
      const obj = await this.service.uploadFile("UploadAttachment", headers, frmData);
      this.showProcess = true;

      if (obj.IsSuccess) {
        this.setDto(obj);
        this.showProcess = false;
        this.showSuccess("Attachment Successfully Uploaded");
      } else {
        this.showErrors(obj.Errors);
        this.showProcess = false;
        return false;
      }
    } else {
      this.showErrors("please select file");
      return false;
    }
    return true;
  }

  getExtension() {
    if (
      this.LookupData &&
      this.LookupData.DocumentTypes &&
      this.fileUpload &&
      this.fileUpload.nativeElement
    ) {
      this.fileUpload.nativeElement.accept = this.LookupData.DocumentTypes.filter(
        (o: any) => o.Id === this.myForm.controls["lovreftypeid"].value
      )[0].ltxt;
    }
  }

  async customSaving() {
    let rtnObj = await this.uploadFiles();
    this.allowSaving = rtnObj;
    if (this.allowSaving) {
      if (
        this.fileUpload &&
        this.fileUpload.nativeElement &&
        this.fileUpload.nativeElement.value
      )
        this.fileUpload.nativeElement.value = "";
    }
  }
}

