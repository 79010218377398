import { customButtonList, Enum } from "./../../../@constant/config";
import { ModalComponent, ModalManager } from "ngb-modal";
import {
  Component,
  ViewChild,
  ContentChild,
  Output,
  EventEmitter,
  AfterViewInit,
  Input,
  OnInit,
  HostListener,
  ElementRef,
} from "@angular/core";
import { List } from "linqts";
import { NavModalComponent } from "../../base/nav/NavModal.Component";
import { Subscription } from "rxjs";
import { DcsDocfieldModalComponent } from '../dcs-docfield-modal/dcs-docfield-modal.component';
import { status } from '../../../@constant/config';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { DcsModalComponent } from '../dcs-modal/dcs-modal.component';
import { filter } from 'rxjs/operators';

declare var $: any;

@Component({
  moduleId: module.id,
  selector: 'dcs-validator-modal',
  templateUrl: 'dcs-validator-modal.component.html',
  styleUrls: ['dcs-validator-modal.component.scss']
})
export class DcsValidatorModalComponent implements AfterViewInit, OnInit {
  Size: any;
  ModalSize: any;
  ngAfterViewInit(): void {

    this.loadData();
  }
  private lookupSubscription?: Subscription;

  async ngOnInit() {

    // var userInfo: any = userInfo = JSON.parse(localStorage.getItem("utuser") || '{}');
    // if (userInfo && userInfo.usertypeid && userInfo.usertypeid == GenEnum.UserType.SuperAdmin) {
    //   this.showConfigButton = true;
    // }

  }

  async drag() {
    $(".modal-dialog").draggable({
      handle: ".modal-header",
    });
    $("modal-dialog").css({ top: 0, left: 0, right: 0, bottom: 0 });
  }

  @ContentChild("ngContent") screen?: NavModalComponent<any>;
  // @ViewChild("FieldConfigModal") FieldConfigModal?: DcsDocfieldModalComponent;

  // showConfigButton: boolean = false;
  @Input()
  showList: boolean = false;
  @Input()
  backdrop: boolean = true;

  @Input()
  closeOnOutsideClick: boolean = false;

  @Output()
  toolbarClickList: EventEmitter<customButtonList> = new EventEmitter<customButtonList>();

  groups: any = [];
  FormData: any;
  FieldConfig: boolean = false;
  HaveField: boolean = false;

  data: customButtonList[] = [];

  @Input()
  set customButtonList(value: any) {
    this.data = value;
    this.loadData();
  }
  get customButtonList() {
    return this.data;
  }


  // openDocfieldModal() {
  //   this.FieldConfig = true;
  //   this.FieldConfigModal?.open();
  // }
  // closeDocfieldModal() {
  //   this.FieldConfigModal?.closeModal();
  // }
  customButtonListClick(item: customButtonList) {
    this.toolbarClickList.emit(item);
  }

  @Input()
  public Caption: String = "";

  @Input()
  screenCode: string = "";

  width: string = Enum.modalSize.Large;
  height: string = "auto";

  @Input()
  modaltype: number = Enum.modalType.Normal;

  @Input()
  displayLastRecord: boolean = false;

  @Input()
  showClose: boolean = true;

  @Input()
  mode: number = Enum.Mode.Default;

  @Input()
  set lvl(val: number) {
    this.zindex += Number(val);
  }
  get lvl() {
    return this.zindex;
  }

  @Input()
  modalSize: string = Enum.modalSize.Large;

  @Input()
  customClose: boolean = false;

  zindex: number = 1049;

  @ViewChild("myModal") myModal?: ModalComponent;
  private modalRef: any;

  @Input()
  hideNew: boolean = false;

  @Input()
  hideSave: boolean = false;

  @Input()
  hideSaveClose: boolean = false;

  @Input()
  hideClose: boolean = false;
  @Input()
  hideModalToolbar: boolean = false;

  @Input()
  fields: any = [];

  fetchFields: any = []
  myForm = new FormGroup({});
  dateFormat: any;

  constructor(private modalService: ModalManager,
    public dcsModal: DcsModalComponent) { }
  getdata(fields: any) {

    this.dateFormat = localStorage.getItem("defaultDateFormat");


    var ContorlProperties = JSON.parse(localStorage.getItem('ControlProperties') || '{}');
    var FormContorlData = JSON.parse(localStorage.getItem('FormControlData') || '{}');
    if (fields) {
      var arr = [];
      this.fetchFields = [];
      Object.keys(fields).map(function (key: any) {
        if (fields[key].status == "INVALID" && (fields[key].value == "" || fields[key].value == null)) {
          arr.push({
            field: key,
            value: fields[key].value
          })
        }
      });
    }
    var Gnfields: any = {};
    this.fetchFields = arr;

    if (this.fetchFields && this.fetchFields.length && this.fetchFields.length > 0) {
      this.fetchFields.forEach((par: any) => {
        var check = ContorlProperties.filter((child: any) => child.field == par.field);
        if (check && check.length && check.length != 0) {
          par.caption = check[0].caption.replace('*', '');
          par.type = check[0].type;
          par.controltype = check[0].controltype;
          if (check[0].config) {
            par.config = check[0].config;
            if (FormContorlData && FormContorlData.length && FormContorlData.length > 0) {
              var confdata = FormContorlData.filter((a: any) => a.field == check[0].field)[0];
              if (confdata && confdata.lookupData) {
                par.dataset = confdata.lookupData;
              }
            }
          }
          if (check && check[0].valueMember && check[0].displayMember) {
            par.valueMember = check[0].valueMember,
              par.displayMember = check[0].displayMember
          }
        }
      });
      
      for (let i = 0; i < this.fetchFields.length; i++) {
        var element: any = this.fetchFields[i];
        Gnfields[element.field] = new FormControl('');
      }
      this.myForm.controls = Gnfields;
    }
  }
  SaveFields() {

    this.fetchFields.forEach((ele: any) => {
      this.dcsModal?.screen?.myForm.controls[ele.field].setValue(this.myForm.controls[ele.field].value);
    });
    this.dcsModal.CLoseValidFieldModal();
  }
  async open(size: string = "lg", isCentered: boolean = false) {
    await this.drag();
    if (this.screen) {
      this.Caption =
        this.Caption && this.Caption === this.screen.title
          ? this.Caption
          : this.screen.title
            ? this.screen.title
            : "-";
      if (this.screen && this.screen.myForm && this.screen.myForm.controls) {
        var arr: any = [];
        Object.keys(this.screen.myForm.controls).map(function (key: any) {
          arr.push({ key })
        });
        if (arr && arr.length && arr.length > 0) {
          this.HaveField = true;
        }
        else {
          false;
        }
      }
    }
    else {
      this.HaveField = false;
    }

    if (!this.modalRef || !this.modalRef.isOpened) {
      this.modalRef = this.modalService.open(this.myModal, {
        size: size,
        modalClass: "mymodal",
        hideCloseButton: this.hideClose,
        centered: isCentered,
        // backdrop: this.backdrop,
        animation: true,
        keyboard: false,
        closeOnOutsideClick: this.closeOnOutsideClick,
        backdropClass: "modal-backdrop",
      });

      if (this.screen) {
        this.screen.LookupData = [];
        await this.screen.initialize();

        // if (this.screen.myForm) {
        //   this.FormData = this.screen.myForm.value;
        // }


      }

      this.triggeronOpen(this.screen);
      if (this.displayLastRecord && this.mode !== Enum.Mode.New) {
        await this.screen?.moveLast();
      }
    }
  }

  async customClosed() {
    this.myModal?.close();
  }

  async closeModal() {
    //or this.modalRef.close();
    // if (document.getElementsByClassName('show')) {
    //   var elements:any = document.getElementsByClassName('show');
    //   this.modalRef.backdropClass = undefined
    //   $(".modal-backdrop").remove();

    //   $(".fade").addClass("d-none");

    //   }
    await this.innerClose();

    this.modalService.close(this.modalRef);

    // let invalidEl = document.getElementsByClassName("ng-invalid");
  }


  // async openFieldConfig(){
  //   this.FieldConfigModal?.open();
  // }
  private async loadData() {
    if (this.data) {
      this.groups = new List<customButtonList>(this.data)
        .Select((o) =>
          o.grpname === "" || o.grpname === null || o.grpname === undefined
            ? "Options"
            : o.grpname
        )
        .Distinct()
        .ToArray();
    }
  }

  filterData(grp: any): Array<customButtonList> {
    if (grp === "Options") {
      grp = undefined;

      return this.data.filter((o) => o.grpname === grp);
    } else {
      return this.data.filter((o) => o.grpname === grp);
    }
  }

  disabledAction = false;
  async saveAndClose() {

    if (this.screen && !this.disabledAction) {
      this.disabledAction = true;
      const issuccess = await this.screen.save();
      this.disabledAction = false;
      if (issuccess) {
        await this.closeModal();
        // await this.innerClose();
      }
    }
  }

  // Modal side menu working

  isShown: boolean = false;

  dropdownToggle($event: { stopPropagation: () => void }) {
    $event.stopPropagation();
    this.isShown = !this.isShown;
  }
  @ViewChild("modalsidemenu") modalsidemenu?: ElementRef;
  @HostListener("document:click", ["$event"]) DocumentClick(event: Event) {
    if (
      this.modalsidemenu &&
      this.modalsidemenu.nativeElement &&
      this.modalsidemenu.nativeElement.contains(event.currentTarget)
    ) {
      this.isShown = true;
    } else {
      this.isShown = false;
    }
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.modalService.close(this.modalRef);
  }
  outsideClick() {
    this.isShown = false;
  }

  // End

  @Output()
  onOpen: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onClose: EventEmitter<any> = new EventEmitter<any>();

  public triggeronOpen(event: any) {
    this.onOpen.emit(event);
  }

  public triggeronClose(event: any) {
    this.onClose.emit(event);
  }

  innerClose(triggerclose: boolean = true) {
    if (this.lookupSubscription) {
      this.lookupSubscription.unsubscribe();
    }
    if (this.screen && this.screen.service) {
      this.screen.service.clearCondition();
    }
    if (this.screen) {
      this.screen.clear();
    }

    if (triggerclose) {
      this.triggeronClose(this);
    } else {
      if (this.customClose) {
        this.triggeronClose(this);
      }
    }
  }
}

