import { Injectable } from "@angular/core";
import { AppService } from "../../../@core/service/App.Service";

@Injectable({
  providedIn: "root",
})
export class EmployeeinfohdrService extends AppService<any> {
  constructor() {
    super("hdr_EREmployeeinfo");
  }

  async getEmployee(clientid: any, entityid: any, employeecategory: any) {
    const data = await this.get("Employeeinfohdr", [
      clientid,
      entityid,
      employeecategory,
    ]);
    return data;
  }
  async getEmployeeNew(clientid: any, entityid: any) {
    const data = await this.get("EmployeeinfohdrNew", [
      clientid,
      entityid,
    ]);
    return data;
  }

  async GetCategoryWiseEmployee(employeecategory: any) {
    let entityid = this.localStorage.get("selectedEntity");
    const data = await this.get("CategoryWiseEmployee", [
      entityid,
      employeecategory,
    ]);
    return data;
  }

  async GetEmployeeEntity(entityid: any) {
    const data = await this.get("EmployeeEntity", entityid);
    return data;
  }
  async GetEmpDesig(designationid:any) {
    let entityid: any = this.localStorage.get("selectedEntity");
    const data = await this.get("EmpDesig", [entityid,designationid]);
    return data;
  }

  async GetClientEmployees() {
    const data = await this.get("ClientEmployees");
    return data;
  }
  async Getuseremployees() {
    const data = await this.get("UserEmployee");
    return data;
  }
  async GetEntityEmployee() {
    let entityid: any = this.localStorage.get("selectedEntity");
    const data = await this.get("EmployeeEntity", [entityid]);
    return data;
  }
  async GetEntityEmployeeDesig(desigid:any) {
    let entityid: any ="null" //this.localStorage.get("selectedEntity");
    const data = await this.get("EmployeeEntityDesig", [entityid,desigid]);
    return data;
  }

  async GetEntityEmployeeV4() {
    let entityid = this.localStorage.get("selectedEntity");
    const data = await this.get("EntityEmployeeV4", [entityid]);
    return data;
  }

  async GetEmployeEntity(companyId: number = 0) {
    let entityid =
      companyId > 0 ? companyId : this.localStorage.get("selectedEntity");
    const data = await this.get("EmployeeEntity", [entityid]);
    return data;
  }

  async GetEmployeesListType(entityid: any, employeecategory: any) {
    const dto = await this.get("EmployeesListType", [
      entityid,
      employeecategory,
    ]);
    return dto;
  }

  async GetEmployeeListbycategory(entityid: any) {
    const dto = await this.get("EmployeeListbycategory", [entityid]);
    return dto;
  }
  async GetEmployeeTeachercategory(entityid: any) {
    const dto = await this.get("EmployeeListbyTeachercategory", [entityid]);
    return dto;
  }

  async getemployeeComponent(employeeid: any) {
    const data = await this.get("EmployeeComponenet", employeeid);
    return data;
  }

  async getEmployeeList(entityid: any, Id: any) {
    const result = await this.get("Employee_Detail", [entityid, Id]);
    return result;
  }

  async getLoginUserInfo(Id: any) {
    const result = await this.get("LoginTeacherInfo", [Id]);

    return result;
  }
  async getEmployeeData(employeecode: any) {
    let refentityid = this.localStorage.get("selectedEntity");
    const result = await this.get("EmployeeData", [
      refentityid,
      "null",
      employeecode,
    ]);
    return result;
  }

  async getEmployeeInfoList(
    entityid: any,
    employeeno: any,
    name: any,
    depid: any,
    desigid: any,
    employeetypeid: any,
    genderid: any
  ) {
    let refentityid =
      entityid == undefined || entityid == 0 || entityid == ""
        ? this.localStorage.get("selectedEntity")
        : entityid;
    let refemployeeno =
      employeeno == "" || employeeno == undefined ? "null" : employeeno;
    let refname = name == undefined || name == 0 || name == "" ? "null" : name;
    let refdepid =
      depid == undefined || depid == 0 || depid == "" ? "null" : depid;
    let refdesigid =
      desigid == undefined || desigid == 0 || desigid == "" ? "null" : desigid;
    let refemployeetypeid =
      employeetypeid == undefined || employeetypeid == 0 || employeetypeid == ""
        ? "null"
        : employeetypeid;
    let refgenderid =
      genderid == undefined || genderid == 0 || genderid == ""
        ? "null"
        : genderid;

    const result = await this.get("EmployeeList", [
      refentityid,
      refemployeeno,
      refname,
      refdepid,
      refdesigid,
      refemployeetypeid,
      refgenderid,
    ]);
    return result;
  }

  async getHeadOfficeEmployee() {
    const result = await this.get("HeadOfficeEmployee");
    return result;
  }

  async getEmployeeBirthdays() {
    let entityid = Number(this.localStorage.get("selectedEntity"));
    const result = await this.get("EmployeeBirthdays", [entityid]);
    return result;
  }

  async GetEntityagainstEmployees() {
    let entityid = this.localStorage.get("selectedEntity");
    const data = await this.get("EntityagainstEmployees", [entityid]);
    return data;
  }

  async getEntityagainstEmployees(entityid:any) {
    // let entityid = this.localStorage.get("selectedEntity");
    const data = await this.get("EntityagainstEmployees", [entityid]);
    return data;
  }
  async GetAdminEmployees() {
    let entityid = this.localStorage.get("selectedEntity");
    const data = await this.get("AdminEmployees", [entityid]);
    return data;
  }
  async getCourseagainstEmployee(courseid: any, sectionid: any) {
    let entityid = this.localStorage.get("selectedEntity");
    const data = await this.get("CourseAgainstTeacher", [
      entityid,
      courseid,
      sectionid,
    ]);
    return data;
  }

  async getTeacherSubjectPerformance(teacherid: any) {
    let entityid = this.localStorage.get("selectedEntity");
    const result = await this.get("TeacherSubjectPerformance", [
      entityid,
      teacherid,
    ]);
    return result;
  }

  async GetEmployeeSybjectRatingList(entityid: any) {
    const dto = await this.get("EmployeeSybjectRatingList", [entityid]);
    return dto;
  }

  async getTeacherSubjects(Id: any) {
    const result = await this.get("TeacherSubjects", [Id]);
    return result;
  }
  async getTeacherSubjectForPlan(Id: any) {
    const result = await this.get("TeacherSubjectForPlanNew", [Id]);
    return result;
  }

  async getEmployeeinfoAgainstCateory(category: any) {
    let entity = Number(this.localStorage.get("selectedEntity"));
    const result = await this.get("EmployeeinfoAgainstCateory", [entity, category]);
    return result;
  }


  async Getemployees(refid: any, senttoid: any){
    let entityid = this.localStorage.get('selectedEntity');
    const data = await this.get('Getemployees', [entityid,refid,senttoid]);
    return data;
}

  async GetUserImage(){
    const data = await this.get("GetUserImage")
    return data;
  }
  async GetForgetPassword(cnic: any, datebirth: any, numb: any) {
    const result = await this.get('EmpForgetPassword', [cnic, datebirth, numb]);
    return result;
}
async GetEmployeeId(empid: any) {
  const data = await this.get("EmployeeId", [empid]);
  return data;
}
async GetLoginUser() {
  const data = await this.get("LoginUser");
  return data;
}
async GetTeacherSubjectList() {
  let entityid = Number(this.localStorage.get("selectedEntity"));
  let loginedEmployee = JSON.parse(this.localStorage.get("LoginUserInfo"));
  const data = await this.get("TeacherSubjectList", [entityid,loginedEmployee.Id]);
  return data;
}
}
