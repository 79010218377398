import { Injectable } from "@angular/core";
import * as XLSX from "xlsx";
// import { GenEnum } from '../../@constant/general.enum';
import { saveAs } from 'file-saver';

@Injectable()
export class DownloadService {
  constructor() { }

  //   static toExportFileName(excelFileName: string): string {
  //     if (excelFileName != "") {
  //       return `${excelFileName}.xlsx`;
  //     }
  //     else {
  //       return `export_${new Date().getTime()}.xlsx`;
  //     }
  //   }

  // id is like which document.getElementById
  // FileName = friendly download filename
  // format = if its a table then format number should be 1  or  if its graph then it should be 2

  public async downloadData(id: any, FileName: string, format: number): Promise<void> {
    if (format == 1) {
      const table = document.getElementById(id);
      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      if (FileName != "") {
        XLSX.writeFile(wb, `${FileName}.xlsx`)
      }
      else {
        XLSX.writeFile(wb, `${new Date().getTime()}.xlsx`)
      }
    }
    else if (format == 2) {
      const imagelink = document.createElement('a')
      const canvas = document.getElementById(id) as any
      if (FileName != "") {
        imagelink.download = `${FileName}.png`
      }
      else {
        imagelink.download = `${new Date().getTime()}.png`
      }
      imagelink.href = canvas?.toDataURL('image/jpg', 1)
      imagelink.click()
    }
    else {
      return
    }
  }
  ////////////////////////////Latest Approach////////////////////////////////////////
}
