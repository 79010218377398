import { DcsTabSetComponent } from "./dcs-tab.component";
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [DcsTabSetComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  exports: [DcsTabSetComponent],
})
export class DcsTabSetModule { }