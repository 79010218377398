import { Observable } from "rxjs/Observable";
import { Injectable } from "@angular/core";
import { DataTransferObject } from "../../../../@core/dto/DataTransferObject";
import { AppService } from "../../../../@core/service/App.Service";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class SMAttachmentService extends AppService<any> {
  constructor(private _http: HttpClient) {
    super("mst_SMAttachment");
  }

  public async getdriveInfo() {
    const result = await this.get("DriveInfo", [
      Number(this.localStorage.get("selectedEntity")),
    ]);
    return result;
  }
  public async uploadImage(attachment: any, typeid: any, filename:any) {
    const dto: DataTransferObject<any> = <DataTransferObject<any>>{};
    dto.Data = {
      attachment: attachment,
      filetypeid: typeid,
      filename: filename,
    };

    const result = await this.post("attachment", dto);
    return result;
  }

  public async GetAttachments(referenceid:any, typeid:any) {
    const result = await this.get("Attachments", [referenceid, typeid]);
    return result;
  }
  
  async uploadAttachments(data:any) {
    const result = await this.post("UploadAttachment", data);
    return result;
  }

  public async GetImportTemplate(referenceid:any, typeid:any) {
    const result = await this.get("ImportTemplate", [referenceid, typeid]);
    return result;
  }

  public async GetAttachmentsAll( typeid:any) {
    const result = await this.get("AttachmentsAll", [typeid]);
    return result;
  }
}
