import { Client } from './../../modules/userManagement/client/client';
import { Injectable } from "@angular/core";
import { AppService } from "../../@core/service/App.Service";


@Injectable()
export class FIPostkeyaccountService extends AppService<any> {

    constructor() {
        super('mst_FIPostkeyaccount');
    }

    async PostkeyAccountsave(_data: any) {
        
        var result = await this.post("postkeyAccountsave", _data)
        return result;
    }
    async GetPostKeyAccounts(postgrpid:any,glacid:any) {
        let clientid = await this.localStorage.get('clientid');
        let entityid = await this.localStorage.get('selectedEntity');
        
        var result = await this.get("PostKeyAccounts", [clientid,entityid,postgrpid,glacid])
        return result;
    }
}
