import { GridDatePickerComponent } from './date.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';

@NgModule({
    imports: [FormsModule, CommonModule, ReactiveFormsModule,DatePickerModule],
    exports: [GridDatePickerComponent],
    declarations: [GridDatePickerComponent],
})
export class GridDatePickerModule { }
