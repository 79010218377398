<!--component html goes here -->
<!-- [ngClass]="position === 1 ? '' : _captionWidth" -->
<div *ngIf="!ReportDropdown">

    <label *ngIf="showCaption">{{caption}} </label>

    <span style="float: right;" *ngIf="(controlEl && controlEl.errors && controlEl.errors?.invalid && (controlEl.dirty || controlEl.touched) ) || this.touchedEL ||( controlEl && controlEl.errors && controlEl.errors.maxInvalid)">
        <span class="tooltipss" style="color: red; margin-left: 5px; font-size: 12px; font-weight: 500; " type="button">
            <i class="fa fa-info-circle" aria-hidden="true"></i>
            <!-- <object data="../../../../assets/ICONS/icon_error.svg" class="icon-error"> </object> -->
            <span class="tooltiptextss">
                <i class="fas fa-exclamation-triangle" style="color: red; font-size: 12px; padding-right: 4px;"></i>
                <strong>{{cleanCaption()}}</strong> {{errMsg}}
            </span>
    </span>
    </span>
    <!-- <span *ngIf ="controlEl && controlEl.errors && controlEl.errors?.max">HELLO </span> -->
    <!-- [ngClass]="position === 1 ? '' : _fieldWidth" -->
    <div>
        <!-- (keypress)="innerChange($event)" -->
        <input *ngIf="isFloat == false" id="fieldName" #numeric [disabled]="disabled" (keypress)="innerChange($event)" type="number" [ngClass]=" (controlEl && controlEl.errors && controlEl.errors?.invalid && (controlEl.dirty || controlEl.touched) ) || this.touchedEL ||( controlEl && controlEl.errors && controlEl.errors.maxInvalid) ? 'form-control dcs-control border-invalid ' : 'form-control dcs-control border-valid '"
            (blur)="onBlur()" [placeholder]="placeholderValue()" required="required" [(ngModel)]="value" />

        <input *ngIf="isFloat == true" id="fieldName" #numeric [disabled]="disabled" type="number" [ngClass]=" (controlEl && controlEl.errors && controlEl.errors?.invalid && (controlEl.dirty || controlEl.touched) ) || this.touchedEL ||( controlEl && controlEl.errors && controlEl.errors.maxInvalid) ? 'form-control dcs-control border-invalid ' : 'form-control dcs-control border-valid '"
            (blur)="onBlur()" [placeholder]="placeholderValue()" required="required" [(ngModel)]="value" />
    </div>
</div>

<div *ngIf="ReportDropdown">
    <div class="report-control">
        <label class="report-label">{{ caption }}</label>
        <input id="fieldName" #numeric [disabled]="disabled" (keypress)="innerChange($event)" type="number" [ngClass]=" (controlEl && controlEl.errors && controlEl.errors?.invalid && (controlEl.dirty || controlEl.touched) ) || this.touchedEL ||( controlEl && controlEl.errors && controlEl.errors.maxInvalid) ? 'form-control dcs-control border-invalid ' : 'form-control dcs-control border-valid '"
            (blur)="onBlur()" [placeholder]="placeholderValue()" required="required" [(ngModel)]="value" />
        <!-- START VALIDATION SPAN -->
        <span style="float: right;" *ngIf="(controlEl && controlEl.errors && controlEl.errors?.invalid && (controlEl.dirty || controlEl.touched) ) || this.touchedEL ||( controlEl && controlEl.errors && controlEl.errors.maxInvalid)">
            <span class="tooltipss" style="color: red; margin-left: 5px; font-size: 12px; font-weight: 500; "
                type="button">
                <i class="fa fa-info-circle" aria-hidden="true"></i>
                <span class="tooltiptextss">
                    <i class="fas fa-exclamation-triangle" style="color: red; font-size: 12px; padding-right: 4px;"></i>
                    <strong>{{cleanCaption()}}</strong> {{errMsg}}
                </span>
        </span>
        </span>
    </div>
</div>


<style>
    .border-invalid {
        border-color: #f1a0ab !important;
    }
</style>