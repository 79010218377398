import { FIPostkeyaccountService } from './../../../shared/services/FIPostkeyaccount.Service';
import { FICOAService } from 'src/app/modules/General-Ledger/Chart of Account/FICOA/FICOA.service';
import { FIPostinggrpService } from './../../../modules/General-Ledger/Setup/postinggrp/postinggrp.service';
import { utdocsubtypeassignService } from './../../../shared/services/utdocsubtypeassignService';
import { DCSAggridComponent } from "./dcs-aggrid.component";
import { CommonModule, DecimalPipe } from '@angular/common';
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AgGridModule } from "ag-grid-angular";
import { ExcelService } from "../../service/excel.service";
import { DcsModalModule } from "../dcs-modal/dcs-modal.module";
import { DcsCheckboxModule } from "../dcs-checkbox/dcs-checkbox.module";
import { DcsNumericModule } from "../dcs-numeric/dcs-numeric.module";
import { DcsTextboxModule } from "../dcs-textbox/dcs-textbox.module";
import { DcsDropdownModule } from "../dcs-dropdown/dcs-dropdown.module";
import { DcsDimentionModule } from "../dcs-dimention/dcs-dimention.module";
import { CellButtonsComponent } from "./controls/cell-buttons/cell-buttons.component";
import { SMAcadpolicyService } from "../../../modules/Administration/setting/systempolicy/smacadpolicy/smacadpolicy.service";
import { DCSErrorModule } from '../error/error.module';
@NgModule({
  imports: [
    AgGridModule.withComponents(null),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DcsModalModule,
    DcsCheckboxModule,
    DcsNumericModule,
    DcsTextboxModule,
    DcsDropdownModule,
    DcsDimentionModule,
    DCSErrorModule,
  ],
  exports: [DCSAggridComponent, CellButtonsComponent],
  declarations: [DCSAggridComponent, CellButtonsComponent],
  providers: [ExcelService, SMAcadpolicyService,DecimalPipe,utdocsubtypeassignService,FIPostinggrpService,FIPostinggrpService,FICOAService,FIPostkeyaccountService]
})
export class DCSAggridModule {}
