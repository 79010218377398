import { Injectable } from "@angular/core";
import { AppService } from "../../../service/App.Service";

@Injectable({ providedIn: "root" })
export class DCSDimensionbaseService extends AppService<any> {
  constructor() {
    super("mst_FIDimension");
  }

  public selecteddata :any; 
  async GetCoaDimensions(coaid: any) {
    const rslt = await this.get("CoaDimensions", coaid);
    return rslt;
  }
}
