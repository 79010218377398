<!--component html goes here -->
<!-- [ngStyle]="position === 1 ? {} : { 'margin-left': '15px' }" -->
<!-- <input [id]="fieldName" [disabled]="disabled" class="inp-cbx" type="checkbox" (blur)="onBlur()" required="required"
  [(ngModel)]="value" style="display: none" /> -->

<!-- <label [for]="fieldName">{{ caption }}</label> -->
<!-- <input class="inp-cbx" id="cbx" type="checkbox" style="display: none" />
<label class="cbx" for="cbx"><span>
    <svg width="12px" height="10px" viewbox="0 0 12 10">
      <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
    </svg></span><span>CodePenChallenge</span></label> -->

<div class="grid-design">

    <label class="checkbox path">
        <input type="checkbox" [id]="fieldName" [disabled]="disabled" (blur)="onBlur()" (change)="onChange($event)"
            required="required" [(ngModel)]="value">
        <svg viewBox="0 0 31 30">
            <path
                d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186">
            </path>
        </svg>
    </label>
    <span style="padding-left: 5px; font-size: 11px;">{{ caption }}</span>

</div>

<style>
    .grid-design {
        display: grid;
        grid-template-columns: 1fr 26fr;
        padding-top: 5px;
    }
    
    .checkbox {
        --background: #fff;
        --border: #D1D6EE;
        --border-hover: #BBC1E1;
        --border-active: #228ab1;
        --tick: #fff;
        position: relative;
    }
    
    .checkbox input,
    .checkbox svg {
        width: 14px;
        height: 14px;
        display: block;
    }
    
    .checkbox input {
        -webkit-appearance: none;
        -moz-appearance: none;
        position: relative;
        outline: none;
        background: var(--background);
        border: none;
        margin: 0;
        padding: 0;
        cursor: pointer;
        border-radius: 4px;
        transition: box-shadow 0.1s;
        box-shadow: inset 0 0 0 var(--s, 1px) var(--b, var(--border));
    }
    
    .checkbox input:hover {
        --s: 2px;
        --b: var(--border-hover);
    }
    
    .checkbox input:checked {
        --b: var(--border-active);
    }
    
    .checkbox svg {
        pointer-events: none;
        fill: none;
        stroke-width: 2px;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke: var(--stroke, var(--border-active));
        position: absolute;
        top: 0;
        left: 0;
        width: 21px;
        height: 21px;
        transform: scale(var(--scale, 1)) translateZ(0);
    }
    
    .checkbox.path input:checked {
        --s: 2px;
        transition-delay: 0.2s;
    }
    
    .checkbox.path input:checked+svg {
        --a: 16.1 86.12;
        --o: 102.22;
    }
    
    .checkbox.path svg {
        stroke-dasharray: var(--a, 86.12);
        stroke-dashoffset: var(--o, 86.12);
        transition: stroke-dasharray 0.4s, stroke-dashoffset 0.4s;
    }
    
    .checkbox.bounce {
        --stroke: var(--tick);
    }
    
    .checkbox.bounce input:checked {
        --s: 11px;
    }
    
    .checkbox.bounce input:checked+svg {
        -webkit-animation: bounce 0.4s linear forwards 0.2s;
        animation: bounce 0.4s linear forwards 0.2s;
    }
    
    .checkbox.bounce svg {
        --scale: 0;
    }
    
    @-webkit-keyframes bounce {
        50% {
            transform: scale(1.2);
        }
        75% {
            transform: scale(0.9);
        }
        100% {
            transform: scale(1);
        }
    }
    
    @keyframes bounce {
        50% {
            transform: scale(1.2);
        }
        75% {
            transform: scale(0.9);
        }
        100% {
            transform: scale(1);
        }
    }
    
    html {
        box-sizing: border-box;
        -webkit-font-smoothing: antialiased;
    }
    
    * {
        box-sizing: inherit;
    }
    
    *:before,
    *:after {
        box-sizing: inherit;
    }
    
    body {
        min-height: 100vh;
        display: flex;
        font-family: "Roboto", Arial;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background: #F6F8FF;
    }
    
    body .grid {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-gap: 24px;
    }
    
    body .dribbble {
        position: fixed;
        display: block;
        right: 20px;
        bottom: 20px;
    }
    
    body .dribbble img {
        display: block;
        height: 28px;
    }
    
    body .twitter {
        position: fixed;
        display: block;
        right: 64px;
        bottom: 14px;
    }
    
    body .twitter svg {
        width: 32px;
        height: 32px;
        fill: #1da1f2;
    }
</style>