import { ExpenseService } from './../../../../modules/Cash-Bank/Cash/Transaction/expense/expense.service';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { DCSCustomService } from "./../../dcs-report/dcs-custom.service";
import { DcsDropdownComponent } from "src/app/@core/components/dcs-dropdown/dcs-dropdown.component";
import {
  Component,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  ViewChildren,
  forwardRef,
  QueryList,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { List } from "linqts";
import * as moment from "moment";
import { NavModalComponent } from "src/app/@core/base/nav/NavModal.Component";
import { OrgDepartmentConfigs } from "src/app/modules/Orgnization/setup/orgdepartment/orgdepartment.config";
import { employeeConfigs } from "src/app/modules/Library/General/Transaction/Issue/issue.Config";
import { DCSDimensionbaseService } from "./dcs-dimentionbase.service";
import { ModalManager } from "ngb-modal";

@Component({
  selector: "fidimensionbase",
  templateUrl: "dcs-dimentionbase.component.html",
})
export class FiDimensionbaseComponent extends NavModalComponent<any> {
  protected fb: FormBuilder = new FormBuilder();
  public myForm!: FormGroup;
  data: any = {};
  config: Dimensions[] = [];
  @Output()
  afterDimenstionSelected: EventEmitter<Array<DimensionValue>> =
    new EventEmitter<Array<DimensionValue>>();

  @ViewChildren(forwardRef(() => DcsDropdownComponent))
  private dropDowns!: QueryList<DcsDropdownComponent>;

  depandentOn: any = [];
  collapsed: boolean = false;
  public Values: Array<DimensionValue> = Array<DimensionValue>();
  private listerConfigs: Array<listerConfig> = [
    {
      key: "dep",
      value: OrgDepartmentConfigs.lister,
    },
    {
      key: "empid",
      value: employeeConfigs.lister,
    },
  ];

  constructor(
    private dimensionService: DCSDimensionbaseService,
    private customService: DCSCustomService,
    private modalService: ModalManager,
    public activeModal: NgbActiveModal,
    private expenseService: ExpenseService,
  ) {
    super(dimensionService);
    this.Modal = true;
  }

  async ngOnInit() {
    
    this.myForm = this.fb.group({});
    this.showToolbar(false);
    this.Values
  }

  public async getDimension(coaid: any) {
    
    var newvalue:any = []
    if(this.dimensionService.selecteddata != undefined){
      this.dimensionService.selecteddata.forEach((element:any) => {
        var a = 0;
          Object.keys(element).forEach((o) => {
            newvalue.push({
              key: o,
              value: element[o],
            });
          });
      });
    }
    
    if(newvalue != undefined && newvalue != null && newvalue.length > 0){
      this.getReport(coaid).then(() => {
        
        this.generateData().then(() => {
          
          this.setDefaultValues().then(async () => {
            
            if (newvalue && newvalue.length > 0) {
              for (let index = 0; index < newvalue.length; index++) {
                const element = newvalue[index];
  
                if (this.myForm.controls[element.key]) {
                  this.myForm.controls[element.key].setValue(element.value);
                  await this.valueChange(element.key);
                }
              }
            }
          });
        });
      });
    }
    else{
      this.getReport(coaid).then(() => {
        
        this.generateData().then(() => {
          
          this.setDefaultValues().then(async () => {
            
            if (this.Values && this.Values.length > 0) {
              for (let index = 0; index < this.Values.length; index++) {
                const element = this.Values[index];
  
                if (this.myForm.controls[element.key]) {
                  this.myForm.controls[element.key].setValue(element.value);
                  await this.valueChange(element.key);
                }
              }
            }
          });
        });
      });
    }
  }

  async getReport(coaid: any) {
    
    const result = await this.dimensionService.GetCoaDimensions(coaid);

    if (result.IsSuccess) {
      this.data = result.Data;
    } else {
      this.showErrors(result.Errors);
    }
  }

  async getData(
    fieldname: string,
    controller: string,
    route: string,
    params: Array<any>
  ): Promise<Array<any>> {
    let returnData: any = [];
    this.customService.controller = controller;
    
    const result = await this.customService.get(route, params);
    ;
    if (result && result.IsSuccess) {
      ;
      returnData = result.Data;
      this.LookupData[fieldname] = result.Data;
    } else {
      // this.showErrors("There Is an Error...!");
    }
    ;
    return returnData;
  }

  async generateData() {
    
    // utreportdtls
    this.collapsed = true;
    if (this.data && this.data) {
      var dtl = new List<Dimensions>(this.data)
        .OrderBy((o: any) => o.lineindex)
        .ToArray();

      this.depandentOn = new List<Dimensions>(this.data)
        .Where((o: any) => o.dependenton !== "")
        .OrderBy((o: any) => o.lineindex)
        .ToArray();

      var fields: any = {};

      for (let i = 0; i < dtl.length; i++) {
        var element: any = dtl[i];
        if (element) {
          fields[element.code] = [];
        }
      }

      this.myForm = this.fb.group(fields);

      for (let index = 0; index < dtl.length; index++) {
        var element: any = dtl[index];

        var field: Dimensions = {
          Id: element.Id,
          stxt: element.stxt + (element.ismandatory ? "*" : ""),
          code: element.code,
          ismandatory: element.ismandatory,
          fieldtypeid: element.fieldtypeid,
          statusid: element.statusid,
          defaultvalue: element.defaultvalue,
          capwidth: "3,3,6,6", //element.capwidth, //,
          fieldwidth: "6,6,12,12",
          paramsSplit: element.parms.split(","),
        };

        //if (element.fieldtypeid === ReportingEnum.Lov.FieldType.Combo) {
        var params: any = [];
        if (!element.dependenton) {
          var indexOf = element.parms ? element.parms.indexOf(",") : -1;

          var paramsSplit: string[] = [];
          var subentityparams: any;
          if (indexOf !== -1) {
            paramsSplit = element?.parms.split(",");
            subentityparams = element?.code == "subentityid" ? element?.code : "";
          }

          for (let i = 0; i < paramsSplit.length; i++) {
            if (paramsSplit[i] && paramsSplit[i] !== ",")
              if (this.myForm.controls[paramsSplit[i]]) {
                params.push(this.myForm.controls[paramsSplit[i]].value);
              } else if (paramsSplit[i] && paramsSplit[i] === "{userentity}") {
                if(subentityparams == "subentityid"){
                  params.push(this.localStorage.get("parentselectedEntity"));
                }
                else{
                  params.push(this.localStorage.get("selectedEntity"));
                }
              } else {
              }
          }

          var dropdownData = await this.getData(
            element.code,
            element.controller,
            element.routemethod,
            params
          );
          if (dropdownData) {
            field.data = dropdownData;
          }
        }

        field.valuemember = element.valuemember;
        field.displaymember = element.displaymember;
        field.params = element.parms;
        field.controller = element.controller;
        field.routemethod = element.routemethod;
        let configData = this.listerConfigs.filter(
          (o) => o.key === element.code
        )[0];
        field.utlisterconfigdtls = configData ? configData.value : [];
        field.utlisterconfighdr = element.utlisterconfighdr;
        if (
          element.utlisterconfighdr &&
          element.utlisterconfighdr.utlisterconfigdtls
        ) {
          
          element.utlisterconfighdr.utlisterconfigdtls.map((obj: any) => {
            obj.name = obj.fieldname;
            (obj.width = obj.fieldwidth ? obj.fieldwidth + "px" : ""),
              (obj.type = obj.fieldtypeid
                ? obj.fieldtypeid === ReportingEnum.Lov.FieldType.Date
                  ? "date"
                  : obj.fieldtypeid === ReportingEnum.Lov.FieldType.Time
                  ? "time"
                  : ""
                : "");
            obj.format = obj.format ? obj.format : "";
          });

          field.utlisterconfigdtls = new List<listerDtl>(
            element.utlisterconfighdr.utlisterconfigdtls
          )
            .OrderBy((o) => o.lineindex)
            .ToArray();
        }
        this.config.push(field);
      }
    } else {
    }
  }

  async valueChange(fieldname: string) {
    if (this.depandentOn.length > 0) {
      var depandantsFields = this.depandentOn.filter(
        (e: Dimensions) => e.dependenton && e.dependenton.match(fieldname)
      );

      if (depandantsFields && depandantsFields.length > 0) {
        for (let index = 0; index < depandantsFields.length; index++) {
          const element: Dimensions = depandantsFields[index];

          var params = [];
          var indexOf = element.parms ? element.parms.indexOf(",") : -1;

          var paramsSplit: string[] = [];
          if (indexOf !== -1) {
            if (element && element.parms)
              paramsSplit = element ? element.parms.split(",") : [];
          }

          for (let i = 0; i < paramsSplit.length; i++) {
            if (paramsSplit[i] && paramsSplit[i] !== ",") {
              let data = this.myForm.controls[paramsSplit[i]] && this.myForm.controls[paramsSplit[i]].value ? this.myForm.controls[paramsSplit[i]].value : this.localStorage.get(paramsSplit[i]) ;
              params.push(data ? data : "NULL");
            }
          }
          
          let checkNULL = params.filter((o) => o === "NULL")[0];
          if (!checkNULL) {
            await this.getData(
              element.code ? element.code : "",
              element.controller ? element.controller : "",
              element.routemethod ? element.routemethod : "",
              params
            );
          } else {
            // this.LookupData[fieldname] = [];
          }
          if (this.myForm.controls[element.code ? element.code : ""])
            this.myForm.controls[element.code ? element.code : ""].setValue("");
        }
      }
    }
  }

  async setDefaultValues() {
    
    if (this.data && this.data) {
      var dtl = new List<Dimensions>(this.data)
        .OrderBy((o) => o.lineindex)
        .ToArray();

      for (let index = 0; index < dtl.length; index++) {
        const element: any = dtl[index];

        if (element.defaultvalue) {
          let defaultVal = element.defaultvalue;

          if (element.defaultvalue === "{userentity}") {
            defaultVal = this.localStorage.get("selectedEntity");
          }
          if (element.fieldtypeid === ReportingEnum.Lov.FieldType.Date) {
            element.defaultvalue = element.defaultvalue.replace("{", "");
            element.defaultvalue = element.defaultvalue.replace("}", "");
            const defaultValues =
              element.defaultvalue.indexOf(",") !== -1
                ? element.defaultvalue.split(",")
                : [];

            if (defaultValues && defaultValues.length === 3) {
              var date = new Date();
              let type = defaultValues[1];

              if (type === "D") {
                date.setDate(date.getDate() + Number(defaultValues[2]));
              } else if (type === "M") {
                date.setMonth(date.getMonth() + Number(defaultValues[2]));
              } else if (type === "Y") {
                date.setFullYear(date.getFullYear() + Number(defaultValues[2]));
              }

              defaultVal = moment(date).format("YYYY-MM-DD");
            }
          }

          if (this.myForm.controls[element.code]) {
            this.myForm.controls[element.code].setValue(defaultVal);

            await this.valueChange(element.code);
          }
        }
      }
    }
  }

  private validate(): boolean {
    let isDirty: boolean = false;
    let ctrl;
    let mandatoryFields: any = [];

    Object.keys(this.myForm.controls).map((controlName) => {
      ctrl = this.myForm.get(controlName);
      if (ctrl) {
        ctrl.markAsDirty({ onlySelf: true });
        ctrl.markAsTouched({ onlySelf: true });

        if (!isDirty) {
          let ctrlConfig = this.config.filter((o) => o.code === controlName)[0];
          isDirty = ctrl.errors !== null;

          if (
            (isDirty && !this.myForm.controls[controlName].value) ||
            (ctrlConfig.ismandatory &&
              this.myForm.controls[controlName].value === 0)
          ) {
            isDirty = true;
            mandatoryFields.push({
              Description:
                (ctrlConfig ? ctrlConfig.stxt.replace("*", "") : controlName) +
                " cannot be empty",
            });
          }
        }
      }
    });
    if (mandatoryFields.length > 0) {
      this.showErrors(mandatoryFields);
    }
    return isDirty;
  }

  private setReportValues() {
    Object.keys(this.myForm.value).forEach((name) => {
      this.myForm.controls[name].setValue(undefined);
    });
  }

  async close(event: any) {
    //temporary commit start
    //  $("#dimensionbaseviewer").modal("hide");
    //temporary commit end
    // this.showCriteria = false;
    // Object.keys(this.myForm.controls).map(controlName => {
    //   this.myForm.get(controlName).markAsUntouched({ onlySelf: true });
    //   this.myForm.get(controlName).markAsPristine({ onlySelf: true });
    // });
    // this.setReportValues();
    // await this.setDefaultValues();
    // this.depandentOn.forEach((element: reportDetail) => {
    //   delete this.LookupData[element.fieldname];
    // });
  }

  async saveClose() {
      this.Values = [];
    if (Object.keys(this.myForm.controls).length > 0) {
      Object.keys(this.myForm.controls).forEach((o) => {
        const element = this.myForm.controls[o];

        let displayMemberVal;
        let dropcaption;
        let Id;
        if (element.value) {
          if (this.dropDowns.filter((i) => i.fieldName === o).length > 0) {
            displayMemberVal = this.dropDowns.filter(
              (i) => i.fieldName === o 
            )[0].displayMemberValue;
            dropcaption = this.dropDowns.filter(
              (i) => i.fieldName === o
            )[0].DefaultProperties?.caption;

            Id = this.dropDowns.filter((i) => i.fieldName === o)[0].Id;
          }

          this.Values.push({
            key: o,
            value: element.value,
            displayMemberValue: displayMemberVal,
            Id: Id,
            caption:dropcaption
          });
        }

        //await this.valueChange(element.key);
      });
    }

    if (!this.validate()) {
      //$("#dimensionbaseviewer").modal("hide");
      this.afterDimenstionSelected.emit(this.Values);
    }
  }

  loadEnd() {}
}

const ReportingEnum = {
  Lov: {
    FieldType: {
      Text: 16,
      Date: 17,
      Combo: 18,
      Numeric: 19,
      Checkbox: 20,
      Time: 26,
    },

    ControlStatus: {
      Enable: 21,
      Disable: 22,
      Hide: 23,
    },
  },
};

interface listerDtl {
  lineindex?: number;
  fieldname?: string;
  name?: string;
  caption: string;
  fieldwidth?: number;
  fieldtypeid?: number;
  format?: string;
  type?: string;
  width?: string;
}

interface Dimensions {
  Id: number;
  stxt: string;
  controller?: string;
  routemethod?: string;
  parms?: string;
  valuemember?: string;
  displaymember?: string;
  data?: Array<any>;
  defaultvalue?: any;
  code?: string;
  ismandatory?: boolean;
  lineindex?: number;
  fieldtypeid?: number;
  statusid?: number;
  dependenton?: string;
  params?: string;
  utlisterconfighdr?: { utlisterconfigdtls?: Array<any> };
  utlisterconfigdtls?: Array<any>;
  capwidth?: string;
  fieldwidth?: string;
  paramsSplit: any;
}

interface listerConfig {
  key: string;
  value: Array<any>;
}

export interface DimensionValue {
  key: string;
  value: any;
  displayMemberValue: any;
  Id: any;
  caption:any;
}
