import { Component, Input, forwardRef, Self, OnInit, EventEmitter, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { ControlValueAccessor } from '@angular/forms';

@Component({
    selector: 'dcs-radio',
    templateUrl: 'dcs-radio.component.html',
    // styleUrls: ['radio-button.component.css']
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DcsRadioButtonComponent),
            multi: true
        }
    ]
})
export class DcsRadioButtonComponent implements ControlValueAccessor {

    constructor() { }
    // data: any = [
    //     {
    //         'Id' :'01',
    //         'answer' : 'Male',
    //     },
    //     {
    //         'Id' :'02',
    //         'answer' : 'Female',
    //     }

    // ]
    private innerValue: any = "";

    private onTouchedCallback!: () => void;
    private onChangeCallback!: (_: any) => void;

    @Output()
    onRadionChanged: EventEmitter<any> = new EventEmitter<any>();
    @Input()
    get value(): any {
        return this.innerValue;
    }

    onChange(ev: any) {
        this.onRadionChanged.emit(ev);
    }

    set value(v: any) {
        if (v !== this.innerValue) {
            this.innerValue = v;
            this.onChangeCallback(v);
        }
    }

    onBlur() {
        this.onTouchedCallback();
    }

    writeValue(value: any) {
        if (value !== this.innerValue) {
            this.innerValue = value;
        }
    }

    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }

    @Input()
    caption: any;

    @Input()
    name: any;

    @Input()
    disabled: boolean = false;

    @Input()
    fieldName: any;

    @Input()
    position: number = 1;

    @Input()
    required: boolean = false;

    DocFieldsData: any = [];
    @Input()
    formControlName: string | any;

    async ngOnInit() {
        this.DocFieldsData = JSON.parse(localStorage.getItem("DocFields") || '{}');
        if (this.DocFieldsData && this.DocFieldsData.length && this.DocFieldsData.length > 0) {
            var isConfig = this.DocFieldsData.filter((dt: any) => dt.fieldname == this.formControlName)[0];
            if (isConfig && isConfig.caption) {
                if (this.caption.includes("*")) {
                    this.caption = isConfig.caption + "*";
                }
                else {
                    this.caption = isConfig.caption;
                }
            }
            if(isConfig != undefined){
                if (isConfig && isConfig.disabled == "True") {
                  this.disabled = true;
                }
                else {
                  this.disabled = false;
                }
              }
        }
    }

}