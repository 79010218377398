<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div id="main-wrapper" [ngClass]="{ 'show-sidebar': showMobileMenu }" [dir]="options.dir"
  [attr.data-theme]="options.theme" [attr.data-layout]="options.layout" [attr.data-sidebartype]="options.sidebartype"
  [attr.data-sidebar-position]="options.sidebarpos" [attr.data-header-position]="options.headerpos"
  [attr.data-boxed-layout]="options.boxed">
  <!-- ============================================================== -->
  <!-- Topbar header - style you can find in topbar.scss -->
  <!-- ============================================================== -->
  <header class="topbar" [attr.data-navbarbg]="options.navbarbg" style="background: #f6faff;">
    <nav class="d-flex top-navbar navbar-expand-md"
      [ngClass]="options.navbarbg == 'skin6' ? 'navbar-light' : 'navbar-dark'">

      <!-- ============================================================== -->
      <!-- End Logo -->
      <!-- ============================================================== -->
      <div class="navbar-collapse collapse custom-header" id="navbarSupportedContent"
        [attr.data-navbarbg]="options.navbarbg" [ngbCollapse]="!isCollapsed">
        <app-navigation (toggleSidebar)="toggleSidebarType()" class="w-100 w-100-header" style="z-index: 999999;">
        </app-navigation>
      </div>
    </nav>
    <!-- Support Chat Button -->
    <!-- <abbr title="Make a Live Chat Now" class="MainAbbr" style="padding: 0 !important;">
      <a href="javascript:void(Tawk_API.toggle())">
        <div class="LiveChatBtn">
          <span class="iconSpan">
            <i class="fas fa-comments"></i>
          </span>
          <span class="textSpan">
            <span> Live Support </span>
          </span>
        </div>
      </a>
    </abbr> -->
    <!-- End -->
    <div class="scrollmenu">
      <!-- <span class="notify-badge">10</span> -->
     <div class="scrollmenu-sub">
      <div>
        <a (click)="dropdownFunction($event)" style="width: auto;padding-left: 5px;padding-right: 5px;"><i
            class="fa fa-star  star-icon" [ngClass]="{ blueColor: showDropdown }"></i></a>
        <a data-toggle="tab" [class.active]="item.id === (selectedItem && selectedItem.id ? selectedItem.id : 0) "
          (click)="menuClick(item)" *ngFor="let item of tabjson"
          [ngStyle]="{'display': (item.visible == true) ? 'inline-block' : 'none'}">
          <!-- <i class="{{ item.icon }}"></i> -->
          <img class="top-tab-menu" src="{{ item.icon }}" alt="">{{ item.Value }}
        </a>
      </div>
      <div>
        <app-breadcrumb></app-breadcrumb>
      </div>
     </div>
      <!-- <span><i class="fas fa-bell" style="cursor: pointer;
                padding: 11px;
                font-size: 18px !important;float: right;" (click)="notificationFunction($event)"><span
                        class="notify-badge">10</span></i></span> -->

      <!-- <span> -->
      <!-- <i class="fas fa-bell" (click)="notificationFunction($event)"><span class="icon-button__badge">2</span></i
      > -->

      <!-- </span> -->
    </div>
    <div class="row" *ngIf="showDropdown" #dropdown>
      <div class="col-lg-6">
        <!-- *ngFor="let item of dataFav" -->
        <div id="myDropdown" class="dropdown-content box box-default" style="border: 1px solid var(--edpmain);">
          <span class="custom-triangle"></span>
          <div class="row with-border">
            <h5 class="col-11" style="margin-bottom: 3px; padding: 0px;"><span class="favorit-head">Favorites</span></h5>
            <span class="col-1">
              <!-- (already updated data in the favourit modal so no need of refresh) -->
              <!-- <i class="fa fa-refresh" aria-hidden="true" style="float: right; cursor: pointer; text-align: center"
                (click)="refreshFavoriteScreens()" [ngClass]="{ 'fa-spin': isClicked }"></i> -->
            </span>
          </div>
          <div class="row scroll-fav" *ngIf="LookupData.FavoriteScreensModule.length > 0">
            <h4 class="fav-heads">Modules</h4>
            <div class="col-lg-3 mb-2" *ngFor="let item of LookupData.FavoriteScreensModule">
              <h6 class="dropdown-header with-border">{{ item.label }}</h6>
              <div *ngFor="let item2 of item.children">
                <p class="para">
                  <a class="screen-link" (click)="changeTitle(item, item2)"><i class="fa fa-dot-circle-o pr-1"
                      aria-hidden="true"></i>{{ item2.label }}</a>
                </p>
              </div>
            </div>
          </div>

          <div class="row scroll-fav" *ngIf="LookupData.FavoriteScreensReport.length > 0">
            <h4 class="fav-heads">Reports</h4>
            <div class="col-lg-3 mb-2" *ngFor="let item of LookupData.FavoriteScreensReport">
              <h6 class="dropdown-header with-border">{{ item.label }}</h6>
              <div *ngFor="let item2 of item.children">
                <p class="para">
                  <a class="screen-link" (click)="changeTitlereport(item, item2)"><i class="fa fa-dot-circle-o pr-1"
                      aria-hidden="true"></i>{{ item2.label }}</a>
                </p>
              </div>
            </div>
          </div>
          <div class="p-2" *ngIf="
              LookupData.FavoriteScreens &&
              LookupData.FavoriteScreens.length == 0
            ">
            No Record Found
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="row justify-content-center menu-component">
      <div class="col-12 p-0">
        <div class="header-tile h3 d-flex align-items-baseline">
          <app-breadcrumb></app-breadcrumb>
        </div>
      </div>
    </div> -->




  </header>



  <!-- ============================================================== -->
  <!-- Left Sidebar - style you can find in sidebar.scss  -->
  <!-- ============================================================== -->

  <aside class="left-sidebar" #leftsidebar [attr.data-sidebarbg]="options.sidebarbg" (mouseover)="Logo()"
    (mouseout)="Logo()">
    <div class="nav-main-sec">
      <div class="navbar-header1">
        <!-- This is for the sidebar toggle which is visible on mobile only -->
        <a (click)="showMobileMenu = !showMobileMenu" class="nav-toggler waves-effect waves-light d-block d-md-none"
          href="javascript:void(0)">
          <i [ngClass]="showMobileMenu ? 'ti-close' : 'ti-menu'"></i>
        </a>
        <!-- ============================================================== -->
        <!-- Logo -->
        <!-- ============================================================== -->
        <a class="">
          <!-- Logo icon -->
          <b class="logo-icon">
            <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
            <!-- Dark Logo icon -->
            <!-- <img
    src="assets/images/logo.png"
    alt="homepage"
    class="dark-logo"
  /> -->
            <!-- Light Logo icon -->
            <!-- <img src="assets/images/logo-light-icon\.png" alt="homepage" class="light-logo" /> -->
            <!-- <img src="../../../assets/images/logo1.png" alt="" class="light-logo logo-width"> -->
            <!-- [src]="EntityLogo" -->
            <div
              [style.background-image]="EntityLogo  ? 'url(' + EntityLogo +')' : 'url(../../../assets/images/flash.png)'"
              alt="" class="light-logo logo-width school-logo-icon">
            </div>
          </b>

          <!--End Logo icon -->
          <!-- Logo text -->
          <!-- <span class="logo-text" style="color: #1d84ad; font-size: 14px"> -->
          <!-- dark Logo text -->
          <!-- Edap Grammer School -->
          <!-- <img
    src="assets/images/logo.png"
    alt="homepage"
    class="dark-logo"
  /> -->
          <!-- Light Logo text -->
          <!-- <img src="../../../assets/images/logo1.png" class="light-logo logo-text-width" alt="homepage" /> -->
          <!-- </span> -->
        </a>
        <!-- ============================================================== -->
        <!-- End Logo -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Toggle which is visible on mobile only -->
        <!-- ============================================================== -->
        <a class="topbartoggler d-block d-md-none waves-effect waves-light" href="javascript:void(0)"
          (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent">
          <i class="ti-more"></i>
        </a>

      </div>
      <div class="sidebar-search" (click)="focusin()">
        <input type="text" placeholder="Search" #inputfield (keyup)="onKeyup($event)"
          (focusout)="onFocusOutEvent($event)">
        <i class="fa fa-search" (click)="menumodalopen()"></i>
      </div>
      <div class="menulist-main"
        *ngIf="LookupData.listmenu && LookupData.listmenu.length > 0 && finallistdata && finallistdata.length > 0">
        <ul class="menulist" #activelist>
          <!-- <li *ngFor="let item of finallistdata">
            <span class="listdatamenu" (click)="searchmenu(item, item)">{{item.label}}</span>
            <span *ngIf="item && item.label == undefined" class="norecorfnd">{{item}}</span>
          </li> -->

          <li *ngFor="let item of finallistdata; let i = index;" [class.activeSearchLink]="i === linkIndex"
            [ngClass]="{'section-color' : i === linkIndex}" #activelist class="listdatamenu"
            (click)="searchmenu(item, item)">
            <span> {{item.label}}</span>
            <span *ngIf="item && item.label == undefined" class="norecorfnd">{{item}}</span>
          </li>





        </ul>
      </div>
      <!-- <table class="schoolnametable">
    <tr>
      <td>{{ schoolname }}</td>
    </tr>
  </table> -->
    </div>
    <!-- Sidebar scroll-->
    <div class="scroll-sidebar" [perfectScrollbar]="config">
      <!-- <app-sidebar></app-sidebar> -->
      <div *ngIf="(selectedItem && selectedItem.id ? selectedItem.id : 0) === 1">
        <app-sidebar #sidebarmenu [type]="(selectedItem && selectedItem.id ? selectedItem.id : 0)"></app-sidebar>
      </div>
      <div *ngIf="(selectedItem && selectedItem.id ? selectedItem.id : 0) === 2">
        <app-sidebar #sidebarmenu [type]="(selectedItem && selectedItem.id ? selectedItem.id : 0)"></app-sidebar>
      </div>
      <div *ngIf="(selectedItem && selectedItem.id ? selectedItem.id : 0) === 3">
        <app-sidebar #sidebarmenu [type]="(selectedItem && selectedItem.id ? selectedItem.id : 0)"></app-sidebar>
      </div>
      <div *ngIf="(selectedItem && selectedItem.id ? selectedItem.id : 0) === 4">
        <app-sidebar [type]="(selectedItem && selectedItem.id ? selectedItem.id : 0)"></app-sidebar>
      </div>
    </div>
    <!-- End Sidebar scroll-->
  </aside>
  <!-- ============================================================== -->
  <!-- End Left Sidebar - style you can find in sidebar.scss  -->
  <!-- ============================================================== -->
  <!-- ============================================================== -->
  <!-- Page wrapper  -->
  <!-- ============================================================== -->
  <div class="page-wrapper">
    <!-- <app-breadcrumb></app-breadcrumb> -->

    <app-api-action [showAdd]="!showAdd" [showButtonList]="sList" [customButtonList]="cList" [showDelete]="!showDelete"
      [hidden]="!showTb"></app-api-action>

    <!-- ============================================================== -->
    <!-- Container fluid  -->
    <!-- ============================================================== -->
    <div class="container-fluid p-0 pb-4">
      <!-- ============================================================== -->
      <!-- Start Page Content -->
      <!-- ============================================================== -->

      <router-outlet></router-outlet>

      <!-- ============================================================== -->
      <!-- End Start Page Content -->
      <!-- ============================================================== -->
    </div>
    <div class="">
      <!-- <i class="fa fa-star" style="cursor: pointer;" (click)="dropdownFunction()"></i> -->

      <div *ngIf="showNotification" id="myNotification" class="notification-content">
        <span class="custom-triangle"></span>
        <div class="container-fluid text-center">
          <div class="row">
            <div class="col-12">
              <div class="new-message-box">
                <div class="new-message-box-alert">
                  <div class="info-tab tip-icon-alert" title="error">
                    <i></i>
                  </div>
                  <div class="tip-box-alert">
                    <!--<p><strong>Tip:</strong> If you want to enable the fading transition effect while closing the alert boxes, apply the classes <code>.fade</code> and <code>.in</code> to them along with the contextual class.</p>-->
                    <p>
                      Ha ocurrido un problema por favor
                      <a class="btn btn-sm" href="555"> intente nuevamente</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="new-message-box">
                <div class="new-message-box-danger">
                  <div class="info-tab tip-icon-danger" title="error">
                    <i></i>
                  </div>
                  <div class="tip-box-danger">
                    <!--<p><strong>Tip:</strong> If you want to enable the fading transition effect while closing the alert boxes, apply the classes <code>.fade</code> and <code>.in</code> to them along with the contextual class.</p>-->
                    <p>
                      Ha ocurrido un problema por favor
                      <a class="btn btn-sm" href="555"> intente nuevamente</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- -->
          <div class="row">
            <div class="col-12">
              <div class="new-message-box">
                <div class="new-message-box-success">
                  <div class="info-tab tip-icon-success" title="success">
                    <i></i>
                  </div>
                  <div class="tip-box-success">
                    <!--<p><strong>Tip:</strong> If you want to enable the fading transition effect while closing the alert boxes, apply the classes <code>.fade</code> and <code>.in</code> to them along with the contextual class.</p>-->
                    <p>
                      Ha ocurrido un problema por favor
                      <a class="btn btn-sm" href="555"> intente nuevamente</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- -->
          <div class="row">
            <div class="col-12">
              <div class="new-message-box">
                <div class="new-message-box-warning">
                  <div class="info-tab tip-icon-warning" title="error">
                    <i></i>
                  </div>
                  <div class="tip-box-warning">
                    <!--<p><strong>Tip:</strong> If you want to enable the fading transition effect while closing the alert boxes, apply the classes <code>.fade</code> and <code>.in</code> to them along with the contextual class.</p>-->
                    <p>
                      Ha ocurrido un problema por favor
                      <a class="btn btn-sm" href="555"> intente nuevamente</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- -->
          <div class="row">
            <div class="col-12">
              <div class="new-message-box">
                <div class="new-message-box-info">
                  <div class="info-tab tip-icon-info" title="error">
                    <i></i>
                  </div>
                  <div class="tip-box-info">
                    <!--<p><strong>Tip:</strong> If you want to enable the fading transition effect while closing the alert boxes, apply the classes <code>.fade</code> and <code>.in</code> to them along with the contextual class.</p>-->
                    <p>
                      Ha ocurrido un problema por favor
                      <a class="btn btn-sm" href="555"> intente nuevamente</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- -->
        </div>
      </div>
    </div>
    <!-- ============================================================== -->
    <!-- End Container fluid  -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- footer -->
    <!-- ============================================================== -->
    <footer class="footer text-center fixed-bottom" style="
        box-shadow: 0 -1px 7px rgb(0 0 0 / 15%) !important;
        padding: 5px 15px 2px !important;
      ">
      <div class="row">
        <div class="col-4 d-none d-lg-block">
          <div class="breadcrumb-custom">
            <p class="loginuserinfo">User :<span style="color: var(--edpmain);"> {{UserLog.fullname}} </span></p>
            <!-- <app-breadcrumb></app-breadcrumb> -->
          </div>
        </div>
        <div class="col-lg-8 col-md-12 col-sm-12 col-xs-12">
          <div class="row justify-content-end">
            <div class="
                col-lg-2 col-md-2 col-sm-3 col-xs-3
                d-none d-lg-block p-0
              ">
              <div *ngIf="online == true" title="Connected">
                <div class="status-show">
                  <i class="fa fa-globe status-show-first"></i>
                  <img src="../../../assets/images/LIne-01.gif" alt="">
                  <i class="fa fa-wifi status-show-second statusonline"></i>
                </div>
              </div>
              <div *ngIf="online == false" title="No Internet Connected">
                <div class="status-show">
                  <i class="fa fa-globe status-show-first"></i>
                  <img src="../../../assets/images/LIne-01.gif" alt="">
                  <i class="fa fa-wifi status-show-second statusoffline"></i>
                </div>
              </div>
            </div>
            <div class="
                col-lg-1 col-md-2 col-sm-3 col-xs-3
                d-none d-lg-block
              ">
              <div class="pull-left edap-logo px-2 border-left border-right">
                <a href="http://edap.com.pk/" target="_blank"><img class="image-fluid"
                    src="assets/images/edap-size-2.png" /></a>
              </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12 col-xs-6">
              <div class="small px-2 border-right bordernone">
                <h6 class="full-footer">All Rights Reserved by <a href="https://digicopsolutions.com/"
                    target="_blank"><strong> Digicop
                      Solutions </strong></a> <a *ngIf="OurPartner" href="https://www.etisalcom.com/" target="_blank"> &
                    <strong> {{ OurPartner}}</strong> </a></h6>
              </div>
            </div>
            <div class="
                col-lg-1 col-md-2 col-sm-3 col-xs-3
                d-none d-lg-block
              ">
              <div class="pull-right digicop-logo px-2">
                <a href="https://www.digicopsolutions.com" target="_blank"><img class="image-fluid"
                    src="assets/images/digi-size-2.png" /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- ============================================================== -->
    <!-- End footer -->
    <!-- ============================================================== -->
  </div>
  <!-- ============================================================== -->
  <!-- End Page wrapper  -->
  <!-- ============================================================== -->

  <div class="chat-windows"></div>
</div>
<dcs-error #dcserror></dcs-error>
<app-dcsMessageBox #dcsmessagebox></app-dcsMessageBox>
<Dcs-loader></Dcs-loader>
<dcs-lookup #dcslookup></dcs-lookup>
<dcs-modal #menumodal [mode]="4" Modal="true" Caption="Search">
  <div>
    <dcs-aggrid #menugrid [lvl]="3" (onRowDbClicked)="clickeddoouble($event , $event)" [hideRecord]="false"
      [filter]="true" [columns]="gridmenus" [type]="1" [data]="LookupData.listmenu">
    </dcs-aggrid>
  </div>
</dcs-modal>
<style>
  .LiveChatBtn {
    z-index: 9999;
    position: absolute;
    top: 40px;
    right: 12px;
    cursor: pointer !important;
    box-shadow: 0px 1px 1px #cacaca, 2px 2px 4px #cfcfcf;
    padding: 3px 0px 0px 0px;
    width: 95px;
    font-size: 12px;
    border-radius: 4px;
    text-align: center;
    border: 1px solid #f5f5f5;
  }

  .LiveChatBtn span.iconSpan {
    color: var(--edpmain);
  }

  .LiveChatBtn span.textSpan span {
    font-size: 11px;
    font-weight: 600;
  }

  .MainAbbr a:hover {
    color: var(--edpmain) !important;
  }
</style>