import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  Renderer2,
  ViewChild,
} from "@angular/core";
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from "@angular/forms";
import { AppConstants } from "src/app/@constant/app.constant";
import { ControlBase } from "../../../@constant/config";
import * as moment from "moment";
import { DcsModalComponent } from "../dcs-modal/dcs-modal.component";
// import { AmazingTimePickerService } from "amazing-time-picker";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { LocalStorage } from "../../helper/localStorage";
import { GenEnum } from '../../../@constant/general.enum';
declare var $: any;

@Component({
  selector: "dcs-time",
  templateUrl: "dcs-time.component.html",
  styleUrls: ["dcs-time.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DcsTimeComponent),
      multi: true,
    },
    {
      // Is an InjectionToken required by this class to be able to be used as an Validator
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DcsTimeComponent),
      multi: true,
    },
    {
      provide: ControlBase,
      useExisting: DcsTimeComponent,
    },
  ],
})
export class DcsTimeComponent
  extends ControlBase
  implements ControlValueAccessor, Validator {
  selectedItem: any[] = [];
  @Output() close = new EventEmitter();
  @ViewChild("timepickermodal") timepickermodal: DcsModalComponent | any;
  @Output() changeTime = new EventEmitter<any>();

  private date = new Date();
  // private onChange = (v: Date) => { };
  // private onTouched = () => { };
  auto = true;
  hhmm = "hh";
  ampm = "am";
  dial: any = [];
  hour = "12";
  minute = "00";
  @Input()
  ReportDropdown: boolean = false;

  @Output()
  selectedTime: EventEmitter<any> = new EventEmitter<any>();
  timepickershow: boolean = false;
  controlEl: AbstractControl | any;
  errMsg: undefined;
  errorCaption: string = "";
  public ErrorDetail: any = this.localStorage.get("Errorlogs"); // ErrorDetails coming from localstorage
  ErrorsLog: any;
  constructor(
    private element: ElementRef,
    private renderer: Renderer2, //Renderer,
    private modalService: NgbModal,
    private localStorage: LocalStorage
  ) {
    super();
    const j = 84;
    for (let min = 1; min <= 12; min++) {
      const hh = String(min);
      const mm = String("00" + ((min * 5) % 60)).slice(-2);
      const x = 1 + Math.sin(Math.PI * 2 * (min / 12));
      const y = 1 - Math.cos(Math.PI * 2 * (min / 12));
      this.dial.push({ top: j * y + "px", left: j * x + "px", hh, mm });
    }
  }

  DocFieldsData: any = [];
  DocFieldsReportData: any = [];
  @Input()
  formControlName: string | any;
  DefaultProperties: any = {};
  async ngOnInit() {

    // Default Modifications
    this.DefaultProperties = {};
    this.DefaultProperties.caption = this.caption;
    this.DefaultProperties.disabled = this.disabled;
    // Default Modifications

    if (this.ReportDropdown == true) {
      this.DocFieldsReportData = JSON.parse(this.localStorage.get("DocFieldsReport"));
      if (this.DocFieldsReportData && this.DocFieldsReportData.length && this.DocFieldsReportData.length > 0) {
        var isConfig = this.DocFieldsReportData.filter((dt: any) => dt.fieldname == this.formControlName)[0];
        if (isConfig && isConfig.caption) {
          if (this.caption.includes("*")) {
            this.caption = isConfig.caption + "*";
          }
          else {
            this.caption = isConfig.caption;
          }
        }
        if (isConfig && isConfig.disabled == "True") {
          this.disabled = true;
        }
        else {
          this.disabled = false;
        }
      }
    }
    else if (this.ReportDropdown == false) {
      this.DocFieldsData = JSON.parse(this.localStorage.get("DocFields"));
      if (this.DocFieldsData && this.DocFieldsData.length && this.DocFieldsData.length > 0) {
        var isConfig = this.DocFieldsData.filter((dt: any) => dt.fieldname == this.formControlName)[0];
        if (isConfig && isConfig.caption) {
          if (this.caption.includes("*")) {
            this.caption = isConfig.caption + "*";
          }
          else {
            this.caption = isConfig.caption;
          }
        }
        if (isConfig != undefined) {
          if (isConfig && isConfig.disabled == "True") {
            this.disabled = true;
          }
          else {
            this.disabled = false;
          }
        }
      }
    }

    // Setup Form Control Properties 
    var item: any = [{
      field: this.formControlName,
      caption: this.caption,
      type: GenEnum.ControlType.Time,
    }];
    var old = this.localStorage.get('ControlProperties');
    if (old === "null" || old === null || old == undefined || old == "undefined") {
      this.localStorage.set('ControlProperties', JSON.stringify(item));
    } else {
      old = JSON.parse(old);
      var dupli = old.filter((a: any) => a.field == this.formControlName);
      if (dupli.length == 0) {
        old.push({
          field: this.formControlName,
          caption: this.caption,
          type: GenEnum.ControlType.Time,
        });
        this.localStorage.set('ControlProperties', JSON.stringify(old));
      }
    }
  }
  // Validation
  validate(control: AbstractControl): ValidationErrors | null {
    // Field Configuration Algorithem 
    setTimeout(async () => {
      
      await this.FieldCOnfigurationAlgorithm();
    }, 0);
    // Field Configuration Algorithem
    // this.dirtytext = false
    this.controlEl = control;
    // if ((this.innerValue === null) && this.dcsTextBox && control.errors && control.errors.required ) {

    if (
      (this.innerValue === null ||
        this.innerValue === "" ||
        this.innerValue === undefined) &&
      control.errors &&
      control.errors.required
    ) {
      this.errMsg = this.getErrorDescrption(this.cleanCaption());
      // this.isInvalid = true
      return { invalid: true };
    } else {
      // this.errMsg = null
      // this.isInvalid = false
    }
    return null;
  }
  cleanCaption() {
    if (this.caption.slice(-1) === "*") {
      return (this.errorCaption = this.caption.slice(0, -1));
    } else {
      return (this.errorCaption = this.caption);
    }
  }
  // Error description set on the basis of errorcode
  getErrorDescrption(caption: any) {
    var errordescription;
    this.ErrorsLog = JSON.parse(this.ErrorDetail);
    if (this.ErrorsLog && this.ErrorsLog.length && this.ErrorsLog.length > 0)
      this.ErrorsLog.forEach((element: any) => {
        if (element.code == "0002") {
          errordescription = element.stxt.replace("{0}", caption);
        }
      });

    return errordescription;
  }

  // for the purpose of fire event on lister select event
  public triggerChanged() {
    let event = new CustomEvent("change", { bubbles: true });
    this.renderer.listen(
      this.element.nativeElement,
      "dispatchEvent",
      (_callback) => {
        event;
      }
    );
  }

  private innerValue: any = null;

  private onTouchedCallback!: () => void;
  private onChangeCallback!: (_: any) => void;

  get value(): any {
    //   const rtnVal : string  = this.innerValue != null ? moment(this.innerValue).format( 'HH:mm') : null ;
    // return rtnVal;
    return this.innerValue;
  }

  @Input()
  set value(v: any) {
    if (v !== this.innerValue && v !== null) {
      this.innerValue = v;
      this.onChangeCallback(v);
      this.triggerChanged();
    }
  }

  onBlur() {
    this.onTouchedCallback();
  }
  public writeValue(v: Date) {
    if (!v) {
      this.innerValue = "";
    } else if (v) {
      const timeFormat = AppConstants.settings.timeFormat;
      const convertDate =
        this.innerValue != null
          ? moment(v).format(timeFormat ? timeFormat : "HH:mm:ss")
          : null;
      this.innerValue = convertDate !== "Invalid date" ? convertDate : v;
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  @Input()
  caption: string = "";
  @Input()
  type: number = 1;

  @Input()
  required: boolean = false;

  @Input()
  disabled: boolean = false;

  keyPress(event: any) {
    if (event.keyCode === 13) {
      this.openTimePicker();
    }
  }
  @ViewChild("heel") heel: ElementRef | any;
  change(event: any) {

    this.changeTime.emit(event);
  }
  openTimePicker() {
    this.timepickermodal.open("sm");
    this.timepickershow = true;
  }
  openModal(content: any) {
    if (this.type == 2) {
      this.timepickershow = true;
      const modalRef = this.modalService.open(content, {
        size: "sm",
      });
      // this.setColumns();
    }
  }

  @Input()
  position: number = 1;

  public _fieldWidth = "input-group col-lg-3 col-md-3 col-sm-9 col-xs-9";

  @Input()
  get fieldWidth(): string {
    return this._fieldWidth;
  }
  set fieldWidth(value: string) {
    if (value && value.indexOf(",") !== -1) {
      let colSplit = value.split(",");

      if (colSplit && colSplit.length === 4) {
        this._fieldWidth =
          "input-group col-lg-" +
          (colSplit[0] ? colSplit[0] : "3") +
          " col-md-" +
          (colSplit[1] ? colSplit[1] : "3") +
          " col-sm-" +
          (colSplit[2] ? colSplit[2] : "3") +
          " col-xs-" +
          (colSplit[3] ? colSplit[3] : "3") +
          " p-0";
      }
    }
  }

  public _captionWidth = "col-lg-1 col-md-1 col-sm-3 col-xs-3 col-form-label";

  @Input()
  get captionWidth(): string {
    return this._captionWidth;
  }
  set captionWidth(value: string) {
    if (value && value.indexOf(",") !== -1) {
      let colSplit = value.split(",");

      if (colSplit && colSplit.length === 4) {
        this._captionWidth =
          "col-lg-" +
          (colSplit[0] ? colSplit[0] : "1") +
          " col-md-" +
          (colSplit[1] ? colSplit[1] : "1") +
          " col-sm-" +
          (colSplit[2] ? colSplit[2] : "3") +
          " col-xs-" +
          (colSplit[3] ? colSplit[3] : "3") +
          " col-form-label p-0";
      }
    }
  }
  async FieldCOnfigurationAlgorithm() {
    
    var AccessKey = this.localStorage.get("AccessKey");
    if (this.ReportDropdown != true) {
      this.DocFieldsReportData = JSON.parse(this.localStorage.get("DocFieldssingle"));
      if (this.DocFieldsReportData && this.DocFieldsReportData.length && this.DocFieldsReportData.length > 0) {
        var isConfig = this.DocFieldsReportData.filter((dt: any) => dt.fieldname == this.formControlName && dt.rightid == AccessKey)[0];
        if (isConfig) {
          if (isConfig && isConfig.caption) {
            if (this.caption.includes("*")) {
              this.caption = isConfig.caption + "*";
            }
            else {
              this.caption = isConfig.caption;
            }
          }
          // if (isConfig && isConfig.maskingtypeid) {
          //   this.type = "cprid";
          // }
          if (isConfig && (isConfig.disabled == true || isConfig.disabled == 'true')) {
            this.disabled = true;
          }
          else {
            this.disabled = false;
          }
        }
        else {
          if (this.caption && this.caption != "" ? (this.DefaultProperties.caption != this.caption) : false) {
            await this.SetDefaultStates();
          }
        }

      }
      else {
        if (this.caption && this.caption != "" ? (this.DefaultProperties.caption != this.caption) : false) {
          await this.SetDefaultStates();
        }
      }
    }
  }

  async SetDefaultStates() {
    this.caption = this.DefaultProperties.caption && this.DefaultProperties.caption != '' ? this.DefaultProperties.caption : this.caption;
    this.disabled = this.DefaultProperties.disabled;
  }
}
