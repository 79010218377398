
import { Injectable } from "@angular/core";
import { AppService } from '../../@core/service/App.Service';


@Injectable({
    providedIn: "root",
  })
export class utdocfieldsReportService extends AppService<any>{

    constructor() {
        super('utdocfieldsReport');
    }

    async getDocfieldsReport(reportid: any, reportcode: any) {
        let clientid = await this.localStorage.get('clientid');
        const result = await this.get('utdocumentFieldsReport', [reportid, reportcode, clientid]);
        return result;
    }

    async SaveDocfieldReportConfig(_data: any) {

        var result = await this.post("SaveDocfieldReportConfig", _data)
        return result;
    }

    async DeletedocfieldReport(Data: any) {
        const result = await this.post('DeletedocfieldReport', Data);
        return result;
      }

}