import { Component, Input, OnInit } from "@angular/core";
import { LoaderService } from "../../helper/loader.service";
import { ToolbarEvent } from "../../events/toolbar.event";
import { AppConstants } from "src/app/@constant/app.constant";
import { Subscription } from "rxjs";

@Component({
  selector: "Dcs-loader",
  templateUrl: "dcs-loader.component.html",
  styleUrls: ["dcs-loader.component.scss"],
})
export class DCSLoaderComponent implements OnInit {
  loading: boolean = false;
  forceloading: boolean = false;
  toolbarEvent: ToolbarEvent;
  toolbarSubscription: Subscription;
  constructor(private loaderService: LoaderService) {
    this.loaderService.isLoading.subscribe((v) => {
      if (this.loaderService.forceloader.value === false) {
        if (this.forceloading === false) {
          this.loading = v;
        }
      }
      else {
        this.startLoading()
      }

    });
    {
      this.toolbarEvent = AppConstants.injector.get(ToolbarEvent);

      this.toolbarSubscription = this.toolbarEvent
        .click()
        .subscribe(async arg => {
          switch (arg.key) {
            case "start":
              await this.startLoading();
              break;
            case "stop":
              await this.stopLoading();
              break;
            case "forcestart":
              await this.forcestartLoading();
              break;
            case "forcestop":
              await this.forcestopLoading();
              break;
          }
        });
    }
  }
  ngOnInit() { }
  startLoading() {
    this.loading = true;
  }
  stopLoading() {
    this.loading = false;
  }
  forcestartLoading() {
    this.forceloading = true;
    this.startLoading()
  }
  forcestopLoading() {
    this.forceloading = false;
    this.stopLoading()
  }

}
