/**
 * The main component that renders single TabComponent
 * instances.
 */

import {
    Component,
    ContentChildren,
    QueryList,
    AfterContentInit,
    ViewChild,
    ComponentFactoryResolver,
    ViewContainerRef,
    Input,
    Output,
    EventEmitter
} from "@angular/core";


// @Component({
//     selector: "dcs-additional-tabs",
//     templateUrl: 'dcs-additional-tabs.component.html',
//     styleUrls: ['dcs-additional-tabs.component.scss']
// })
@Component({
    selector: 'dcs-additional-tabs',
    templateUrl: 'dcs-additional-tabs.component.html'
})
export class DcsAdditionalTabsComponent implements AfterContentInit {
    @Input('data') data: any
    selectedItem: any = 0;
    ngAfterContentInit(): void {
        //   throw new Error("Method not implemented.");
        setTimeout(() => {

            if (this.data && this.data.length && this.data.length > 0 && this.data[0])
                this.menuClick(this.data[0])
        }, 0);
    }
    // @Output('menuClick') menuclick = new EventEmitter < any >
    @Output() menuClickEvent = new EventEmitter<any>();

    menuClick(item: any) {
        
        this.selectedItem = item;
        this.menuClickEvent.emit(item)


    }

}