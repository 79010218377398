<div class="form-group">
  <label *ngIf="showCaption" [ngClass]="position === 1 ? '' : _captionWidth"
    >{{ caption }}
  </label>
  <span
    *ngIf="
      (controlEl &&
        controlEl.errors &&
        controlEl.errors?.invalid &&
        (controlEl.dirty || controlEl.touched)) ||
      this.dirtytext
    "
    style="float: right"
  >
    <span class="tooltipss" type="button">
      <i class="fa fa-info-circle" aria-hidden="true"></i>
      <span class="tooltiptextss">
        <i
          class="fas fa-exclamation-triangle"
          style="color: red; font-size: 12px; padding-right: 2px"
        ></i>
        <!-- errMsg coming from ts file after sett its content-->
        {{ errMsg }}
      </span>
    </span>
  </span>
  <div
    [ngClass]="
      (controlEl &&
        controlEl.errors &&
        controlEl.errors?.invalid &&
        (controlEl.dirty || controlEl.touched)) ||
      this.dirtytext
        ? 'border-outline-invalid invalid'
        : 'border-outline-valid'
    "
  >
    <textarea
      #input="ngModel"
      id="name"
      [disabled]="disabled"
      class="form-control dcs-control"
      (blur)="onBlur()"
      [placeholder]="placeholderValue()"
      [(ngModel)]="value"
      [maxlength]="maxlength"
      [rows]="rows"
      [ngModelOptions]="{ standalone: true }"
      autocomplete="off"
    ></textarea>
  </div>
</div>
