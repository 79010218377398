import { ErrorComponent } from "./../../components/error/error.component";
import { FormGroup } from "@angular/forms";
import { Component, OnDestroy, ViewChild } from "@angular/core";
import { BaseComponent } from "./Base.Component";
import { Subscription } from "rxjs";
import { MessageBoxComponent } from "../../components/MessageBox/MessageBox.component";
import { MessageBoxSettings } from "../../components/MessageBox/messagebox.config";
import { errorArgs, ErrorConfig } from "../../../@constant/config";

@Component({
  template: "",
})
export abstract class ContainerComponent
  extends BaseComponent
  implements OnDestroy {
  ngOnDestroy(): void {
    if (this.errorSubscription) this.errorSubscription.unsubscribe();
    if (this.showMessageSubscription)
      this.showMessageSubscription.unsubscribe();
  }
  protected myForm!: FormGroup;

  public title: string = "";
  public tree: string = "";
  public showTb: Boolean = true;
  public showAdd: Boolean = true;
  public sList: Boolean = false;
  public showDelete: Boolean = true;
  public menu: string = "";
  public submenu: string = "";
  public titleIcon: string = "";

  public cList: any;
  private errorSubscription: Subscription;
  private showMessageSubscription: Subscription;

  @ViewChild("dcsmessagebox")
  public messageBox?: MessageBoxComponent;

  @ViewChild("dcserror") public errorForm?: ErrorComponent;
  public modulename: any;
  public screenname: any;
  constructor() {
    super();
    this.menu = "";
    this.submenu = "";

    this.modulename = this.localStorage.get("modulename");
    this.screenname = this.localStorage.get("screenname");
    this.broadcast.observable<FormGroup>("formgroup").subscribe((x) => {
      this.myForm = x.value;
    });

    this.broadcast.observable<string>("title").subscribe((x) => {
      let modulename = this.localStorage.get("modulename");
      let screenname = this.localStorage.get("screenname");

      // if((modulename != null  || modulename != '' ) || (screenname  != null || screenname  != '')){

      //   this.localStorage.remove('modulename');
      //   this.localStorage.remove('screenname');

      //
      // }
      setTimeout(() => {
        this.modulename = modulename !== null ? modulename : "";
        this.title = screenname !== null ? screenname : ""; ///x.value;
        // this.title = ( modulename  != null ?  modulename : '' )+ " / "  +(screenname != null ?  screenname : ''); 
      }, 0);

    });

    this.broadcast.observable<string>("tree").subscribe((x) => {
      this.tree = x.value;
    });

    this.showMessageSubscription = this.broadcast
      .observable<MessageBoxSettings>("showMessage")
      .subscribe((x) => {
        this.messageBox?.open(x.value);
      });

    this.broadcast.observable<Boolean>("showToolbar").subscribe((x) => {
      this.showTb = x.value;
    });

    this.broadcast.observable<Boolean>("hideadd").subscribe((x) => {
      this.showAdd = x.value;
    });

    this.broadcast.observable<Boolean>("hidedelete").subscribe((x) => {
      this.showDelete = x.value;
    });

    this.broadcast.observable<string>("menu").subscribe((x) => {
      this.menu = x.value;
    });
    this.broadcast.observable<string>("submenu").subscribe((x) => {
      this.submenu = x.value;
    });
    this.broadcast.observable<string>("titleIcon").subscribe((x) => {
      this.titleIcon = x.value;
    });
    this.broadcast.observable<Boolean>("showButtonList").subscribe((x) => {
      this.sList = x.value;
    });
    this.broadcast.observable<any>("customList").subscribe((x) => {
      this.cList = x.value;
    });

    this.errorSubscription = this.broadcast
      .observable<ErrorConfig>("showError")
      .subscribe((x) => {

        if (this.errorForm) {

          this.errorForm.Id = this.title;
          this.errorForm.showErrors(
            x.value.messages,
            x.value.title,
            x.value.type,
            x.value.displaymode
          );

          //   this.isModalOpen = true;
        }
      });
  }

  async confirm(event: errorArgs) {
    //this.isModalOpen = false;
    await this.broadcast.publish<errorArgs>("confirmdialog", event);
  }

  async ngOnInit() { }
}
