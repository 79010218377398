<div *ngIf="!ReportDropdown">
  <!-- [ngClass]="position === 1 ? '' : _captionWidth" -->
  <label *ngIf="showCaption === true">{{ caption }}</label>
  <!-- START VALIDATION SPAN -->
  <span *ngIf="(controlEl && controlEl.errors && controlEl.errors?.invalid && (controlEl.dirty || controlEl.touched))"
    style="float: right;">
    <!-- Condition to show Tooltip -->
    <span class="tooltipss" type="button">
      <i class="fa fa-info-circle" aria-hidden="true"></i>
      <span class="tooltiptextss">
        <i class="fas fa-exclamation-triangle" style="color: red;font-size: 12px ;padding-right: 2px ;"></i>
        <!-- errMsg coming from ts file after sett its content-->
        {{errMsg}}
      </span>
    </span>
  </span>
  <!-- End Validation span -->
  <!-- class="dcs-control w-100" -->
  <!-- [ngClass]="position === 1 ? '' : _fieldWidth" -->
  <input #date [type]="type" [disabled]="disabled" min="{{minDate}}" max="{{maxDate}}"
    [ngClass]=" (controlEl && controlEl.errors && controlEl.errors?.invalid && (controlEl.dirty || controlEl.touched))  ? 'form-control dcs-control border-outline-invalid w-100' : 'form-control dcs-control border-outline-valid w-100'"
    [(ngModel)]="value" (blur)="onBlur()" (keydown)="onKeyDown($event)" />
</div>


<div *ngIf="ReportDropdown">
  <div class="report-control">
    <label class="report-label" *ngIf="showCaption === true">{{ caption }}</label>
    <input #date [type]="type" [disabled]="disabled"
      [ngClass]=" (controlEl && controlEl.errors && controlEl.errors?.invalid && (controlEl.dirty || controlEl.touched))  ? 'form-control dcs-control border-outline-invalid w-100' : 'form-control dcs-control border-outline-valid w-100'"
      [(ngModel)]="value" (blur)="onBlur()" (keydown)="onKeyDown($event)" />
    <!-- <label>{{ caption }}</label> -->
    <!-- START VALIDATION SPAN -->
    <span *ngIf="(controlEl && controlEl.errors && controlEl.errors?.invalid && (controlEl.dirty || controlEl.touched))"
      style="float: right;">
      <!-- Condition to show Tooltip -->
      <span class="tooltipss" type="button">
        <i class="fa fa-info-circle" aria-hidden="true"></i>
        <span class="tooltiptextss">
          <i class="fas fa-exclamation-triangle" style="color: red;font-size: 12px ;padding-right: 2px ;"></i>
          <!-- errMsg coming from ts file after sett its content-->
          {{errMsg}}
        </span>
      </span>
    </span>
  </div>
</div>