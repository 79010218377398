// Angular Imports
import { NgModule } from '@angular/core';

// This Module's Components
import { DcsMultiselectDropdownComponent } from './dcs-multiselect-dropdown.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

@NgModule({
    imports: [
        CommonModule, FormsModule, ReactiveFormsModule,
        NgMultiSelectDropDownModule
    ],
    declarations: [
        DcsMultiselectDropdownComponent,
    ],
    exports: [
        DcsMultiselectDropdownComponent,
    ]
})
export class DcsMultiselectDropdownModule {

}

