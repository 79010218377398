<!--component html goes here -->
<modal #printViewer class="report printmodal">

    <modal-header>
        <h5 style="padding: 3px; font-weight:bold;" class="modal-title">
            {{ reportTitle }}
        </h5>
    </modal-header>
    <modal-content>
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <template #report></template>
            </div>
        </div>
    </modal-content>

</modal>