import { LookupConfiguration } from "../../../../@constant/config";

export const SMAttachmentConfigs = {
    lister: <LookupConfiguration[]>[
        {
            name: 'attchmentno',
            caption: 'Attachment No',
            width: '130px'
        },
        {
            name: 'docname',
            caption: 'Document Name',
            width: '200px'
        },
        {
            name: 'browsepath',
            caption: 'Path',
            width: '350px'
        }
    ]
};
export const DocumenttypeConfigs = {
    lister: <LookupConfiguration[]>[
      {
        name: "code",
        caption: "Code",
        width: "110px",
      },
      {
        name: "stxt",
        caption: "Document Type",
        width: "180px",
      },
    ],
  };