import { customButtonList, Enum } from "./../../../@constant/config";
import { ModalComponent, ModalManager } from "ngb-modal";
import {
  Component,
  ViewChild,
  ContentChild,
  Output,
  EventEmitter,
  AfterViewInit,
  Input,
  OnInit,
  HostListener,
  ElementRef,
} from "@angular/core";
import { List } from "linqts";
import { NavModalComponent } from "../../base/nav/NavModal.Component";
import { Subscription } from "rxjs";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { utdocfieldsService } from "src/app/shared/services/utdocfields.Service";
import { ErrorComponent } from "../error/error.component";
import { utdocfieldsReportService } from '../../../shared/services/utdocfieldsReport.Service';
import { lowerCase, upperCase } from "lodash";
import { ClientService } from "src/app/modules/userManagement/client/client.service";
import { ClientConfigs } from "src/app/modules/userManagement/client/client.config";
import { filter } from 'rxjs/operators';

declare var $: any;

@Component({
  selector: "dcs-docfield-modal",
  templateUrl: "dcs-docfield-modal.component.html",
  styleUrls: ['dcs-docfield-modal.component.scss']
})
export class DcsDocfieldModalComponent implements AfterViewInit, OnInit {
  Size: any;
  ModalSize: any;
  ngAfterViewInit(): void {

    this.loadData();
  }
  private lookupSubscription?: Subscription;

  myForm = new FormGroup({
    caption: new FormControl(''),
    disabled: new FormControl(''),
    maskingtypeid: new FormControl(''),
    searchbox: new FormControl(''),
    userclientid: new FormControl(''),
  });
  async ngOnInit() { 

  }

  FieldsDataArray: any = [];
  async drag() {
    $(".modal-dialog").draggable({
      handle: ".modal-header",
    });
    $("modal-dialog").css({ top: 0, left: 0, right: 0, bottom: 0 });
  }

  @ContentChild("ngContent") screen?: NavModalComponent<any>;

  // @ViewChild("FieldConfigModal") FieldConfigModal?: DcsModalComponent;
  public LookupData: any = {};
  public lookupConfigs: any = {};
  @Input()
  showList: boolean = false;
  @Input()
  backdrop: boolean = true;

  @Input()
  closeOnOutsideClick: boolean = false;

  @Output()
  toolbarClickList: EventEmitter<customButtonList> = new EventEmitter<customButtonList>();

  groups: any = [];
  searchControl: boolean = false;
  data: customButtonList[] = [];

  @Input()
  set customButtonList(value: any) {
    this.data = value;
    this.loadData();
  }
  get customButtonList() {
    return this.data;
  }

  customButtonListClick(item: customButtonList) {
    this.toolbarClickList.emit(item);
  }

  closeDocModal() {
    this.CloseDocModal.emit();
  }
  @Output() CloseDocModal = new EventEmitter<string>();
  selectedKey: any;
  @Input()
  public Caption: String = "";

  @Input()
  screenCode: string = "";

  width: string = Enum.modalSize.Large;
  height: string = "auto";

  @Input()
  modaltype: number = Enum.modalType.Normal;

  @Input()
  displayLastRecord: boolean = false;

  @Input()
  showClose: boolean = true;

  @Input()
  mode: number = Enum.Mode.Default;

  @Input()
  set lvl(val: number) {
    this.zindex += Number(val);
  }
  get lvl() {
    return this.zindex;
  }

  @Input()
  modalSize: string = Enum.modalSize.Large;

  @Input()
  customClose: boolean = false;

  zindex: number = 1049;

  @ViewChild("myModal") myModal?: ModalComponent;
  private modalRef: any;

  @Input()
  hideNew: boolean = false;

  @Input()
  fields: any = [];
  @Input()
  ModuleType: any = [];

  @Input()
  hideSave: boolean = false;

  @Input()
  hideSaveClose: boolean = false;

  @Input()
  hideClose: boolean = false;
  @Input()
  hideModalToolbar: boolean = false;

  constructor(private modalService: ModalManager, private _utdocfieldsService: utdocfieldsService, private _utdocfieldsReportService: utdocfieldsReportService,
    private utclientservice: ClientService
    ) {
      this.lookupConfigs.utclientConfig = ClientConfigs.lister;
     }

  fetchData = [];
  fetchDataFiltered = [];
  @ViewChild('dcserror') dcserror: ErrorComponent | any;

  async open(size: string = "lg", isCentered: boolean = false) { 
    
    this.selectedKey = '';
    this.myForm.reset();
    await this.getClient();
    this.myForm.controls["userclientid"].setValue(Number(localStorage.getItem('clientid')));
    let arr: any = [];
    if (this.ModuleType == "1") {
      if (this.fields) {
        arr = [];
        Object.keys(this.fields).map(function (key: any) {
          arr.push({ key })
        });
      }
    }
    else if (this.ModuleType == "2") {
      if (this.fields) {
        arr = [];
        arr = this.fields;
      }
    }
    this.fetchData = arr;
    this.fetchDataFiltered = this.fetchData;


    await this.drag();

    if (this.screen) {
      this.Caption =
        this.Caption && this.Caption === this.screen.title
          ? this.Caption
          : this.screen.title
            ? this.screen.title
            : "-";
    }

    if (!this.modalRef || !this.modalRef.isOpened) {
      this.modalRef = this.modalService.open(this.myModal, {
        size: size,
        modalClass: "mymodal",
        hideCloseButton: this.hideClose,
        centered: isCentered,
        // backdrop: this.backdrop,
        animation: true,
        keyboard: false,
        closeOnOutsideClick: this.closeOnOutsideClick,
        backdropClass: "modal-backdrop",
      });

      if (this.screen) {
        this.screen.LookupData = [];
        await this.screen.initialize();

        this.screen.myForm.controls;

      }

      this.triggeronOpen(this.screen);
      if (this.displayLastRecord && this.mode !== Enum.Mode.New) {
        await this.screen?.moveLast();
      }
    }
  }

  SearchAction() {
    
    this.searchControl = !this.searchControl;
  }
  InputForSearch(event: any) {
    
    if (this.ModuleType == "1") {
      this.fetchDataFiltered = this.fetchData.filter((a: any) => a.key.includes(upperCase(this.myForm.controls["searchbox"].value)) || a.key.includes(lowerCase(this.myForm.controls["searchbox"].value)));
    }
    else if (this.ModuleType == "2") {
      this.fetchDataFiltered = this.fetchData.filter((a: any) => a.fieldname.includes(upperCase(this.myForm.controls["searchbox"].value)) || a.fieldname.includes(lowerCase(this.myForm.controls["searchbox"].value)));
    }
  }

  saveArray() {

    if (this.ModuleType == "1") {


      if (this.FieldsDataArray && this.FieldsDataArray.length && this.FieldsDataArray.length > 0) {
        var duplicate = this.FieldsDataArray.filter((a: any) => a.fieldname == this.selectedKey);
      }
      if (duplicate && duplicate.length && duplicate.length > 0) {

        this.FieldsDataArray.forEach((ele: any) => {
          if (ele.fieldname == this.selectedKey) {
            ele.caption = this.myForm.controls['caption'].value;
            ele.disabled = this.myForm.controls['disabled'].value && this.myForm.controls['disabled'].value == true ? 1 : 0;
            ele.maskingtypeid = this.myForm.controls['maskingtypeid'].value ? this.myForm.controls['maskingtypeid'].value : 0;
            ele.docclientid = this.myForm.controls['userclientid'].value ? this.myForm.controls['userclientid'].value : 0;
          }
        });
      }
      else {
        if (this.selectedKey != undefined) {
          // this.FieldsDataArray = []
          this.FieldsDataArray.push({
            fieldname: this.selectedKey,
            caption: this.myForm.controls['caption'].value,
            disabled: this.myForm.controls['disabled'].value && this.myForm.controls['disabled'].value == true ? 1 : 0,
            maskingtypeid: this.myForm.controls['maskingtypeid'].value ? this.myForm.controls['maskingtypeid'].value : 0,
            doctype: localStorage.getItem('utdoctype'),
            subdoctype: localStorage.getItem('utdocsubtype'),
            docclientid : this.myForm.controls['userclientid'].value ? this.myForm.controls['userclientid'].value : 0,
          });
        }
      }

    }
    else if (this.ModuleType == "2") {
      
      if (this.FieldsDataArray && this.FieldsDataArray.length && this.FieldsDataArray.length > 0) {
        var duplicate = this.FieldsDataArray.filter((a: any) => a.fieldname == this.selectedKey);
      }
      if (duplicate && duplicate.length && duplicate.length > 0) {

        this.FieldsDataArray.forEach((ele: any) => {
          if (ele.fieldname == this.selectedKey) {
            ele.caption = this.myForm.controls['caption'].value;
            ele.disabled = this.myForm.controls['disabled'].value && this.myForm.controls['disabled'].value == true ? 1 : 0;
            ele.docclientid = this.myForm.controls['userclientid'].value ? this.myForm.controls['userclientid'].value : 0;
          }
        });
      }
      else {
        if (this.selectedKey != undefined) {
          this.FieldsDataArray.push({
            fieldname: this.selectedKey,
            caption: this.myForm.controls['caption'].value,
            disabled: this.myForm.controls['disabled'].value && this.myForm.controls['disabled'].value == true ? 1 : 0,
            reportid: localStorage.getItem('reportId'),
            reportcode: localStorage.getItem('reportCodeName'),
            docclientid : this.myForm.controls['userclientid'].value ? this.myForm.controls['userclientid'].value : 0,
          });
        }
      }
    }

    this.PostDocFieldsConfig();

  }

  DocFieldsData: any = [];
  FieldClick(event: any) {

    var EditData;

    if (this.ModuleType == "1") {

      this.DocFieldsData = JSON.parse(localStorage.getItem("DocFields") || '{}');

      if (this.DocFieldsData && this.DocFieldsData.length && this.DocFieldsData.length > 0) {
        this.FieldsDataArray = this.DocFieldsData;
      }
      EditData = this.FieldsDataArray.filter((a: any) => a.fieldname == event);
      if (EditData && EditData.length && EditData.length > 0) {
        this.myForm.controls["caption"].setValue(EditData[0].caption);
        this.myForm.controls["disabled"].setValue(EditData[0].disabled == "True" || EditData[0].disabled == 1 ? 1 : 0);
        this.myForm.controls["maskingtypeid"].setValue(EditData[0].maskingtypeid);
      }
      else {
        this.myForm.controls['caption'].setValue("");
        this.myForm.controls['maskingtypeid'].setValue("");
        this.myForm.controls["disabled"].setValue(0);
      }

    }
    else if (this.ModuleType == "2") {
      this.DocFieldsData = JSON.parse(localStorage.getItem("DocFieldsReport") || '{}');

      if (this.DocFieldsData && this.DocFieldsData.length && this.DocFieldsData.length > 0) {
        this.FieldsDataArray = this.DocFieldsData;
      }
      EditData = this.FieldsDataArray.filter((a: any) => a.fieldname == event);
      if (EditData && EditData.length && EditData.length > 0) {
        this.myForm.controls["caption"].setValue(EditData[0].caption);
        this.myForm.controls["disabled"].setValue(EditData[0].disabled == "True" || EditData[0].disabled == 1 ? 1 : 0);
      }
      else {
        this.myForm.controls['caption'].setValue("");
        this.myForm.controls["disabled"].setValue(0);
      }
    }


    this.selectedKey = event;

  }
    async getDocfields(doctypeid: any, docsubtypeid: number) {

    const dto = await this._utdocfieldsService.getDocfields(doctypeid, docsubtypeid)
    if (dto && dto.IsSuccess) {

      localStorage.setItem("DocFields", JSON.stringify(dto.Data))
    }
  }
  async getDocfieldsReport(reportid: any, reportcode: number) {

    const dto = await this._utdocfieldsReportService.getDocfieldsReport(reportid, reportcode)
    if (dto && dto.IsSuccess) {

      localStorage.setItem("DocFieldsReport", JSON.stringify(dto.Data))
    }
  }
  async PostDocFieldsConfig() {

    let obj: any;
    if (this.FieldsDataArray && this.FieldsDataArray.length && this.FieldsDataArray.length > 0) {

      if (this.ModuleType == '1') {
        
        obj = await this._utdocfieldsService.SaveDocfieldConfig(this.FieldsDataArray);
       let doctype = localStorage.getItem('utdoctype');
       let subdoctype :any = localStorage.getItem('utdocsubtype');
        await this.getDocfields(doctype,subdoctype)
      }
      else if (this.ModuleType == '2') {
        
        obj = await this._utdocfieldsReportService.SaveDocfieldReportConfig(this.FieldsDataArray);
        let reportId = localStorage.getItem('reportId');
        let reportCodeName :any = localStorage.getItem('reportCodeName');
         await this.getDocfieldsReport(reportId,reportCodeName)
      }


      if (obj.IsSuccess) {
        this.dcserror.showErrors("Record saved successfully...", "Success", 2);
      } else {
        this.dcserror.showErrors("There is an Error...!", "Error", 4);
      }
    }
    else {
      this.dcserror.showErrors("No Fields Has Been Configure...!", "Error", 4);
    }

  }

  

  async DeleteArray(){
    
    let obj: any;
    if (this.FieldsDataArray && this.FieldsDataArray.length && this.FieldsDataArray.length > 0) {

      var deletefor = this.FieldsDataArray.filter((a:any) => a.fieldname == this.selectedKey)
      if (this.ModuleType == '1') {
        obj = await this._utdocfieldsService.Deletedocfield(deletefor);
      }
      else if (this.ModuleType == '2') {
        obj = await this._utdocfieldsReportService.DeletedocfieldReport(deletefor);
      }


      if (obj && obj.IsSuccess) {
        var selectedclient = this.myForm.controls["userclientid"].value;
        this.myForm.reset();
        this.myForm.controls["userclientid"].setValue(selectedclient);
        this.dcserror.showErrors("Record successfully delete...", "Success", 2);
      } else {
        this.dcserror.showErrors("There is an Error...!", "Error", 4);
      }
    }
    else {
      this.dcserror.showErrors("No Fields Has Been Configure...!", "Error", 4);
    }
  }

  async customClosed() {
    this.myModal?.close();
  }

  async closeModal() {
    //or this.modalRef.close();
    // if (document.getElementsByClassName('show')) {
    //   var elements:any = document.getElementsByClassName('show');
    //   this.modalRef.backdropClass = undefined
    //   $(".modal-backdrop").remove();

    //   $(".fade").addClass("d-none");

    //   }
    await this.innerClose();

    this.modalService.close(this.modalRef);

    // let invalidEl = document.getElementsByClassName("ng-invalid");
  }


  async getClient() {
    const dto = await this.utclientservice.getAll();
    if (dto && dto.IsSuccess) {
        this.LookupData.utclient = dto.Data;
    }
    else {
        console.log(dto.Errors);
    }
}

  // async openFieldConfig(){
  //   this.FieldConfigModal?.open();
  // }
  private async loadData() {

    if (this.data) {
      this.groups = new List<customButtonList>(this.data)
        .Select((o) =>
          o.grpname === "" || o.grpname === null || o.grpname === undefined
            ? "Options"
            : o.grpname
        )
        .Distinct()
        .ToArray();
    }
  }

  filterData(grp: any): Array<customButtonList> {
    if (grp === "Options") {
      grp = undefined;

      return this.data.filter((o) => o.grpname === grp);
    } else {
      return this.data.filter((o) => o.grpname === grp);
    }
  }

  disabledAction = false;
  async saveAndClose() {
    if (this.screen && !this.disabledAction) {
      this.disabledAction = true;
      const issuccess = await this.screen.save();
      this.disabledAction = false;
      if (issuccess) {
        await this.closeModal();
        // await this.innerClose();
      }
    }
  }

  // Modal side menu working

  isShown: boolean = false;

  dropdownToggle($event: { stopPropagation: () => void }) {
    $event.stopPropagation();
    this.isShown = !this.isShown;
  }
  @ViewChild("modalsidemenu") modalsidemenu?: ElementRef;
  @HostListener("document:click", ["$event"]) DocumentClick(event: Event) {
    if (
      this.modalsidemenu &&
      this.modalsidemenu.nativeElement &&
      this.modalsidemenu.nativeElement.contains(event.currentTarget)
    ) {
      this.isShown = true;
    } else {
      this.isShown = false;
    }
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.modalService.close(this.modalRef);
  }
  outsideClick() {
    this.isShown = false;
  }

  // End

  @Output()
  onOpen: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onClose: EventEmitter<any> = new EventEmitter<any>();

  public triggeronOpen(event: any) {
    this.onOpen.emit(event);
  }

  public triggeronClose(event: any) {
    this.onClose.emit(event);
  }

  innerClose(triggerclose: boolean = true) {
    if (this.lookupSubscription) {
      this.lookupSubscription.unsubscribe();
    }
    if (this.screen && this.screen.service) {
      this.screen.service.clearCondition();
    }
    if (this.screen) {
      this.screen.clear();
    }

    if (triggerclose) {
      this.triggeronClose(this);
    } else {
      if (this.customClose) {
        this.triggeronClose(this);
      }
    }
  }
}
