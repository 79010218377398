import { Injectable } from "@angular/core";
import * as _ from "lodash";

@Injectable()
export class SyncDataService {

  private array: Array<SyncValue> = Array<SyncValue>();
  constructor() { }

  clear() {
    this.array = Array<SyncValue>();
  }

  remove(key: string) {
    let item = this.array.filter((o) => o.key === key)[0];
    if (item) {
      const index = this.array.indexOf(item);
      this.array.splice(index, 1);
    }
  }

  setValue(item: SyncValue) {
    var _oldItem = this.array.find((w) => w.key === item.key);
    if (_oldItem) {
      var index = _.indexOf(this.array, _oldItem);
      this.array.splice(index, 1, item);
    } else {
      this.array.push(item);
    }
  }

  getValue(key: string): SyncValue {
    var result = this.array.filter((o) => o.key === key)[0];

    return result ? result.value : result;
  }

  getSerialized(arg: SyncValue): SyncValue {
    return <SyncValue>JSON.parse(JSON.stringify(arg));
  }
}

export interface SyncValue {
  key: string;
  value: any;
}
