<div *ngIf="ReportDropdown == false">
  <!-- [ngClass]="position === 1 ? '' : _captionWidth" -->
  <label *ngIf="showCaption === true">{{ caption }}</label>
  <span
    *ngIf="(controlEl && controlEl.errors && controlEl.errors?.invalid && (controlEl.dirty || controlEl.touched)) || this.dirtytext"
    style="float: right;">
    <!-- Condition to show Tooltip -->
    <span class="tooltipss" type="button">
      <i class="fa fa-info-circle" aria-hidden="true"></i>
      <span class="tooltiptextss">
        <i class="fas fa-exclamation-triangle" style="color: red;font-size: 12px ;padding-right: 2px ;"></i>
        <!-- errMsg coming from ts file after sett its content-->
        {{errMsg}}
      </span>
    </span>
  </span>
  <!-- [ngClass]="position === 1 ? '' : _fieldWidth" -->
  <div 
    [ngClass]=" (controlEl && controlEl.errors && controlEl.errors?.invalid && (controlEl.dirty || controlEl.touched)) || this.dirtytext  ? 'border-outline-invalid invalid' : 'border-outline-valid'">
    <div *ngIf="AdditionalMenu == true">
      <span (click)="AdditionalMenuDisabled || ClickOnAdd()">
        <i title="{{AdditionalMenuPlaceholder}}" class="fa fa-bars flat-icon search-icon pl-0 input-button" style="    cursor: pointer;
        position: absolute;
        top: 22px;
        z-index: 11;
        right: 40px;"></i>
      </span>
    </div>
    <!-- ()="validate_int($event)" -->
    <input #input="ngModel" id="name" [disabled]="disabled" [style.text-align]="textright == true ?'right' : 'left'" [type]="Type == 'password' ? 'password' : 'text'"
      class="form-control dcs-control" (blur)="onBlur()" [placeholder]="placeholderValue()" [(ngModel)]="value"
      [ngModelOptions]="{standalone: true}" pattern="{{patternOfElement}}" [minlength]="minlength" [ngClass]="AdditionalMenu == true ? 'menuspace' : ''"
      [maxlength]="maxlength" (keypress)="validate_int($event)" (keyup)="masking($event);" autocomplete="off" />
  </div>
</div>

<div *ngIf="ReportDropdown == true">
  <div class="report-control">
    <label class="report-label">{{ caption }}</label>
    <input #input="ngModel" id="name" [disabled]="disabled" [style.text-align]="textright == true ?'right' : 'left'" [type]="Type == 'password' ? 'password' : 'text'"
      class="form-control dcs-control" (blur)="onBlur()" [placeholder]="placeholderValue()" [(ngModel)]="value"
      [ngModelOptions]="{standalone: true}" pattern="{{patternOfElement}}" [minlength]="minlength"
      [maxlength]="maxlength" (keyup)="masking($event)" (keypress)="validate_int($event)" autocomplete="off">
    <span
      *ngIf="(controlEl && controlEl.errors && controlEl.errors?.invalid && (controlEl.dirty || controlEl.touched)) || this.dirtytext"
      style="float: right;">
      <!-- Condition to show Tooltip -->
      <span class="tooltipss" type="button">
        <i class="fa fa-info-circle" aria-hidden="true"></i>
        <span class="tooltiptextss">
          <i class="fas fa-exclamation-triangle" style="color: red;font-size: 12px ;padding-right: 2px ;"></i>
          <!-- errMsg coming from ts file after sett its content-->
          {{errMsg}}
        </span>
      </span>
    </span>
  </div>
</div>
