import { Component, ElementRef, forwardRef, Input, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { ControlBase } from '../../../@constant/config';
import * as moment from 'moment';
import { LocalStorage } from '../../helper/localStorage';
import { GenEnum } from '../../../@constant/general.enum';

@Component({
  selector: 'dcs-date',
  templateUrl: 'dcs-date.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DcsDateComponent),
      multi: true
    },
    {
      // Is an InjectionToken required by this class to be able to be used as an Validator
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DcsDateComponent),
      multi: true,
    },
    {
      provide: ControlBase,
      useExisting: DcsDateComponent
    }
  ]
})
export class DcsDateComponent extends ControlBase implements ControlValueAccessor, Validator {
  selectedItem: any[] = [];
  errorCaption: any;
  ErrorsLog: any = [];  // Errorlogs in JSON format
  DocFieldsData: any = [];
  DocFieldsReportData: any = [];
  @Input()
  formControlName: string | any;
  public ErrorDetail: any = this.localStorage.get("Errorlogs"); // ErrorDetails coming from localstorage
  controlEl: AbstractControl | any;
  errMsg: String | any;
  constructor(private element: ElementRef, private cf: ChangeDetectorRef,
    // private renderer: Renderer
    private localStorage: LocalStorage
  ) {
    super();
  }
  DefaultProperties: any = {};
  async ngOnInit() {

    // Default Modifications
    this.DefaultProperties = {};
    this.DefaultProperties.caption = this.caption;
    this.DefaultProperties.disabled = this.disabled;
    // Default Modifications

    if (this.ReportDropdown == true) {
      this.DocFieldsReportData = JSON.parse(this.localStorage.get("DocFieldsReport"));
      if (this.DocFieldsReportData && this.DocFieldsReportData.length && this.DocFieldsReportData.length > 0) {
        var isConfig = this.DocFieldsReportData.filter((dt: any) => dt.fieldname == this.formControlName)[0];
        if (isConfig && isConfig.caption) {
          if (this.caption.includes("*")) {
            this.caption = isConfig.caption + "*";
          }
          else {
            this.caption = isConfig.caption;
          }
        }
        if (isConfig && isConfig.disabled == "True") {
          this.disabled = true;
        }
        else {
          this.disabled = false;
        }
      }
    }
    else if (this.ReportDropdown == false) {
      this.DocFieldsData = JSON.parse(this.localStorage.get("DocFields"));
      if (this.DocFieldsData && this.DocFieldsData.length && this.DocFieldsData.length > 0) {
        var isConfig = this.DocFieldsData.filter((dt: any) => dt.fieldname == this.formControlName)[0];
        if (isConfig && isConfig.caption) {
          if (this.caption.includes("*")) {
            this.caption = isConfig.caption + "*";
          }
          else {
            this.caption = isConfig.caption;
          }
        }
        if (isConfig != undefined) {
          if (isConfig && isConfig.disabled == "True") {
            this.disabled = true;
          }
          else {
            this.disabled = false;
          }
        }
      }
    }

    // Setup Form Control Properties 
    var item: any = [{
      field: this.formControlName,
      caption: this.caption,
      type: GenEnum.ControlType.DateControl,
      controltype: this.type,
    }];
    var old = this.localStorage.get('ControlProperties');
    if (old === "null" || old === null || old == undefined || old == "undefined") {
      this.localStorage.set('ControlProperties', JSON.stringify(item));
    } else {
      old = JSON.parse(old);
      var dupli = old.filter((a: any) => a.field == this.formControlName);
      if (dupli.length == 0) {
        old.push({
          field: this.formControlName,
          caption: this.caption,
          type: GenEnum.ControlType.DateControl,
          controltype: this.type,
        });
        this.localStorage.set('ControlProperties', JSON.stringify(old));
      }
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    // Field Configuration Algorithem 
    setTimeout(async () => {
      
      await this.FieldCOnfigurationAlgorithm();
    }, 0);
    // Field Configuration Algorithem

    // this.dirtytext = false
    this.controlEl = control
    // if ((this.innerValue === null) && this.dcsTextBox && control.errors && control.errors.required ) {

    if ((this.innerValue === null || this.innerValue === "" || this.innerValue === undefined) && control.errors && control.errors.required) {
      this.errMsg = this.getErrorDescrption(this.cleanCaption())
      // this.isInvalid = true
      return { invalid: true }
    }
    else {
      // this.errMsg = null
      // this.isInvalid = false
    }
    setTimeout(() => {
      this.cf.detectChanges() // line added
    }, 10);
    return null;
  }
  cleanCaption() {
    if (this.caption.slice(-1) === "*") {
      return this.errorCaption = this.caption.slice(0, -1)
    }
    else {
      return this.errorCaption = this.caption
    }
  }
  // Error description set on the basis of errorcode
  getErrorDescrption(caption: any) {

    var errordescription
    this.ErrorsLog = JSON.parse(this.ErrorDetail)
    if (this.ErrorsLog && this.ErrorsLog.length && this.ErrorsLog.length > 0)
      this.ErrorsLog.forEach((element: any) => {

        if (element.code == "0002") {
          errordescription = element.stxt.replace("{0}", caption)
        }
      });

    return errordescription
  }

  public triggerChanged() {
    let event = new CustomEvent("change", { bubbles: true });
    // this.renderer.invokeElementMethod(
    //   this.element.nativeElement,
    //   "dispatchEvent",
    //   [event]
    // );
  }

  private innerValue: any = "";

  private onTouchedCallback!: () => void;
  private onChangeCallback!: (_: any) => void;
  @Input()
  ReportDropdown: boolean = false;
  get value(): any {
    const rtnVal: string = moment(this.innerValue).format(
      this.type === "month" ? "YYYY-MM" : "YYYY-MM-DD"
    );
    return rtnVal;
  }
  @Input()
  showCaption: boolean = true;
  @Input()
  set value(v: any) {

    if (v !== this.innerValue && v !== null) {
      this.innerValue = v;

      this.onChangeCallback(v);
    }
  }

  onBlur() {
    var requiredField
    // check whether the control is required or not
    if (this.controlEl && this.controlEl.errors && this.controlEl.errors.required) {
      requiredField = this.controlEl.errors.required
    }
    if ((this.innerValue === null || this.innerValue === "" || this.innerValue === undefined) && requiredField) {
      this.errMsg = this.getErrorDescrption(this.cleanCaption())
      // this.dirtytext = true
    }
    else {
      this.errMsg = null
    }
    this.onTouchedCallback();

    this.onDateSelected.emit();
  }

  writeValue(value: any) {
    if (!value) {
      this.innerValue = "";
    } else if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  @Input()
  caption: any;

  @Input()
  disabled: boolean = false;

  @Input()
  type: string = "date";
  @Input()
  minDate: string = "";
  @Input()
  maxDate: string = "";

  @Input()
  position: number = 1;

  @Input()
  required: boolean = false;

  public _fieldWidth = "input-group col-lg-3 col-md-3 col-sm-9 col-xs-9 p-0";

  @Input()
  get fieldWidth(): string {
    return this._fieldWidth;
  }
  set fieldWidth(value: string) {

    if (value && value.indexOf(",") !== -1) {
      let colSplit = value.split(",");

      if (colSplit && colSplit.length === 4) {
        this._fieldWidth =
          "input-group col-lg-" +
          (colSplit[0] ? colSplit[0] : "3") +
          " col-md-" +
          (colSplit[1] ? colSplit[1] : "3") +
          " col-sm-" +
          (colSplit[2] ? colSplit[2] : "3") +
          " col-xs-" +
          (colSplit[3] ? colSplit[3] : "3") +
          " p-0";
      }
    }
  }

  public _captionWidth = "col-lg-1 col-md-1 col-sm-3 col-xs-3 col-form-label";

  @Output()
  onDateSelected: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  get captionWidth(): string {
    return this._captionWidth;
  }
  set captionWidth(value: string) {
    if (value && value.indexOf(",") !== -1) {
      let colSplit = value.split(",");

      if (colSplit && colSplit.length === 4) {
        this._captionWidth =
          "col-lg-" +
          (colSplit[0] ? colSplit[0] : "1") +
          " col-md-" +
          (colSplit[1] ? colSplit[1] : "1") +
          " col-sm-" +
          (colSplit[2] ? colSplit[2] : "3") +
          " col-xs-" +
          (colSplit[3] ? colSplit[3] : "3") +
          " col-form-label p-0";
      }
    }
  }
  // currentdate(event: any)
  // {
  //   
  //   
  // }
  onKeyDown(e: any) {
    if (e.ctrlKey && e.keyCode == 114) {
      var currentdate = new Date();
      this.value = moment(currentdate).format("YYYY-MM-DD");
    }
  }
  async FieldCOnfigurationAlgorithm() {
    
    var AccessKey = this.localStorage.get("AccessKey");
    if (this.ReportDropdown != true) {
      this.DocFieldsReportData = JSON.parse(this.localStorage.get("DocFieldssingle"));
      if (this.DocFieldsReportData && this.DocFieldsReportData.length && this.DocFieldsReportData.length > 0) {
        var isConfig = this.DocFieldsReportData.filter((dt: any) => dt.fieldname == this.formControlName && dt.rightid == AccessKey)[0];
        if (isConfig) {
          if (isConfig && isConfig.caption) {
            if (this.caption.includes("*")) {
              this.caption = isConfig.caption + "*";
            }
            else {
              this.caption = isConfig.caption;
            }
          }
          // if (isConfig && isConfig.maskingtypeid) {
          //   this.type = "cprid";
          // }
          if (isConfig && (isConfig.disabled == true || isConfig.disabled == 'true')) {
            this.disabled = true;
          }
          else {
            this.disabled = false;
          }
        }
        else {
          if (this.caption && this.caption != "" ? (this.DefaultProperties.caption != this.caption) : false) {
            await this.SetDefaultStates();
          }
        }

      }
      else {
        if (this.caption && this.caption != "" ? (this.DefaultProperties.caption != this.caption) : false) {
          await this.SetDefaultStates();
        }
      }
    }
  }

  async SetDefaultStates() {
    this.caption = this.DefaultProperties.caption && this.DefaultProperties.caption != '' ? this.DefaultProperties.caption : this.caption;
    this.disabled = this.DefaultProperties.disabled;
  }
}
