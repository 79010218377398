import { ToolbarEvent } from "./../../events/toolbar.event";
import { Component, Input, OnInit } from "@angular/core";
import { customButtonList } from "src/app/@constant/config";
import { List } from "linqts";

@Component({
  selector: "app-api-action",
  templateUrl: "./api-action.component.html",
  styleUrls: ["./api-action.component.scss"],
})
export class ApiActionComponent implements OnInit {
  @Input()
  showButtonList: boolean = false;

  @Input()
  showAdd = true;

  @Input()
  showDelete = true;

  groups: any = [];

  private data = {} as [customButtonList];

  @Input()
  set customButtonList(value: any) {
    this.data = value;
    this.loadData();
  }
  get customButtonList() {
    return this.data;
  }

  constructor(public toolbarEvent: ToolbarEvent) {}


  private async loadData() {
    if (this.data) {
      this.groups = this.data
    }
  }

  filterData(grp: any): Array<customButtonList> {
    if (grp === "Options") {
      grp = undefined;
      return this.data.filter((o) => o.grpname === grp);
    } else {
      return this.data.filter((o) => o.grpname === grp);
    }
  }

  ngOnInit() 
  {}
}
