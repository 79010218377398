import { AfterViewInit, Component, ViewChild, ViewContainerRef } from "@angular/core";
import { ICellEditorAngularComp } from "ag-grid-angular";
import { moodConfig } from "../../../../../@constant/config";
import { List } from "linqts";

@Component({
  selector: "moodeditor-cell",
  template: `
  
      <div #container class="addition-btn-new addtional-button dropdown-menu-new  grid-menu-mood mood " tabindex="0" (keydown)="onKeyDown($event)">
      
      <div *ngFor="let grp of groups"
          class="panel panel-default"
          style="  
          margin-top: 0 !important;
        "
        >
        <div class="panel-heading px-2 py-1">
        <h4 class="panel-title">
      {{grp}}
        </h4>
       
        </div>
        
        <div class="panel-collapse collapse in">
          <div class="panel-body">
          <div *ngFor="let item of filterData(grp)" style="margin-bottom: 5px">
            <a
            class="dropdown-item px-2 py-1"
            type = "button"
            [id]="item[valueMember]"
            (click)="onClick($event.target.id)"
            
            >{{
              item[displayMember] ? "  " + item[displayMember] : ""
            }}</a>
          </div>
          </div>
        
        </div>
        </div>
      </div>
    `,
  styles: [
    `
        .mood {
          // border-radius: 15px;
          // border: 2px solid #287fc4;
          // background: #e6e6e6;
          // padding: 3px 0 0 0;
          // text-align: left;
          // display: inline-block;
          // outline: none;
          // background-color: white;
          font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial,
            sans-serif;
            
         
            padding-top: 0px;
           
        }
  
        i {
          margin: 3px;
          padding: 3px;
          color: #287fc4;
          background-color: transparent;
          font-size: 12px;
        }
  
        // i span {
        //   font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial,
        //     sans-serif;
        //   font-weight: 600;
        // }
  
        i:hover {
          cursor: pointer;
          color: #fff;
          background-color: #1764a1;
        }
  
        // i:hover span {
        //   color: #fff !important;
        // }
        .boxWrapper {
          min-height: auto;
          max-width: 180px;
        }
  
        .selected {
          color: #fff;
          background-color: #1764a1;
        }
        .default {
          color: black;
          background-color: transparent;
        }
      `
  ]
})
export class MoodEditorComponent
  implements ICellEditorAngularComp, AfterViewInit {
  private params: any;

  @ViewChild("container", { read: ViewContainerRef }) public container: any;
  private data: moodConfig[] = [];
  private valueMember: any;
  private displayMember: any;
  private column: any;
  public value: any;
  groups: any = [];

  private async loadData() {
    this.valueMember = this.column.colDef.dataValueField;
    this.displayMember = this.column.colDef.dataDisplayField;
    this.data = this.column.colDef.dropdownData;

    //  this.groups = new List<moodConfig>(this.data).Select(x => <moodConfig>{
    //    MoodGroup : x['MoodGroup']
    // }).Distinct().ToArray();

    this.groups = new List<moodConfig>(this.data)
      .Select(o => o.MoodGroup === "" || o.MoodGroup === null || o.MoodGroup === undefined ? "Options" : o.MoodGroup)
      .Distinct()
      .ToArray();

  }

  // dont use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
  ngAfterViewInit() {
    //this.container.element.nativeElement.focus();
  }
  async agInit(params: any) {

    this.params = params;
    this.value = this.params.value;
    this.column = this.params.column;
    params.value = undefined;
    await this.loadData();
    // this.setClass(this.value);
    var $ = jQuery;
    var tab = $(".ag-popup-editor")[0];
    if (tab) {
      var top = tab.style.top;
      var splittop = Number(top.split('px')[0]);
      var db = splittop + 17;
      tab.style.top = db + "px";

    }
  }

  getValue(): any {
    return this.value;
  }

  isPopup(): boolean {
    return true;
  }

  toggleMood(): void {
    this.value = this.params.value;
  }

  onClick(value: any) {
    
    this.value = value;
    this.setClass(value);
    this.params.api.stopEditing();
    if (typeof this.params.colDef.onClickMood === "function") {
      this.params.colDef.onClickMood(this.params);
    }

  }

  onKeyDown(event: any): void {
    let key = event.which || event.keyCode;
    if (
      key == 37 || // left
      key == 39
    ) {
      // right
      this.toggleMood();
      event.stopPropagation();
    }
  }

  filterData(grp: any): Array<moodConfig> {

    if (grp === "Options") {

      grp = "";
      return this.data.filter(o => o.MoodGroup === grp);
    }
    else {

      return this.data.filter(o => o.MoodGroup === grp);
    }

  }

  private setClass(id: any) {
    if (id && !Boolean(this.column.colDef.isMenu)) {
      var tags: NodeListOf<
        HTMLElement
      > = this.container.element.nativeElement.getElementsByTagName("a");
      for (var index = 0; index < tags.length; index++) {
        if (Number(tags[index].id) === Number(id)) {
          tags[index].classList.add("selected");
        } else {
          tags[index].classList.add("default");
        }
      }
    }
  }
}