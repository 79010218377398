// Angular Imports
import { NgModule } from '@angular/core';

// This Module's Components
import { DcsDashboardheaderComponent } from './dcs-dashboardheader.component';
import { DownloadService } from 'src/app/dashboards/download.service';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        DcsDashboardheaderComponent,
    ],
    exports: [
        DcsDashboardheaderComponent,
    ],
    providers: [
        DownloadService
    ]
})
export class DcsDashboardheaderModule {

}
