<div class="modal-header with-border p-0 m-0">
  <h5 style="padding: 3px; font-weight: bold" class="modal-title">
    Select Dimension
  </h5>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form [formGroup]="myForm">
  <div class="row">
    <div class="div-toolbar">
      <button class="btn-group" style="padding-left: 14px !important;" (click)="saveClose()">
        <i class="fa fa-gear"></i>&nbsp;Save & Close
      </button>
    </div>
  </div>
  <!-- <div class="box box-default">
    <div class="box-body"> -->
      <div id="collapseOne" class="box-collapse collapse in box-body">
        <div *ngFor="let item of config" class="row" style="height: 25px;">
          <div *ngIf="item.statusid !== 23">
            <div class="form-group-cust">
              <dcs-textbox
                [caption]="item.caption"
                *ngIf="item.fieldtypeid === 16"
                [formControlName]="item.fieldname"
                [disabled]="item.statusid === 21 ? false : true"
                [required]="item.ismandatory"
                [position]="2"
                [fieldWidth]="item.fieldwidth"
                [captionWidth]="item.capwidth"
              ></dcs-textbox>
              <dcs-date
                [caption]="item.caption"
                *ngIf="item.fieldtypeid === 17"
                [formControlName]="item.fieldname"
                [disabled]="item.statusid === 21 ? false : true"
                [required]="item.ismandatory"
                [position]="2"
                [fieldWidth]="item.fieldwidth"
                [captionWidth]="item.capwidth"
              ></dcs-date>
              <dcs-dropdown
                [type]="2"
                [Id]="item.Id"
                [caption]="item.stxt"
                [formControlName]="item.code"
                [fieldName]="item.code"
                [data]="LookupData[item.code]"
                [config]="item.utlisterconfigdtls"
                [valueMember]="item.valuemember"
                [displayMember]="item.displaymember"
                (change)="valueChange(item.code)"
                [required]="item.ismandatory"
                [position]="2"
                [fieldWidth]="item.fieldwidth"
                [captionWidth]="item.capwidth"
              >
              <!-- (change)="valueChange(item.fieldname)" -->
              </dcs-dropdown>
              <dcs-numeric
                [caption]="item.caption"
                *ngIf="item.fieldtypeid === 19"
                [formControlName]="item.fieldname"
                [disabled]="item.statusid === 21 ? false : true"
                [required]="item.ismandatory"
                [position]="2"
                [fieldWidth]="item.fieldwidth"
                [captionWidth]="item.capwidth"
              ></dcs-numeric>
              <dcs-checkbox
                [caption]="item.caption"
                *ngIf="item.fieldtypeid === 20"
                [formControlName]="item.fieldname"
                [disabled]="item.statusid === 21 ? false : true"
                [required]="item.ismandatory"
                [position]="2"
              >
              </dcs-checkbox>
              <dcs-time
                [caption]="item.caption"
                *ngIf="item.fieldtypeid === 26"
                [formControlName]="item.fieldname"
                [disabled]="item.statusid === 21 ? false : true"
                [required]="item.ismandatory"
                [position]="2"
                [fieldWidth]="item.fieldwidth"
                [captionWidth]="item.capwidth"
              ></dcs-time>
            </div>
          </div>
        </div>
      </div>
    <!-- </div>
  </div> -->
</form>


