import { DcsCheckboxComponent } from './dcs-checkbox.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [DcsCheckboxComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  exports: [DcsCheckboxComponent],
})
export class DcsCheckboxModule { }
