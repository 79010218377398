import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { GridcolorComponent } from "./color.component";

@NgModule({
  imports: [FormsModule, CommonModule, ReactiveFormsModule],
  exports: [GridcolorComponent],
  declarations: [GridcolorComponent],
})
export class colorModule { }
