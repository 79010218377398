import { GridCheckBoxComponent } from "../dcs-aggrid/controls/checkbox-component/checkbox.component";
import { DCSAggridComponent } from "../dcs-aggrid/dcs-aggrid.component";
import { ModalComponent, ModalManager } from "ngb-modal";
import {
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { LookupConfiguration, LookupResult } from "./dcs-lookup.config";
import { AppConstants } from "../../../@constant/app.constant";
import * as moment from "moment";
import { Broadcast } from "./../../events/broadcast";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { type, customButton } from '../../../@constant/config';
import { DcsDropdownComponent } from '../dcs-dropdown/dcs-dropdown.component';
import { Data } from '@angular/router';
declare var $: any;

@Component({
  selector: "dcs-lookup",
  templateUrl: "dcs-lookup.component.html",
})
export class DcsLookupComponent {
  protected broadcast!: Broadcast;
  customButtongrid: any;

  @ViewChild("modal")
  public modal?: ModalComponent;
  @ViewChild("modal")
  public nativemodal: any;
  @Input()
  public Config: LookupConfiguration[] = [];
  @Input()
  public Data: any[] = [];
  @Input()
  public Caption: String = "";
  @Input()
  public LookupId: String = "";

  @Input()
  public displayMember: string = "";

  @Input()
  public valueMember: string = "";

  @Input()
  public type: number = 1;

  @Input()
  public Gridtype?: number = 4;

  @Input() totalrecord: number = 0;

  @Input() defaultOrderby: boolean = true;

  @Output() selectedRow = new EventEmitter<any>();

  private dateFormat: string = AppConstants.settings.dateFormatforString;
  private timeFormat: string = AppConstants.settings.timeFormatforString;
  @ViewChild("lookupGrid", { read: ViewContainerRef }) container: any;
  componentRef: any;
  public columns: any = [];
  modalRef: any;

  // @ViewChild(DCSAggridComponent) lookupGrid?: DCSAggridComponent | any;
  @ViewChild('agGrid') LookupGrid?: DCSAggridComponent | any;

  constructor(
    private resolver: ComponentFactoryResolver,
    private modalService: ModalManager,
    public activeModal: NgbActiveModal,
  ) {
    this.broadcast = AppConstants.injector.get(Broadcast);
  }

  async ngOnInit() {

    var caption = this.Caption ? this.Caption.replace("*", "") : this.Caption;
    this.Caption = caption;
    if (this.type == 2) {
      $(document).ready(function () {
        let modalContent: any = $(".modal-content");
        let modalHeader = $(".modal-header");
        modalHeader.addClass("cursor-all-scroll");
        modalContent.draggable({
          handle: ".modal-header",
        });
      });
      this.setColumns();
    }

    if (this.Gridtype == 2) {
      this.customButtongrid = <customButton[]>[
        {
          name: "btnselect",
          caption: "Select",
          icon: "fa fa-check"
        }
      ];
    }
  }

  async toolbarclickgrid(item: customButton) {

    if (item.name === "btnselect") {
      this.valueMember
      this.LookupGrid
      this.selectForMulti(item);
    }
  }
  async drag() {
    $(".modal-dialog").draggable({
      handle: ".modal-header",
    });
    $("modal-dialog").css({ top: 0, left: 0, right: 0, bottom: 0 });
  }

  async setColumns() {
    if (this.Config) {
      var col = [
        {
          headerName: "",
          field: "lookupcol",
          editable: false,
          width: 15,
          cellClass: "grid-filter-remove",
          filter: false,
        },
      ];

      this.Config.forEach((element) => {
        let obj = {
          headerName: element.caption,
          field: element.name,
          width: element.width ? Number(element.width.replace("px", "")) : 200,
          cellClass: "grid-column",
          filter: true,
          floatingFilter: true,
          cellRendererFramework:
            element.type === "checkbox" ? GridCheckBoxComponent : undefined,
          valueFormatter: (data: any) => {
            if (element.type && element.type === "date" && data && data.value) {
              return moment(data.value).format(this.dateFormat);
            } else if (
              element.type &&
              element.type === "time" &&
              data &&
              data.value
            ) {
            }
          },

          editable: false,
        };
        col.push(obj);
      });

      if (col) {
        var data = col[1];
        if (data) data.cellClass = "grid-first-column";
      }
      this.columns = col;
      if (this.Data) {
          this.totalrecord = this.Data.length;
        }
    }
  }

  @Output()
  onListerClose: EventEmitter<any> = new EventEmitter<any>();

  async open() {

    this.setColumns();
    // setTimeout(async () => {
    //   this.container.clear();
    //   const factory: ComponentFactory<any> =
    //     this.resolver.resolveComponentFactory(DCSAggridComponent);
    //   this.componentRef = this.container.createComponent(factory);
    //   this.componentRef.instance.hideSelection = false;
    //   this.componentRef.instance.hideSerial = false;
    //   this.componentRef.instance.hideAdd = true;
    //   this.componentRef.instance.hideDelete = true;
    //   this.componentRef.instance.hideRecord = true;
    //   this.componentRef.instance.columns = this.columns.map((o: any) => ({
    //     ...o,
    //     filter: o.field === "lookupcol" ? false : true,
    //     floatingFilter: o.field === "lookupcol" ? false : true,
    //   }));

    //   this.componentRef.instance.data = this.Data.sort((a, b) =>
    //     a[this.displayMember].localeCompare(b[this.displayMember])
    //   );
    //   this.componentRef.instance.pagination = true;
    //   this.componentRef.instance.pageSize = "15";
    //   this.componentRef.instance.type = 1;
    //   this.componentRef.instance.columnPanel = true;
    //   this.componentRef.instance.isExport = true;
    //   await this.componentRef.instance.onRowClicked.subscribe((event: any) => {
    //     this.select(event);
    //   });

    //   await this.drag();

    //   if (this.Data) {
    //     this.totalrecord = this.Data.length;
    //   }
    // }, 0);

    setTimeout(async () => {
      this.modalRef = this.modalService.open(this.modal, {
        size: "lg",
        modalClass: "mymodal",
        hideCloseButton: false,

        centered: false,
        backdrop: true,
        animation: true,
        keyboard: true,
        closeOnOutsideClick: false,
        backdropClass: "modal-backdrop",
      });

      // var api = this.componentRef.instance.options.api;

      // if (api) {
      //   this.componentRef.instance.options.enableSorting = true;
      //   await this.componentRef.instance.changePageSize();
      // }
    }, 0);
  }

  async innerClose() {
    if (this.type == 1) {
      this.modalService.close(this.modalRef);
      this.onListerClose.emit(this);
    } else {
      this.activeModal.dismiss();
      this.onListerClose.emit(this);
    }
  }

  select(event: any) {

    this.broadcast.publish<LookupResult>("lookup", <LookupResult>{
      lookupId: this.LookupId,
      data: event.data,
    });

    this.selectedRow.emit(event);
    this.innerClose();
  }
  selectForMulti(event: any) {
    this.broadcast.publish<LookupResult>("lookup", <LookupResult>{
      lookupId: this.LookupId,
      data: this.LookupGrid.selectedData,
    });
    this.selectedRow.emit(this.LookupGrid.selectedData);
    this.innerClose();
  }
  async setSelectedShow(data: any) {
    
    this.valueMember
    if (data && data.length > 0) {
      data.forEach((ele: any) => {
        ele[this.valueMember] = ele.item_id;
      });
    }
    setTimeout(() => {
      
      if (this.LookupGrid) {
        this.LookupGrid.display(data, this.valueMember);
      }
    }, 0);
  }
}
