import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { GenEnum } from "src/app/@constant/general.enum";
import { DataTransferObject } from "../dto/DataTransferObject";
import { UtService } from "./Ut.Service";

@Injectable({
  providedIn: "root",
})
export class LoginService extends UtService<any> {
  constructor(private router: Router) {
    super("utuser");
  }

  async login(username: string, password: string, clientid: any) {
    const dto: DataTransferObject<any> = <DataTransferObject<any>>{};
    dto.Data = {
      user: {
        username: username,
        password: password,
        userclientid: clientid,
      },
    };

    const result = await this.put("loginV3", dto);
    if (result.IsSuccess) {
      this.localStorage.set("token", `bearer ${result.Data.Session.token} `);

      if (result && result.Data && result.Data.User && result.Data.User.utrole)

        localStorage.setItem(
          "userrole",
          JSON.stringify(result.Data.User.utrole)
        );

    }

    return result;
  }
  async Parentlogin(username: string, password: string, clientid: any) {
    const dto: DataTransferObject<any> = <DataTransferObject<any>>{};
    dto.Data = {
      user: {
        username: username,
        password: password,
        userclientid: clientid,
      },
    };

    const result = await this.put("loginV3", dto);
    if (result.IsSuccess) {
      if (result.Data.User.utuser.usercatid != GenEnum.User_Category.Parent) {
        this.router.navigate(["authentication/parent-login"]);
      } else {

        this.localStorage.set("token", `bearer ${result.Data.Session.token} `);
        if (result && result.Data && result.Data.User && result.Data.User.utrole)
          localStorage.setItem(
            "userrole",
            JSON.stringify(result.Data.User.utrole)
          );

        this.router.navigate(["parentsgateway/guardian"]);
      }


    }

    return result;
  }

  async ChangePassword(data: DataTransferObject<any>) {
    const result = await this.put("ChangePassword", data);
    return result;
  }
  async UpdateEmpActivation(empid: any, userStatus: any) {
    const result = await this.get("UpdateEmpActivation", [empid, userStatus]);
    return result;
  }

  async GetUserEntityData(entities: any) {
    const data = <DataTransferObject<any>>{
      Data: entities,
    };

    const result = await this.post("UserEntityData", data);

    return result;
  }
}
