<div *ngIf="!ReportDropdown">
  <label>{{ caption }}</label>
  <span
    *ngIf="
      (controlEl &&
        controlEl.errors &&
        controlEl.errors?.invalid &&
        (controlEl.dirty || controlEl.touched)) ||
      this.dirtytext
    "
    style="float: right"
  >
    <span class="tooltipss" type="button">
      <i class="fa fa-info-circle" aria-hidden="true"></i>
      <span class="tooltiptextss">
        <i
          class="fas fa-exclamation-triangle"
          style="color: red; font-size: 12px; padding-right: 2px"
        ></i>
        {{ errMsg }}
      </span>
    </span>
  </span>
  <!-- [ngClass]="position === 1 ? '' : _fieldWidth" -->
  <div
    [ngClass]="
      (controlEl &&
        controlEl.errors &&
        controlEl.errors?.invalid &&
        (controlEl.dirty || controlEl.touched)) ||
      this.dirtytext
        ? 'border-outline-invalid invalid'
        : 'border-outline-valid'
    "
  >
    <input
      type="time"
      (focusout)="change($event)"

      (keypress)="keyPress($event)"
      theme="material-blue"
      [disabled]="disabled"
      style="position: relative; height: 23px !important; font-size: 12px"
      class="form-control"
      [(ngModel)]="value"
      (blur)="onBlur()"
    />
  </div>
</div>

<div *ngIf="ReportDropdown">
  <div class="report-control">
    <label class="report-label">{{ caption }}</label>
    <input
      type="time"
      (focusout)="change($event)"

      (keypress)="keyPress($event)"
      theme="material-blue"
      [disabled]="disabled"
      style="position: relative; height: 28px"
      class="report-input form-control dcs-control"
      [(ngModel)]="value"
      (blur)="onBlur()"
    />
  </div>
</div>

<style>
  .pointer-disabled {
    cursor: not-allowed;
  }

  .report-input {
    font-weight: 400;
    line-height: 1.5;
    color: #4f5467;
    font-size: 11px;
    height: 21px;
    border-bottom-left-radius: 0px !important;
    border-radius: 3px;
    border: 1px solid #e2e2e2;
    background: #f3f3f3;
  }
</style>
